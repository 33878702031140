import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
import lensConsultationData from '../../constants/lens-consultation-data.constants';
import pricingBreakdown from '../../constants/pricing-breakdown';
import iLensColor from '../../interface/lens-color.inteface';
import iLensConsultationBrand from '../../interface/lens-consultation-brand.interface';
import iLensConsultationType from '../../interface/lens-consultation-type.interface';
import iLensRecommendationPair from '../../interface/lens-recommendaiton-pair.interface';
import iPriceBreakdown from '../../interface/price-breakdown.interface';
import iProductBrand from '../../interface/product-brand.interface';
import iProductFamily from '../../interface/product-family.interface';
import iProduct from '../../interface/product.inteface';
import iSubProduct from '../../interface/sub-product.inteface';
import LensRecommendation from '../../pages/lens-consultation-v2/components/LensRecommendation';
import ExperienceType from '../../types/experience.type';
import LensFamilyType from '../../types/lens-family.type';
import LensPairType from '../../types/lens-pair.type';
import iLensConsultationStore from '../interface/lens-consultation-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iLensConsultationStore  = {
    activePair: LensPairType.PAIR1,
    isLensThickness: false,
    data: lensConsultationData,
    sliderPage: 0,
    lensType: null,
    lensRecommendation: {},
    lensRecommendationStandard: {},
    lensRecommendationSun: {},
    storedLensRecommendation: {},
    isValidated: false,
    selectedProtect : {},
    lensProducts: {
        correct: {
            product: null,
            subProduct: null
        },
        protect: {
            product: null,
            subProduct: null
        },
        enhance: {
            product: null,
            subProduct: null
        }
    },
    breakdown: pricingBreakdown,
    family:'',
    sliderPageTwo:0,
}

const lensConsultationSlice = createSlice({
    name: 'lensConsultation',
    initialState,
    reducers: {
        setSliderPage: (state, action: PayloadAction<number>) => ( {...state, sliderPage: action.payload} ),
        setSliderPageTwo: (state, action: PayloadAction<number>) => ( {...state, sliderPageTwo: action.payload} ),
        selectLensType: (state, action: PayloadAction<iLensConsultationType | any>) => ( {...state, lensType: action.payload} ),
        selectLensBrand: (state, action: PayloadAction<iLensConsultationBrand>) => ( {...state, lensBrand: action.payload} ),
        selectLensProduct: (state, action: PayloadAction<any>) => ( {...state, lensProducts: action.payload} ),
        setPrefilledDataFromNeedExplorer: (state, action: PayloadAction<any>) => ( {...state, lensRecommendation: action.payload} ),
        setPrefilledDataFromNeedExplorerStored: (state, action: PayloadAction<any>) => ( {...state, storedLensRecommendation: action.payload} ),
        
        changePair: (state, action: PayloadAction<LensPairType>) => ( {...state, activePair: action.payload} ),  
        setIsLensThickness: (state, action: PayloadAction<boolean>) => ( {...state, isLensThickness: action.payload} ),  
        validate: (state, action: PayloadAction<boolean>) => ({
            ...state, 
            isValidated: action.payload,
            dateValidated: new Date().toISOString()
        }),  
        
        removeProduct: (state, action: PayloadAction<{
            lensFamily: LensFamilyType,
            index?: number
        }>) => {
            const { lensFamily, index } = action.payload;

            const currentActivePair: any = state.lensRecommendation && 
                          state.lensRecommendation[state.activePair] ? 
                          state.lensRecommendation[state.activePair] : null;            

            let newFamilyState = null;

            if(lensFamily === LensFamilyType.PROTECT){
                const currentProtects = currentActivePair[lensFamily];
                newFamilyState = currentProtects.filter( (item: any, i: number) => index !== i);
            }

            const newState = {
                ...state,
                lensRecommendation: {
                    ...state.lensRecommendation,
                    [state.activePair]: {
                        ...currentActivePair,
                        [lensFamily]: newFamilyState
                    }
                },
                validOnEPROM: {
                    ...state.validOnEPROM,
                    [state.activePair]: true
                }
            }
            return {
                ...state,                 
                ...newState,
            }
        },  
        selectProduct: (state, action: PayloadAction<{
            lensFamily: LensFamilyType,
            brand?: iProductBrand,
            product?: iProduct,
            subProduct?: iSubProduct,
            manual?: boolean,
            pair?: LensPairType
            experience?: ExperienceType
        }>) => {
            const { lensFamily, brand, product, subProduct, manual, experience } = action.payload;
            const isManual = (manual === null) || (manual === undefined) ? true : manual;
            const pair = action.payload.pair || state.activePair;

            const currentActivePair: any = state.lensRecommendation && 
                          state.lensRecommendation[pair] ? 
                          state.lensRecommendation[pair] : {};

            const currentLensFamily = currentActivePair[lensFamily] || [];
            
            let newLensFamily: any = null;

            if(lensFamily === LensFamilyType.CORRECT) {
                newLensFamily = product ? { brand, product, subProduct } : null;
            }else if(lensFamily === LensFamilyType.ENHANCE) {
                newLensFamily = product ? { brand, product } : null;
            }else {
                if(!product){
                    newLensFamily = null
                } else {
                    newLensFamily = isManual ? [...currentLensFamily, { brand, product }] : [{ brand, product }]
                }
            }

            let newState;
            if(experience === ExperienceType.SUN){
                 newState = {
                    ...state,
                    lensRecommendationSun: {
                        ...state.lensRecommendationSun,
                        [pair]: {
                            ...currentActivePair,
                            [lensFamily]: newLensFamily
                        }
                    }
                }
            }else{
                 newState = {
                    ...state,
                    lensRecommendation: {
                        ...state.lensRecommendation,
                        [pair]: {
                            ...currentActivePair,
                            [lensFamily]: newLensFamily
                        }
                    }
                }
            }
            return {
                ...state,                 
                ...newState,
            }
        },  
        saveSelectProtect: (state, action: PayloadAction<any>)=>{
            // const currentActivePair: any = state.lensRecommendation && 
            // state.lensRecommendation[state.activePair] ? 
            // state.lensRecommendation[state.activePair] : {};
            const newState = {
                ...state,
                selectedProtect: {
                    ...state.selectedProtect,
                    [state.activePair]: action.payload
                }
                
            }
            return {
                ...state,
                ...newState
            }
        },
        selectProductFamily: (state, action: PayloadAction<iProductFamily | null | undefined>) => ( {...state, productFamily: action.payload} ),
        selectProductBrand: (state, action: PayloadAction<iProductBrand>) => ( {...state, productBrand: action.payload} ),        
        setProtectColor: (state, action: PayloadAction<{
            color:iLensColor,
            index: number,
        }>) => {
            const currentActivePair: any = state.lensRecommendation && 
                          state.lensRecommendation[state.activePair] ? 
                          state.lensRecommendation[state.activePair] : {};
            
            const newState = {
                ...state,
                lensRecommendation: {
                    ...state.lensRecommendation,
                    [state.activePair]: {
                        ...currentActivePair,
                        protect: currentActivePair.protect.map( (x: any, index: number) => {
                            if(index === action.payload.index) {
                                return {
                                    ...x,
                                    color: action.payload.color,
                                }
                            }
                            return x;
                        })
                    }
                }
            }

            return { ...state, ...newState }
        },        
        saveLensRecommendationDataFromStore: (state , action: PayloadAction<{
                pair1?: iLensRecommendationPair,
                pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendation: action.payload
        }),
        saveLensRecommendationStandard: (state , action: PayloadAction<{
            pair1?: iLensRecommendationPair,
            pair2?: iLensRecommendationPair
    }>) => ({
        ...state,
        lensRecommendation: action.payload
    }),
    saveLensRecommendationSun: (state , action: PayloadAction<{
        pair1?: iLensRecommendationPair,
        pair2?: iLensRecommendationPair
    }>) => ({
        ...state,
        lensRecommendationSun: action.payload
    }),
        resetData: (state) => ({
            ...state, ...initialState, 
            breakdown:state.breakdown,
            lensRecommendation: undefined,
            validOnEPROM: {},
            showWarning:false
        }),
        sessionStart: (state, action: PayloadAction<string | undefined>) => ({...state, sessionStart: action.payload }),
        
        //BREAKDOWN
        setBreakdown: (state, action: PayloadAction<iPriceBreakdown>) => ( {
            ...state, 
            breakdown: action.payload
        }),

        //CURRENCY
        setCurrency: (state, action: PayloadAction<any>) => ( {
            ...state, 
            breakdown: {
                ...state.breakdown,
                currency: action.payload
            }
        }),
        setValidOnEPROM: (state, action: PayloadAction<boolean>) => ({
            ...state,
            validOnEPROM: {
                ...(state.validOnEPROM || {}),
                [state.activePair]: action.payload
            }
        }),
        setFamily: (state, action: PayloadAction<string>) => ({
            ...state,
            family: action.payload
        }),
        clearSecondPair: (state, action: PayloadAction<boolean>) => ({
            ...state,
            lensRecommendation:{
                ...state.lensRecommendation,
                pair2: !action.payload ? undefined : state.lensRecommendation?.pair2
            },
            showDialogue:!action.payload,
            showWarning:action.payload
        }),
        setShowDialogue: (state, action: PayloadAction<boolean>) => ({
            ...state,
            showDialogue:action.payload
        }),
        setShowWarning: (state, action: PayloadAction<boolean>) => ({
            ...state,
            showWarning:action.payload
        })
    }
});

export const getLensConsultation = (state:iRootState) => state.lensConsultation;
export default lensConsultationSlice;