import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import { KIDS_THEMES } from '../../../constants/theme.constants';
import iMeasurementDataOne from '../../../interface/measurement-one-data.inteface';
import iMeasurementDataThree from '../../../interface/measurement-three-data.inteface';
import iMeasurementDataTwo from '../../../interface/measurement-two-data.inteface';
import { getApp } from '../../../store/slices/app.slice';
import measureSlice, { getMeasure } from '../../../store/slices/measure.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import ExperienceType from '../../../types/experience.type';

interface Props {
    // TEMP
    empty?:boolean,

    one?: iMeasurementDataOne,
    two?: iMeasurementDataTwo,
    three?: iMeasurementDataThree,
    index: number,
    experience: ExperienceType
}

const MeasureDataCard : React.FC<Props>  = ({
    empty = false,
    one,
    two,
    three,
    index,
    experience
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('measure-data-card');
    const dispatch = useDispatch();
    const { ageCategory } = useSelector(getUser);
    const { generateRandomNumbers, getThemeVariables } = Utils();
    const themes = KIDS_THEMES;
    const { theme } = useSelector(getApp);
    const { t } = useTranslation(useSelector(getApp));
    const pairTranslationKeys = ['measures_mes__3_pair_1_new', 'delivery_del_3_pair_2']
    const { breakdown } = useSelector(getLensConsultation);
    const {activeDataIndex, isIvsGenerated} = useSelector(getMeasure)
    // HOOKS

    // METHODS
    const onEdit = () => {
        dispatch(measureSlice.actions.changeView('measure'));
        dispatch(measureSlice.actions.changeStep(0));
        dispatch(measureSlice.actions.editPair(index));
        
    }

    const onAdd = () => {
        dispatch(measureSlice.actions.changeView('measure'));
        dispatch(measureSlice.actions.changeStep(0));
        dispatch(measureSlice.actions.addPair());
    }

    const getAge = () => {
        const age = ageCategory?.value;
        return age ? age : ''
    }

    const themeStyle = () => {
        if(!theme || theme.id !== 'multi')return {};
        const randomNumbers = generateRandomNumbers(themes.length - 1);
        const randomTheme = themes[randomNumbers[0]];
        return getThemeVariables(randomTheme)
    }

    const aAndB = () => {
        const sum = (one?.a.value || 0) + (one?.b.value || 0);
        return sum.toFixed(2);
    }

    return (
        <div className={B( [empty ? 'empty' : '', experience] )}>
            <div className={E('head')}>
                <label>{t(pairTranslationKeys[index])}</label>
                <span>{`${one?.price.value} ${breakdown.currency}`}</span>
            </div>

            <div className={E('content')}>
                    <div className={E('data', 'one')}>      
                        <label className={E('data-group')}>
                            {t('pair_1_tab_del_p1__measure_1')}    
                        </label>    
                        <div className={E('data-field')}>                                                                              
                            <label className={E('data-field','one-label')}>{t('measures_mes_a_▭_b')}</label>
                            <span>{aAndB()} mm</span>                                

                            <label>{t('measures_mes_d')}</label>
                            <span>{one?.d.value} mm</span> 
                        </div>                               
                    </div>

                    <div className={E('data', 'two')}>                                
                        <label className={E('data-group')}>
                            {t('pair_1_tab_del_p1_measure_2')}        
                        </label>       

                        <div className={E('data-field')}>
                            <label>{t('measurement_left_eye_pd')}</label>
                            <span>{two?.left.pd.value} mm</span>                                
                        </div>
                        <div className={E('data-field')}>
                            <label>{t('measurement_left_eye_hei')}</label>
                            <span>{two?.left.height.value} mm</span>                                
                        </div>
                        <div className={E('data-field')}>
                            <label>{t('measurement_right_eye_pd')} </label>
                            <span>{two?.right.pd.value} mm</span>                                
                        </div>
                        <div className={E('data-field')}>
                            <label>{t('measurement_right_eye_hei')}</label>
                            <span>{two?.right.height.value} mm</span>                                
                        </div>
                    </div>

                    <div className={E('data', 'three')}>                                
                        <label className={E('data-group')}>
                            {t('pair_1_tab_del_p1_measure_3')}
                        </label>       

                        <div className={E('data-field')}>
                            <label>{t('measures_mes__3_pantoscopic_angle')}</label>
                            <span>{three?.pantoscopicAngle.value} °</span>                                
                        </div>
                        <div className={E('data-field')}>
                            <label>{t('measures_mes__3_wrap_angle')}</label>
                            <span>{three?.wrapAngle.value} °</span>                                
                        </div>

                        {index === 0 && isIvsGenerated? 
                        <div className={E('data-field')}>
                            <label>{t('measures_mes__3_vertex_distance')}</label>
                            <span>Left:{three?.vertexDistance.ivsValue?.left} | Right:{three?.vertexDistance.ivsValue?.right}mm</span>                                
                        </div> :
                         <div className={E('data-field')}>
                            <label>{t('measures_mes__3_vertex_distance')}</label>
                            <span>{three?.vertexDistance.value}mm</span>                                
                        </div>
                        }

                        <div className={E('data-field')}>
                            <label>{t('measures_mes__3_reading_distance')}</label>
                            <span>{three?.readingDistance.value} cm</span>                                
                        </div>
                    </div>
            </div>{/* CONTENT END*/}
            

            <div className={E('footer',getAge())}
            style={themeStyle()}
             onClick={onEdit}>
                {t('measures_mes__3_edit')} <Icon name="pencil-white"/>
            </div>

            { 
                empty && 
                <Button 
                    click={onAdd}
                    icon="plus" 
                    type="secondary" 
                     
                    circle={true}
                /> 
            }
        </div>
    )
}

export default MeasureDataCard;