import { useState, useEffect } from "react";
import useTranslation from "../../../../utils/hooks/useTranslation";
import useBEM from "../../../../utils/hooks/useBEM";
import { useSelector } from "react-redux";
import { getApp } from "../../../../store/slices/app.slice";
import iNeedExplorerQuestion from "../../../../interface/need-explorer-question.interface";
import Icon from "../../../../components/ui/Icon";

interface Props {
  question: iNeedExplorerQuestion;
  onAnswer?: (answers: number) => void;
}

const Frequency: React.FC<Props> = ({ question, onAnswer }) => {
  const [B, E] = useBEM("frequency");
  const { t } = useTranslation(useSelector(getApp));

  //variables
  const frequencyTypes = [
    {
      name: 1,
      choices: [
        "Never",
        "Less than once a month",
        "Once a month",
        "Once a week",
        "More than two times a week",
        "Everyday",
      ],
    },
    {
      name: 2,
      choices: [
        "Less than once a year",
        "Less than once a month",
        "At least once a month",
        "At least once a week",
      ],
    },
    {
      name: 3,
      choices: ["Never", "At least once a year", "More than once a year"],
    },
    {
      name: 4,
      choices: ["1", "2", "3", '4', '5', '6', '7', '8', '9', '10+'],
    },
    {
      name: 5,
      choices: ['Yes', 'No'],
    },
  ];

  //hooks
  const [activeAnswer, setActiveAnswer] = useState<string>("");
  const [width, setWidth] = useState<number>(0);
  const [activeType, setActiveType] = useState<string[]>([]);

  //setActiveType
  useEffect(() => {
    let type = frequencyTypes.find((t) => t.name === question.frequency);
    if (type) {
      setActiveType(type.choices);
    }
  }, [question]);

  //for dyanmic width of the cells
  useEffect(() => {
    if(question.frequency === 4){
      let numberOfCell = activeType.length
      let computedWidth = 100 / numberOfCell;
      setWidth(computedWidth);
    }else setWidth(100)
  }, [activeType]);

  //functions
  const onSelect = (freq: string, i: number) => {
    setActiveAnswer(freq);
    if (onAnswer) onAnswer(i + 1);
  };

  const onSelectClass = (freq: string, i: number) => {
    if (question.answer === i+1) return "selected";
    if (question.answer && i < question.answer) return "skipped";
  };

  const dynamicClass = () => {
   return  question?.frequency === 4 ? "content-choices-flat" : "content-choices"
  }

  const classExtender = (frequency: any) => {
    switch(frequency){
      case 1:
        return '-1'
      case 2:
        return '-2'
      case 3:
        return '-3'
      case 5:
        return '-5'
      default:
        return ''
    }
  }

  return (
    <div className={B()}>
      <div className={E("content")}>
        <Icon
          type="image"
          name={question.image || ""}
          className="box-bg"
          background={true}
          position="center"
        />
        <div className={E("content-choices-container")}>
          <div className={E(dynamicClass())}>
            <div className={E( `choices${classExtender(question?.frequency)}`)}>
              {activeType.map((freq, i) => {
                return (
                    <div
                      className={E(`choice${classExtender(question?.frequency)}`, onSelectClass(freq, i))}
                      onClick={() => onSelect(freq, i)}
                      style={{ width: `${width}%` }}
                    >
                      <span>{freq}</span>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequency;
