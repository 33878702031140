import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    diameter?: number,
    background?: string,
    color?: string,
}

const CircleCheck : React.FC<Props>  = ({
    diameter = 1.5,
    background = '#ffffff',
    color = '#333'
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('circle-check');

    // HOOKS

    // METHODS
    const customStyle = () => { 
        const dim = `${diameter}rem`;
        return {
            width: dim,
            height: dim,
            backgroundColor: background,
            color,
            borderColor: color
        }
    }

    return (
        <div className={B()} style={customStyle()}>
            
        </div>
    )
}

export default CircleCheck;