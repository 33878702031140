import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Demonstrations from '../../pages/demonstrations/Demonstrations';
import { getApp } from '../../store/slices/app.slice';
import productDemoSlice from '../../store/slices/products-demo.slice';
import productsSlice, { getProducts } from '../../store/slices/products.slice';
import DemoDownloadType from '../../types/product-download.type';
import useBEM from '../../utils/hooks/useBEM';
import useTranslation from '../../utils/hooks/useTranslation';
import Modal from '../Modal';
import Button from '../ui/Button';
import ProductSelection from './components/ProductSelection';

interface Props {

}

const Products : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('products');
    const { isOpen, view, lensDemo, downloadStatus, downloadCountText } = useSelector(getProducts);
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));

    const modalStyle = {
        width: "100%",
        height: "69.8rem",
        borderRadius: "15px"
    }


    // HOOKS
    useEffect(() => {        
        dispatch(productsSlice.actions.attachedProductDemo({
            demos: lensDemo
        }))
    }, [])

    useEffect(() => {
        if(view === 'selection'){
            dispatch(productDemoSlice.actions.setActiveProductDemo(undefined));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(undefined));
            dispatch(productDemoSlice.actions.changeDemoType(undefined));
            dispatch(productsSlice.actions.selectBrandTodemo(undefined))
        }
    }, [view])

    // METHODS
    const closeProducts = () => {
        dispatch(productsSlice.actions.reset());
    }

    const back = () => {
        dispatch(productsSlice.actions.changeView('selection'));
    }

    const isDownloading = () => {
        return downloadStatus === DemoDownloadType.DOWNLOADING;
    }

    const downloadCount = () => {
        return `${t('demo_downloading')} ${downloadCountText}`;
    }

    return (
        <Modal 
            style={modalStyle}
            noPadding={true}
            show={isOpen}
            onClose={closeProducts}  
            hideCloseButton={isDownloading()}
            closeModalOnOutsideClick={!isDownloading()}
            rightHeaderDetail={downloadCount()}
        >
            <div className={B()}>
                
                <div className={E('head')}>
                    {
                        view === 'demo' &&
                        <Button
                            outlineType="light"
                            text={t('demo_demo__back')} 
                            icon="left-arrow-circle-gray" 
                            iconPosition="left" type="secondary" 
                            click={back}
                        />
                    }
                    
                    { view === 'selection' && <h4>{t('products_prod_products')}</h4> }                    
                </div>

                { view === 'selection' &&  <ProductSelection />}                            
                { view === 'demo' &&  <Demonstrations type="modal"/>}                            
                
            </div>
        </Modal>
    )
}

export default Products;