import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import { getApp } from '../../../store/slices/app.slice';
import ExperienceType from '../../../types/experience.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    contentKey: string,
    buttonLabel: string,
    onAction: (action: string) => void
}

const NoRxFooter : React.FC<Props>  = ({
    contentKey,
    buttonLabel,
    onAction,
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('no-rx-footer');
    const {t} = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS

    return (
        <div className={B()}>
            <ul className={E('page', contentKey)}>
                {
                    contentKey !== 'default' &&
                    <li className={E('back')} onClick={() => onAction('default')}>
                        <CircleArrow type='left' background='#eee'/>
                    </li>
                }
                <li className={contentKey === 'default' ? 'active' : ''}>
                    <span></span>
                </li>
                <li className={contentKey !== 'default' ? 'active' : ''}>
                    <span></span>
                </li>
            </ul>

            <div className={E('buttons')}>
                <Button 
                    text={`${t('sun_exp_no_rx_learn_more', 'Learn More')} ( ${t('education_edu_coming_soon', 'Comming Soon')} )` } 
                    icon={<CircleArrow type='right' background='#eee'/>} type='secondary'
                    experience={ExperienceType.SUN}
                />

                <Button 
                    text={t(buttonLabel)}
                    icon={<CircleArrow type='right'/>}
                    click={() => onAction( contentKey === 'default' ? 'myopia' : 'close' )}    
                    experience={ExperienceType.SUN}
                />
            </div>
        </div>
    )
}

export default NoRxFooter;