import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { setTimeout } from 'timers';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
     hideProgress: (hide:boolean) => any
 }


const ProgressPopup:React.FC<Props> = ({hideProgress}) => {
    //variables
    const [B, E] = useBEM('progress-popup');
    const { t } = useTranslation(useSelector(getApp));
    const [progress, setProgress] = useState(['', 'waiting'])
    const loadingText = {
         first:{
              translate:'sun_equip_configure',
              fallBack:'Your equipment is being configured',
              icon:'acute-ruler'
          },
          second:{
               translate:'sun_equip_ready',
               fallBack:'Your equipment is ready for your lifestyle',
               icon:'shine'
          }
     }
     const {first, second} = loadingText 
     
     //hooks
     const [content, setContent] = useState(first)
     const [changeBarLoading, setChangeBarLoading] = useState(true)

     
     let newList = progress;
    useEffect(() => {
      setTimeout(() => {
          newList[0] = 'done'
          newList[1] = ''
          setChangeBarLoading(!changeBarLoading)
          setProgress(newList)
          setContent(second)
     }, 1500);
     }, []);

     useEffect(() => {
          setTimeout(() => {
              newList[1] = 'done'
              setChangeBarLoading(!changeBarLoading)
              setProgress(newList)
              hideProgress(false)
         }, 1500);
     }, [progress[0]]);


  return (
        <div className={B()}>
                <div className={E('label')}>
                   <div className={E('label-icon')}>
                        <Icon name={content.icon}/>
                   </div>
                   <p className={E('label-text')}>
                        {t(content.translate, content.fallBack)}
                   </p>
                   <div className={E('progress-container')}>
                   <div className="progress-container__bar 1">
                        <div className={`progress-value ${progress[0]}`}></div>
                    </div>
                   <div className="progress-container__bar 2">
                        <div className={`progress-value ${progress[1]}`}></div>
                    </div>
                   </div>
                </div>
        </div>
  )
}

export default ProgressPopup