import React from 'react';
import Icon from '../../../../components/ui/Icon';
import iQuestionOption from "./../../../../interface/question-option.interface";
import iQuestionChoice from '../../../../interface/question-option.interface';
import useBEM from '../../../../utils/hooks/useBEM';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: iQuestionOption | iQuestionOption[]) => void
}

const ChoiceList : React.FC<Props>  = ({
    question,
    onAnswer,
}) =>  {
    // VARIABLES
    const  [B, E] = useBEM('choice-list');
    const { t } =  useTranslation(useSelector(getApp));
    const leftAlignedQuestions = ['1.5', '2.1', '2.4', '4.2', '4.7']
    const normalChoiceListQuestions = ['1.2', '1.5', '2.1', '2.4', '4.2', '4.7']
    const oneColumnQuestions = ['1.2', '8.6']
    const {experience} = useSelector(getApp);

    // HOOKS

    // METHODS
    const getAnswer = () => {
        if(!question.answer)return question.canSelectMultiple ? [] : '';

        if(question.canSelectMultiple) {
            return question.answer as iQuestionOption[];
        }
        return (question.answer as iQuestionOption).value;
    }

    const select = (option: iQuestionChoice) => {
        if(question.canSelectMultiple) {
            const answer = getAnswer() as iQuestionOption[];
            const isExist = answer.filter(c => c.value === option.value);
            let finalAnswer;
            if( isExist.length > 0) {
                finalAnswer = answer?.filter(c => c.value !== option.value);
            } else {
                finalAnswer = [ ...answer, option ]
            }

            if(question.id === '5.2') {
                console.log('5.2')
                finalAnswer = option.value === '5.2.3' ?
                [option] : // one answer only
                finalAnswer.filter(x => x.value !== '5.2.3') // remove the 5.2.3
            }

            if(onAnswer) onAnswer(finalAnswer);
            return;
        }

        if(onAnswer)onAnswer(option)
    }

    const selectedChoiceClassName = (option: iQuestionChoice) => {
        // debugger;
        if( question.canSelectMultiple ) {
            const answer = getAnswer() as iQuestionOption[];
            const isSelected = answer.filter(c => c.value === option.value)
            return [isSelected.length > 0 ? 'selected' : '']
        }
        return [getAnswer() === option.value ? 'selected' : '']
    }

    const renderIconName = (choice: iQuestionChoice) => {
        if(choice.icon){
            return getAnswer() === choice.value ? `${choice.icon}-white` : `${choice.icon}`
        }
        else if(choice.simpleIcon){
            return getAnswer() === choice.value ? `${choice.simpleIcon}-white` : `${choice.simpleIcon}`
        }
        else{
            return ''
        }
    }

    const renderChoiceListType = () => {
        if(!question.options)return '';

        return question.options[0].icon ? 'choice-list-icon' :
            question.options[0].simpleIcon ? 'choice-list-icon-simple' : ''
    }

    return (
        <ul
            className={`
                ${B()} ${renderChoiceListType()}
                ${normalChoiceListQuestions.includes(question.id) ? '-no-min-height' : '-flex'}
                ${oneColumnQuestions.includes(question.id) ? '-one-column' : ''}
                ${`--${experience}`}
            `}>
            {
                question.options && question.options.map(option => (
                    <li
                        key={option.value}
                        className={`${E('item', selectedChoiceClassName(option))} ${experience} ${leftAlignedQuestions.includes(question.id) ? '-align-left' : ''}`}
                        onClick={() => select(option)}>

                        {
                            option.icon || option.simpleIcon ?
                            <Icon type='png'
                            name={renderIconName(option)}
                            className={E('choice')}/> : null
                        }

                        {t(option.label)}
                    </li>
                ))
            }
        </ul>
    )
}

export default ChoiceList;
