import iNeedExplorerCategory from "../interface/need-explorer-category.interface";


const GeneralCategory:iNeedExplorerCategory = {
    id: '1',
    category: 'questionnaires_q_general',
    questions: [
        {
            id: '1.1',
            question: 'general_q_gen_gender',
            kind: 'need_explorer_single_choice_question',
            type:'CHOICE_LIST',
            options: [
                { value: '1.1.1', label: 'general_q_gen_female' },
                { value: '1.1.2', label: 'general_q_gen_male' },
                { value: '1.1.3', label: 'lens_consultation_question_other' }
            ],
            isAnswered: false
        },
        {
            id: '1.2',
            question: 'need_explorer_age',
            kind: 'need_explorer_single_choice_question',
            type:'CHOICE_LIST',
            options: [
                { value: '1.2.1', label: 'general_q_gen_age_18-34' },
                { value: '1.2.2', label: 'general_q_gen_age_35-39' },
                { value: '1.2.3', label: 'general_q_gen_age_40-50' },
                { value: '1.2.4', label: 'general_q_gen_age_51-64' },
                { value: '1.2.5', label: 'general_q_gen_age_65+' }
            ],
            isAnswered: false
        },
        {
            id: '1.3',
            question: 'general_q__gen_vision_solution',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                {
                    value: '1.3.1',
                    label: 'general_q_gen_contact_lenses',
                    image: 'gen_eyes'
                },
                {
                    value: '1.3.2',
                    label: 'general_q_gen_eyeglasses',
                    image: 'gen_eyeglasses'
                },
                {
                    value: '1.3.3',
                    label: 'general_q_gen_no_lenses_or_contact_lenses',
                    image: 'gen_stop',
                    type: 'clear'
                }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '1.4',
            question: 'general_q_gen__distances_do_you_see_less_clearly',
            kind: 'need_explorer_single_choice_question',
            type:'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                {
                    value: '1.4.1',
                    label: 'general_q_gen_nearby',
                    image: 'gen_nearby'
                },
                {
                    value: '1.4.2',
                    label: 'general_q_gen_computer_distances',
                    image: 'gen_distance'
                },
                {
                    value: '1.4.3',
                    label: 'general_q_gen_far_away',
                    image: 'gen_far_away'
                }
            ],
            isAnswered: false
        },
        {
            id: '1.5',
            question: 'general_q_gen_reason_for_your_consultation',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_LIST',
            options: [
                { value: '1.5.1', label: 'general_q_gen_i_want_new_spectacles' },
                { value: '1.5.2', label: 'general_q_gen__eye_exam' },
                { value: '1.5.3', label: 'general_q_gen__viewing_at_short_distances' },
                { value: '1.5.4', label: 'general_q_gen_viewing_my_computer_screen' },
                { value: '1.5.5', label: 'general_q_gen_looking_for_multimedia_lenses' },
                { value: '1.5.6', label: 'general_q_gen_dont_see_sharp_at_long_distances' },
                { value: '1.5.7', label: 'general_q_gen_have_dry_and_tired_eyes' },
                { value: '1.5.8', label: 'general_q_gen_want_sun_lenses' },
                { value: '1.5.9', label: 'general_q_gen_want_contact_lenses' },
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '1.6',
            question: 'general_q_gen_rank_your_daily_life_activities',
            kind: 'need_explorer_rank_question',
            type: 'DRAG_AND_DROP_SORTER',
            options: [
                {
                    value: '1.6.1',
                    label: 'general_q_gen_screen_usage',
                    image: 'gen_drag_screen'
                },
                {
                    value: '1.6.2',
                    label: 'general_q_gen_daily_mobility',
                    image: 'gen_drag_daily'
                },
                {
                    value: '1.6.3',
                    label: 'questionnaires_q_outdoor_activities',
                    image: 'gen_drag_outdoor'
                },
                {
                    value: '1.6.4',
                    label: 'questionnaires_q_indoor',
                    image: 'gen_drag_indoor'
                }
            ],
            answer: [],
            isAnswered: false
        },
        {
            id: '1.7',
            question: "general_q_gen_spend_each_day_on_the_following_activities",
            kind: 'need_explorer_duration_question',
            type: 'SLIDER_GROUP',
            sliders: [
                { key: '1.7.1', label: 'general_q_gen_spend_watching_digital_screens_', min: 0, max: 10, minValue: 1},
                { key: '1.7.2', label: 'general_q_gen_hours_do_you_drive_in_traffic', min: 0, max: 10 , minValue: 1},
                { key: '1.7.3', label: 'general_q_gen_hours_spend_indoor_activities_', min: 0, max: 10, minValue: 1},
                { key: '1.7.4', label: 'general_q_gen_spend_on_outdoor_activities_', min: 0, max: 10, minValue: 1}
            ],
            answer: [
                1,1,1,1
            ],
            isAnswered: false
        },
        {
            id: '1.8',
            question: 'general_q_gen_participate__activities',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                {
                    value: '1.8.1',
                    label:'general_q_gen_active_play_with_children_and/or_pets',
                    image:'gen_woman_children'
                },
                {
                    value: '1.8.2',
                    label:'general_q_gen_team_sports_',
                    image:'running',
                },
                {
                    value: '1.8.3',
                    label:'general_q_gen_work_or_hobbies',
                    image:'gen_expose'

                },
                {
                    value: '1.8.4',
                    label:'general_q_gen_outdoor_activities',
                    image:'running-solo'
                }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '1.9',
            question: 'general_q_gen_experience_following',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                {
                    value: '1.9.1',
                    label: 'general_q_gen_discomfort_from_glare_',
                    image: 'gen_discomfort'
                },
                {
                    value: '1.9.2',
                    label: 'general_q_gen_eyeglass_lenses_that_smudge_easily',
                    image: 'gen_smudge'
                },
                {
                    value: '1.9.3',
                    label: 'general_q_gen_eyeglass_lenses_that_scratch_easily',
                    image: 'gen_scratch_glasses'
                 },
                {
                    value: '1.9.4',
                    label: "general_q_gen_exposure_to_dirt_rain_and_wind",
                    image: 'gen_rain'
                }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
    ]
}

const ScreenUsageCategory:iNeedExplorerCategory = {
    id: '2',
    category: 'questionnaires_q_screen_usage',
    questions: [
        {
            id: '2.1',
            question: 'screen_usage_q_sc_which_screens_',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '2.1.1', label: 'screen_usage_q_sc_smartwatch', simpleIcon: 'watch' },
                { value: '2.1.2', label: 'screen_usage_q_sc_digital_camera', simpleIcon: 'cam' },
                { value: '2.1.3', label: 'screen_usage_q_sc_smartphone', simpleIcon: 'mobile' },
                { value: '2.1.4', label: 'screen_usage_q_sc_navigation', simpleIcon: 'map' },
                { value: '2.1.5', label: 'screen_usage_q_sc_e-reader', simpleIcon: 'ereader' },
                { value: '2.1.6', label: 'screen_usage_q_sc_tablet', simpleIcon: 'tablet' },
                { value: '2.1.7', label: 'screen_usage_q_sc_laptop', simpleIcon: 'laptop' },
                { value: '2.1.8', label: 'screen_usage_q_sc_desktop', simpleIcon: 'computer2' },
                { value: '2.1.9', label: 'screen_usage_q_sc_tv', simpleIcon: 'tv' },
                { value: '2.1.10', label: 'screen_usage_q_sc_digiboard', simpleIcon: 'digiboard' },
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '2.2',
            question: 'screen_usage_q_sc_how_many_hours_per_day_on_average_screen',
            kind: 'need_explorer_duration_question',
            subQuestion: '',
            type: 'DIAL',
            dial: {
                label: 'need_explorer_hr_per_day',
                min: 0,
                max: 10
            },
            answer: 0,
            isAnswered: false
        },
        {
            id: '2.3',
            question: 'screen_usage_q_sc_do_one_or_more_of_the_following_',
            kind: 'need_explorer_multiple_choice_question',
            type:'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                { value: '2.3.1', label: 'screen_usage_q_sc_work_at_a_desk', image: 'gen_multiple_screen' },
                { value: '2.3.2', label: 'screen_usage_q_sc_regularly_use_my_desk_', image: 'gen_discussion' },
                { value: '2.3.3', label: 'screen_usage_q_sc_besides_working_at_my_desk', image: 'gen_meetings' }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '2.4',
            question: 'screen_usage_q_sc_do_you_regularly_experience_discomforts',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '2.4.1', label: 'screen_usage_q_sc_stinging_eyes', simpleIcon: 'sting' },
                { value: '2.4.2', label: 'screen_usage_q_sc_often_squeeze_eyes', simpleIcon: 'often' },
                { value: '2.4.3', label: 'screen_usage_q_sc_tired_eyes', simpleIcon: 'tired' },
                { value: '2.4.4', label: 'screen_usage_q_sc_running_eyes', simpleIcon: 'running' },
                { value: '2.4.5', label: 'screen_usage_q_sc_red/irritated_eyes', simpleIcon: 'irritated' },
                { value: '2.4.6', label: 'screen_usage_q_sc_dry_eyes', simpleIcon: 'dry' },
                { value: '2.4.7', label: 'screen_usage_q_sc_neck_and_shoulder_pain', simpleIcon: 'neckpain' },
                { value: '2.4.8', label: 'screen_usage_q_sc_headache', simpleIcon: 'headache' },
                { value: '2.4.9', label: 'screen_usage_q_sc_backache', simpleIcon: 'backpain' },
                { value: '2.4.10', label: 'screen_usage_q_sc_problems_with_focussing', simpleIcon: 'focus' },
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
    ]
}

const TransportsCategory: iNeedExplorerCategory = {
    id: '3',
    category: 'questionnaires_q_transports',
    questions: [
        {
            id: '3.1',
            question: 'daily_mobility_q_sc__transport_',
            kind: 'need_explorer_multiple_choice_question',
            subQuestion: 'need_explorer_proceed',
            type: 'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                { value: '3.1.1', label: 'daily_mobility_q_sc_bicycle', image: 'bike'},
                { value: '3.1.2', label: 'daily_mobility_q_sc_scooter', image: 'scooter'},
                { value: '3.1.3', label: 'daily_mobility_q_sc_motorcycle', image: 'motor'},
                { value: '3.1.4', label: 'daily_mobility_q_sc_car', image: 'gen_car'},
                { value: '3.1.5', label: 'daily_mobility_q_sc_coach,_truck_or_van', image: 'gen_bus_driver'},
                { value: '3.1.6', label: 'indoor_activities_q_ic_discomforts_neither_of_these', image: 'gen_stop', type: 'clear' }
            ],
            answer: [],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '3.2',
            question: 'daily_mobility_q_sc_drive_on_a_daily_basis',
            kind: 'need_explorer_duration_question',
            type: 'DIAL',
            dial: {
                label: 'need_explorer_hr_per_day',
                min: 0,
                max: 10
            },
            answer: 0,
            isAnswered: false
        },
        {
            id: '3.3',
            question: 'daily_mobility_q_sc_reason_drive',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '3.3.1', label: 'daily_mobility_q_sc_private_/_recreational'},
                { value: '3.3.2', label: 'daily_mobility_q_sc_home-job_traffic'},
                { value: '3.3.3', label: 'daily_mobility_q_sc_professional_for_business_purposes'}
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '3.4',
            question: 'daily_mobility_q_sc_navigation_system',
            kind: 'need_explorer_single_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '3.4.1', label: 'daily_mobility_q_sc_yes'},
                { value: '3.4.2', label: 'daily_mobility_q_sc_no'}
            ],
            isAnswered: false
        },
        {
            id: '3.5',
            question: 'daily_mobility_q_sc__drive_in_the_morning_in_the_dark',
            kind: 'need_explorer_single_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '3.5.1', label: 'daily_mobility_q_sc_drive_yes'},
                { value: '3.5.2', label: 'daily_mobility_q_sc_drive_no'}
            ],
            isAnswered: false
        },
        {
            id: '3.6',
            question: 'daily_mobility_q_sc_inconveniences',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                { value: '3.6.1', label: 'daily_mobility_q_sc_inconv_bright_sunlight', image: 'gen_brightlight' },
                { value: '3.6.2', label: 'daily_mobility_q_sc_inconv_low_sunlight', image: 'gen_lowlight' },
                { value: '3.6.3', label: 'daily_mobility_q_sc_inconv_reduced_sight_in_the_dark', image: 'gen_reduced_dark' },
                { value: '3.6.4', label: 'daily_mobility_q_sc_inconv_changing_light_circumstances', image: 'gen_changing_light' },
                { value: '3.6.5', label: 'daily_mobility_q_sc_inconv_dazzlement_by_glare', image: 'gen_glare' },
                { value: '3.6.6', label: 'daily_mobility_q_sc_inconv_head_lights_by_opposing_traffic', image: 'gen_headlights'  },
            ],
            canSelectMultiple: true,
            isAnswered: false
        }
    ]
}

const OutdoorActivities: iNeedExplorerCategory = {
    id: 4,
    category: 'questionnaires_q_outdoor_activities',
    questions: [
        {
            id: '4.1',
            question: 'outdoor_activities_q_oc_focus_outdoor_activities',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                { value: '4.1.1', label: 'outdoor_activities_q_oc_focus_outdoor_act_tasks_within_arms_reach', image: 'gen_multitask' },
                { value: '4.1.2', label: 'outdoor_activities_q_oc_focus_outdoor_act_precision_', image: 'gen_precision_task' },
                { value: '4.1.3', label: 'outdoor_activities_q_oc_focus_outdoor_tasks_at_short_distance', image: 'gen_electrician' },
                { value: '4.1.4', label: 'outdoor_activities_q_oc_focus_outdoor_far_distances_during_your_work', image: 'gen_farsighted' }
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '4.2',
            question: 'outdoor_activities_q_oc__outdoors',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '4.2.1', label: 'outdoor_activities_q_oc_walking'},
                { value: '4.2.2', label: 'outdoor_activities_q_oc_outdoor_sports_1'},
                { value: '4.2.3', label: 'outdoor_activities_q_oc_riding_a_bicylcle'},
                { value: '4.2.4', label: 'outdoor_activities_q_oc_diy_/_doing_odd_jobs'},
                { value: '4.2.5', label: 'outdoor_activities_q_oc_gardening'},
                { value: '4.2.6', label: 'outdoor_activities_q_oc_sun_bathing'},
                { value: '4.2.7', label: 'outdoor_activities_q_oc_reading_in_the_sun'}
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
        {
            id: '4.3',
            question: 'outdoor_activities_q_oc_time_spend_outdoors_per_day',
            kind: 'need_explorer_duration_question',
            type: 'DIAL',
            dial: {
                label: 'need_explorer_hr_per_day',
                min: 0,
                max: 10
            },
            answer: 0,
            isAnswered: false
        },
        {
            id: '4.4',
            question: 'outdoor_activities_q_oc_outdoor_sports_2',
            kind: 'need_explorer_rank_question',
            type: 'DRAG_AND_DROP_SORTER',
            options: [
                {
                    value: '4.4.1',
                    label: 'outdoor_activities_q_oc_outdoor_sports__coordination',
                    image: 'gen_drag_quick_eye'
                },
                {
                    value: '4.4.2',
                    label: 'outdoor_activities_q_oc_outdoor_sports_breaking_your_glasses',
                    image: 'gen_drag_high_risk'
                },
                {
                    value: '4.4.3',
                    label: 'outdoor_activities_q_oc_outdoor_sports_fast_response_times',
                    image: 'gen_fast_response'
                },
                {
                    value: '4.4.4',
                    label: 'outdoor_activities_q_oc_outdoor_sports_high_level_of_precision',
                    image: 'gen_drag_kitchen'
                }
            ],
            answer: [],
            isAnswered: false
        },
        {
            id: '4.5',
            question: 'outdoor_activities_q_oc__holiday_destinations',
            kind: 'need_explorer_duration_question',
            type: 'DURATION_GROUP',
            durations: [
                {
                    key: '4.5.1',
                    label: 'outdoor_activities_q_oc__holiday_destinations_beach',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_holidays'
                },
                {
                    key: '4.5.2',
                    label: 'outdoor_activities_q_oc__holiday_destinations_snow',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_winter'
                },
                {
                    key: '4.5.3',
                    label: 'outdoor_activities_q_oc__holiday_destinations_water',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_waterside'
                },
                {
                    key: '4.5.4',
                    label: 'outdoor_activities_q_oc__holiday_destinations_nature',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_nature'
                },
                {
                    key: '4.5.5',
                    label: 'outdoor_activities_q_oc__holiday_destinations_mountains',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_climbing'
                },
                {
                    key: '4.5.6',
                    label: 'outdoor_activities_q_oc__holiday_destinations_city_trips',
                    min: 0,
                    max: 10,
                    minValue: 0,
                    image: 'gen_slide_urban'
                }
            ],
            answer: [
                1,1,1,1,1,1
            ],
            isAnswered: false
        },
        {
            id: '4.6',
            question: 'outdoor_activities_q_oc__spare_glasses',
            kind: 'need_explorer_single_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '4.6.1', label: 'outdoor_activities_q_oc__spare_glasses_yes'},
                { value: '4.6.2', label: 'outdoor_activities_q_oc__spare_glasses_no'}
            ],
            isAnswered: false
        },
        {
            id: '4.7',
            question: 'outdoor_activities_q_oc__outdoor_activities',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_LIST',
            options: [
                { value: '4.7.1', label: 'outdoor_activities_q_oc__outdoor_activities_smudge'},
                { value: '4.7.2', label: 'outdoor_activities_q_oc__outdoor_activities__circumstances'},
                { value: '4.7.3', label: 'outdoor_activities_q_oc__outdoor_activities_dazzlement'},
                { value: '4.7.4', label: 'outdoor_activities_q_oc__outdoor_activities_fogged_lenses'},
                { value: '4.7.5', label: 'outdoor_activities_q_oc__outdoor_activities_perspiration_'},
                { value: '4.7.6', label: 'outdoor_activities_q_oc__outdoor_activities_reflections_'},
                { value: '4.7.7', label: 'outdoor_activities_q_oc__outdoor_activities_rain_drops'},
                { value: '4.7.8', label: 'outdoor_activities_q_oc__outdoor_activities_wind'},
                { value: '4.7.9', label: 'outdoor_activities_q_oc__outdoor_activities_shining_surfaces'}
            ],
            canSelectMultiple: true,
            isAnswered: false
        },
    ]
}

const IndoorCategory: iNeedExplorerCategory = {
    id: 5,
    category: 'questionnaires_q_indoor',
    questions: [
        {
            id: '5.1',
            question: 'indoor_activities_q_ic_indoor_activities',
            kind: 'need_explorer_duration_question',
            type: 'SLIDER_GROUP',
            sliders: [
                { key: '5.1.1', label: 'indoor_activities_q_ic__computer_activities', min: 0, max: 10, minValue: 1},
                { key: '5.1.2', label: 'indoor_activities_q_ic__reading', min: 0, max: 10 , minValue: 1},
                { key: '5.1.3', label: 'indoor_activities_q_ic__television_or_movies', min: 0, max: 10, minValue: 1},
                { key: '5.1.4', label: 'indoor_activities_q_ic__sewing_/_stitching', min: 0, max: 10, minValue: 1},
                { key: '5.1.5', label: 'indoor_activities_q_ic__music', min: 0, max: 10, minValue: 1},
                { key: '5.1.6', label: 'indoor_activities_q_ic__painting', min: 0, max: 10 , minValue: 1},
                { key: '5.1.7', label: 'indoor_activities_q_ic__cooking', min: 0, max: 10, minValue: 1},
                { key: '5.1.8', label: 'indoor_activities_q_ic__diy', min: 0, max: 10, minValue: 1},
                { key: '5.1.9', label: 'indoor_activities_q_ic__beauty_care', min: 0, max: 10, minValue: 1},
                { key: '5.1.10', label: 'indoor_activities_q_ic__indoor_sports', min: 0, max: 10 , minValue: 1}
            ],
            answer: [
                1,1,1,1,1,1,1,1,1,1
            ],
            isAnswered: false
        },
        {
            id: '5.2',
            question: 'indoor_activities_q_ic_discomforts_during_your_indoor_activities',
            kind: 'need_explorer_multiple_choice_question',
            type: 'CHOICE_BOX_IMAGE',
            position: 'center',
            options: [
                {
                    value: '5.2.1',
                    label: 'indoor_activities_q_ic_discomforts_smudge',
                    image: 'gen_smudge_dust_grease'
                },
                {
                    value: '5.2.2',
                    label: 'indoor_activities_q_ic_discomforts_changing_light_circumstances',
                    image: 'gen_light_circumstances'
                },
                {
                    value: '5.2.3',
                    label: 'indoor_activities_q_ic_discomforts_neither_of_these',
                    image: 'gen_stop',
                    type: 'clear'
                }
            ],
            canSelectMultiple: true,
            isAnswered: false
        }
    ]
}

const neddExplorerQuestionaires: iNeedExplorerCategory[] = [
    GeneralCategory,
    ScreenUsageCategory,
    TransportsCategory,
    OutdoorActivities,
    IndoorCategory
];

export default neddExplorerQuestionaires;
