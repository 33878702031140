import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import NeedExplorerViewType from '../../../types/need-explorer-view.type';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import NeedExplorerQuestion from './NeedExplorerQuestion';
import iQuestionOption from '../../../interface/question-option.interface';
import { getUser } from '../../../store/slices/user.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import CategoryProgressBar from './CategoryProgressBar';

interface Props {

}

const NeedExplorerQuestionPanel : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-question-panel');
    const {
        questions,
        categoryStep,
        questionStep,
        activeCategory,
        activeQuestion,
        showSaved
    } = useSelector(getNeedExplorer);
    const {
       experience
    } = useSelector(getApp);
    const dispatch = useDispatch();
    const { ageCategory } = useSelector(getUser);
    const { t } =  useTranslation(useSelector(getApp));
    const optionalQuestionIds = ['6.2', '3.1', '1.4', '1.9', '2.3', '2.4', '3.6', '4.1', '4.6', '4.7']
    const [show, setShow] = useState<any>(null)
    const [showLoader, setShowLoader] = useState(false);
    const [questionsWithAnswer, setQuestionsWithAnswer] = useState<number[]>([]);


    // HOOKS    
    useEffect(() => {
        if (showSaved) {
            setTimeout(() => setShowLoader(true), 25)
            setShowLoader(false)

            dispatch(needExplorerSlice.actions.setShowSaved(false))
        } else {
            setShow(null)
        }

        setShowLoader(false)
    }, [showSaved])

    useEffect(() => {
        if (showLoader) {
            setTimeout(() => {
                setShow("-show")
            }, 800)
        }
    }, [showLoader])

    useEffect(() => {
        setShowLoader(false)
    }, [show])

    useEffect(() => {
        const withAnswers: number[] = [];
        const categoryQuestions = questions.filter( c => c.id === activeCategory.id)[0].questions;
        categoryQuestions.forEach( (x,i) => {
            if(x.isAnswered)withAnswers.push(i);
        });
        setQuestionsWithAnswer(withAnswers);
    }, [questions, categoryStep, questionStep, activeCategory])



    // METHODS
    const isLastCategory = () => {
        return questions.length === (categoryStep + 1);
    }

    const isLastQuestion = () => {
        return activeCategory.questions.length === (questionStep + 1);
    }

    const isGeneralQuestionDone = () => {
        var penultimateQuestionInGeneral = questions[0].questions.length-1;

        return questionStep === penultimateQuestionInGeneral && categoryStep === 0
    }

    const nextQuestion = () => {
        if (isGeneralQuestionDone() && ageCategory?.value !== 'kids'){
            dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.GENERAL_CHECKPOINT));
        }
        dispatch(needExplorerSlice.actions.nextQuestion());

        if (isLastCategory() && isLastQuestion()){
            dispatch(needExplorerSlice.actions.changeView(NeedExplorerViewType.FINISHED));
        }

        dispatch(needExplorerSlice.actions.setShowSaved(false))
        setShow(null)
    }

    const navigationQuestion = (dir?: 'back' | 'next') => {
        if(dir === 'back') {
            dispatch(needExplorerSlice.actions.previousQuestion());
            return;
        }
        nextQuestion();
    }

    const disableButton = (callerId: 'button' | 'progress') => {
        if(activeQuestion.type === 'DRAG_AND_DROP_SORTER') {
            const answers = activeQuestion.answer as iQuestionOption[];
            if(answers.length < 1)return true;
            return answers.reduce((a,b) => {
                if(!b)return a;
                return a + 1
            },0) < 4;
        }

        // For kids question to accept if the user has no answer
        if(optionalQuestionIds.includes(activeQuestion.id) && callerId === 'button'){
            return false
        }

        if(Array.isArray(activeQuestion.answer)) return activeQuestion.answer.length < 1;
        if(typeof activeQuestion.answer === 'number') return activeQuestion.answer < 0;
        return activeQuestion.answer ? false : true;
    }

    const isQuestionTypeKids = () => {
        const isKids = String(activeQuestion.type).toLocaleLowerCase().indexOf('kids') >= 0;
        return isKids;
    }

    return (
        <div className={B( isQuestionTypeKids() ? 'kids' : '')}>

            {/* <div className={E('step')}>
                { questionStep > 0 && <Icon name="left-arrow-circle-gray" click={backQuestion}/> }
                {questionStep + 1}/ {activeCategory.questions.length}
            </div> */}

            <NeedExplorerQuestion question={activeQuestion}/>

            <div className={E('button')}>
                <Button
                    type={isQuestionTypeKids() ? 'secondary' : 'primary'}
                    outlined={false}
                    disabled={disableButton('button')}
                    click={nextQuestion}
                    icon="right-arrow"
                    text={t('questionnaires_q_next')}
                    themed={ageCategory?.value === 'kids'}
                />

                <div className={`loader ${showLoader ? '-show' : ''}`} />

                <div className={`saved ${show}`}>
                    <Icon className='icon' name='saved' />
                    <label>
                        {t('my_practice_my_saved')}!
                    </label>
                </div>
            </div>


            <CategoryProgressBar 
                isKids={isQuestionTypeKids()}
                fullIndexes={questionsWithAnswer}
                disableNext={disableButton('progress')}
                disableBack={questionStep === 0}
                onNavigate={navigationQuestion}
                isCurrentQuestionWithAnwer={!disableButton('progress')}
                numberOfQuestions={activeCategory.questions.length}
                currentQuestion={questionStep}
            />
            {/* <BrickProgressBar /> */}
        </div>
    )
}

export default NeedExplorerQuestionPanel;
