import React, { useEffect, useRef, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM'

interface Props {
    reflectionDiameter: number,
    dimension: {
        h: number,
        w: number
    },

    crystalLineBallHeight:number,
    crystalLineBallWidth:number,
    crystalLineWidth: number,
    linesColor: string,
    classname: string
}

const EyeballCrystalline : React.FC<Props>  = ({
    reflectionDiameter,
    dimension,
    crystalLineBallHeight,
    crystalLineBallWidth,
    crystalLineWidth,
    linesColor,
    classname
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('eyeball-crystalline');
    const [canvas, setCanvas] = useState<any>();
    const [context, setContext] = useState<CanvasRenderingContext2D>();


    // HOOKS
    useEffect(() => {
        if(!canvas || !context)return;


        // Note: avoid reflection to go outside d canvas
        if((dimension.w - reflectionDiameter) <= crystalLineWidth)return;

        context.clearRect(0, 0, dimension.w, dimension.h);


        drawObjectReflection(context);
        drawCrystalLineBall(context);

        const yPosition = dimension.h / 2;
        drawLine(
            context,
            [ crystalLineBallWidth + 20, yPosition - crystalLineBallHeight ], // starting point x and y
            [
                crystalLineWidth,
                yPosition - reflectionDiameter
            ]
        )

        drawLine(
            context,
            [ crystalLineBallWidth + 20, yPosition + crystalLineBallHeight ],
            [crystalLineWidth, yPosition + reflectionDiameter]
        )

        drawLine(
            context,
            [ 0, yPosition - crystalLineBallHeight ],
            [crystalLineBallWidth + 20, yPosition - crystalLineBallHeight]
        )

        drawLine(
            context,
            [ 0, yPosition + crystalLineBallHeight ],
            [crystalLineBallWidth + 20, yPosition + crystalLineBallHeight]
        )


    }, [crystalLineWidth, crystalLineBallHeight, crystalLineBallWidth, canvas, context]);

    useEffect(() => {
        if(!canvas)setCanvas(document.getElementById('eyeball-canvas'));
        if(canvas && !context)setContext(canvas.getContext("2d"));

    }, [canvas, context])

    // METHODS
    const drawLine = (
            ctx: CanvasRenderingContext2D,
            start: number[],
            end: number[]
    ) => {
        ctx.beginPath();
        ctx.moveTo(start[0], start[1]);
        ctx.strokeStyle = linesColor;
        ctx.lineTo(end[0], end[1]);
        ctx.stroke();
        ctx.lineWidth = 2;
    }

    const drawObjectReflection = ( ctx: CanvasRenderingContext2D ) => {
        ctx.beginPath();
        ctx.arc(
            crystalLineWidth,  // X
            dimension.h / 2, // Y
            reflectionDiameter, // diameter
            0,
            2 * Math.PI
        );
        ctx.fillStyle = '#ffffff';
        ctx.strokeStyle = "#ffffff";
        ctx.setLineDash([0, 0]);
        ctx.stroke();
    }

    const drawCrystalLineBall = (
        ctx: CanvasRenderingContext2D,
    ) => {
        ctx.ellipse(
            crystalLineBallWidth + 65, // X
            dimension.h / 2, // Y
            crystalLineBallWidth, // width
            crystalLineBallHeight, // height
            0,
            0,
            Math.PI*2
        );
        ctx.fillStyle = '#BAE6EF';
        ctx.fill();
    }

    return (
        <canvas className={`${classname} ${B()}`} width={dimension.w} height={dimension.h} id="eyeball-canvas"></canvas>
    )
}

export default EyeballCrystalline;
