import React, { useEffect, useState } from 'react';
import ButtonTab from '../../../../components/ui/ButtonTab';
import Icon from '../../../../components/ui/Icon';
import useBEM from '../../../../utils/hooks/useBEM';
import Advantages from './SunExperienceAdvantages';
import front from './../../../../assets/images/temp-frame-front.jpg';
import left from './../../../../assets/images/temp-frame-left.jpg';
import right from './../../../../assets/images/temp-frame-right.jpg';
import iSunExperienceSolutionColor from '../../../../interface/sun-experience-solution-color.interface';
import Loading from './../../../../components/ui/Loading';
import { getLensConsultationSun } from '../../../../store/slices/lens-consultation-sun-exp.slice';
import { useSelector } from 'react-redux';

interface Props {
    tint?: iSunExperienceSolutionColor
}

const FrameView : React.FC<Props>  = ({
    // tint
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('frame-view');  
    const [isLoading, setLoading] = useState(false);
    const defaultImage = 'https://companion-cdn-qua.azureedge.net/demonstration/optimized/Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'
    const [imageUrl, setImageUrl] = useState<string | undefined | null>(defaultImage)
    const { solution, tint } = useSelector(getLensConsultationSun);
    const imagePath = tint?.image_path 


    //hooks 
    useEffect(() => {
      getImage(imagePath)
    }, [tint])
    


    // METHODS 

    const onImageLoaded = (success: boolean) => {
        if(!success)console.log('Image not found');        
        setLoading(false);
    }

    const getImage = async(url?:string|undefined|null) => {
        if(!url)return
        setLoading(true)
        await fetch(url).then(result =>
        {
        setImageUrl(result.url)
        })
        .catch(err => {
            console.log(err)
        })
    }
    return (
        <div className={B()}> 
            {
                (isLoading) &&
                <Loading dim='3rem' size='.35rem'/>
            }

            {imageUrl && <img 
                src={imageUrl} 
                className={E('frame', isLoading ? 'loading' : '')} 
                onLoad={() => onImageLoaded(true)}
                onError={() => onImageLoaded(false)}
            />}
            
            {solution?.sun_experience?.advantages && 
                <Advantages/>
            }
        </div>
    )
}

export default FrameView;