import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import InputText from '../../../components/ui/InputText';
import iMeasurementDataTwo from '../../../interface/measurement-two-data.inteface';
import useBEM from '../../../utils/hooks/useBEM';
import { validateMeasures } from '../../../utils/MeasuresValidation';
import { useSelector } from 'react-redux';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import ExperienceType from '../../../types/experience.type';
import CircleArrow from '../../../components/ui/CircleArrow';
interface Props {
    onSave: (data: iMeasurementDataTwo) => void,
    data?: iMeasurementDataTwo,
    isKids: boolean,
    experience: ExperienceType
}

const MeasureStepTwo : React.FC<Props>  = ({
    onSave,
    data,
    isKids,
    experience
}) =>  {
    // VARIABLES
    const defaultData = {
        left: {
            height: {
                value: 0,
                error: false,
                errorMsg: ''
            },
            pd: {
                value: 0,
                error: false,
                errorMsg: ''
            }
        },
        right: {
            height: {
                value: 0,
                error: false,
                errorMsg: ''
            },
            pd: {
                value: 0,
                error: false,
                errorMsg: ''
            }
        }
    }
    const [B, E] = useBEM('measures-step');
    const { t } = useTranslation(useSelector(getApp));
    const [localData, setLocalData] = useState<iMeasurementDataTwo>(defaultData)

    // HOOKS
    useEffect(() => {
        setLocalData(data || defaultData)
    }, [data])

    // METHODS
    const onSaveHandler = () => {
        onSave(localData);
    }

    const inputChangeHandler = (e: any, position: 'left' | 'right', key: 'pd' | 'height') => {
        const value = e.target.value
        const result = validateMeasures(2, value, key)
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')
        if(value === '' || floatRegExp.test(value)){
            setLocalData((prev: any) => {
                return {
                    ...prev,
                    [position]: {
                        ...prev[position],
                        [key]: {
                            value: value ? Number(value) : 0,
                            error: result?.isError,
                            errorMsg: result?.errorMsg
                        }
                    }
                }
            })
        }


    }

    const validateCurrentState = () => {
        return localData.left.pd.error === true ||
        localData.right.pd.error === true ||
        localData.left.height.error === true ||
        localData.right.height.error === true ||
        localData.left.pd.value === 0 ||
        localData.right.pd.value === 0 ||
        localData.left.height.value === 0 ||
        localData.right.height.value === 0
    }

    const getNextButtonIcon = () => {
        if(isKids)return "right-arrow-circle";
        return <CircleArrow type='right' background='#eee'/>
    }


    return (
        <div className={B()}>

            <div className={E('form', 'two')}>
                <label>{t('measures_mes_left_eye')}</label>
                <label>{t('measures_mes_righ_eye')}</label>

                <label>{t('measures_mes_pd')}</label>
                <label>{t('measures_mes_pd')}</label>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" type="light" value={String(localData?.left.pd.value)} onChange={(v) => inputChangeHandler(v, 'left', 'pd')}/>
                    {localData?.left.pd.error && <span>{t(localData?.left.pd.errorMsg)}</span>}
                </div>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" type="light" value={String(localData?.right.pd.value)} onChange={(v) => inputChangeHandler(v, 'right', 'pd')}/>
                    {localData?.right.pd.error && <span>{t(localData?.right.pd.errorMsg)}</span>}
                </div>

                <label>{t('measures_mes_height')}</label>
                <label>{t('measures_mes_height')}</label>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" type="light" value={String(localData?.left.height.value)} onChange={(v) => inputChangeHandler(v, 'left', 'height')}/>
                    {localData?.left.height.error && <span>{t(localData?.left.height.errorMsg)}</span>}
                </div>

                <div className="container-mm" data-placeholder={"mm"}>
                    <InputText inputType="number" type="light" value={String(localData?.right.height.value)} onChange={(v) => inputChangeHandler(v, 'right', 'height')}/>
                    {localData?.right.height.error && <span>{t(localData?.right.height.errorMsg)}</span>}
                </div>

            </div>

            <Button
                className={E('button')}
                text={t('measures_mes_2_next')}
                icon={getNextButtonIcon()}
                click={onSaveHandler}
                disabled={validateCurrentState()}
                experience={experience}
            />

        </div>
    )
}

export default MeasureStepTwo;
