import React, { useEffect , useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import routes from '../../../constants/routes.constant';
import { getApp } from '../../../store/slices/app.slice';
import ExperienceType from '../../../types/experience.type';
import useGoto from '../../../utils/hooks/useGoto';
import NeedExplorerQuestion from '../components/NeedExplorerQuestion';
import useTranslation from '../../../utils/hooks/useTranslation';
import useBEM from './../../../utils/hooks/useBEM';
import RatingGroup from '../components/question-types/RatingGroup';
import RatingGroupImage from '../components/question-types/RatingGroupImage'
import RankingGroupImage from '../components/question-types/RankingGroupImage'
import needExplorerSunQuestionaire from "../../../constants/need-explorer-sun-questionaires.constant"
import iNeedExplorerQuestion from '../../../interface/need-explorer-question.interface';
import Frequency from '../components/question-types/Frequency'
import CircleCheck from '../../../components/ui/CircleCheck'; 
import Loading from '../../../components/ui/Loading';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import needExplorerSliceSun, { getNeedExplorerSun } from '../../../store/slices/need-explorer-sun.slice';
import ProgressPopup from './ProgressPopup';
import Modal from '../../../components/Modal';
import { evaluateSunExperience } from '../../../utils/misc';
import { getProducts } from '../../../store/slices/products.slice';
import lensConsultationSun, { getLensConsultationSun } from '../../../store/slices/lens-consultation-sun-exp.slice';
import iLensRecommendationPair from '../../../interface/lens-recommendaiton-pair.interface';

interface Props {

}

const NeedExplorerSunExperience : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-sun-experience');
    const goto = useGoto();
    const { t } = useTranslation(useSelector(getApp));
    const [test, setTest] =useState<iNeedExplorerQuestion>();
    const {
        questions,
        categoryStep,
        questionStep,
        activeCategory,
        activeQuestion,
        showSaved
    } = useSelector(getNeedExplorerSun);
    const {
        experience
    } = useSelector(getApp);
    const dispatch = useDispatch();
    // const test = sunExperienceQuestionaire.find((x => x.id === "8.2"))
    const currentNeedExplorerData = useSelector(getNeedExplorerSun);
    const { temporaryProductList } = useSelector(getProducts);
    const currentLensConsultationSun = useSelector(getLensConsultationSun);


    // HOOKS

    const [show, setShow] = useState<any>(null)
    const [showLoader, setShowLoader] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [answerLength, setAnswerLength] = useState(0)
    useEffect(() => {
        setTest(test);
    },[])

    // useEffect(() => {
    //     const answer = activeQuestion?.answer
    //     const situations = activeQuestion?.situations
    //     if(isLastQuestion() && answerLength === 3){
    //         if(answer && Array.isArray(answer) && situations && answer[0] === undefined || 
    //         answer && Array.isArray(answer) && situations && 
    //         answer.some(v=> typeof v === 'object' || typeof v === 'undefined' || v === null))return
    //         else return setShowProgress(true)
    //     }
    // },[activeQuestion,answerLength])


    useEffect(() => {
        if (showSaved) {
            setTimeout(() => setShowLoader(true), 25)
            setShowLoader(false)
            dispatch(needExplorerSliceSun.actions.setShowSaved(false))
        } else {
            setShow(null)
        }

        setShowLoader(false)
    }, [showSaved])

    useEffect(() => {
        if (showLoader) {
            setTimeout(() => {
                setShow("-show")
            }, 800)
            setTimeout(() => {
                setShow(null)
            }, 2000)
        }
    }, [showLoader])

    useEffect(() => {
        setShowLoader(false)
    }, [show])

    // METHODS
    const backHandler = () => {
        goto.route(routes.lensConsultation)
    }

    const isLastQuestion = () => {
        return activeCategory.questions.length === (questionStep + 1);
    }

    const saveRecommendedData = () => {
        const recom = evaluateSunExperience(currentNeedExplorerData, temporaryProductList)
        if(!recom)return
        const tint = recom?.pair1.protect[0].color
        const solution = {
            sun_experience:recom?.pair1.protect[0]?.product?.sun_experience,
            id:recom?.pair1.protect[0]?.product?.id,
        }


        dispatch(userSlice.actions.saveNeedExplorerData(currentNeedExplorerData));

        const temp = {
            ...currentLensConsultationSun,
            lensRecommendation: {
                pair1: recom?.pair1,
            },
            storedLensRecommendation: {
                pair1: recom?.pair1,
            }
        }
        dispatch(userSlice.actions.savePrefilledData(temp));
        dispatch(lensConsultationSun.actions.setPrefilledDataFromNeedExplorer(recom))
        dispatch(lensConsultationSun.actions.setPrefilledDataFromNeedExplorerStored(recom))
        dispatch(lensConsultationSun.actions.setSolution({ solution, tint}))
        dispatch(lensConsultationSun.actions.setSolutionId(solution.id))
        dispatch(userSlice.actions.saveLensRecommendationData({
            pair1: recom?.pair1 as iLensRecommendationPair,
        }));
    }
    const nextQuestion = () => {
        if (isLastQuestion()){
            dispatch(needExplorerSliceSun.actions.nextQuestion());
            setShowProgress(true)
            setTimeout(() => {
                dispatch(lensConsultationSun.actions.setAlreadyKnow2('yes'));
                saveRecommendedData()
                goto.route(routes.lensConsultation)
            }, 3000);
            return
        }
        dispatch(needExplorerSliceSun.actions.nextQuestion());
        dispatch(needExplorerSliceSun.actions.setShowSaved(false))
        // setShow(null)
    }
    const previousQuestion = () => {
        if(questionStep < 1) return
        dispatch(needExplorerSliceSun.actions.previousQuestion())
    }

    const currentQuestion = (questionId:string) =>{
        if(questionId === activeQuestion?.id) return 'current'
    }
    const isQuestionAnswered = (question: iNeedExplorerQuestion) => {
        const { type, situations, id} = question
        let answer = question?.answer
        const length = situations && situations[3].type ? 3 : situations?.length
        if( answer && Array.isArray(answer) && answer.length === 0)return true
        if(answer && Array.isArray(answer) && situations && answer.length !== length)return true
        if(answer && Array.isArray(answer) && situations && answer.some(v=> typeof v === 'object' || typeof v === 'undefined' || v === null))return true
        if(answer && Array.isArray(answer) && situations && answer[0] === undefined)return true
        if(type === 'FREQUENCY' && answer && answer < 1)return true
        if(answer === 0 || !answer)return true
        else return false
    }


    const disableNextButton = (question:iNeedExplorerQuestion) =>{
        const {id} = question
        const questionIdNotRequired = ['8.3', '8.4', '8.5', '8.8', '8.9', '8.10']
        if(questionIdNotRequired.includes(id))return false
        else return isQuestionAnswered(question)
    }

    const modalStyle = {
        height:'235px',
        width:'315px',
        borderRadius:'15px',
        top:'44%'
    }

    const getAnswers = (answer: number[] | number | undefined) =>{
        if(!Array.isArray(answer))return
        else setAnswerLength(answer.length)
    }

    const hideProgress = (hide:boolean) => {
        setTimeout(() => {
            setShowProgress(hide)
        }, 1500);
    }

    return (
        <div className={B() + ' page'}>
            
            {showProgress &&
                <Modal show={true} style={modalStyle} hideCloseButton={true}>
                    <ProgressPopup hideProgress={(hide)=>hideProgress(hide)}/>
                </Modal>
            }
            
            {/* {console.log('answered?',disableNextButton(activeQuestion))} */}
            
            {/* <Button
                click={backHandler}
                className={E('back')}
                experience={ExperienceType.SUN}
                text={t('my_practice_my_back')}
                iconPosition='left'
                icon={
                    <CircleArrow 
                        type='left'
                    />
                }
            /> */}

            <NeedExplorerQuestion question={activeQuestion} getAnswer={(ans:any) => getAnswers(ans)}/>

            {/* NOTE: CREATE SEPARATE COMPONENT */}
            <div className={E('footer')}>
                <div className={E('footer-previous-question')} onClick={previousQuestion}>
                <CircleArrow 
                    type='left'
                    background='#eeeff1'
                    />
                </div>
                <span className={E('footer-divider')}></span>


                {/* NOTE: CREATE SEPARATE COMPONENT */}
                <ul className={E('footer-pagination')}>
                    {needExplorerSunQuestionaire[0].questions.map((question:any, index:number) => {
                        return (
                            <li className={E('footer-pagination-page', currentQuestion(question.id))}></li>
                        )
                    })}
                </ul>


                <label className={E('saved')}>
                    {showLoader && 
                        <Loading dim='2rem' size='.25rem'/>
                    }
                    { show &&
                    <div className={E('saved-label')}>
                    {t('sun_exp_sun_solution_tint_yes', 'Saved')}
                    <CircleCheck color='#F35D1D' diameter={1.75}/>
                    </div>
                    }
                </label>

                <Button
                    experience={ExperienceType.SUN}
                    text={t('questionnaires_q_next')}
                    icon={
                        <CircleArrow type='right'/>
                    }
                    disabled={disableNextButton(activeQuestion)}
                    click={nextQuestion}
                />
            </div>
        </div>
    )
}

export default NeedExplorerSunExperience;