import React from 'react';
import { useSelector } from 'react-redux';
import iSunExperience from '../../../../interface/sun-experience.interface';
import { getApp } from '../../../../store/slices/app.slice';
import { getLensConsultationSun } from '../../../../store/slices/lens-consultation-sun-exp.slice';
import useBEM from '../../../../utils/hooks/useBEM';
import useTranslation from '../../../../utils/hooks/useTranslation';
import AdvantageCircle from './AdvantageCircle';

const Advantages = () => {

const [B,E] = useBEM('advantage');
    const { solution } = useSelector(getLensConsultationSun);
    const advantage = solution?.sun_experience?.advantages
    const disclaimer = solution?.sun_experience.disclaimer
    const twoDisclaimer = disclaimer && disclaimer?.length > 1 ? '**' : ''
    const { t } = useTranslation(useSelector(getApp));

    const advantageLabel = [
        'Mitigation of Reflections',
        'Comfort',
        'Changing Colors']

    return(
        <div className={B()}>
            <h5 className={E('gradient-text')}>{t('advantage_header_txt', 'My Advantages')}{twoDisclaimer}</h5>
            <div className={E('container')}>
                {advantage && advantage.map((adv:any, i:number) => {
                    return <AdvantageCircle radius={32} pct={adv.value} advName={adv.label} advText={adv.disclaimer} key={i} advId={adv.id}/>
                })}
            </div>
            {disclaimer && <div className={E('disclaimer')}>
                <span>
                    {t(disclaimer[0])}
                </span>
                {disclaimer[1] &&
                <div>
                    {t(disclaimer[1])}
                </div>}
            </div>}
        </div>
    )
}

export default Advantages;