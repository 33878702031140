import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import deliverySlice, { getDelivery } from '../../../store/slices/delivery.slice';
import { getSummary } from '../../../store/slices/summary.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import DeliveryAdvices from './DeliveryAdvices';
import DeliveryNavigation from './DeliveryNavigation';
import DeliveryPair from './DeliveryPair';
import DeliveryPrescription from './DeliveryPrescription';
import DeliverySummarySection from './DeliverySummarySection';
import DeliveryVisiontest from './DeliveryVisionTest';

interface Props {

}

const DeliverySummary : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-summary');
    const dispatch = useDispatch();
    const { scrollView, isShowSummaryDetails } = useSelector(getDelivery);
    const deliverySummaryRef = useRef(null);
    const { summaryList1, summaryList2 } = useSelector(getUser);
    const { experience } = useSelector(getApp);

    // HOOKS
    useEffect(() => {
        const observer = viewObserver();
        const lensObserver = lensViewObserver();
        return () => {
            observer.disconnect();
            lensObserver.disconnect()
        }; 
    }, [])

    useEffect(() => {        
        scrollToView();
    }, [scrollView])

    // METHODS
    const viewObserver = () => {
        const options = {
            root: document.querySelector('#parent'),
            threshold: 0
        }
        const observer = new IntersectionObserver(checkView, options);
        const targetElements = document.querySelectorAll(".target");
        targetElements.forEach(el => observer.observe(el));        
        return observer;
    }

    const lensViewObserver = () => {
        const options = {
            root: document.querySelector('#parent'),
            threshold: 0.25
        }
        const observer = new IntersectionObserver(checkLensView, options);
        const targetElements = document.querySelectorAll(".lens-transition-in-animation");
        targetElements.forEach(el => observer.observe(el));        
        return observer;
    }

    const checkView = (entries: any[]) => {
        entries.forEach( entry => {
            if(entry.isIntersecting) {
                const view = entry.target.getAttribute('data-view');
                if(view)dispatch(deliverySlice.actions.setActiveSection(view));                
            }
        });
    }

    const checkLensView = (entries: any[]) => {
        entries.forEach( entry => {
            if(entry.isIntersecting) {
                if(!entry.target)return;
                if(entry.target.classList.contains('lens-transition-in-animation--animate'))return;                
                entry.target.classList.add('lens-transition-in-animation--animate');
            }
        });
    }

    const onNull = (list:any) =>{
        if(!list)return false;
        const arr = list.filter((el: any) => el );
        const condition = arr && arr.length > 0 ? true: false;         
        return condition;
    }

    const scrollToView = () => {
        if(!scrollView)return;
        const el = document.querySelector(`.${scrollView}`);
        if(!el)return;
        el?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });        
    }
    return (
        <div className={B( isShowSummaryDetails ? 'show' : '' )}>
            
            <DeliveryNavigation 
                experience={experience}
                summaryDomRef={deliverySummaryRef}
            />
            

            <div id="parent" className={E('details')} ref={deliverySummaryRef}>
                
                <DeliverySummarySection name="prescription">
                    <DeliveryPrescription/>
                </DeliverySummarySection>
                {
                    onNull(summaryList1) && (
                        <DeliverySummarySection name="pair1">
                            <DeliveryPair details={summaryList1} pair={1}/>
                        </DeliverySummarySection>
                    )
                }
                {
                    onNull(summaryList2) && (
                        <DeliverySummarySection name="pair2">
                            <DeliveryPair details={summaryList2} pair={2}/>
                        </DeliverySummarySection>
                    )
                }

                <DeliverySummarySection name="advices">
                    <DeliveryAdvices/>
                </DeliverySummarySection>                

                <DeliverySummarySection name="vision_test">
                    <DeliveryVisiontest/>
                </DeliverySummarySection>

            </div>

        </div>
    )
}

export default DeliverySummary;