import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import Button from '../../components/ui/Button';
import InputText from '../../components/ui/InputText';
import routes from '../../constants/routes.constant';
import userSlice, { getUser } from '../../store/slices/user.slice';
import withAnimationTransition from '../../utils/hoc/withAnimationTransition';
import useBEM from '../../utils/hooks/useBEM';
import LensProposaBuilderlExperience from './components/LensProposaBuilderlExperience';
import LensProposalBuilderCard from './components/LensProposalBuilderCard';
import LensProposalBuilderFooter from './components/LensProposalBuilderFooter';
import needExplorerSlice, { getNeedExplorer } from '../../store/slices/need-explorer.slice';
import { KIDS_THEMES } from '../../constants/theme.constants';
import { getApp } from '../../store/slices/app.slice';
import Utils from '../../utils/Utils';
import lensThicknessSlice from '../../store/slices/lens-thickness.slice';
import lensConsultationSlice from '../../store/slices/lens-consultation.slice';
import useTranslation from '../../utils/hooks/useTranslation';
import measureSlice, { getMeasure } from '../../store/slices/measure.slice';
import summarySlice, { getSummary } from '../../store/slices/summary.slice';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import deliverySlice, { getDelivery } from '../../store/slices/delivery.slice';
import iUserStore from '../../store/interface/user-store.interface';
import ExperienceType from '../../types/experience.type';
import Icon from '../../components/ui/Icon';
import CircleArrow from '../../components/ui/CircleArrow';

interface Props {

}

const LensProposalBuilder : React.FC<Props>  = () =>  {
    // VARIABLES
    const user = useSelector(getUser);

    const [B, E] = useBEM(routes.lensProposalBuilder.id);
    const [isExperience, setIsExperience] = useState(false);
    const [modifyId, setModifyId] = useState(false);
    const [modifyIDField, setModifyIDField] = useState<string>('');

    const dispatch = useDispatch();
    const { needExplorer, measures, lensConsultation, delivery, prescription } = routes;
    const { generateRandomNumbers, getDateTimeDifference } = Utils();
    const [randomThemeIndex, setRandomThemeIndex] = useState<number[]>([]);
    const appStore = useSelector(getApp);
    const { theme, experience } = appStore;
    const themes = KIDS_THEMES;
    const {measurementData} = useSelector(getMeasure);
    const { 
        id, 
        summaryList1, 
        summaryList2, 
        sessionStart, 
        prescription: rx, 
        measures: measurements
    } = user;
    const {get} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const { t } = useTranslation(appStore);
    const ga = useGoogleAnalytics();
    const { validatedTimestamp, isSaved } = useSelector(getDelivery);
    const measuresState = useSelector(getMeasure);
    const deliveryMessages = [
        '',
        'sun_exp_complete_rx_and_mes',
        'sun_exp_complete_rx_and_mes',
        'sun_exp_complete_rx_mes_and_lc', 
        'sun_exp_complete_all'
    ]
    const deliveryMessagesIcon = [
        '',
        'Delivery_01',
        'Delivery_01',
        'Delivery_02', 
        'Delivery_03'
    ]

    // HOOKS
    useEffect(() => {
        const currentTimeStamp = new Date();

        if(user.lensConsultation.isValidated && sessionStart){
            dispatch(userSlice.actions.sessionStart(undefined));
            const timediff = getDateTimeDifference(new Date(sessionStart), currentTimeStamp);
            ga.event({
                category: 'Patient Journey',
                action: 'Engagement - LC',
                label: timediff
            });
        }

        if(validatedTimestamp){
            dispatch(deliverySlice.actions.clearValidatedTimestamp());
            const timediff = getDateTimeDifference(new Date(validatedTimestamp), currentTimeStamp);
            ga.event({
                category: 'Patient Journey',
                action: 'Engagement - Delivery',
                label: timediff
            });
        }

        if(measuresState.sessionStart && measuresState.isSavePairData) {
            const timediff = getDateTimeDifference(new Date(measuresState.sessionStart), currentTimeStamp);
            ga.event({
                category: 'Measurement',
                action: 'Measurement Complete',
                label: `${timediff} - pair${measuresState.isSavePairData}`
            });
            dispatch(measureSlice.actions.savePairData(0));
            dispatch(measureSlice.actions.sessionStart(undefined));
        }
    }, [])

    useEffect(() => {
        setModifyIDField(id || '');
    }, [id])

    useEffect(() => {
        setRandomThemeIndex(generateRandomNumbers(themes.length - 1));
    }, [theme])

    useEffect(()=>{
        if(measurementData.three.length > 0){
            dispatch(userSlice.actions.saveMeasuresData(measurementData));
        }
    }, [measurementData])
    useEffect(()=>{
        if(lensConsultation)
        dispatch(userSlice.actions.saveSummary1(summaryList1));
    }, [summaryList1])
    useEffect(()=>{
        dispatch(userSlice.actions.saveSummary2(summaryList2));
    }, [summaryList2])

    useEffect(() => {
        setTimeout(() => setIsExperience(false), 250)
    }, [experience])

    // METHODS
    const footerActionListener = (action: string) => {
        if(action === 'id')
            setModifyId(true);
        else
            setIsExperience(!isExperience);
    }
    const getWord = (value:string) =>{

        return  value.substr(0, value.indexOf(':'));
    }
    const lensConsultationFooter = () => {
        const checker = checkIfAllModuleAreCompleted()
        if(IsModuleCompleted(lensConsultation.label)){
            <span></span>
         }
        if(checker && !IsModuleCompleted(lensConsultation.label)){
            const completedModules = checker.map((label:string, k:number) => {
                return (<span key={k}>{t(label)}</span>)
            })


            return checker.length > 0 && <label className="impacted">
                {getWord(t('lens_proposal_builder_lpb__need_explorer_2_'))}: {completedModules}
            </label>
        }else{
            return false
        }
    }

    const checkIfAllModuleAreCompleted = () => {
        const completed = []

        IsModuleCompleted(needExplorer.label) && completed.push(needExplorer.label)
        IsModuleCompleted(prescription.label) && completed.push(prescription.label)
        IsModuleCompleted(measures.label) && completed.push(measures.label)
        IsModuleCompleted(lensConsultation.label) && completed.push(lensConsultation.label)
        IsModuleCompleted(delivery.label) && completed.push(delivery.label)

        return completed.length > 0 ? completed : false
    }

    const modalOnClose = () => {
        setModifyId(false);
    }

    const idOnchange = (event: any) => {
        setModifyIDField(event.target.value);
    }

    // TODO: Create utility function/ or custom hooks
    const transformNeedExplorer = (userData: iUserStore) => {
        let newData = userData.needExplorer;

        // Note: set canSelectMultiple: false for question '6.3' for kids
        // select only one answer
        if(userData.ageCategory?.value === 'kids'){
            newData = {
                ...newData,
                questions: newData.questions.map( q => {
                    if(q.id === '6'){
                        return {
                            ...q,
                            questions: q.questions.map( x => {
                                if(
                                    x.id === '6.3' &&
                                    x.canSelectMultiple &&
                                    x.answer
                                ){
                                    return {
                                        ...x,
                                        answer: (x.answer as any[])[0],
                                        canSelectMultiple: false
                                    }
                                }
                                return x;
                            })
                        }
                    }
                    return q;
                })
            }
        }
        return newData;
    }

    const editId = async () => {
        const user = await get('userStore',String(modifyIDField));
        if(!modifyIDField || modifyIDField.length < 6 || !user){
            alert('You have entered an invalid  or not existing ID, Please Try Again');
            return;
        }
        dispatch(userSlice.actions.setUserData(user));
        dispatch(lensThicknessSlice.actions.setLensThickness(user.lensThickness));
        const finalNeedExplorer = transformNeedExplorer(user);
        dispatch(needExplorerSlice.actions.setData(finalNeedExplorer));
        dispatch(lensConsultationSlice.actions.saveLensRecommendationDataFromStore(user.lensRecommendation));
        dispatch(measureSlice.actions.saveMeasureFromStore(user.measures));
        dispatch(summarySlice.actions.saveSummary1(user.summaryList1));
        dispatch(summarySlice.actions.saveSummary2(user.summaryList2));
        dispatch(measureSlice.actions.saveMeasureFromStore(user.measures));
        dispatch(deliverySlice.actions.reset({all: true}));
        setModifyId(false);
    }

    const IsModuleCompleted = (module: string) => {
        // Validate here if any of the module is completed and return it's appropriate properties
        switch(module) {
            case needExplorer.label: return isGeneralComplete() ? true : false
            case prescription.label: return isPrescriptionComplete() ? true : false
            case measures.label: return isMeasuresComplete() ? true : false
            case lensConsultation.label: return isLensConsultationComplete() ? true : false
            case delivery.label: return false
            default: return false
        }

    }
    const closeExperience = () => {
        if (isExperience) {
            setIsExperience(!isExperience)
        }
    }

    const isGeneralComplete = () => {
        const { needExplorer } = user

        return needExplorer.isGeneralDone
    }

    const isPrescriptionComplete = () => {
        const { prescription } = user

        return (prescription.values && prescription.values.sphere.od) ? true : false
    }
    const isMeasuresComplete = () => {
        const { measures } = user
        return measures.one.length > 0 &&
            measures.two.length > 0 &&
            measures.three.length > 0
    }
    const isLensConsultationComplete = () => {
        const { lensConsultation } = user
        const { isValidated } = lensConsultation

        return isValidated;
    }

    const getRandomTheme = (index: number) => {
        if(theme.id !== 'multi')return undefined;
        const randomIndex = randomThemeIndex[index];
        if(!randomIndex && randomIndex!==0)return undefined;
        return themes[randomIndex];
    }

    const getDeliveryMessageScore = () => {
        let score = 0;

        if(rx.values.sphere.od)score += 1;
        if(measurements.one.length)score += 1;
        if(IsModuleCompleted(lensConsultation.label))score += 1;
        if(isSaved)score += 1;

        return score;
    }

    const isSunXP = () => {
        return experience === ExperienceType.SUN
    }

    return (
        <div className={B(experience)}>

            <div className={E('main', [isExperience ? 'experienced' : '', experience])}>

                {
                    experience !== ExperienceType.SUN &&
                    <LensProposalBuilderCard theme={getRandomTheme(0)} route={needExplorer} label={needExplorer.label} icon="need-explorer"  completed={IsModuleCompleted(needExplorer.label)}/>
                }

                <LensProposalBuilderCard 
                    id="prescription"
                    theme={getRandomTheme(1)} 
                    route={routes.prescription} 
                    label={'lens_proposal_builder_lpb__prescription'} 
                    icon={isSunXP() ? 'prescription-sun' : 'prescription'} 
                    completed={IsModuleCompleted(prescription.label)}
                />

                {/* label={measures.label}  */}
                <LensProposalBuilderCard 
                    id="measures"
                    theme={getRandomTheme(2)} route={measures} label={'lens_proposal_builder_lpb__measures'} icon="measures" completed={IsModuleCompleted(measures.label)}/>

                <LensProposalBuilderCard 
                    id="lens-consultation"
                    theme={getRandomTheme(3)} route={lensConsultation} label={lensConsultation.label} 
                    icon={isSunXP() ? 'LensConsultation_Blue' : 'lens-consultation'} 
                    footer={lensConsultationFooter()}
                    className="lens-consultation" completed={IsModuleCompleted(lensConsultation.label)}/>
                
                <LensProposalBuilderCard 
                    id="delivery"
                    theme={getRandomTheme(4)} 
                    route={delivery} 
                    label={delivery.label} 
                    className={`delivery ${getDeliveryMessageScore() < 4 ? 'incomplete' : ''}`} 
                    icon="delivery" 
                    completed={IsModuleCompleted(delivery.label)}
                >

                    {
                        isSunXP() &&
                        <div className={E('message-status', getDeliveryMessageScore() >= 4 ? 'complete': '')}>
                            
                            <Icon className={E('message-status-icon')} name={deliveryMessagesIcon[getDeliveryMessageScore()]}/>

                            {t(deliveryMessages[getDeliveryMessageScore()])}
                            
                            {
                                getDeliveryMessageScore() >= 4 &&
                                <Button icon={'plus-white-outline'} type='primary' circle={true}/>
                            }
                        </div>
                    }

                </LensProposalBuilderCard>

                <LensProposalBuilderFooter isExperience={isExperience} onActionTrigger={footerActionListener}/>
                <div className={E('overlay')} onClick={closeExperience}/>
            </div>

            <LensProposaBuilderlExperience/>


            <Modal show={modifyId} onClose={() => modalOnClose()}>
                <div className={E('search-id-popup')}>
                    <h2>{t('search_patient_id')}</h2>
                    <p>{t('data_retention_60_days')}</p>
                    <div>
                        <InputText
                            value={modifyIDField}
                            placeholder={t('patient_search_id')}
                            maxlength={25}
                            onChange={idOnchange}/>

                        <Button 
                            text={t('home_hom_next')} 
                            // icon="right-arrow-circle-white" 
                            icon={
                                <CircleArrow type='right' size='1rem'/>
                            }
                            click={editId}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default withAnimationTransition(LensProposalBuilder, {
    entrance: 'FADE_IN'
});
