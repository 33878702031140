import React from 'react';
import Button from '../../../components/ui/Button';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import { useSelector, useDispatch } from 'react-redux';
import routes from '../../../constants/routes.constant';
import useGoto from '../../../utils/hooks/useGoto';
import needExplorerSlice from '../../../store/slices/need-explorer.slice';
import lensThicknessSlice from '../../../store/slices/lens-thickness.slice';
import lensConsultationSlice from '../../../store/slices/lens-consultation.slice';
import measureSlice from '../../../store/slices/measure.slice';
import summarySlice from '../../../store/slices/summary.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import prescriptionSlice from '../../../store/slices/prescription.slice';
import SvgIcon from '../../../components/ui/svg-icon/SvgIcon';
import CircleArrow from '../../../components/ui/CircleArrow';
import lensConsultationSun from '../../../store/slices/lens-consultation-sun-exp.slice';
import needExplorerSliceSun from '../../../store/slices/need-explorer-sun.slice';
import ExperienceType from '../../../types/experience.type';


interface Props {

}

const ContinueWithID : React.FC<Props>  = () =>  {
    // VARIABLES
    const { id } = useSelector(getUser);
    const dispatch = useDispatch();
    const goto = useGoto();
    const { t } = useTranslation(useSelector(getApp));
    // HOOKS

    // METHODS
    const clearUser = () => {
        dispatch(userSlice.actions.clearUserState());
        dispatch(needExplorerSlice.actions.resetData());
        dispatch(needExplorerSliceSun.actions.resetData())
        dispatch(lensThicknessSlice.actions.resetData());
        dispatch(lensConsultationSlice.actions.resetData());
        dispatch(lensConsultationSun.actions.resetData());
        dispatch(measureSlice.actions.resetData());
        dispatch(summarySlice.actions.resetData());
        dispatch(prescriptionSlice.actions.resetData());
    }

    const continueWithID = () => {
        dispatch(needExplorerSlice.actions.changeInitialStateToStandard())
        goto.routeWithTransition(
            routes.home, 
            routes.lensProposalBuilder,
            'SLIDE_LEFT',
            500
        );
    }

    return (
        <div className="continue-with-id">
            <Button 
                outlined={false} 
                icon={
                    <SvgIcon name='close' height='2.5rem'/>
                } 
                type="secondary" 
                click={clearUser}
            />
    
            <div>
                <label>ID {id}</label>
                <Button 
                    outlined={false} 
                    text={t('home_hom_cont')}
                    icon={
                        <CircleArrow type='right' background='#eee' diameter={2} />
                    }
                    type="secondary" click={continueWithID}/>
            </div>
        </div>
    )
}

export default ContinueWithID;