import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import Text from '../../../components/ui/Text';
import iLensProposalField from '../../../interface/lens-proposal-field.interface';
import { getApp } from '../../../store/slices/app.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';



interface Props {
    pair: number,
    type: string,
    price: string | undefined,
    description: string,
    benefits: []
}

const DeliveryPairDetails: React.FC<Props> = ({
    pair,
    type,
    price,
    description,
    benefits
}) => {
    // VARIABLES
    const [B, E] = useBEM('delivery-pair-details');
    const { t } = useTranslation(useSelector(getApp));
    const { complementaryEquipment } = useSelector(getUser);
    const { experience } = useSelector(getApp);
    // HOOKS

    // METHODS
    const groupBy = (data: any[], type: number) => {
        const newData = data.filter((
            filter) =>
            parseInt(filter.type) === type)
        return newData;
    }

    const withComplementaryEquipment = () => {
        return complementaryEquipment && complementaryEquipment[0].value;
    }

    const getPairVisionType = (): string => {
        if (pair === 1) return t(type);
        if (!withComplementaryEquipment()) return t(type);
        const translationKey: {
            [key: string]: string
        } = {
            prescription_rx_driving: 'delivery_design_lens6',
            prescription_rx_computer: 'delivery_design_lens4',
            prescription_rx_sun: 'delivery_design_lens5'
        }
        const label = (complementaryEquipment as iLensProposalField[])[0].value?.label;
        return t(translationKey[label as string]);
    }

    return (
        <div className={B(String(pair))}>
            <div className={E('info')}>
                <label>{price}</label>
                <h2>
                    {pair === 1 ? t('delivery_del__pair_1') : t('delivery_del__pair_2')}
                    <span>{getPairVisionType()}</span>
                </h2>

                <Text type="white">
                    {t(description)}
                </Text>

                {benefits && (
                    <ul>
                        {
                            groupBy(benefits, 1).map((desc: any, iii: number) => (
                                <li key={iii}>
                                    <Icon type="png" name={desc.icon + "-white"} className={`${E('benefit')}--${experience}`} />
                                    <label>{t(desc.label)}</label>
                                </li>
                            ))
                        }
                    </ul>
                )
                }
                {(benefits && benefits.length > 3) && (
                    <ul>
                        {
                            groupBy(benefits, 2).map((desc: any, iii: number) => (
                                <li key={iii}>
                                    <Icon type="png" name={desc.icon+ "-white"} className={`${E('benefit')}--${experience}`} />
                                    <label>{t(desc.label)}</label>
                                </li>
                            ))
                        }
                    </ul>
                )
                }
            </div>
        </div>

    )
}

export default DeliveryPairDetails;
