import React, { useState } from 'react';
import useBEM from '../../../../utils/hooks/useBEM';
import Utils from '../../../../utils/Utils';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import Icon from '../../../../components/ui/Icon';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answers: number[]) => void
}

const RankingGroupImage: React.FC<Props> = ({
    question,
    onAnswer
}) => {
    // VARIABLES
    const [B, E] = useBEM('ranking-group-image');
    const { cloneObject } = Utils();
    const { t } = useTranslation(useSelector(getApp));
    const [rate, setRate] = useState<number[]>([1, 1, 1, 1, 1, 1, 1, 1, 1]);
    // HOOKS

    // METHODS
    const handleOnClick = (data: number, index: number) => {
        const newAnswers = cloneObject(question.answer) as number[];
        newAnswers[index] = data;
        setRate(newAnswers);
        if (onAnswer) onAnswer(newAnswers);

    }

    const getRankingValue = (index: number) => {
        return (question.answer as number[])[index];
    }

    return (
        <div className={B()}>
            {
                question.situations && question.situations.map((slider, index) => (

                    <div className={` ${E('box')} ${slider.type ? 'clear' : ''}` } key={index}>
                        <Icon
                            type='image'
                            name={slider.image || ''}
                            className={` ${'box-bg'} ${slider.type ? 'clear' : ''}`}
                            background={slider.type ? false : true}
                            position='center'
                        />

                        {
                            
                            <div className={`${'label-group'} ${slider.type ? '' : 'withImage'}`}>
                                <label className={slider.type ? '-black' : ''} >
                                    {t(slider.label)}

                                </label>
                                <label className='sublabel'>
                                    {slider.subLabel}
                                </label>
                               { !slider.type &&  <div className={E('ranking-container')}>
                                    {[...Array(4)].map((item, i) => {
                                        const givenRanking = i + 1;
                                        const ansRanking = getRankingValue(index)
                                        const style = givenRanking === (ansRanking) ? "ranking-selected" : "ranking";

                                        return (
                                                <div
                                                    className={E(style)}
                                                    onClick={() => handleOnClick(givenRanking, index)}
                                                >{givenRanking}</div>
                                        );

                                    })
                                    }
                                </div> }
                            </div>
                        }

                    </div>
                ))
            }

        </div>
    )
}

export default RankingGroupImage;
