import iProductFamily from "../interface/product-family.interface";
import ComplementaryEquipmentType from "../types/complementary-equipment.type";
import LensCategoryType from "../types/lens-category.type";
import LensEnhanceType from "../types/lens-enhance.type";
import LensFamilyType from "../types/lens-family.type";
import LensProtectType from "../types/lens-protect.type";

import variluxCard from './../assets/images/varilux-card.png';
import experioCard from './../assets/images/experio-card.jpg';
import blueUVCard from './../assets/images/blue-uv-card.jpg';
import eyezenCard from './../assets/images/eyezen-card.png';
import crizalCard from './../assets/images/crizal-card.png';
import optifogCard from './../assets/images/optifog-card.png';
import transitionsCard from './../assets/images/transitions-card.png';
import stellestCard2 from './../assets/images/stellest-card2.png';

import comformaxBlur from './../assets/svg/temp/Varilux_Stairs_ComfortMax.svg';
import eseriesBlur from './../assets/svg/temp/Varilux_Stairs_Eseries.svg';
import libertyBlur from './../assets/svg/temp/Varilux_Stairs_Liberty.svg';
import physioBlur from './../assets/svg/temp/Varilux_Stairs_Physio.svg';
import sseriesBlur from './../assets/svg/temp/Varilux_Stairs_Sseries.svg';
import xseriesBlur from './../assets/svg/temp/Varilux_Stairs_Xseries.svg';
import standardBlur from './../assets/svg/temp/Varilux_Stairs_STANDARD.svg';
import comfor3Blur from './../assets/svg/temp/Varilux_Stairs_Comfort3.svg';

import comformaxLine from './../assets/svg/temp/Varilux_Stairs_ComfortMax-Line.svg';
import eseriesLine from './../assets/svg/temp/Varilux_Stairs_Eseries-Line.svg';
import libertyLine from './../assets/svg/temp/Varilux_Stairs_Liberty-Line.svg';
import physioLine from './../assets/svg/temp/Varilux_Stairs_Physio-Line.svg';
import sseriesLine from './../assets/svg/temp/Varilux_Stairs_Sseries-Line.svg';
import xseriesLine from './../assets/svg/temp/Varilux_Stairs_Xseries-Line.svg';
import standardLine from './../assets/svg/temp/Varilux_Stairs_STANDARD-Line.svg';
import comfor3Line from './../assets/svg/temp/Varilux_Stairs_Comfort3-Line.svg';

import iLensDemo from "../interface/lens-demo";

// DEMO
// import variluxScooter from './../assets/videos/Varilux_Xseries_Trottinette_ESSILOR.mp4';
// import standardScooter from './../assets/videos/Varilux_Xseries_Trottinette_STANDARD.mp4';
// import variluxStairs from './../assets/videos/Varilux_Eseries_Urban_Stairs_ESSILOR.mp4';
// import standardStairs from './../assets/videos/Varilux_Eseries_Urban_Stairs_STANDARD.mp4';


// import comfortInFrontOfComputer from './../assets/videos/Varilux_Transversal_Comfort_3.0.mp4';
// import comfortMaxInFrontOfComputer from './../assets/videos/Varilux_Transversal_ComfortMax.mp4';
// import eSeriesInFrontOfComputer from './../assets/videos/Varilux_Transversal_eSeries.mp4';
// import libertyInFrontOfComputer from './../assets/videos/Varilux_Transversal_Liberty.mp4';
// import physioInFrontOfComputer from './../assets/videos/Varilux_Transversal_Physio.mp4';
// import standardInFrontOfComputer from './../assets/videos/Varilux_Transversal_Standard.mp4';
// import xSeriesInFrontOfComputer from './../assets/videos/Varilux_Transversal_xSeries.mp4';

// import comfortMaxJournalist from './../assets/videos/ComfortMax_Journalist_ESSILOR.mp4';
// import standardJournalist from './../assets/videos/ComfortMax_Journalist_STANDARD.mp4';

// import roadpilotDay from './../assets/videos/Varilux_Roadpilot_Jour_ESSILOR.mp4';
// import standardDay from './../assets/videos/Varilux_Roadpilot_Jour_STANDARD.mp4';
// import roadpilotNight from './../assets/videos/Varilux_Roadpilot_Nuit_ESSILOR.mp4';
// import standardNight from './../assets/videos/Varilux_Roadpilot_Nuit_STANDARD.mp4';

// import xSeriesStairsEffect from './../assets/videos/Varilux_Stairs_Xseries.mp4';
// import comfort3StairsEffect from './../assets/videos/Varilux_Stairs_Comfort3.mp4';
// import comfortMaxStairsEffect from './../assets/videos/Varilux_Stairs_ComfortMax.mp4';
// import libertyStairsEffect from './../assets/videos/Varilux_Stairs_Liberty.mp4';
// import physioStairsEffect from './../assets/videos/Varilux_Stairs_Physio.mp4';
// import sSeriesStairsEffect from './../assets/videos/Varilux_Stairs_Sseries.mp4';
// import standardStairsEffect from './../assets/videos/Varilux_Stairs_STANDARD.mp4';
// import eSeriesStairsEffect from './../assets/videos/Varilux_Stairs_Eseries.mp4';

// import epsFamilyScene from './../assets/videos/BlueUVfilter_EPS_Protect_ESSILOR.mp4';
// import standardFamilyScene from './../assets/videos/BlueUVfilter_EPS_Protect_STANDARD.mp4';

import ProductDemoType from "../types/product-demo.type";
import settings from "./settings.constants";
import PRODUCT_LENS_DEMO from "./lens-demo/lens-demo.constants";

// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';
// import test from './../assets/videos/test';


const varilux = [
    'Varilux X Series',
    'Varilux E Series',
    'Varilux Physio',
    'Varilux Comfort Max',
    'Varlix Liberty',
    'Varilux Digitime'
]

const eyezen = [
    'Eyezen Boost',
    'Eyezen® Kids',
    'Eyezen Start'
]

const blueUV = [
    'eye_protect_system',
    'Blue UV Filter System',
    'Transitions Signature Gen 8'
]

const PRODUCTS = [
    {
        label:"Varilux",
        background: 'varilux-card',
        logo:'varilux',
        subProducts: varilux
    },
    {
        label:"Eyezen",
        background: 'eyezen-card',
        logo:'eyezen',
        subProducts: eyezen
    },
    {
        label:"BlueUV Filter System",
        background: 'blue-uv-card',
        // logo:'varilux',
        subProducts: blueUV

    },
    {
        label:"Varilux",
        background: 'varilux-card',
        logo: 'varilux',
        subProducts: varilux
    }
];

const BRANDS = [
    {
        id: 'varilux',
        label: 'Varilux',
        background: variluxCard,
        logo:'varilux',
        products: [
            {
                id: 'varilux_x_series',
                label: 'Varilux X Series',                
            },
            {
                id: 'varilux_e_series',
                label: 'Varilux E Series',                
            },
            {
                id: 'varilux_s_series',
                label: 'Varilux S Series',                
            },
            {
                id: 'varilux_physio_3.0',
                label: 'Varilux Physio 3.0',                
            },
            {
                id: 'varilux_comfort_max',
                label: 'Varilux Comfort Max',
            },
            {
                id: 'varilux_liberty_3.0',
                label: 'Varilux Liberty 3.0', 
            },
            {
                id: 'varilux_digitime',
                label:'Varilux Digitime ',
            }
        ]
    },
    {
        id: 'eyezen',
        label: 'Eyezen',
        background: eyezenCard,
        logo:'eyezen',
        products: [
            {
                id: 'eyezen_boost',
                label:'Eyezen Boost',                
            },
            {
                id: 'eyezen_start',
                label:'Eyezen Start',
            },
            {
                id: 'eyezen_kids',
                label:'Eyezen® Kids',                
            },
        ]
    },
    {
        id: 'BlueUV_Filter_System',
        label: 'BlueUV Filter System',
        background: blueUVCard,
        logo:'BlueUV_FilterSystem',
        
        products: [
            {
                id: 'eye_protect_system',
                label:'Eye Protect System',                
            },
            {
                id: 'Blue_UV_Capture',
                label:'Blue UV Filter System',
            },
        ]
    },
    {
        id: 'experio',
        label: 'Experio',
        background: experioCard,
        products: [
            {
                id: 'Xperio_UV',
                label:'Xperio UV',                
            }
        ]
    },
    // {
    //     id: 'xperio',
    //     label: 'Xperio',
    //     background: 'eyezen-card',
    //     products: [
    //         {
    //             id: 'Xperio_React',
    //             label:'Xperio React',                
    //         },
    //         {
    //             id: 'Xperio_Mirrors',
    //             label:'Xperio Mirrors',
    //         },
    //         {
    //             id: 'Xperio_Polarised',
    //             label:'Xperio Polarised',
    //         },
    //         {
    //             id: 'Xperio_Tinted',
    //             label:'Xperio Tinted',
    //         },
    //     ]
    // },
    // {
    //     id: 'crizal',
    //     label: 'Crizal',
    //     background: 'varilux-card',
    //     products: [
    //         {
    //             id: 'Crizal_Easy',
    //             label:'Crizal Easy',                
    //         },
    //         {
    //             id: 'Crizal_Forte',
    //             label:'Crizal Forte',                
    //         },
    //         {
    //             id: 'Crizal_Prevencia',
    //             label:'Crizal Prevencia',                
    //         },
    //         {
    //             id: 'Crizal_Drive',
    //             label:'Crizal Drive',                
    //         },
    //     ]
    // }
]

const FAMILIES = {
    correct: {
        label: 'Correct',
        brands: [
            BRANDS[0],
            BRANDS[1],            
        ]
    },
    protect: {
        label: 'Protect',
        brands: [
            BRANDS[2],     
            BRANDS[3],     
            // BRANDS[4],     
        ]
    },
    enhance: {
        label: 'Enhance',
        brands: [
            // BRANDS[5]       
        ]
    }
}



const TEMPORARY_PRODUCT_LIST: iProductFamily[] = [
    {
        id: LensFamilyType.CORRECT,
        label: 'lens_consultation_lccorrect',
        brands: [
            {
                id: 'varilux',
                label: 'Varilux',
                background: variluxCard,
                logo:'varilux',

                products: [
                    {
                        id: 'varilux_x_series',
                        label: 'Varilux® X Series',
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        enabled: true,
                        downgrade: [`varilux_x_series`, `varilux_e_series`, `varilux_physio_30`],
                        subProducts:[
                            {id: "1varilux_x_4D", label: "Varilux® X 4D", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_4D", label: "Varilux® X 4D", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_4D", label: "Varilux® X 4D", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_4D", label: "Varilux® X 4D", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_4D", label: "Varilux® X 4D", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_x_design", label: "Varilux X Design", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_design", label: "Varilux X Design", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_design", label: "Varilux X Design", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_design", label: "Varilux X Design", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_design", label: "Varilux X Design", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_x_design_short", label: "Varilux® X design short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_design_short", label: "Varilux® X design short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_design_short", label: "Varilux® X design short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_design_short", label: "Varilux® X design short", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_design_short", label: "Varilux® X design short", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_x_f-360", label: "Varilux® X f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_f-360", label: "Varilux® X f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_f-360", label: "Varilux® X f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_f-360", label: "Varilux® X f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_f-360", label: "Varilux® X f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_x_track", label: "Varilux® X track™", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_track", label: "Varilux® X track™", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_track", label: "Varilux® X track™", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_track", label: "Varilux® X track™", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_track", label: "Varilux® X track™", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_x_track_f-360", label: "Varilux® X track™ f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_x_track_f-360", label: "Varilux® X track™ f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_x_track_f-360", label: "Varilux® X track™ f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_x_track_f-360", label: "Varilux® X track™ f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_x_track_f-360", label: "Varilux® X track™ f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_xclusive_4D", label: "Varilux® Xclusive™ 4D", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_xclusive_4D", label: "Varilux® Xclusive™ 4D", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_xclusive_4D", label: "Varilux® Xclusive™ 4D", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_xclusive_4D", label: "Varilux® Xclusive™ 4D", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_xclusive_4D", label: "Varilux® Xclusive™ 4D", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'questionnaires_q_recomm_short_description',
                        description: 'questionnaires_q_recomm_long_desc',
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                            {
                                icon: "effortless-vision",
                                label: "pair_1_tab_del_p1__effortless_",
                                type: 1
                            },
                            {
                                icon: "balance",
                                label: "pair_1_tab_del_p1__balance_when_moving",
                                type: 2
                            },
                            {
                                icon: "head-move",
                                label: "pair_1_tab_del_p1_visual_fluidity",
                                type: 2

                            },
                        ]
                    },
                    {
                        id: 'varilux_sunmax',
                        label: 'Varilux® SunMax',
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        enabled: true,
                        downgrade: [],
                        subProducts: [
                            {id: "1varilux_sunMax", label: "Varilux® SunMax", index: 1.59,price:0, enabled: true },
                            {id: "2varilux_sunMax", label: "Varilux® SunMax", index: 1.6,price:0, enabled: true }
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'varilux_s_series',
                        label: 'Varilux® S™ Series',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        downgrade: [`varilux_s_series`, `varilux_e_series`, `varilux_physio_30`],
                        subProducts: [
                            {id: "1varilux_s_4D", label: "Varilux® S™ 4D", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_s_4D", label: "Varilux® S™ 4D", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_s_4D", label: "Varilux® S™ 4D", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_s_4D", label: "Varilux® S™ 4D", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_s_4D", label: "Varilux® S™ 4D", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_s_design", label: "Varilux® S™ design", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_s_design", label: "Varilux® S™ design", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_s_design", label: "Varilux® S™ design", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_s_design", label: "Varilux® S™ design", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_s_design", label: "Varilux® S™ design", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_s_design_short", label: "Varilux® S™ design short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_s_design_short", label: "Varilux® S™ design short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_s_design_short", label: "Varilux® S™ design short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_s_design_short", label: "Varilux® S™ design short", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_s_design_short", label: "Varilux® S™ design short", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_s_f-360", label: "Varilux® S™ f-360°", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                            {
                                icon: "effortless-vision",
                                label: "pair_1_tab_del_p1__effortless_",
                                type: 1
                            },
                            {
                                icon: "balance",
                                label: "pair_1_tab_del_p1__balance_when_moving",
                                type: 2
                            },
                        ]
                    },
                    {
                        id: 'varilux_physio_30',
                        label: 'Varilux® Physio® 3.0',
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        enabled: true,
                        downgrade: [ `varilux_physio_30`, `varilux_comfort_max`,  `varilux_liberty_30`],
                        subProducts: [
                            {id: "1varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_physio_30", label: "Varilux® Physio® 3.0", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_physio_30_f-360", label: "Varilux® Physio® 3.0 f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_physio_30_f-360_far_vision", label: "Varilux® Physio® 3.0 f-360° Far Vision", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_physio_30_f-360_near_vision", label: "Varilux® Physio® 3.0 f-360° Near Vision", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_physio_30_short", label: "Varilux® Physio® 3.0 short", index: 1.74,price:0, enabled: true }
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                            {
                                icon: "effortless-vision",
                                label: "pair_1_tab_del_p1__effortless_",
                                type: 1
                            },
                        ]
                    },
                    {
                        id: 'varilux_liberty_30',
                        label: 'Varilux® Liberty™ 3.0',
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        enabled: true,
                        downgrade: [],
                        subProducts: [
                            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.5,price:0, enabled: true },
                            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.59,price:0, enabled: true },
                            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.6,price:0, enabled: true },
                            {id: "varilux_liberty_30", label: "Varilux® Liberty™ 3.0", index: 1.67,price:0, enabled: true },
                            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "varilux_liberty_30_f-360", label: "Varilux® Liberty™ 3.0 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.5,price:0, enabled: true },
                            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.59,price:0, enabled: true },
                            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.6,price:0, enabled: true },
                            {id: "varilux_liberty_30_short", label: "Varilux® Liberty™ 3.0 short", index: 1.67,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'varilux_e_series',
                        label: 'Varilux® E Series',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        downgrade: [`varilux_e_series`,`varilux_physio_30`,  `varilux_comfort_max`],
                        subProducts: [
                            {id: "1varilux_e_design", label: "Varilux® E design", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_e_design", label: "Varilux® E design", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_e_design", label: "Varilux® E design", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_e_design", label: "Varilux® E design", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_e_design", label: "Varilux® E design", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_e_design_short", label: "Varilux® E design short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_e_design_short", label: "Varilux® E design short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_e_design_short", label: "Varilux® E design short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_e_design_short", label: "Varilux® E design short", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_e_design_short", label: "Varilux® E design short", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_e_f-360", label: "Varilux® E f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_e_f-360", label: "Varilux® E f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_e_f-360", label: "Varilux® E f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_e_f-360", label: "Varilux® E f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_e_f-360", label: "Varilux® E f-360°", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                            {
                                icon: "effortless-vision",
                                label: "pair_1_tab_del_p1__effortless_",
                                type: 1
                            },
                            {
                                icon: "balance",
                                label: "pair_1_tab_del_p1__balance_when_moving",
                                type: 2
                            },
                            
                        ]
                    },
                    {
                        id: 'varilux_sunfit',
                        label: 'Varilux® SunFit',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        subProducts: [
                            {id: "1varilux_sunfit", label: "Varilux® Sunfit", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_sunfit", label: "Varilux® Sunfit", index: 1.59,price:0, enabled: true },
                            {id: "3arilux_sunfit", label: "Varilux® Sunfit", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_sunfit", label: "Varilux® Sunfit", index: 1.67,price:0, enabled: true },
                            {id: "5arilux_sunfit", label: "Varilux® Sunfit", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "contrast-sensitivity",
                                label: "sun_exp_lensbenefitsvariluxsun1",
                                type: 1,
                            },
                            {
                                icon: "field-vision",
                                label: "sun_exp_lensbenefitsvariluxsun2",
                                type: 1
                            },
                            {
                                icon: "picto-devices",
                                label: "sun_exp_lensbenefitsvariluxsun3",
                                type: 1
                            },
                            {
                                icon: "smooth-transition",
                                label: "sun_exp_lensbenefitsvariluxsun4",
                                type: 2
                            },
                            {
                                icon: "vision-comfort",
                                label: "sun_exp_lensbenefitsvariluxsun5",
                                type: 2
                            }
                        ]
                        },
                    {
                        id: 'varilux_digitime',
                        label: 'Varilux® Digitime™',
                       
                        complementaryEquipments: [ComplementaryEquipmentType.COMPUTER],
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        enabled: true,
                        downgrade: [`varilux_digitime`, ``,  ``],
                        subProducts: [
                            {id: "1varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_mid", label: "Varilux Digitime Mid", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_mid_f-360", label: "Varilux® Digitime™ Mid f-360°", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_mid_plus", label: "Varilux® Digitime™ Mid Plus", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_near", label: "Varilux® Digitime™ Near", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_near_f-360", label: "Varilux® Digitime™ Near f-360°", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_near_plus", label: "Varilux® Digitime™ Near Plus", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_room", label: "Varilux® Digitime™ Room", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_room_f-360", label: "Varilux® Digitime™ Room f-360°", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_digitime_room_plus", label: "Varilux® Digitime™ Room Plus", index: 1.67,price:0, enabled: true },
                        ],
                        smallTitle: 'questionnaires_q_recom_short_desc_var_digi',
                        description: 'questionnaires_q_recom_long_desc_var_digi',
                        benefits: [
                            {
                                icon: "relax-screen",
                                label: "summary_bene1",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'varilux_comfort_max',
                        label: 'Varilux® Comfort Max',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        downgrade: [ `varilux_comfort_max`, `varilux_liberty_30`,  `spherical_essilor`],
                        subProducts: [
                            {id: "1varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_comfort_max", label: "Varilux® Comfort Max", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_comfort_max_f-360", label: "Varilux® Comfort Max f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.67,price:0, enabled: true },
                            {id: "5varilux_comfort_max_short", label: "Varilux® Comfort Max short", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                        ]
                    },
                    {
                        id: 'varilux_comfort_30',
                        label: 'Varilux® Comfort 3.0',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        downgrade: [`varilux_comfort_30`, `varilux_liberty_30`,  `spherical_essilor`],
                        subProducts: [
                            {id: "1varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_30", label: "Varilux® Comfort 3.0", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_30_f-360", label: "Varilux® Comfort 3.0 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "1varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.5,price:0, enabled: true },
                            {id: "2varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.59,price:0, enabled: true },
                            {id: "3varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.6,price:0, enabled: true },
                            {id: "4varilux_comfort_30_short", label: "Varilux® Comfort 3.0 short", index: 1.67,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "seamless",
                                label: "pair_1_tab_del_p1__seamless_vision_",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "pair_1_tab_del_p1__instant_sharp",
                                type: 1
                            },
                        ]
                    },
                    {
                        id: 'varilux_road_pilot_ii',
                        label: 'Varilux Road Pilot® II',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE,
                        downgrade: [`varilux_road_pilot_ii`, `essidrive`,  ``],
                        subProducts: [
                            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.5,price:0, enabled: true },
                            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.6,price:0, enabled: true },
                            {id: "varilux_road_pilot_ii", label: "Varilux Road Pilot® II", index: 1.67,price:0, enabled: true },
                        ],
                        smallTitle: 'questionnaires_q_recom_short_desc_var_rpii',
                        description: "questionnaires_q_recom_long_desc_var_rpii",
                        benefits: [
                            {
                                icon: "adapt-quickly",
                                label: "summary_bene2",
                                type: 1,
                            },
                            {
                                icon: "optimize-driving",
                                label: "summary_bene3",
                                type: 1
                            },
                        ]
                    }
                ]
            },
            {
                id: 'standard_design',
                label: 'Standard Design',
                products: [
                    {
                        id: 'kids_standard_design',
                        label: 'Standard Design Kids',
                        enabled: true,
                        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
                        subProducts: [
                            {id: "1standard_design_kids" ,label: "Standard Design Kids" ,index:1.5 ,price: 20, enabled: true },
                            {id: "2standard_design_kids" ,label: "Standard Design Kids" ,index:1.59 ,price: 20, enabled: true },
                            {id: "3standard_design_kids" ,label: "Standard Design Kids" ,index:1.6 ,price: 20, enabled: true },
                        ],
                        isKids: true,
                        downgrade: [],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                    },
                    {
                        id: 'spherical_essilor',
                        label: 'Spherical Essilor',
                        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
                        enabled: true,
                        subProducts: [
                            {id: "1spherical_essilor" ,label: "Spherical Essilor" ,index:1.5 ,price: 0, enabled: true },
                            {id: "2spherical_essilor" ,label: "Spherical Essilor" ,index:1.59 ,price: 0, enabled: true },
                            {id: "3spherical_essilor" ,label: "Spherical Essilor" ,index:1.6 ,price: 0, enabled: true },
                            {id: "4spherical_essilor" ,label: "Spherical Essilor" ,index:1.67 ,price: 0, enabled: true },
                        ],
                        downgrade: [],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                    },
                    {
                        id: 'aspherical_essilor',
                        label: 'Aspherical Essilor',
                        lensDesign: LensCategoryType.PROGRESSIVE_SV_LENS,
                        enabled: true,
                        subProducts: [
                            {id: "1aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.5 ,price: 0, enabled: true },
                            {id: "2aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.59 ,price: 0, enabled: true },
                            {id: "3aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.6 ,price: 0, enabled: true },
                            {id: "4aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.67 ,price: 0, enabled: true },
                            {id: "5aspherical_essilor" ,label: "Aspherical Essilor" ,index:1.74 ,price: 0, enabled: true },
                        ],
                        downgrade: [],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                    },
                ]
            },
            {
                id: 'eyezen',
                label: 'Eyezen',
                background: eyezenCard,
                logo:'eyezen',
                products: [
                    {
                        id: 'eyezen_start',
                        label: 'Eyezen® Start',
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        enabled: true,
                        subProducts: [
                            {id: "1eyezen_start", label: "Eyezen® Start", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_start", label: "Eyezen® Start", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_start", label: "Eyezen® Start", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_start", label: "Eyezen® Start", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_start", label: "Eyezen® Start", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_start_f-360", label: "Eyezen® Start f-360°", index: 1.74,price:0, enabled: true },
                        ],
                        downgrade: ['eyezen_start' , 'eyezen_boost', 'spherical_essilor'],
                        smallTitle: 'questionnaires_q_recom_short_desc_eyes_start',
                        description: "questionnaires_q_recom_long_desc_eyes_start",
                        benefits: [
                            {
                                icon: "sharp-vision-icon",
                                label: "summary_bene5",
                                type: 1,
                            },
                            {
                                icon: "acuity-near-vision",
                                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                                type: 1,
                            },
                            {
                                icon: "relax-screen",
                                label: "summary_bene4",
                                type: 1,
                            },
                        ]
                    },{
                        id: 'eyezen_sunfit',
                        label: 'Eyezen® SunFit',
                        enabled: true,
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        subProducts: [
                            {id: "1eyezen_sunfit", label: "Eyezen® Sunfit", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_sunfit", label: "Eyezen® Sunfit", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_sunfit", label: "Eyezen® Sunfit", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_sunfit", label: "Eyezen® Sunfit", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_sunfit", label: "Eyezen® Sunfit", index: 1.74,price:0, enabled: true },
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "contrast-sensitivity",
                                label: "sun_exp_lensbenefitsvariluxsun1",
                                type: 1,
                            },
                            {
                                icon: "field-vision",
                                label: "sun_exp_lensbenefitsvariluxsun2",
                                type: 1
                            },
                            {
                                icon: "picto-devices",
                                label: "sun_exp_lensbenefitsvariluxsun3",
                                type: 1
                            },
                            {
                                icon: "vision-comfort",
                                label: "sun_exp_lensbenefitsvariluxsun5",
                                type: 2
                            }
                        ]
                    },
                    {
                        id: 'eyezen_boost',
                        label: 'Eyezen® Boost',
                        enabled: true,
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        subProducts: [
                            {id: "1eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_04", label: "Eyezen® Boost 0.4", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_04_f-360", label: "Eyezen® Boost 0.4 f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_06", label: "Eyezen® Boost 0.6", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_06_f-360", label: "Eyezen® Boost 0.6 f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_085", label: "Eyezen® Boost 0.85", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_085_f-360", label: "Eyezen® Boost 0.85 f-360°", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_11", label: "Eyezen® Boost 1.1", index: 1.74,price:0, enabled: true },
                            {id: "1eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.5,price:0, enabled: true },
                            {id: "2eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.59,price:0, enabled: true },
                            {id: "3eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.6,price:0, enabled: true },
                            {id: "4eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.67,price:0, enabled: true },
                            {id: "5eyezen_boost_11_f-360", label: "Eyezen® Boost 1.1 f-360°", index: 1.74,price:0, enabled: true },                        
                        ],
                        downgrade: ['eyezen_boost' , 'eyezen_start', 'spherical_essilor'],
                        smallTitle: 'questionnaires_q_recom_short_desc_eyes_start',
                        description: "questionnaires_q_recom_long_desc_eyes_boost",
                        benefits: [
                            {
                                icon: "sharp-vision-icon",
                                label: "summary_bene5",
                                type: 1,
                            },
                            {
                                icon: "acuity-near-vision",
                                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                                type: 1,
                            },
                            {
                                icon: "relax-screen",
                                label: "summary_bene4",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'eyezen_kids',
                        label: 'Eyezen® Kids',
                        subProducts: [
                            {id: "1eyezen_kids" ,label: "Eyezen® Kids" ,index:1.5 ,price: 200, enabled: true},
                            {id: "2eyezen_kids" ,label: "Eyezen® Kids" ,index:1.59 ,price: 200, enabled: true},
                            {id: "3eyezen_kids" ,label: "Eyezen® Kids" ,index:1.6 ,price: 200, enabled: true},
                        ],
                        isKids: true,
                        enabled: true,
                        downgrade: ["eyezen_kids","standard_design",""],
                        smallTitle: "questionnaires_q_recomm_short_desc_eye_kid",
                        description: 'questionnaires_q_recomm_long_desc_eye_kid',
                        kidsPrescriptionRange: {
                            min: -0.50,
                            max: 20
                        },
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        benefits: [
                            {
                                icon: "sharp-vision-icon",
                                label: "summary_bene5",
                                type: 1,
                            },
                            {
                                icon: "acuity-near-vision",
                                label: "pair_1_tab_del_p1_better_acuity_in_near_vision",
                                type: 1,
                            },
                            {
                                icon: "relax-screen",
                                label: "summary_bene4",
                                type: 1,
                            },
                        ]
                    }
                ]
            },
            {
                id: 'essidrive',
                label: 'Essidrive',
                products: [
                    {
                        id: 'essidrive',
                        label: 'Essidrive',
                        enabled: true,
                        downgrade: [],
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        subProducts: [
                            {id: "1essidrive" ,label: "Essidrive" ,index:1.5 ,price: 0, enabled: true},
                            {id: "2essidrive" ,label: "Essidrive" ,index:1.6 ,price: 0, enabled: true},
                            {id: "3essidrive" ,label: "Essidrive" ,index:1.67 ,price: 0, enabled: true},
                            {id: "1essidrive_f-360" ,label: "Essidrive f-360" ,index:1.5 ,price: 0, enabled: true},
                            {id: "2essidrive_f-360" ,label: "Essidrive f-360" ,index:1.6 ,price: 0, enabled: true},
                            {id: "3essidrive_f-360" ,label: "Essidrive f-360" ,index:1.67 ,price: 0, enabled: true},

                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "optimize-driving",
                                label: "summary_bene3",
                                type: 1,
                            },
                            {
                                icon: "sharp-vision-icon",
                                label: "summary_bene5",
                                type: 1,
                            },
                        ]
                    },
                ]
            },
            {
                id: 'stellest',
                label: 'Stellest',

                //TEMP
                background: stellestCard2,
                logo:'stellest-logo',
                products: [
                    {
                        id: 'stellest',
                        label: 'Stellest',
                        lensDesign: LensCategoryType.MYOPIA,
                        subProducts: [
                            // {id: "1stellest" ,label: "Stellest" ,index:1.5 ,price: 200, enabled: true},
                            {id: "2stellest" ,label: "Stellest" ,index:1.59 ,price: 200, enabled: true},
                        ],
                        isKids: true,
                        enabled: true,
                        downgrade: ['stellest','eyezen_kids','standard_design'],
                        smallTitle: 'questionnaires_q_recomm_short_desc_stell',
                        description: 'questionnaires_q_recomm_long_desc_stell',
                        kidsPrescriptionRange: {
                            min: -20,
                            max: -0.75
                        },
                        benefits: [
                            {
                                icon: "control",
                                label: "summary_bene6",
                                type: 1,
                            },
                            {
                                icon: "adapt-quickly",
                                label: "summary_bene7",
                                type: 1,
                            },
                        ]
                    }
                ],
            }
        ]
    },
    {
        id: LensFamilyType.PROTECT,
        label: 'lens_consultation_lc_protect_',
        brands: [
            {
                id: 'xperio',
                label: 'Xperio',
                background: experioCard,
                logo: 'xperio-logo',
                products: [
                    {
                        id: 'xperio_react',
                        label: 'Xperio React',
                        price: 0,
                        enabled: true,
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "env-light",
                                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 1,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'xperio_mirrors',
                        label: 'Xperio Mirrors',
                        price: 0,
                        enabled: true,
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        colors: [
                            {
                                id: "lensMirrorBronzeShadow",
                                label: "lens_propo_colors_bronze_shadow",
                                value: "#33070e"
                            },
                            {
                                id: "lensMirrorBronze",
                                label: "lens_propo_colors_bronze",
                                value: "#c66a29"
                            },
                            {
                                id: "lensMirrorCandy",
                                label: "lens_propo_colors_candy",
                                value: "#9d2b49"
                            },
                            {
                                id: "lensMirrorForestGreen",
                                label: "lens_propo_colors_forest_green",
                                value: "#39721a"
                            },
                            {
                                id: "lensMirrorGoldPink",
                                label: "lens_propo_colors_gold_pink",
                                value: "#924a35"
                            },
                            {
                                id: "lensMirrorGreenShadow",
                                label: "lens_propo_colors_green_shadow",
                                value: "#1b160f"
                            },
                            {
                                id: "lensMirrorKaki",
                                label: "lens_propo_colors_kaki",
                                value: "#59621f"
                            },
                            {
                                id: "lensMirrorMauveSilver",
                                label: "lens_propo_colors_mauve_silver",
                                value: "#ada5d2"
                            },
                            {
                                id: "lensMirrorNewBlue",
                                label: "lens_propo_colors_new_blue",
                                value: "#3c8ea9"
                            },
                            {
                                id: "lensMirrorNewSilver",
                                label: "lens_propo_colors_new_silver",
                                value: "#778fa3"
                            },
                            {
                                id: "lensMirrorOceanBlue",
                                label: "lens_propo_colors_ocean_blue",
                                value: "#243459"
                            },
                            {
                                id: "lensMirrorOrange",
                                label: "lens_propo_colors_orange",
                                value: "#7b1d16"
                            },
                            {
                                id: "lensMirrorParma",
                                label: "lens_propo_colors_parma",
                                value: "#b9adc2"
                            },
                            {
                                id: "lensMirrorPinkSilver",
                                label: "lens_propo_colors_pink_silver",
                                value: "#c1b4c0"
                            },
                            {
                                id: "lensMirrorSilverShadow",
                                label: "lens_propo_colors_silver_shadow",
                                value: "#4e4340"
                            },
                            {
                                id: "lensMirrorSilver",
                                label: "lens_propo_colors_silver",
                                value: "#8b8d92"
                            },
                            {
                                id: "lensMirrorSkyBlueSilver",
                                label: "lens_propo_colors_blue_silver",
                                value: "#9fb6cf"
                            },
                            {
                                id: "lensMirrorViolet",
                                label: "lens_propo_colors_violet",
                                value: "#21092f"
                            }
                        ],
                        benefits: [
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 1,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'xperio_polarised',
                        label: 'Xperio Polarised',
                        price: 0,
                        enabled: true,
                        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
                        colors: [
                            {
                                id: "lensPolarizedAmber",
                                label: "lens_propo_colors_amber",
                                value: "#A52A2A"
                            },
                            {
                                id: "lensPolarizedBlueInk",
                                label: "lens_propo_colors_blue_ink",
                                value: "#395158"
                            },
                            {
                                id: "lensPolarizedBluePurple",
                                label: "lens_propo_colors_blue_purple",
                                value: "#6437b8"
                            },
                            {
                                id: "lensPolarizedBlueSky",
                                label: "lens_propo_colors_blue_sky",
                                value: "#87ceeb"
                            },
                            {
                                id: "lensPolarizedBrown",
                                label: "lens_propo_colors_brown",
                                value: "#9C703B"
                            },
                            {
                                id: "lensPolarizedGrey",
                                label: "lens_propo_colors_grey",
                                value: "#808080"
                            },
                            {
                                id: "lensPolarizedGreyGreen",
                                label: "lens_propo_colors_grey_green",
                                value: "#656255"
                            },
                            {
                                id: "lensPolarizedHerbal",
                                label: "lens_propo_colors_herbal",
                                value: "#8a8849"
                            },
                            {
                                id: "lensPolarizedViolet",
                                label: "lens_propo_colors_violet",
                                value: "#6b2d7a"
                            }
                        ],
                        smallTitle: 'questionnaires_q_recom_short_desc_xpe_pol',
                        description: "questionnaires_q_recom_long_desc_xpe_pol",
                        benefits: [
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 1,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'xperio_tinted',
                        label: 'Xperio Tinted',
                        lensDesign: LensProtectType.TINTED,
                        price: 0,
                        enabled: true,
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",

                        colors: [
                            {
                                id: "lensTintedBlack",
                                label: "lens_propo_colors_black",
                                value: "#000000"
                            },
                            {
                                id: "lensTintedBlueInk",
                                label: "lens_propo_colors_blue_ink",
                                value: "#415c80"
                            },
                            {
                                id: "lensTintedBrown",
                                label: "lens_propo_colors_brown",
                                value: "#6d5a45"
                            },
                            {
                                id: "lensTintedBurgundy",
                                label: "lens_propo_colors_burgundy",
                                value: "#89676f"
                            },
                            {
                                id: "lensTintedGreyGreen",
                                label: "lens_propo_colors_grey_green",
                                value: "#3c5854"
                            },
                            {
                                id: "lensTintedGrey",
                                label: "lens_propo_colors_grey",
                                value: "#4c5d6e"
                            },
                            {
                                id: "lensTintedHerbal",
                                label: "lens_propo_colors_herbal",
                                value: "#8e8565"
                            },
                            {
                                id: "lensTintedNewBrown",
                                label: "lens_propo_colors_new_brown",
                                value: "#806b4d"
                            },                            
                        ],
                        benefits: [
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 1,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 1,
                            },
                        ]
                    },
                ]
            },
            {
                id: 'blueuv_filter_system',
                label: 'BlueUV Filter System',
                background: blueUVCard,
                logo:'BlueUV_FilterSystem',
        
                products: [
                    {
                        id: 'eye_protect_system',
                        label: 'Eye Protect System',
                        price: 0,
                        enabled: true,
                        isKids: true,
                        lensDesign: LensProtectType.BLUE_CUT,
                        complementaryEquipments:[ComplementaryEquipmentType.COMPUTER],
                        smallTitle: 'questionnaires_q_recomm_short_desc_eps',
                        description: 'questionnaires_q_recomm_long_desc_eps',
                        benefits: [
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_2_p1_transparent_lens",
                                type: 1,
                            },
                        ]
                    },
                    {
                        id: 'blue_uv_capture',
                        label: 'Blue UV Filter System',
                        price: 0,
                        enabled: true,
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_2_p1_transparent_lens",
                                type: 1,
                            },
                        ]
                    },             
                ]
            },
            {
                id: 'transitions',
                label: 'Transitions',
                background: transitionsCard,
                logo: 'transitions-logo',
                products: [
                    {
                        id: 'transitions_signature_gen_8',
                        label: 'Transitions Gen 8',
                        lensDesign: LensProtectType.PHOTOCROMIC,
                        complementaryEquipments:[ComplementaryEquipmentType.SUN],
                        price: 0,
                        enabled: true,
                        isKids: true,
                        colors: [
                            {
                                id: "lensTransitionGreen",
                                label: "lens_propo_colors_green",
                                value: "#3F9A49"
                            },
                            {
                                id: "lensTransitionGrey",
                                label: "lens_propo_colors_grey",
                                value: "#B2B2B6"
                            },
                            {
                                id: "lensTransitionAmber",
                                label: "lens_propo_colors_amber",
                                value: "#9C703B"
                            },
                            {
                                id: "lensTransitionSapphire",
                                label: "lens_propo_colors_sapphire",
                                value: "#346297"
                            },
                            {
                                id: "lensTransitionEmerald",
                                label: "lens_propo_colors_emerald",
                                value: "#046253"
                            },
                            {
                                id: "lensTransitionBrown",
                                label: "lens_propo_colors_brown",
                                value: "#9E5425"
                            },
                            {
                                id: "lensTransitionAmethyst",
                                label: "lens_propo_colors_amethyst",
                                value: "#4F3281"
                            }
                        ],
                        smallTitle: 'questionnaires_q_recom_short_desc_trans_gen_8',
                        description: 'questionnaires_q_recom_long_desc_trans_gen_8',
                        benefits: [
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "env-light",
                                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 2,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'transitions_xtractive',
                        label: 'Transitions XTRActive',
                        price: 0,
                        enabled: true,
                        colors: [
                            {
                                id: "lensTransitionGreen",
                                label: "lens_propo_colors_green",
                                value: "#3F9A49"
                            },
                            {
                                id: "lensTransitionGrey",
                                label: "lens_propo_colors_grey",
                                value: "#B2B2B6"
                            },
                            {
                                id: "lensTransitionAmber",
                                label: "lens_propo_colors_amber",
                                value: "#9C703B"
                            },
                            {
                                id: "lensTransitionSapphire",
                                label: "lens_propo_colors_sapphire",
                                value: "#346297"
                            },
                            {
                                id: "lensTransitionEmerald",
                                label: "lens_propo_colors_emerald",
                                value: "#046253"
                            },
                            {
                                id: "lensTransitionBrown",
                                label: "lens_propo_colors_brown",
                                value: "#9E5425"
                            },
                            {
                                id: "lensTransitionAmethyst",
                                label: "lens_propo_colors_amethyst",
                                value: "#4F3281"
                            }
                        ],
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "env-light",
                                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 2,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'transitions_style_mirrors',
                        label: 'Transitions Style Mirrors',
                        price: 0,
                        enabled: true,
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        benefits: [
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 1,
                            },
                            {
                                icon: "env-light",
                                label: "pair_1_tab_del_2_p1_reacts_to_environmental_light",
                                type: 1,
                            },
                            {
                                icon: "colors",
                                label: "pair_1_tab_del_p1__available_in_a_range_of_colours",
                                type: 2,
                            },
                            {
                                icon: "outdoor-sun",
                                label: "pair_1_tab_del_p1_outdoor_sun_glare_reduc",
                                type: 2,
                            },
                        ]
                    },
                ]
            }
        ]
    },
    {
        id: LensFamilyType.ENHANCE,
        label: 'lens_consultation_lc_enhance',
        brands: [
            {
                id: 'crizal',
                label: 'Crizal',
                background: crizalCard,
                logo: 'crizal-logo',
                products: [                    
                    {
                        id: 'crizal_easy',
                        label: 'Crizal Easy',
                        smallTitle: 'questionnaires_q_recomm_short_desc_criz_eas',
                        description: 'questionnaires_q_recomm_long_desc_criz_eas',
                        enabled: true,
                        price:0,
                        isKids: true,
                        lensDesign: LensCategoryType.SINGLE_VISION,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        enabled: true,
                        price:0,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        complementaryEquipments: [ComplementaryEquipmentType.COMPUTER],
                        smallTitle: 'questionnaires_q_recomm_short_desc_cp',
                        description: 'questionnaires_q_recomm_long_desc_cp',
                        enabled: true,
                        price:0,
                        isKids: true,
                        benefits: [
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 1,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                            {
                                icon: "harmful-light",
                                label: "pair_1_tab_del_p1__harmf",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_drive',
                        label: 'Crizal Drive',
                        // complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
                        smallTitle: 'questionnaires_q_recomm_short_desc_cd',
                        description: 'questionnaires_q_recomm_long_desc_cd',
                        enabled: true,
                        price:0,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alizé',
                        smallTitle: '',
                        description: "",
                        enabled: true,
                        price:0,
                        isKids: true,
                        lensDesign: LensCategoryType.MYOPIA,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        lensDesign: LensEnhanceType.AR,
                        complementaryEquipments: [ComplementaryEquipmentType.SUN],
                        smallTitle: 'questionnaires_q_recom_short_desc_cri_sapp',
                        description: 'questionnaires_q_recom_long_desc_cri_sapp',
                        enabled: true,
                        price:0,
                        isKids: true,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        enabled: true,
                        price:0,
                        benefits: [
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_p1__optimal_clarity",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "smudge-res",
                                label: "pair_1_tab_del_p1__smudge_resistant",
                                type: 1,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                            {
                                icon: "water-repel",
                                label: "pair_1_tab_del_p1__water_repellent",
                                type: 2,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_p1__harmful",
                                type: 2,
                            },
                        ]
                    },
                    {
                        id: 'crizal_sun_xprotect',
                        label: 'Crizal Sun Xprotect',
                        lensDesign: LensEnhanceType.TINTED,
                        complementaryEquipments: [ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING],
                        smallTitle: 'questionnaires_q_recomm_short_desc_cri_sun',
                        description: 'questionnaires_q_recomm_long_desc_cri_sun',
                        enabled: true,
                        price:0,
                        benefits: [
                            {
                                icon: "dust-repels",
                                label: "sun_exp_lensbenefitssunxprosun1",
                                type: 1,
                            },
                            {
                                icon: "resist-scratches",
                                label: "sun_exp_lensbenefitssunxprosun2",
                                type: 1,
                            },
                            {
                                icon: "res-smudges",
                                label: "sun_exp_lensbenefitssunxprosun3",
                                type: 1,
                            },
                            {
                                icon: "durability",
                                label: "sun_exp_lensbenefitssunxprosun4",
                                type: 2,
                            },
                            {
                                icon: "uv-protection",
                                label: "sun_exp_lensbenefitssunxprosun5",
                                type: 2,
                            },
                            {
                                icon: "glare-cut",
                                label: "sun_exp_lensbenefitssunxprosun6p",
                                type: 2,
                            },
                        ]
                    },
                ]
            },
            {
                id: 'optifog',
                label: 'Optifog',
                logo: 'optifog-logo',
                background: optifogCard,
                products: [
                    {
                        id: 'optifog',
                        label: 'Optifog',
                        smallTitle: 'Lorem ipsum dolor sit amet',
                        description: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                        enabled: true,
                        price:0,
                        benefits: [
                            {
                                icon: "fog-rep",
                                label: "pair_1_tab_del_p1__fog_repellence",
                                type: 1,
                            },
                            {
                                icon: "scratch-icon",
                                label: "pair_1_tab_del_p1__scratch_resistant",
                                type: 1,
                            },
                            {
                                icon: "uv-protect",
                                label: "pair_1_tab_del_2_p1_double_sided_uv_protection.",
                                type: 1,
                            },
                            {
                                icon: "optimal-clarity",
                                label: "pair_1_tab_del_2_p1_outdoor_sun_glare_reduction",
                                type: 2,
                            },
                            {
                                icon: "dust-repel",
                                label: "pair_1_tab_del_p1__dust_repellent",
                                type: 2,
                            },
                        ]
                    },
                ],
                
            },
        ]
    },
]


const baseAssetURL = (url: string) => {
    return `${settings[settings.environment].demoAssetsUrl}${url}`;
}

const newAssetURL = (url: string) => {
    return `${settings[settings.environment].newAssetsUrl}${url}`;
}

const LENS_DEMO: iLensDemo[] = [
    {
      demo: 'scooter', //pictos
      demoType: ProductDemoType.SLIDER_VIDEO,
      products: [
        {
          id: 'varilux_x_series',
          label: 'Varilux X Series',
          asset: baseAssetURL('Varilux/03_Varilux X Series/Varilux_Xseries_Trottinette_ESSILOR.mp4'),
          description: ['demo_demo_varilux_xser_l1', 'demo_demo_varilux_xser_l2', 'demo_demo_varilux_xser_l3']
        },
        {
          id: 'standard',
          label: 'packaged_offer_popup_po_popup_standard',
          asset: baseAssetURL('Varilux/03_Varilux X Series/Varilux_Xseries_Trottinette_STANDARD.mp4'),
        }
      ]
    },
    {
      demo: 'stairs', //pictos
      demoType: ProductDemoType.SLIDER_VIDEO,
      products: [
        {
          id: 'varilux_e_series',
          label: 'Varilux E Series',
          asset: baseAssetURL('Varilux/04_Varilux E Series/Varilux_Eseries_Urban Stairs_ESSILOR.mp4'),
          description: ['demo_demo_varilux_eser_l1','demo_demo_varilux_eser_l2', 'demo_demo_varilux_eser_l3']
        },
        {
          id: 'standard',
          label: 'packaged_offer_popup_po_popup_standard',
          asset: baseAssetURL('Varilux/04_Varilux E Series/Varilux_Eseries_Urban Stairs_STANDARD.mp4'),
        }
      ]
    },
    // {
    //     demo: 'in_front_of_my_computer',
    //     demoType: ProductDemoType.SLIDER_VIDEO,
    //     products: [
    //         {
    //             id: 'varilux_x_series',
    //             label: 'Varilux X Series',
    //             asset: xSeriesInFrontOfComputer,
    //             description: ['demo_demo_varilux_xser_l1', 'demo_demo_varilux_xser_l2', 'demo_demo_varilux_xser_l3']
    //         },
    //         {
    //             id: 'varilux_e_series',
    //             label: 'Varilux E Series',
    //             asset: eSeriesInFrontOfComputer,
    //             description: ['demo_demo_varilux_eser_l1','demo_demo_varilux_eser_l2', 'demo_demo_varilux_eser_l3']
    //         },
    //         {
    //             id: 'varilux_physio_30',
    //             label: 'Varilux Physio 3.0',
    //             asset: physioInFrontOfComputer,
    //             description: ['demo_demo_varilux_physio_l1','demo_demo_varilux_physio_l2','demo_demo_varilux_physio_l3']
    //         },
    //         {
    //             id: 'varilux_liberty_30',
    //             label: 'Varilux Liberty 3.0',
    //             asset: libertyInFrontOfComputer,
    //             description: ['demo_demo_varilux_lib_l1','demo_demo_varilux_lib_l2']
    //         },
    //         {
    //             id: 'varilux_comfort_max',
    //             label: 'Varilux Comfort max',
    //             asset: comfortMaxInFrontOfComputer,
    //             description: ['demo_demo_varilux_comf_max_l1','demo_demo_varilux_comf_max_l2','demo_demo_varilux_comf_max_l3']
    //         },
    //         {
    //             id: 'varilux_comfort_30',
    //             label: 'Varilux Comfort 3.0',
    //             asset: comfortInFrontOfComputer,
    //             description: ['demo_demo_varilux_c3.0_l1','demo_demo_varilux_c3.0_l2','demo_demo_varilux_c3.0_l3']
    //         },
    //         {
    //             id: 'standard',
    //             label: 'Standard progressive lens',
    //             asset: standardInFrontOfComputer,
    //         },
    //   ]
    // },
    {
        demo: 'journalist', //pictos
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'varilux_comfort_max',
            label: 'Varilux® Comfort Max',
            asset: baseAssetURL('Varilux/06_Varilux Comfort Max/ComfortMax_Journalist_ESSILOR.mp4'),
            description: ['demo_demo_varilux_comf_max_l1','demo_demo_varilux_comf_max_l2','demo_demo_varilux_comf_max_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Varilux/06_Varilux Comfort Max/ComfortMax_Journalist_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'day',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'varilux_road_pilot_ii',
            label: 'Varilux Road Pilot® II',
            asset: baseAssetURL('Varilux/11_Varilux Roadpilot/Varilux_Roadpilot_Jour_ESSILOR.mp4'),
            description: ['demo_demo_varilux_roadp_l1','demo_demo_varilux_roadp_l2','demo_demo_varilux_roadp_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Varilux/11_Varilux Roadpilot/Varilux_Roadpilot_Jour_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'night', // pictos
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'varilux_road_pilot_ii',
            label: 'Varilux Road Pilot® II',
            asset: baseAssetURL('Varilux/11_Varilux Roadpilot/Varilux_Roadpilot_Nuit_ESSILOR.mp4'),
            description: ['demo_demo_varilux_roadp_l1','demo_demo_varilux_roadp_l2','demo_demo_varilux_roadp_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Varilux/11_Varilux Roadpilot/Varilux_Roadpilot_Nuit_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'field-of-vision',
        demoType: ProductDemoType.SLIDER_IMAGE,
        is360: true,
        products: [
            {
                id: 'varilux_x_series',
                label: 'Varilux X Series',
                description: ['demo_demo_varilux_xser_l1', 'demo_demo_varilux_xser_l2', 'demo_demo_varilux_xser_l3'],

                drawline: {
                    left:{
                        blur: xseriesBlur,
                        line: xseriesLine
                    },
                    right:{
                        blur: xseriesBlur,
                        line: xseriesLine
                    }
                }
            },
            {
                id: 'varilux_e_series',
                label: 'Varilux E Series',
                asset: experioCard,
                description: ['demo_demo_varilux_eser_l1','demo_demo_varilux_eser_l2', 'demo_demo_varilux_eser_l3'],

                drawline: {
                    left:{
                        blur: eseriesBlur,
                        line: eseriesLine
                    },
                    right:{
                        blur: eseriesBlur,
                        line: eseriesLine
                    }
                }
            },
            {
                id: 'varilux_physio_30',
                label: 'Varilux Physio 3.0',
                asset: blueUVCard,
                description: ['demo_demo_varilux_physio_l1','demo_demo_varilux_physio_l2','demo_demo_varilux_physio_l3'],

                drawline: {
                    left:{
                        blur: physioBlur,
                        line: physioLine
                    },
                    right:{
                        blur: physioBlur,
                        line: physioLine
                    }
                }
            },
            {
                id: 'varilux_liberty_30',
                label: 'Varilux Liberty 3.0',
                asset: eyezenCard,
                description: ['demo_demo_varilux_lib_l1','demo_demo_varilux_lib_l2'],

                drawline: {
                    left:{
                        blur: libertyBlur,
                        line: libertyLine
                    },
                    right:{
                        blur: libertyBlur,
                        line: libertyLine
                    }
                }
            },
            {
                id: 'varilux_comfort_max',
                label: 'Varilux® Comfort Max',
                asset: crizalCard,
                description: ['demo_demo_varilux_comf_max_l1','demo_demo_varilux_comf_max_l2','demo_demo_varilux_comf_max_l3'],

                drawline: {
                    left:{
                        blur: comformaxBlur,
                        line: comformaxLine
                    },
                    right:{
                        blur: comformaxBlur,
                        line: comformaxLine
                    }
                }
            },
            {
                id: 'varilux_comfort_30',
                label: 'Varilux Comfort 3.0',
                asset: optifogCard,
                description: ['demo_demo_varilux_c3.0_l1','demo_demo_varilux_c3.0_l2','demo_demo_varilux_c3.0_l3'],

                drawline: {
                    left:{
                        blur: comfor3Blur,
                        line: comfor3Line
                    },
                    right:{
                        blur: comfor3Blur,
                        line: comfor3Line
                    }
                }
            },
            {
                id: 'varilux_s_series',
                label: 'Varilux S Series',
                asset: transitionsCard,

                drawline: {
                    left:{
                        blur: sseriesBlur,
                        line: sseriesLine
                    },
                    right:{
                        blur: sseriesBlur,
                        line: sseriesLine
                    }
                }
            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',

                drawline: {
                    left:{
                        blur: standardBlur,
                        line: standardLine
                    },
                    right:{
                        blur: standardBlur,
                        line: standardLine
                    }
                }
            },
      ]
    },
    {
        demo: 'benefit',
        demoType: ProductDemoType.DIVIDER_VIDEO,
        products: [
            {
                id: 'varilux_x_series',
                label: 'Varilux X Series',
                asset:'https://cpbxdevstafrccompanion.blob.core.windows.net/demostration/Varilux/13_Varilux%20Benefits/Varilux_Benefits_Xseries.mp4.mp4',
                description: ['demo_demo_varilux_xser_l1', 'demo_demo_varilux_xser_l2', 'demo_demo_varilux_xser_l3']
            },
            {
              id: 'standard',
              label: 'packaged_offer_popup_po_popup_standard',
              asset: 'https://cpbxdevstafrccompanion.blob.core.windows.net/demostration/Varilux/13_Varilux%20Benefits/Varilux_Benefits_Xseries.mp4.mp4' 
            }
        ]
    },
    {
        demo:'competitor',
        demoType:ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products:[
            {
                id: 'varilux_physio_30',
                label: 'Varilux Physio 3.0',
                asset: newAssetURL('Varilux/08_Varilux%20Physio/Varilux_Physio_Demo_New_.mp4'),
                description: ['demo_demo_varilux_physio_l1','demo_demo_varilux_physio_l2','demo_demo_varilux_physio_l3']
            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_competitor',
                asset: newAssetURL('Varilux/08_Varilux%20Physio/Varilux_Physio_Demo_New_Competitor.mp4'),
            },
        ]
    },
    {
        demo:'competitor2',
        demoType:ProductDemoType.DIVIDER_VIDEO,
        products:[
            {
                id: 'varilux_liberty_30',
                label: 'Varilux Liberty 3.0',
                asset: newAssetURL('Varilux/14_Varilux%20Liberty/Varilux_Liberty_Demo_New.mp4'),
                description: ['demo_demo_varilux_lib_l1','demo_demo_varilux_lib_l2']
            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_competitor',
                asset: newAssetURL('Varilux/14_Varilux%20Liberty/Varilux_Liberty_Demo_New.mp4'),
            },
        ]
    },
    {
        demo:'competitor',
        demoType:ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products:[
            {
                id: 'varilux_physio_30',
                label: 'Varilux Physio 3.0',
                asset: newAssetURL('Varilux/08_Varilux%20Physio/Varilux_Physio_Demo_New_.mp4'),
                description: ['demo_demo_varilux_physio_l1','demo_demo_varilux_physio_l2','demo_demo_varilux_physio_l3']
            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_competitor',
                asset: newAssetURL('Varilux/08_Varilux%20Physio/Varilux_Physio_Demo_New_Competitor.mp4'),
            },
        ]
    },
    {
        demo:'competitor2',
        demoType:ProductDemoType.DIVIDER_VIDEO,
        products:[
            {
                id: 'varilux_liberty_30',
                label: 'Varilux Liberty 3.0',
                asset: newAssetURL('Varilux/14_Varilux%20Liberty/Varilux_Liberty_Demo_New.mp4'),
                description: ['demo_demo_varilux_lib_l1','demo_demo_varilux_lib_l2']
            },
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_competitor',
                asset: newAssetURL('Varilux/14_Varilux%20Liberty/Varilux_Liberty_Demo_New.mp4'),
            },
        ]
    },
    {
        demo: 'stairs_effect', // pictos
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
            {
                id: 'varilux_x_series',
                label: 'Varilux X Series',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Xseries.mp4'),
                description: ['demo_demo_varilux_xser_l1', 'demo_demo_varilux_xser_l2', 'demo_demo_varilux_xser_l3']
            },
            {
                id: 'varilux_s_series',
                label: 'Varilux® S™ Series',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Sseries.mp4'),
                // description: 'varilux_s_series_desc_key'
            },
            {
                id: 'varilux_physio_30',
                label: 'Varilux Physio 3.0',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Physio.mp4'),
                description: ['demo_demo_varilux_physio_l1','demo_demo_varilux_physio_l2','demo_demo_varilux_physio_l3']
            },
            {
                id: 'varilux_liberty_30',
                label: 'Varilux Liberty 3.0',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Liberty.mp4'),
                description: ['demo_demo_varilux_lib_l1','demo_demo_varilux_lib_l2']
            },
            {
                id: 'varilux_comfort_max',
                label: 'Varilux® Comfort Max',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_ComfortMax.mp4'),
                description: ['demo_demo_varilux_comf_max_l1','demo_demo_varilux_comf_max_l2','demo_demo_varilux_comf_max_l3']
            },
            {
                id: 'varilux_comfort_30',
                label: 'Varilux Comfort 3.0',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Comfort3.mp4'),
                description: ['demo_demo_varilux_c3.0_l1','demo_demo_varilux_c3.0_l2','demo_demo_varilux_c3.0_l3']
            },
            {
                id: 'varilux_e_series',
                label: 'Varilux E Series',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_Eseries.mp4'),
                description: ['demo_demo_varilux_eser_l1','demo_demo_varilux_eser_l2', 'demo_demo_varilux_eser_l3'],
            },            
            {
                id: 'standard',
                label: 'packaged_offer_popup_po_popup_standard',
                asset: baseAssetURL('Varilux/02_Varilux Transversal_2/Varilux_Stairs_STANDARD.mp4'),
            },
      ]
    },
    {
        demo: 'kitchen',
        demoType: ProductDemoType.SEE_THROUGH_VIDEO,
        products: [
          {
            id: 'crizal_rock',
            label: 'Crizal Rock',
            asset: baseAssetURL('Crizal/09_Crizal%20Rock/Crizal_Rock_ESSILOR.mp4'),
            description: ['demo_demo_crizal_rock_l1', 'demo_demo_crizal_rock_l2', 'demo_demo_crizal_rock_l3', 'demo_demo_crizal_rock_l4']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/09_Crizal%20Rock/Crizal_Rock_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'family-scene', // pictos
        demoType: ProductDemoType.SEE_THROUGH_VIDEO,
        products: [
          {
            id: 'eye_protect_system',
            label: 'Eye Protect System',
            asset: baseAssetURL('Blue UV Filter System/01_Eye Protect System_1/BlueUVfilter_EPS_Protect_ESSILOR.mp4'),
            description: [
                'demo_demo_eps_l1',
                'demo_demo_eps_l2',
                'demo_demo_eps_l3',
                'demo_new_sellout_eps4'
            ]
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Blue UV Filter System/01_Eye Protect System_1/BlueUVfilter_EPS_Protect_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'fog-on-pov',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'optifog',
            label: 'Optifog',
            asset: baseAssetURL('Optifog/Optifog_ESSILOR.mp4'),
            description: ['demo_demo_optifog_l1','demo_demo_optifog_l2','demo_demo_optifog_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Optifog/Optifog_STANDARD.mp4')
          }
        ]
    },

    // CHECK
    {
        demo: 'jungle-video',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'crizal_easy',
            label: 'Crizal Easy',
            asset: baseAssetURL('Crizal/Crizal_Easy/CRIZAL_Easy_Essilor.mp4'),
            description: ['demo_demo_crizal_easy_l1', 'demo_demo_crizal_easy_l2', 'demo_demo_crizal_easy_l3', 'demo_demo_crizal_easy_l4']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/Crizal_Easy/Crizal_Easy_Standard.mp4')
          }
        ]
    },
    
    {
        demo: 'packshot-frame',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'crizal_sapphire',
            label: 'Crizal Sapphire',
            // asset: baseAssetURL('Crizal/Crizal_Sapphire/Packshot_ESSILOR (1).mp4'),
            asset: baseAssetURL('Crizal/Crizal_Sapphire/CRIZAL_Sapphire_ESSILOR.mp4'),
            description: ['demo_demo_crizal_sapp_l1', 'demo_demo_crizal_sapp_l2', 'demo_demo_crizal_sapp_l3', 'demo_demo_crizal_sapp_l4', 'demo_demo_crizal_sapp_l5']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            // asset: baseAssetURL('Crizal/Crizal_Sapphire/Packshot_STANDARD.mp4')
            asset: baseAssetURL('Crizal/Crizal_Sapphire/CRIZAL_Sapphire_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'playing-video-games', ////pictos
        demoType: ProductDemoType.DIVIDER_VIDEO,
        products: [
          {
            id: 'eye_protect_system',
            label: 'Eye Protect System',
            asset: baseAssetURL('Blue UV Filter System/02_Eye Protect System_2/BlueUVfilter_EPS_Protect_2_ESSILOR.mp4'),
            description: [
                'demo_demo_eps_l1',
                'demo_demo_eps_l2',
                'demo_demo_eps_l3',
                'demo_new_sellout_eps4'
            ]            
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Blue UV Filter System/02_Eye Protect System_2/BlueUVfilter_EPS_Protect_2_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'spectrum-of-light', //pictos
        demoType: ProductDemoType.SEE_THROUGH_VIDEO,
        products: [
          {
            id: 'blue_uv_capture',
            label: 'Blue UV Filter System',
            asset: baseAssetURL('Blue UV Filter System/03_Blue UV Capture/BlueUVfilter_BlueUVcapture_ESSILOR.mp4'),
            description: [
                'demo_new_sellout_blue_uv1',
                'demo_new_sellout_blue_uv2',
                'demo_new_sellout_blue_uv3',
                'demo_new_sellout_blue_uv4'
            ]
          },
          {
            id: 'standard',
            label: 'demo_new_standard_bluecut',
            asset: baseAssetURL('Blue UV Filter System/03_Blue UV Capture/BlueUVfilter_BlueUVcapture_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'eyezen-in-front-of-computer',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
        //   {
        //     id: 'eyezen_boost',
        //     label: 'Eyezen® Boost',
        //     asset: baseAssetURL('Eyezen/03_Eyezen Boost/Eyezen_Boost_ESSILOR.mp4'),
        //     description: [
        //         'demo_demo_eyezen_b_l1', 
        //         'demo_demo_eyezen_b_l2', 
        //         'demo_demo_eyezen_b_l3'
        //     ]
        //   },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Eyezen/03_Eyezen Boost/Eyezen_Boost_STANDARD.mp4')
          }
        ],
        children: [
            {
                demo: 'eyezen-in-front-of-computer-night',
                parent: 'eyezen-in-front-of-computer',
                demoType: ProductDemoType.SLIDER_VIDEO,
                compareByDefault: true,
                withSellout: true,
                label: 'Eyezen®',
                products: [
                  {
                    id: 'eyezen_boost',
                    label: 'Eyezen®',
                    asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_EYEZEN_NIGHT.mp4'),
                    description: ['demo_demo_eyezen_b_l1', 'demo_demo_eyezen_b_l2', 'demo_demo_eyezen_b_l3']
                  },
                  {
                    id: 'standard',
                    label: 'packaged_offer_popup_po_popup_standard',
                    asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_STANDARD_NIGHT.mp4'),
                  }
                ]
            }
        ]
    },

    {
        demo: 'looking-at-smartphone',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
        //   {
        //     id: 'eyezen_start',
        //     label: 'Eyezen® Start',
        //     asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_EYEZEN.mp4'),
        //     description: [
        //         'demo_demo_eyezen_start_l1', 
        //         'demo_demo_eyezen_start_l2', 
        //         'demo_demo_eyezen_start_l3'
        //     ]
        //   },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_STANDARD.mp4')
          }
        ],

        children: [
            {
                demo: 'looking-at-smartphone-night',
                parent: 'looking-at-smartphone',
                compareByDefault: true,
                demoType: ProductDemoType.SLIDER_VIDEO,
                label: 'Eyezen®',
                withSellout: true,
                products: [
                  {
                    id: 'eyezen_start',
                    label: 'Eyezen®',
                    asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_EYEZEN_NIGHT.mp4'),
                    description: ['demo_demo_eyezen_start_l1', 'demo_demo_eyezen_start_l2', 'demo_demo_eyezen_start_l3']
                  },
                  {
                    id: 'standard',
                    label: 'packaged_offer_popup_po_popup_standard',
                    asset: baseAssetURL('Eyezen/02_Eyezen_Start/Eyezen_Start_STANDARD_NIGHT.mp4')
                  }
                ]
            }
        ]
    },

    {
        demo: 'essidrive-day',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'essidrive',
            label: 'Essidrive',
            asset: baseAssetURL('Essidrive/Essidrive_Jour_ESSILOR.mp4'),
            description: ['demo_demo_essidrive']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Essidrive/Essidrive_Jour_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'essidrive-night', //pictos
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'essidrive',
            label: 'Essidrive',
            asset: baseAssetURL('Essidrive/Essidrive_Nuit_ESSILOR.mp4'),
            description: ['demo_demo_essidrive']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Essidrive/Essidrive_Nuit_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'sun-outside',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'varilux_sunmax',
            label: 'Varilux® SunMax',
            asset: baseAssetURL('Varilux/12_Varilux SunMax/Varilux_SunMax_BROWN_essilor.mp4'),
            // description : []
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Varilux/12_Varilux SunMax/Varilux_SunMax_BROWN_standard.mp4')
          }
        ]
    },
    {
        demo: 'far-vision',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'eyezen_kids',
            label: 'Eyezen® Kids',
            asset: baseAssetURL('Eyezen/04_Eyezen Kids/Eyezen Kids - Eyezen Kids lens demo - FV.mp4'),
            description: [
                'demo_new_sellout_eyezen_kids1',
                'demo_new_sellout_eyezen_kids2', 
                'demo_new_sellout_eyezen_kids3', 
                'demo_new_sellout_eyezen_kids4',
                'demo_new_sellout_eyezen_kids5'
            ]
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Eyezen/04_Eyezen Kids/Eyezen Kids - STANDARD SV LENS Demo - FV.mp4'),

          }
        ]
    },

    // no pictos
    // school
    // pool
    // near vision

    {
        demo: 'bag-scene',
        demoType: ProductDemoType.VIDEO,
        products: [
          {
            id: 'crizal_sun_xprotect',
            label: 'Crizal Sun',
            asset: baseAssetURL('Crizal/CompanionTool/Scene Videos/BagSceneV2.mp4'),
            description: ['demo_demo_crizal_sun_xp_l1','demo_demo_crizal_sun_xp_l2', 'demo_demo_crizal_sun_xp_l3']
          },
        ]
    },
    {
        demo: 'beach-scene',
        demoType: ProductDemoType.VIDEO,
        products: [
          {
            id: 'crizal_sun_xprotect',
            label: 'Crizal Sun',
            asset: baseAssetURL('Crizal/CompanionTool/Scene Videos/BeachSceneV2.mp4'),
            description: ['demo_demo_crizal_sun_xp_l1','demo_demo_crizal_sun_xp_l2', 'demo_demo_crizal_sun_xp_l3']
          },
        ]
    },
    {
        demo: 'tumble-scene',
        demoType: ProductDemoType.VIDEO,
        products: [
          {
            id: 'crizal_sun_xprotect',
            label: 'Crizal Sun',
            asset: baseAssetURL('Crizal/CompanionTool/Scene Videos/TumbleScene.mp4'),
            description: ['demo_demo_crizal_sun_xp_l1','demo_demo_crizal_sun_xp_l2', 'demo_demo_crizal_sun_xp_l3']
          },
        ]
    },
    {
        demo: 'wiping-scene',
        demoType: ProductDemoType.VIDEO,
        products: [
          {
            id: 'crizal_sun_xprotect',
            label: 'Crizal Sun',
            asset: baseAssetURL('Crizal/CompanionTool/Scene Videos/WipingScene2.mp4'),
            description: ['demo_demo_crizal_sun_xp_l1','demo_demo_crizal_sun_xp_l2', 'demo_demo_crizal_sun_xp_l3']
          },
        ]
    },
    {
        demo: 'near-vision',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'eyezen_kids',
            label: 'Eyezen® Kids',
            asset: baseAssetURL('Eyezen/04_Eyezen Kids/Eyezen Kids - Eyezen Kids lens demo - NV.mp4'),
            description: ['demo_demo_eyezen_kids_l1','demo_demo_eyezen_kids_l2', 'demo_demo_eyezen_kids_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Eyezen/04_Eyezen Kids/Eyezen Kids - STANDARD SV LENS Demo - NV.mp4'),
          }
        ]
    },
    {
        demo: 'smartphone',
        demoType: ProductDemoType.SEE_THROUGH_VIDEO,
        products: [
          {
            id: 'crizal_prevencia',
            label: 'Crizal Prevencia',
            // Note: switch with standard on API integration
            asset: baseAssetURL('Crizal/05_Crizal Prevencia/Crizal_Prevencia_STANDARD.mp4'),
            description: ['demo_demo_crizal_prev_l1','demo_demo_crizal_prev_l2','demo_demo_crizal_prev_l3']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/05_Crizal Prevencia/Crizal_Prevencia_ESSILOR.mp4'),

          }
        ]
    },
    {
        demo: 'glare',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'crizal_drive',
            label: 'Crizal Drive',
            asset: baseAssetURL('Crizal/06_Crizal Drive/Crizal_drive_Glare_ESSILOR.mp4'),
            description: ['demo_demo__crizal_drive_l1','demo_demo__crizal_drive_l2','demo_demo__crizal_drive_l3','demo_demo__crizal_drive_l4']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/06_Crizal Drive/Crizal_drive_Glare_STANDARD.mp4')
          }
        ]
    },
    {
        demo: 'reflections',
        demoType: ProductDemoType.SLIDER_VIDEO,
        products: [
          {
            id: 'crizal_drive',
            label: 'Crizal Drive',
            asset: baseAssetURL('Crizal/06_Crizal Drive/Crizal_drive_Reflections_ESSILOR.mp4'),
            description: ['demo_demo__crizal_drive_l1','demo_demo__crizal_drive_l2','demo_demo__crizal_drive_l3','demo_demo__crizal_drive_l4']
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/06_Crizal Drive/Crizal_drive_Reflections_STANDARD.mp4')
          }
        ]
    },

    {
        demo: 'room', 
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'varilux_digitime',
                label: 'demo_var_digi_near',
                asset: baseAssetURL('Varilux/10_Digitime/VARILUX_DIGITIME_Near.mp4'),
            },
            {
                id: 'standard',
                label: "demo_var_digi__mid",
                asset: baseAssetURL('Varilux/10_Digitime/VARILUX_DIGITIME_Mid.mp4'),
            },
            {
                id: 'standard1',
                label: "demo_var_digi__room",
                asset: baseAssetURL('Varilux/10_Digitime/VARILUX_DIGITIME_Room.mp4'),
            },
        ]
    },
    {
        demo: 'video-advertisement-gen-8', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_signature_gen_8',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/green.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/brown-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/grey-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'video-advertisement-gen-8', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_signature_gen_8_iconic',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/green.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/brown-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/grey-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'video-advertisement-gen-8', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_signature_gen_8_style',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/green.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/brown-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/grey-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'video-advertisement-gen-8', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_signature_gen_8_classic',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/green.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/brown-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/grey-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'video-advertisement-gen-8', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_classic',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/green.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/brown-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/grey-new.mp4'),
                description: ['demo_demo_trans__gen_8_l1','demo_demo_trans__gen_8_l2', 'demo_demo_trans__gen_8_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'video-advertisement-xtractive', //pictos
        demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
        products: [
            {
                id: 'transitions_xtractive',
                label: 'lens_propo_green',
                asset: baseAssetURL('Transitions/XTRActive/3. Grapphite Green/SIMONE_HORIZONTAL_LUC_G.GREEN (3).mp4'),
                description: ['demo_demo_trans__xtra_l1','demo_demo_trans__xtra_l2', 'demo_demo_trans__xtra_l3'],
                colorID: 'lensTransitionGreen'
            },
            {
                id: 'standard',
                label: "lens_propo_colors_brown",
                asset: baseAssetURL('Transitions/XTRActive/2. Brown/brown.mp4'),
                description: ['demo_demo_trans__xtra_l1','demo_demo_trans__xtra_l2', 'demo_demo_trans__xtra_l3'],
                colorID: 'lensTransitionBrown'
            },
            {
                id: 'standard1',
                label: "lens_propo_colors_grey",
                asset: baseAssetURL('Transitions/XTRActive/1. Grey/grey.mp4'),
                description: ['demo_demo_trans__xtra_l1','demo_demo_trans__xtra_l2', 'demo_demo_trans__xtra_l3'],
                colorID: 'lensTransitionGrey'
            },
        ]
    },
    {
        demo: 'packshot-of-frame', 
        demoType: ProductDemoType.NESTED_DEMO,
        products: [
            {
                demo: 'crizal-sun-grey', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_sun_xprotect',
                        label: 'Crizal Sun Grey',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Grey/WireFrames_With.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'demo_uncoated_lens',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Grey/WireFrames_Without.jpg'),
                    },
                ]
            },
            {
                demo: 'crizal-sun-brown', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_sun_xprotect',
                        label: 'Crizal Sun Brown',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Brown/AcetateFrames_With.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'demo_uncoated_lens',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Brown/AcetateFrame_Without.jpg'),
                    },
                ]
            },
            {
                demo: 'crizal-sun-green', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_sun_xprotect',
                        label: 'Crizal Sun Green',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Green/Tortoise_With.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'demo_uncoated_lens',
                        asset: baseAssetURL('Crizal/CompanionTool/Compare%20Section/Compare%20-%20Green/TortoiseFrame_Without.jpg'),
                    },
                ]
            }
        ]
    },

    {
        demo: 'aesthetic-of-the-frame',
        demoType: ProductDemoType.SLIDER_IMAGE,
        products: [
          {
            id: 'crizal_easy',
            label: 'Crizal Easy',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Easy-Alize-Rock-Forte_paysage.jpg'),
            description: ['demo_demo_crizal_easy_l1', 'demo_demo_crizal_easy_l2', 'demo_demo_crizal_easy_l3', 'demo_demo_crizal_easy_l4']
          },
          {
            id: 'crizal_aliz',
            label: 'Crizal Alize',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Easy-Alize-Rock-Forte_paysage.jpg'),
            description: ['demo_demo__crizal_alize_l1']
          },
          {
            id: 'crizal_rock',
            label: 'Crizal Rock',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Easy-Alize-Rock-Forte_paysage.jpg'),
            description: ['demo_demo_crizal_rock_l1', 'demo_demo_crizal_rock_l2', 'demo_demo_crizal_rock_l3']
          },
          {
            id: 'crizal_forte',
            label: 'Crizal Forte',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Easy-Alize-Rock-Forte_paysage.jpg'),
            description: ['demo_demo_crizal_forte_l1','demo_demo_crizal_forte_l2','demo_demo_crizal_forte_l3']
        
          },
          {
            id: 'crizal_prevencia',
            label: 'Crizal Prevencia',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Prevencia_paysage.jpg'),
            description: ['demo_demo_crizal_prev_l1','demo_demo_crizal_prev_l2','demo_demo_crizal_prev_l3']
          },
          {
            id: 'crizal_sapphire',
            label: 'Crizal Sapphire',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_Sapphire_paysage.jpg'),
            description: ['demo_demo_crizal_sapp_l1', 'demo_demo_crizal_sapp_l2', 'demo_demo_crizal_sapp_l3', 'demo_demo_crizal_sapp_l4', 'demo_demo_crizal_sapp_l5']
            
          },
          {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: baseAssetURL('Crizal/01_Crizal Transversal_1/Crizal_Aesthetic_STANDARD_paysage.jpg')
          }
        ]
    },
    ...PRODUCT_LENS_DEMO,
    {
        demo: 'enemies-of-vision', 
        demoType: ProductDemoType.NESTED_DEMO_TRANSVERSAL,
        products: [
            {
                demo: 'enemies-of-vision__water', 
                demoType: ProductDemoType.SLIDER_VIDEO,
                products: [
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alize',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_Easy-Alize.mp4'),
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_Forte.mp4'),
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_Prevencia.mp4'),
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_Rock.mp4'),
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_Sapphire.mp4'),
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Water/Crizal_Water_STANDARD.mp4'),
                    }
                ]
            },    
            {
                demo: 'enemies-of-vision__dust', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alize',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Easy-Alize.jpg'),
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Forte.jpg'),
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Prevencia.jpg'),
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Rock.jpg'),
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Sapphire.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Dust/Crizal_Dust_Standard.jpg'),
                    }
                ]
            },
            {
                demo: 'enemies-of-vision__scratches', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alize',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_Easy-Alize.jpg'),
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_Forte.jpg'),
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_Prevencia.jpg'),
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_Rock.jpg'),
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_Sapphire.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Scratch/Crizal_Scratch_STANDARD.jpg'),
                    }
                ]
            },
            {
                demo: 'enemies-of-vision__glare', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alize',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_Easy-Alize.jpg'),
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_Forte.jpg'),
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_Prevencia.jpg'),
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_Rock.jpg'),
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_Sapphire.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Glare/Crizal_Glare_STANDARD.jpg'),
                    }
                ]
            },                
            {
                demo: 'enemies-of-vision__smudges', 
                demoType: ProductDemoType.SLIDER_IMAGE,
                products: [
                    {
                        id: 'crizal_aliz',
                        label: 'Crizal Alize',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_Easy-Alize.jpg'),
                    },
                    {
                        id: 'crizal_forte',
                        label: 'Crizal Forte',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_Forte.jpg'),
                    },
                    {
                        id: 'crizal_prevencia',
                        label: 'Crizal Prevencia',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_Prevencia.jpg'),
                    },
                    {
                        id: 'crizal_rock',
                        label: 'Crizal Rock',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_Rock.jpg'),
                    },
                    {
                        id: 'crizal_sapphire',
                        label: 'Crizal Sapphire',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_Sapphire.jpg'),
                    },
                    {
                        id: 'standard',
                        label: 'packaged_offer_popup_po_popup_standard',
                        asset: baseAssetURL('Crizal/02_Crizal Transversal_2/Smudges/Crizal_Smudge_STANDARD.jpg'),
                    }
                ]
            }
        ]
    }
  ]

export {
    FAMILIES,
    BRANDS,
    TEMPORARY_PRODUCT_LIST,
    LENS_DEMO
}
export default PRODUCTS;


