import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    type?: 'default' | 'style1' | 'style2',
    active: string,
    items: {
        label?: string,
        id: string,
        icon?: React.ReactNode
    }[],
    onSelect: (id: string) => void
}

const ButtonTab : React.FC<Props>  = ({
    type = 'default',
    active,
    items,
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('button-tab');

    // HOOKS

    // METHODS

    return (
        <ul className={B(type)}>
            { items.map( (x,i) => (
                <>
                    <li className={ active === x.id ? 'selected' : ''} onClick={() => onSelect(x.id)}>
                        {x.label}
                        { x.icon && x.icon }
                    </li>
                    {   
                        type === 'style2' && i !== (items.length - 1) &&
                        <li className='devider'></li>
                    }
                </>
            )) }            
        </ul>
    )
}

export default ButtonTab;