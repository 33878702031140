import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import Text from '../../../components/ui/Text';
import { getApp } from '../../../store/slices/app.slice';
import { getUser } from '../../../store/slices/user.slice';
import ExperienceType from '../../../types/experience.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {

}

const DeliveryAdvices : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-advices');
    const { lensConsultation } = useSelector(getUser);
    const { lensRecommendation } = lensConsultation;
    const { t } = useTranslation(useSelector(getApp))
    const [lensDesign, setLensDesign] = useState<string>();

    const advicesContentMapping: {
        [key: string]: any
    } = {
        wearing: {
            singlevision: 'advices_adv__wearing_advices/text/single_vision',
            progressive: 'advices_adv__wearing_advices/text/progressive',
            stellest: 'advices_adv__wearing_advices/text/stellest',
            progressive_sv_lens: 'advices_adv__wearing_advices/text/single_vision',
            both: 'advices_adv__wearing_advices/text/single_vision',
            personalized:''
        },
        ergo: {
            singlevision: 'advices_adv__ergonomic/text/single_vision',
            progressive: 'advices_adv__ergonomic/text/progressive_lenses',
            stellest: 'advices_adv__ergonomic/text/stellest',
            progressive_sv_lens: 'advices_adv__wearing_advices/text/single_vision',
            both: 'advices_adv__wearing_advices/text/single_vision',
            personalized:''
        }
    }
    const appStore = useSelector(getApp);
    const { experience } = appStore;

    // HOOKS
    useEffect(() => {
        if(!lensRecommendation)return;
        
        if(lensRecommendation.pair1?.correct?.product &&
            lensRecommendation.pair1?.correct.product?.id && 
            lensRecommendation.pair1?.correct.product?.lensDesign){
            const isStellest = lensRecommendation.pair1?.correct.product?.id.includes('stellest');
            const lensDesignValue = lensRecommendation.pair1?.correct.product?.lensDesign;
    
            setLensDesign(isStellest ? 'stellest' : lensDesignValue);
        }


    }, [lensRecommendation])

    // METHODS

    const extendExp = () =>{
        if(experience === ExperienceType.SUN)return '-sun'
    }
    return (
        <div className={B()}>
            <h2>{t('delivery_del_advices')}</h2>

            <div className="section -justify-end -border-radius">
                <div className="info">
                    <h4>{t('advices_adv__header_title')}</h4>

                    <Text type="white">
                        {t('advices_adv__header_subtitle')}
                    </Text>

                    <ul>
                        <li>
                            <span>
                                <Icon name="remove-dirt"/>
                            </span>
                            <p>
                            {t('advices_adv__header_content_1')}
                            </p>
                        </li>
                        <li>
                            <span>
                                <Icon name="remove-dirt"/>
                            </span>
                            <p>
                            {t('advices_adv__header_content_2')}
                            </p>
                        </li>
                        <li>
                            <span>
                                <Icon name="visit-advice"/>
                            </span>
                            <p>
                            {t('advices_adv__header_content_3')}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={E('additional')}>
                {/* <div className="left">
                    <div className="block -advice">a</div>
                    <div className="block -advice">a</div>
                </div>
                <div className="right">
                    <div className="block -step">b</div>
                    <div className="block -step">b</div>
                </div> */}

                <div className={E('specific')}>
                    <label className={E('specific-head')}>{t('delivery_(advices)_specific_advice')}</label>

                    <div className={E('specific-box')}>
                        <Icon name={`wearing-advice${extendExp()}`}/>
                        <label>{t('delivery_(advices)_wearing_advice')}</label>
                        <p>
                            {lensDesign && t(advicesContentMapping.wearing[lensDesign.replace(/\s/g, '')])}
                        </p>
                    </div>

                    <div className={E(`specific-box`)}>
                        <Icon name={`lifestyle-advice${extendExp()}`}/>
                        <label>{t('delivery_(advices)_ergo')}</label>
                        <p>

                            {lensDesign && t(advicesContentMapping.ergo[lensDesign.replace(/\s/g, '')])}
                        </p>
                    </div>
                </div>

                <div className={E('next')}>
                    <label className={E('next-head')}>{t('advices_adv__whats_next')}</label>

                    <div className={E('next-content')}>
                        <label>{t('advices_adv__after_2_weeks')}</label>
                        <div>
                            <label>{t('advices_adv__first_follow-op_(optician)')}</label>
                            {t('advices_adv__check_your_adaptation_and_questions_')}
                            <span>+</span>
                        </div>

                        <label>{t('advices_adv__every_year')}</label>

                        <div>
                            <label>{t('delivery_(advices)_eye_exam_')}</label>
                            {t('delivery_(advices)_frame_adjust')}
                            <span>+</span>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default DeliveryAdvices;