import React from 'react';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    color?: string,
    dim?: string,
    size?: string
}

const Loading : React.FC<Props>  = ({
    color,
    dim = '10rem',
    size = '1rem'
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('loading-spinner');
    // HOOKS

    // METHODS
    const cssProperties = () => {
        return { 
            '--loading-spinner-color': color,
            '--loading-spinner-dim': dim,
            '--loading-spinner-size': size
        } as React.CSSProperties
    }

    return (
        <div className={B()} style={cssProperties()}>
            <div></div>
        </div>
    )
}

export default Loading;