import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import iLensProposalField from '../../../interface/lens-proposal-field.interface';
import { getApp } from '../../../store/slices/app.slice';
import deliverySlice, { getDelivery } from '../../../store/slices/delivery.slice';
import { getUser } from '../../../store/slices/user.slice';
import ExperienceType from '../../../types/experience.type';
import LensCategoryType from '../../../types/lens-category.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useAdvancePricing from '../../advance-price-setting/useAdvancePricing';
import deliverySummary from '../../../assets/images/delivery-summary.jpg'
import deliverySummarySun from '../../../assets/images/delivery-summary-sun.png'

interface Props {

}

const DeliveryTotal : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-total');
    const appStore = useSelector(getApp);
    const { isShowSummaryDetails } = useSelector(getDelivery);
    const { complementaryEquipment, lensConsultation, measures } = useSelector(getUser);
    const { isValidated, breakdown } = lensConsultation
    const { t } = useTranslation(appStore);
    const dispatch = useDispatch();
    const [ advancePrice, setAdvancePrice ] = useState<any>();
    const {
        isAdvancePricing,
        getAdvancePricingTotal,
    } = useAdvancePricing(appStore);
    const { experience } = appStore;


    useEffect(() => {
        if(!lensConsultation?.lensRecommendation || !isAdvancePricing)return;
        setAdvancePrice(getAdvancePricingTotal(lensConsultation.lensRecommendation));
    }, [])


    // HOOKS

    // METHODS
    const showSummary = () => {
        dispatch(deliverySlice.actions.showSummaryDetails(true));
    }

    const validate = () => {
        dispatch(deliverySlice.actions.validate());
    }

    const validatePairs = (isPair1: boolean) => {
        if(isValidated){
            if(isPair1){
                return breakdown.pair1.enabled
            } else{
                return breakdown.pair2.enabled
            }
        }else{
            return false
        }
    }

    const getBreakdown = () => {
        if(advancePrice){
            return `${(getPairPrice('pair1') + getPairPrice('pair2'))} ${breakdown.currency}`;
        }
        return `${breakdown.deliveryTotal} ${breakdown.currency}`
    }

    const getBreakdownSun = () => {
        if (advancePrice) {
            return `${(getPairPrice('pair1'))} ${breakdown.currency}`
        }
        return `${breakdown.deliveryTotal} ${breakdown.currency}`
    }

    const get = (lensDesign: string | undefined) => {
        switch(lensDesign){
            case LensCategoryType.PROGRESSIVE: return t('delivery_design_lens1')
            case LensCategoryType.SINGLE_VISION: return t('delivery_design_lens2')
            case LensCategoryType.PROGRESSIVE_SV_LENS: return t('delivery_design_lens3')
            case 'both': return t('delivery_design_lens3')
            default: return ''
        }
    }

    const withComplementaryEquipment = () => {
        return complementaryEquipment && complementaryEquipment[0].value;
    }

    const getPair2VisionType = () => {
        let vision = '';
        const translationKey: {
            [key:string]: string
        } = {
            prescription_rx_driving: 'delivery_design_lens6',
            prescription_rx_computer: 'delivery_design_lens4',
            prescription_rx_sun: 'delivery_design_lens5'
        }
        const label = (complementaryEquipment as iLensProposalField[])[0].value?.label;

        if(withComplementaryEquipment() && label){
            vision = t(translationKey[label]);
        }
        return vision;
    }

    const getPairPrice = (pair: 'pair1' | 'pair2') => {
        let price = !advancePrice ?
            breakdown[pair].deliveryPrice :
            advancePrice[pair].total;

        if(validatePairs(pair === 'pair1') && advancePrice && measures.one){
            const measuresPair = measures.one[ pair === 'pair1' ? 0 : 1 ];
            price = price + ( measuresPair ? measuresPair.price.value : 0 );
        }

        return price;
    }

    const buttonIcon = (color: string) => {
        return (
            <CircleArrow
                type='right'
                background={color}
            />
        )
    }
    const isSunExp = experience === ExperienceType.SUN

    const backgroundImageExperience = () => {
        return isSunExp ? deliverySummarySun : deliverySummary
    }

    return (
        <div className={B()} style={{
            backgroundImage: `url(${backgroundImageExperience()})`,

        }}>

            <div className={E('modal', [isShowSummaryDetails ? 'dim' : ''])}>
                <h2 className={E('title')}>{t('delivery_del__summary')}</h2>

                <ul className={E('details')}>
                    {
                        validatePairs(true) ?
                        <li>
                            <label>{t('delivery_del_pair_1')}</label>
                            <p>{breakdown.pair1.categoryLens}</p>

                            <span>{getPairPrice('pair1')} {breakdown.currency}</span>

                        </li> : <li>-</li>
                    }
                    {
                        validatePairs(false) && experience !== ExperienceType.SUN ?
                        <li>
                            <label>{t('delivery_del_3_pair_2')}</label>

                            { withComplementaryEquipment() &&
                                <p>{getPair2VisionType()}</p> }

                            { !withComplementaryEquipment() &&
                                <p>{breakdown.pair2.categoryLens}</p> }

                            <span>{getPairPrice('pair2')} {breakdown.currency}</span>

                        </li> : <li>-</li>
                    }
                    {
                        validatePairs(true) || validatePairs(false) ?
                        <li>
                            <label>{t('delivery_del_total')}</label>
                            <span>{experience === ExperienceType.SUN ? getBreakdownSun() : getBreakdown()}</span>
                        </li> : <li>-</li>
                    }
                </ul>

                <div className={E('buttons')}>
                    <Button
                        experience={experience}
                        text={t('delivery_del_view_more_details') }
                        type="secondary"
                        disabled={!(validatePairs(true) || validatePairs(false))}
                        click={showSummary}
                        icon={buttonIcon('#eee')}
                    />

                    <Button
                        experience={experience}
                        text={t('delivery_del_validate') }
                        click={validate}
                        disabled={!(validatePairs(true) || validatePairs(false))}
                        icon={buttonIcon('#fff')}
                    />
                </div>
            </div>

            <div className={E('dim', [isShowSummaryDetails ? 'on' : ''])}></div>
        </div>
    )
}

export default DeliveryTotal;
