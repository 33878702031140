import React from 'react';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';
import Text from '../../../components/ui/Text';
import PrescriptionForm from '../../prescription/components/PrescriptionForm';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import ExperienceType from '../../../types/experience.type';

interface Props {
    
}

const DeliveryPrescription : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-prescription');
    const { t } = useTranslation(useSelector(getApp));
    const { experience } = useSelector(getApp);
    // HOOKS

    // METHODS
    const renderEyeReader = () => {
        switch (experience) {
            case ExperienceType.SUN:
                return <Icon name="eye-reader-sun" type='image'/>
            default:
                return <Icon type="gif" extension="gif" name="eye-reader-ava"/>
        }
    }

    return (
        <div className={B()}>
            
            <div className={E('image')}>
                {/* <Icon name="eye-reader-standard" type="image"/>*/}
                 {renderEyeReader()}
            </div>

            <div className={E('prescription')}>                
                <h2>{t('delivery_del__prescription')}</h2>
                <Text type="light">
                {t('delivery_del__text_underneath_rx')}
                </Text>

                <PrescriptionForm readOnly={true}/>
            </div>

        </div>
    )
}

export default DeliveryPrescription;