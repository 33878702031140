
const routes = {
    home: {
        id: 'home',
        path: '/',
        label: 'Essilor Companion ™'
    },
    myCompanion: {
        id: 'myCompanion',
        path: '/mycompanion',
        label: 'my_practice_my_my_compa'
    },
    practiceID: {
        id: 'practiceID',
        path: '/practice-id',
        label: 'my_practice_my_practice_id'
    },
    legalNotices: {
        id: 'legalNotices',
        path: '/legal-notices',
        label: 'legal_notices_ln_legal_notices'
    },
    lensProposalBuilder: {
        id: 'lens-proposal-builder',
        path: '/lens-proposal-builder',
        label: 'lens_proposal_builder_lpb_lens_pro_builder'
    },
    needExplorer: {
        id: 'needExplorer',
        path: '/need-explorer',
        label: 'lens_proposal_builder_lpb_need_explorer'
    },
    prescription: {
        id: 'prescription',
        path: '/prescription',
        label: 'prescription_rx_prescription'
    },
    measures: {
        id: 'measures',
        path: '/measures',
        label: 'pair_1_tab_del_p1_measures_title'
    },
    lensConsultation: {
        id: 'lensConsultation',
        path: '/lens-consultation',
        label: 'lens_proposal_builder_lpb__lens_consultation'
    },
    lensThickness: {
        id: 'lensThickness',
        path: '/lens-thickness',
        label: 'my_practice_my_lens_thick'
    },
    summary: {
        id: 'summary',
        path: '/summary',
        label: 'summary_sum_summary'
    },
    packageOffer: {
        id: 'packageOffer',
        path: '/package-offer',
        label: 'lens_consultation_lc_packaged_offer'
    },
    delivery: {
        id: 'delivery',
        path: '/delivery',
        label: 'lens_proposal_builder_lpb__delivery'
    },
    demonstration: {
        id: 'demonstrations',
        path: '/demonstrations',
        label: 'legal_notice_ln_01_demonstrations_title'
    },
    demonstration2: {
        id: 'demonstrations',
        path: '/demonstration',
        label: 'legal_notice_ln_01_demonstrations_title'
    },
    emat: {
        id: 'emat',
        path: '/emat',
        label: "home_hommemat"
    },
    whatsNew: {
        id: 'whatsnew',
        path: '/whats-new',
        label: "home_hom_whats_new"
    },
    licenseActivation: {
        id: 'license-activation',
        path: '/license-activation',
        label: 'license_activation_license_activation'
    },
    discovery: {
        id: 'discovery',
        path: '/discovery',
        label: 'discovery_essilor_de_discovery_essilor'
    },

    //temp
    advanceSetting: {
        id: 'advanceSetting',
        path: '/advance-setting',
        label: 'advancedpricing_advance_settings'
    },

    education: {
        id: 'education',
        path: '/education',
        label: 'Education'
    }
}

export default routes;
