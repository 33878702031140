import React, { useState, useEffect } from 'react'
import useBEM from '../../../utils/hooks/useBEM'
import Modal from '../../../components/Modal'
import useTranslation from '../../../utils/hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import measureSlice, { getMeasure } from '../../../store/slices/measure.slice'
import { getApp } from '../../../store/slices/app.slice'
import refresh from '../../../assets/svg/refresh.svg'
import { deleteFrametrace, getFrametraceList } from '../../../utils/api/frametrace'
import Spinner from '../../../components/ui/Spinner'
import Frametrace from '../../../interface/frametrace.interface'
import Button from '../../../components/ui/Button'
import userSlice from '../../../store/slices/user.slice'

interface Props {
  showModal?: boolean,
  onHideModal?: () => void,
  displayModal: () => void
}

const FrametraceListModal : React.FC<Props> = ({
  showModal,
  onHideModal,
  displayModal
}) => {
  const [B, E] = useBEM('patient-list-modal')
  const [frametraces, setFrametraces] = useState([])
  const { t } = useTranslation(useSelector(getApp))
  const [isLoading, setIsLoading] = useState(false)
  const modalStyle = {
    width: "70.5rem",
    height: "52.1rem",
    borderRadius: "2rem"
  }
  const appStore = useSelector(getApp);
  const { accountDetails } = appStore
  const dispatch = useDispatch();

  useEffect(() => {
    loadFrametraces()
  }, [])

  const loadFrametraces = async () => {
    setIsLoading(true)

    try {
      if (accountDetails && accountDetails?.shop?.country && accountDetails?.shop?.shop_account_no) {
        const shopId = accountDetails?.shop?.shop_account_no
        const countryCode = accountDetails?.shop?.country
        const response = await getFrametraceList(countryCode,shopId)

        if (!response.data) {
          throw new Error('Invalid data')
        }

        setFrametraces(response.data)
        setIsLoading(false)
      }

    } catch(err) {
      setIsLoading(false)
    }
  }

  const deleteSelectedFrametrace = async (frametrace: Frametrace) => {
    try {
      const response = await deleteFrametrace(frametrace._id)
      if (response.status === 200) displayModal()
    } catch(err) {
      displayModal()
    }
  }

  const buildMeasureData = (ft: Frametrace) => {
    if (ft) {
      const measureData = {
        one: [{
          price: { value: 0 },
          a: {
            value: ft.HBOX,
            error: false,
          },
          b: {
            value: ft.VBOX,
            error: false,
          },
          d: {
            value: ft.DBL,
            error: false,
          }
        }],
        two: [{
          left: {
            height: {
              value: ft.OCHT.left,
              error: false,
            },
            pd: {
              value: ft.IPD.left,
              error: false
            }
          },
          right: {
            height: {
              value: ft.OCHT.right,
              error: false
            },
            pd: {
              value: ft.IPD.right,
              error: false
            }
          }
        }],
        three: [{
          pantoscopicAngle: {
            value: ft.PANTO,
            error: false,
          },
          wrapAngle: {
            value: ft.ZTILT,
            error: false,
          },
          vertexDistance: {
            value:ft.BVD,
            ivsValue: {
              left:ft.BVDV2.left,
              right:ft.BVDV2.right
            },
            error: false,
          },
          readingDistance: {
            value: 0,
            error: false,
          },
        }]
      }

      return measureData
    }
  }

  const onSelectFrametrace = (id: string) => {
    const frametrace = frametraces.find(({ _id }) => _id === id)
    const measureData = frametrace ? buildMeasureData(frametrace) : ''

    if (measureData !== '') {
      dispatch(measureSlice.actions.saveFrametraceMeasure(measureData))
      dispatch(userSlice.actions.saveFrametraceMeasure(measureData))
      dispatch(measureSlice.actions.generateIvs(true))

      if (frametrace) deleteSelectedFrametrace(frametrace)
    }
  }



  return (<>
    <Modal show={showModal} onClose={onHideModal} style={modalStyle}>
      <div className={B()}>

        <div className={E('head')}>
          <span className={E('head-count')}>
            {frametraces.length} {t('waiting_list_wl_results')}
          </span>

          <img
            src={refresh}
            alt=""
            className="refresh"
            onClick={() => loadFrametraces()}
          />
        </div>

        {
          isLoading && <div className={E('loader')}>
            <Spinner color='#eee'/>
          </div>
        }

        <div className={E('table')}>
          {
            frametraces.map((ft: Frametrace, i: number) => (
              <div className={E('table-row')} key={i}>
                <div className={E('table-column')}>
                  {new Date(ft.created_at).toLocaleTimeString()}
                </div>
                <div className={E('table-column')}>
                  {new Date(ft.created_at).toLocaleDateString()}
                </div>
                <div className={E('table-column')}>
                  {ft.FNCLIENT + " " + ft.LNCLIENT}
                </div>
                <div className={E('table-column')}>
                  <Button
                    click={() => onSelectFrametrace(ft._id)}
                    text={t('waiting_list_wl_select')}
                  />
                </div>
              </div>
            ))
          }
        </div>
        {!navigator.onLine && 
        <div  className={E('offline')}>
          <h2 className={E('offline-message')} >You are in offline mode. Please connect to the internet to donwload data in IVS.</h2> </div>
          }
      </div>
    </Modal>
  </>)
}

export default FrametraceListModal
