import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useBEM from '../../utils/hooks/useBEM';
interface Props {
    percentage:any,
}

const LoadingAssets : React.FC<Props>  = ({
    percentage
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('loading-asset');
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(["waiting","waiting","waiting"])
    const loadingText = {
        first: "Our assistant is currently downloading the catalog",
        second: "We currently adjusting our latest technologies for you",
        third: "Your companion will be ready soon"
    }
    
    
    // HOOKS
    useEffect(() => {
        let newList = progress;
        if(percentage <= 33){
            
        }
        if(percentage <= 33){
            if(percentage === 33){
                newList[0] = 'done'
            }else{
                newList[0] = ''
            }
        }
        else if(percentage >= 34 && percentage <= 66){
            if(percentage === 66){
                newList[1] = 'done'
            } else{
                newList[1] = ''
            }
        }
        else if(percentage >= 67){
                if(percentage === 100){
                    newList[2] = 'done'
                }
                else{
                    newList[2] = ''
                }
        }
            
        
        setProgress(newList)
      }, [percentage]);
      
    //   console.log(percentage,progress)
    
    // METHODS
    const displayText = () =>{
        if(percentage <= 33)
            return loadingText.first;
        else if(percentage >= 34 && percentage <= 66)
            return loadingText.second;
        else if(percentage >= 67)
            return loadingText.third;
    }
    return (
            <div className={B()}>
                <div className={E('container')}>
                    <h3>{displayText()}</h3>
                    <div className="progress-container">
                        <div className="progress-container__bar 1">
                            <div className={`progress-value ${progress[0]}`}></div>
                        </div>
                        <div  className="progress-container__bar 2">
                            <div className={`progress-value ${progress[1]}`}></div>
                        </div>
                        <div  className="progress-container__bar 3">
                            <div className={`progress-value ${progress[2]}`}></div>
                        </div>
                    </div>
                </div>                
            </div>
    )
}

export default LoadingAssets;