import { IProductEprom } from "../../constants/eprom.constants";
import iSubProduct from "../../interface/sub-product.inteface";
import axios from 'axios';
import ENDPOINTS from "../../constants/endpoints";
import { useSelector } from "react-redux";
import { getApp } from "../../store/slices/app.slice";
import LensFamilyType from "../../types/lens-family.type";

const useEprom = () => {
    const { epromData, accountDetails } = useSelector(getApp);


    const formatData = (epromData: IProductEprom[], country: string) => {
        let finalData: IProductEprom = {};
        (epromData || []).forEach(ep => {
            const [key, value] = Object.entries(ep)[0];
            finalData = {
                ...finalData,
                [key]: value
            }
        });
        return finalData;
    }

    const getEPROMData = (region: string, country: string) => {
        const promise = new Promise((res, rej) => {
            axios.get(ENDPOINTS.eprom(region, country))
                .then((resp) => {
                    res({
                        status: 'success',
                        data: resp.data
                    })
                })
                .catch((err) => rej({
                    status: 'error',
                    data: err
                }))
        });
        return promise;
    }

    const getAvailableProduct = (productId: string, type: 'masstint' | 'treatment') => {
        const masstintOrTreatment = (epromData && epromData[productId][type]) || {};
        return Object.keys(masstintOrTreatment);
    }

    const checkIfValidMassTint = (
        correctCode: string,
        protectIds: string[],
        index: number
    ) => {
        const correct = epromData && epromData[correctCode];
        if (!correct || !correct.masstint) return true;
        let valid = true;

        protectIds.forEach(id => {
            const mass = correct.masstint;
            if (!mass || !mass[id]) {
                valid = false;
                return;
            }
            const indexes = mass[id].index;
            if (!indexes.includes(String(index))) valid = false;
        });
        return valid;
    }

    const checkIfValidTreatment = (
        correctCode: string,
        enhanceId: string,
        index: number
    ) => {
        const correct = epromData && epromData[correctCode];
        if (!correct || !correct.treatment) return true;
        let valid = true;

        const treatment = correct.treatment[enhanceId];
        if (!treatment) {
            valid = false;
            return false;
        }
        const indexes = treatment.index;
        if (!indexes.includes(String(index))) valid = false;
        return valid;
    }

    const getAvailableColors = (correctCode: string, protectId: string) => {
        const correct = epromData && epromData[correctCode];
        if (!correct || !correct.masstint || !correct?.masstint[protectId]) return [];
        const colors = correct?.masstint[protectId]?.color || [];
        return colors;
    }


    const filterByAvailableIndex = (
        productId: string,
        subProduct: iSubProduct[],
        enabledIndexes?: number[]
    ) => {
        const product = epromData && epromData[productId];
        const avalableIndexes = product?.index || [];
        if (!avalableIndexes.length) return [];
        return subProduct.filter(x =>
            avalableIndexes.includes(String(x.index)) &&
            (enabledIndexes ? enabledIndexes.includes(x.index) : true)
        )
    }

    const filterByLowestIndex = (subProducts: iSubProduct[]) => {
        let lowestIndexObject: any = {}
        subProducts.forEach(subProduct => {
            const { code, index } = subProduct;
            if (!code) return;
            if (!lowestIndexObject[code] || lowestIndexObject[code].index > index) {
                lowestIndexObject = {
                    ...lowestIndexObject,
                    [code]: subProduct
                }
            }
        });
        return lowestIndexObject;
    }

    // check if product available base on subproducts
    const isSubProducstAvailable = (
        subProductIds: string[]
    ) => {
        if (!epromData) return true;
        const epromSubProducts = Object.keys(epromData);
        return !!subProductIds.filter(x => {
            return epromSubProducts.includes(x)
        }).length;
    }

    const filterSubProductsAvailable = (subProducts: iSubProduct[]) => {
        if (!epromData) return subProducts;
        const epromSubProducts = Object.keys(epromData);
        return subProducts.filter(sb => {
            return epromSubProducts.includes(sb.code as string);
        })
    }

    const getTheLowestIndex = (
        subProducts: iSubProduct[],
        enabledIndexes: number[]
    ) => {
        let baseIndex = 9;
        const subProductCode = subProducts[0]?.code;
        const epromIndexes = (
            epromData &&
            subProductCode &&
            epromData[subProductCode].index
        ) || [];

        subProducts.forEach(sb => {
            if (
                sb.index < baseIndex &&
                enabledIndexes.includes(sb.index) &&
                epromIndexes.includes(String(sb.index))
            ) baseIndex = sb.index;
        });
        return subProducts.filter(x => x.index === baseIndex);
    }

    const getEpromApiEndpoint = () => {
        const country = accountDetails?.shop.country || '';
        const countries = ['it', 'be']; //italy, belgium
        const withCustomEprom = countries.includes(country);
        const query = withCustomEprom ? `&country=${country}` : '';
        return ENDPOINTS.lensThickness + query;
    }

    const isValidOnEpromBO = (
        correctId: string,
        productIds: string[],
        toCheck: 'treatment' | 'masstint'
    ) => {
        if (!epromData) return false;
        const correct = epromData[correctId];
        if (!correct) return false;
        const availableIds = Object.keys(correct[toCheck] || []);
        const notAvailableIds = productIds.filter(id => !availableIds.includes(id));
        return notAvailableIds.length === 0;
    }

    const isValidOnEPROM = (
        productIds: string[],
        validProductIds: string[],
        result: (result: boolean) => void
    ) => {
        //italy
        const italyProductsByPass = ['xperio_mirrors', 'xperio_tinted']
        if(accountDetails?.shop?.country === 'it'){
            validProductIds = [...validProductIds, ...italyProductsByPass]
        }
        //belgium
        if (accountDetails?.shop?.country === 'be') {
            productIds = productIds.filter(id => id !== 'xperio_mirrors')
        }
        const toCheckIds = (!productIds || !productIds.length) ? ['clear'] : productIds;
        const validIds = (!validProductIds || !validProductIds.length) ? [] : validProductIds;
        const invalidIds = toCheckIds.filter(id => !validIds.includes(id));
        result(!invalidIds.length);
    }

    return {
        checkIfValidMassTint,
        checkIfValidTreatment,
        getAvailableColors,
        getEPROMData,
        formatData,
        getAvailableProduct,
        filterByAvailableIndex,
        isSubProducstAvailable,
        filterSubProductsAvailable,
        getTheLowestIndex,
        getEpromApiEndpoint,
        isValidOnEPROM,
        isValidOnEpromBO
    }
}

export default useEprom;