import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import measureSlice, { getMeasure } from '../../store/slices/measure.slice';
import useBEM from '../../utils/hooks/useBEM';
import MeasuresContainer from './components/MeasuresContainer';
import MeasuresData from './components/MeasuresData';
// import measurementAnimation from './../../assets/videos/measurement.mp4';
// import Spinner from '../../components/ui/Spinner';
import { getApp } from '../../store/slices/app.slice';
import Spinner from '../../components/ui/Spinner';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';


interface Props {

}

const Measures : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('measures');
    const { experience } = useSelector(getApp);
    const { view, step } = useSelector(getMeasure);
    const animationRef = useRef<any>();  
    const timeoutDelays = useRef<any>([]);
    const dispatch = useDispatch();
    // const { videos } = useSelector(getApp);
    const { get } = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const [animation, setAnimation] = useState<string>();


    // HOOKS
    useEffect(() => {        
        const downloadVideo = async () => {
            const vid = await get('localAssetsStore', 'measurementAnimation');
            if(vid)setAnimation(vid.asset);
        }        
        downloadVideo();
        
        dispatch(measureSlice.actions.savePairData(0));
        dispatch(measureSlice.actions.sessionStart(new Date().toISOString()));

        return () => {
            dispatch(measureSlice.actions.editPair(0));
            dispatch(measureSlice.actions.changeView('measure'));
            dispatch(measureSlice.actions.changeStep(0));            
        }
    }, [])

    useEffect(() => {
        if(view === 'data')return;    
        return () => {
            clearTimeouts();
            // dispatch(measureSlice.actions.changeStep(0));
        }
    }, [view]);

    useEffect(() => {
        clearTimeouts();
         
        if(step === 0){     
            if(!animationRef.current) return;
            animationRef.current.currentTime = 0.25;
            animationRef.current.play();            
            addDelay(() => {
                animationRef.current.pause();
            }, 3500);
        }

        if(step === 1){
            if(!animationRef.current)return;
            animationRef.current.currentTime = 5;
            animationRef.current.play();            
            addDelay(() => {
                animationRef.current.pause();
            }, 1300);
        }

        if(step === 2){
            if(!animationRef.current)return;
            animationRef.current.currentTime = 8.8;
            animationRef.current.play();
            addDelay(() => {
                animationRef.current.pause();
            }, 3000);
        }

    }, [step, animation])

    // METHODS

    const addDelay = (callback: any, delay: number) => {
        const timeout = setTimeout(() => {
            callback();
        }, delay);
        timeoutDelays.current.push(timeout);
    }

    const clearTimeouts = () => {
        timeoutDelays.current.forEach( (d:any) => clearTimeout(d) );
        timeoutDelays.current = [];
    }

    return (
        <div className={B([`step${step}`, experience])}>             

            {/* {
                !animation &&
                <Spinner text="loading" color={'rgb(0 134 214 / 33%)'} /> 
            } */}

            { view === 'measure' && animation &&
                <div className={E('animation', `step${step}`)}>
                    <video 
                            src={animation}
                            ref={animationRef}
                            playsInline
                            autoPlay={false} loop={false}  muted>                    
                            Your browser does not support the video tag.
                    </video>
                </div>
            }
            { view === 'measure' && <MeasuresContainer/>}
            { view === 'data'  && <MeasuresData/> }        
                        
        </div>
    )
}

export default Measures;