import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iNeedExplorerQuestion from '../../../interface/need-explorer-question.interface';
import { getApp } from '../../../store/slices/app.slice';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import userSlice from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import ChoiceBox from './question-types/ChoiceBox';
import ChoiceBoxImage from './question-types/ChoiceBoxImage';
import BoxImageDurationGroup from './question-types/BoxImageDurationGroup';
import BoxImageDuration from './question-types/BoxImageDuration';
import ChoiceBoxKids from './question-types/ChoiceBoxKids';
import ChoiceImageKids from './question-types/ChoiceImageKids';
import ChoiceList from './question-types/ChoiceList';
import Dial from './question-types/Dial';
import DragAndDropSorter from './question-types/DragAndDropSorter';
import Note from './question-types/Note';
import SliderGroup from './question-types/SliderGroup';
import RatingGroup from '../components/question-types/RatingGroup';
import RatingGroupImage from '../components/question-types/RatingGroupImage'
import RankingGroupImage from '../components/question-types/RankingGroupImage'
import Frequency from '../components/question-types/Frequency'
import ExperienceType from '../../../types/experience.type';
import needExplorerSliceSun from '../../../store/slices/need-explorer-sun.slice';

interface Props {
    question: iNeedExplorerQuestion
    getAnswer?: (ans:any) => any
}

const NeedExplorerQuestion : React.FC<Props>  = ({
    question,
    getAnswer
}) =>  {
    // VARIABLES

    const {
        experience
    } = useSelector(getApp);
    const isSunExp  = experience === ExperienceType.SUN
    const [B, E] = useBEM(isSunExp ? 'need-explorer-question-sun' : 'need-explorer-question');
    const dispatch = useDispatch();
    const { t } =  useTranslation(useSelector(getApp));
    const currentNeedExplorerData = useSelector(getNeedExplorer);
    const {
        categoryStep,
        questionStep,
    } = useSelector(getNeedExplorer);

    // HOOKS
    useEffect(() => {
        dispatch(userSlice.actions.saveNeedExplorerData(currentNeedExplorerData));
    }, [currentNeedExplorerData])

    // METHODS
    const answerQuestion = (answer: any) => {
        const category = categoryStep;
        const question = questionStep;
        if(experience === 'sun'){
            dispatch(needExplorerSliceSun.actions.answerQuestion({answer, category, question}));
            dispatch(needExplorerSliceSun.actions.setShowSaved(true))
        }else{
            dispatch(needExplorerSlice.actions.answerQuestion({answer, category, question}));
            dispatch(needExplorerSlice.actions.setShowSaved(true))
        }
        if (!getAnswer)return
        getAnswer(answer)
    }

    const answerPanel = (q: iNeedExplorerQuestion) => {
        switch (q?.type) {
            case 'CHOICE_LIST':
                return <ChoiceList question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX':
                return <ChoiceBox question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX_KIDS':
                return <ChoiceBoxKids question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_IMAGE_KIDS':
                return <ChoiceImageKids question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'SLIDER_GROUP':
                return <SliderGroup question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DRAG_AND_DROP_SORTER':
                return <DragAndDropSorter question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'CHOICE_BOX_IMAGE':
                return <ChoiceBoxImage question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'NOTE':
                return <Note question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DIAL':
                return <Dial question={q} onAnswer={(ans) => answerQuestion(ans)}/>
            case 'DURATION_GROUP':
                return <BoxImageDurationGroup question={q} onAnswer={(ans:any) => answerQuestion(ans)} />
            case 'DURATION_IMAGE':
                return <BoxImageDuration question={q} onAnswer={(ans:any) => answerQuestion(ans)} />
            case 'RATING_GROUP':
                    return <RatingGroup question={q} onAnswer={(ans:any) => answerQuestion(ans)}/>
            case 'RATING_GROUP_IMAGE':
                    return <RatingGroupImage question={q} onAnswer={(ans:any) => answerQuestion(ans)}/>
            case 'RANKING_GROUP_IMAGE':
                    return <RankingGroupImage question={q} onAnswer={(ans:any) => answerQuestion(ans)}/>
            case 'FREQUENCY':
                     return <Frequency question={q} onAnswer={(ans:any) => answerQuestion(ans)}/>
            default:
                return null;
        }
    }

    return (
        <div className={B()}>
            <h4 className={E( experience === ExperienceType.SUN ? 'question-sun': 'question' , [question?.subQuestion ? 'with-subquestion' : ''])}>{t(question?.question)}<span className={E('question-subtext')}>{question?.subQuestion}</span></h4>
            
            <label className={E(experience === ExperienceType.SUN ? 'question-type-sun': 'question-type')}>{t(question?.kind)}</label>
            {/* {
                question.subQuestion &&
                <label className={E('sub-question')}> {t(question.subQuestion)} </label>
            } */}

            { answerPanel(question) }
        </div>
    )
}

export default NeedExplorerQuestion;
