import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NeedExplorerViewType from '../../types/need-explorer-view.type';
import { getNeedExplorer } from '../../store/slices/need-explorer.slice';
import useBEM from '../../utils/hooks/useBEM';
import { getUser } from '../../store/slices/user.slice';
import NeedExplorerAnamnesis from './components/NeedExplorerAnamnesis';
import NeedExplorerFinished from './components/NeedExplorerFinished';
import NeedExplorerIntro from './components/NeedExplorerIntro';
import NeedExplorerQuestionaires from './components/NeedExplorerQuestionaires';
import NeedExplorerGeneralCheckPoint from './components/NeedExplorerGeneralCheckPoint';
import ExperienceType from '../../types/experience.type';
import NeedExplorerSunExperience from './sun-experience/NeedExplorerSunExperience';
import { getApp } from '../../store/slices/app.slice';

interface Props {

}

const NeedExplorer : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B] = useBEM('need-explorer');
    const { experience } = useSelector(getApp);
    const { view } = useSelector(getNeedExplorer);
    
    // HOOKS

    // METHODS
    const dynamicView = () => {
        if(experience === ExperienceType.SUN)return <NeedExplorerSunExperience/>

        switch (view) {
            case NeedExplorerViewType.INTRO: return <NeedExplorerIntro/>
            case NeedExplorerViewType.QUESTIONNAIRE: return <NeedExplorerQuestionaires/>   
            case NeedExplorerViewType.GENERAL_CHECKPOINT: return <NeedExplorerGeneralCheckPoint/>
            case NeedExplorerViewType.FINISHED: return <NeedExplorerFinished/>        
            case NeedExplorerViewType.ANAMNESIS: return <NeedExplorerAnamnesis/>        
            default: return null
        }
    }
    
    return (
        <div className={B()}>
            {dynamicView()}
        </div>
    )
}

export default NeedExplorer;