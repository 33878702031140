import React from 'react';
import { useSelector } from 'react-redux';
import iButtonGroupButton from '../../interface/button-group-button.interface';
import { getApp } from '../../store/slices/app.slice';
import useBEM from '../../utils/hooks/useBEM';
import useTranslation from '../../utils/hooks/useTranslation';
import Icon from './Icon';

interface Props {
    value?: string,
    className?: string
    buttons: iButtonGroupButton[],
    iconType?: 'svg' | 'image' | 'png' | 'gif',
    disabled?: boolean,
    onSelect?: (value: string) => void,
    productId?:string,
}

const ButtonGroup : React.FC<Props>  = ({
    value,
    buttons,
    className,
    disabled,
    onSelect,
    iconType = 'svg',
    productId
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('button-group');
    const { t } = useTranslation(useSelector(getApp));


    // HOOKS

    // METHODS
    const onSelectHandler = (button: iButtonGroupButton) => {
        if(button.disabled)return;
        const newValue = button.value;
        const isClearValue = (newValue === value && buttons.length === 1);
        if(button.disabled)return;        
        if(onSelect)onSelect(!isClearValue ? newValue : '');        
    }

    const classModifies = (button: iButtonGroupButton) => {
        const classes = [];
        if (!button.label && button.icon) classes.push('icon-only');
        if (button.label && !button.icon) classes.push('label-only');
        if (value === button.value )classes.push('selected');
        if (button.disabled )classes.push('disabled');

        return classes;
    }

    return (
        <ul className={B( disabled ? 'disabled' : '') + ` ${className || ''}`}>
            {
                buttons.map( b => (
                    <li 
                        onClick={() => onSelectHandler(b)}
                        className={E('item', classModifies(b))} 
                        key={b.value}
                        id={`${productId}_${b.value}`}
                        >
                        {b.label && 
                            <label>{t(b.label)}</label>
                        }

                        {
                            b.icon && 
                            <Icon name={b.icon} type={iconType}/>
                        }

                        {
                            value === b.value &&
                            <span className={E('instruction')}>
                                {b.instruction}
                            </span>
                        }
                        
                    </li>
                ))
            }
        </ul>
    )
}

export default ButtonGroup;