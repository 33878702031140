import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/ui/Button'
import CircleArrow from '../../../components/ui/CircleArrow'
import { getApp } from '../../../store/slices/app.slice'
import prescriptionSlice from '../../../store/slices/prescription.slice'
import ExperienceType from '../../../types/experience.type'
import PrescriptionViewType from '../../../types/prescription-view.type'
import useBEM from '../../../utils/hooks/useBEM'
import useTranslation from '../../../utils/hooks/useTranslation'
import sunWave from '../../../assets/images/sun_wave.jpg'
import ReactHTMLParser from 'react-html-parser'
import iLensPrescIcon from '../../../interface/lens-prescription-icons.interface.ts'
import Icon from '../../../components/ui/Icon'

interface Props {
  lensType: string,
  content: {
    title: string,
    titleDescription:string,
    description: string,
    subtitle: string,
    benefits: Array<any>,
    footer: string,
    citation: string,
    default: any
    icons:iLensPrescIcon[]
  }
}

const PrescriptionLens : React.FC<Props> = ({
  lensType,
  content
}) => {

  const [B, E] = useBEM('prescription-lens')
  const appStore = useSelector(getApp);
  const { t } = useTranslation(appStore);
  const dispatch = useDispatch();
  const benefitDetails = content.default.benefits

  const goBack = () => dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.LENSPROPOSAL))

  return (
    <div className={B()}>
      <div className={E('header')}>
        <h2>
          {content.title}
          <span>
            {t(content.titleDescription, content.default.titleDescription)}
          </span>
        </h2>
        <Button
          click={goBack}
          text={t('demo_demo__back')}
          icon={
              <CircleArrow
                  type='left'
                  background='#eee'
              />
          }
          iconPosition="left"
          type="secondary"
          experience={ExperienceType.SUN}
        />
      </div>
    
    <div className={E('content')}>
      <p>{ReactHTMLParser(t(content.description, content.default.description))}</p>
      <label>{t(content.subtitle, content.default.subtitle)}</label>

      <div className={E('content-benefits')}>
        {
          benefitDetails.map((benefit: any, index: number) => {
            if (Array.isArray(benefit)) {
              return (
                <ul>
                  {benefit.map((detail: any, index: any) => {
                    if (Array.isArray(detail))
                    return (
                      <li>
                          <ul>
                            {detail.map((d) => (
                              <li>{d}</li>
                              ))}
                          </ul>
                        </li>
                      )
                    else return <li key={index}>{ReactHTMLParser(detail)}</li>
                  })}
                </ul>
              )
            } else {
              return (
                <li className='benefit' key={index}>
                  {ReactHTMLParser(benefit)}
                </li>
              )
            }
          })
        }
      </div>
      </div>

      <div className={E('content-footer')}>
        <img className='logo' src={sunWave} />
        <p>
          {ReactHTMLParser(t(content.footer, content.default.footer))}
        </p>
      </div>

      {
        content.citation && <span className={E('content-citation')}>{t(content.citation, content.default.citation)}</span>
      }
      {
        content.icons && 
        <div className={E('icons')}>
          {content.icons.map(({icon, label})=>(
            <div className={E('icons-card')}>
              <div className={E('icons-card-icon')}>
              <Icon name={icon} className={E('icons-card-img')}/>
              </div>
              <div className={E('icons-card-label')}>
                {t(label[0],label[1])}
              </div>
            </div>
          )
          )}
        </div>
      }

    </div>
  )
}

export default PrescriptionLens
