import EducationEyeDarkMax from '../assets/images/education/Education_Eye_Dark-Max.svg';
import EducationEyeDarkMid from '../assets/images/education/Education_Eye_Dark-Mid.svg'
import EducationEyeDarkMin from '../assets/images/education/Education_Eye_Dark-Min.svg'
import EducationEyeLightMax from '../assets/images/education/Education_Eye_Light-Max.svg'
import EducationEyeLightMid from '../assets/images/education/Education_Eye_Light-Mid.svg'
import EducationEyeLightMin from '../assets/images/education/Education_Eye_Light-Min.svg'

const landingCards = [
    { image: 'education-a', label: 'education_edu_eye_new', icon: 'eye', id: 'eye', soon: false},
    { image: 'education-b', label: 'education_edu_lens_new', soon: true },
    { image: 'education-c', label: 'Frame advices', soon: true }
];

const controlOptions = {
    visualDefect1: [
        { label: 'Myopia', value: 'myopia' },
        { label: 'Hyperopia', value: 'hyperopia' },
        { label: 'Astigmatism', value: 'astigmatism' },
        { label: 'Presbyopia', value: 'presbyopia' },
    ],
    visualDefect2: {
        myopia: [],
        hyperopia: [],
        presbyopia: [
            { label: '', value: '' },
            { label: 'Myopia', value: 'myopia' },
            { label: 'Hyperopia', value: 'hyperopia' },
        ],
        astigmatism: [
            { label: '', value: '' },
            { label: 'Myopia', value: 'myopia' },
            { label: 'Hyperopia', value: 'hyperopia' },
        ],
        none:[]
    },
    item: [
        { label: 'Standard', value: 'standard' },
        { label: 'Car', value: 'car' },
        { label: 'Flower', value: 'flower' },
    ],
    environment: [
        { label:'education_edu_sunlight', value: 'sunlight' },
        {label:'education_edu_night', value: 'night'}
    ],
    screenMode: [
        { label:'Simplify', value: 'simplify' },
        {label:'Environment', value: 'environment'}
    ]
}

const eyeballVariants: any = {
    lightMax: EducationEyeLightMax,
    lightMid: EducationEyeLightMid,
    lightMin: EducationEyeLightMin,
    darkMax: EducationEyeDarkMax,
    darkMid: EducationEyeDarkMid,
    darkMin: EducationEyeDarkMin
}

const crystallineDimensions: any = {
    myopia: {
        crystallineBallHeight: 36,
        crystallineBallWidth: 20,
        crystallineWidth: 330
    },
    hyperopia: {
        crystallineBallHeight: 36,
        crystallineBallWidth: 20,
        crystallineWidth: 220
    },
    astigmatism: {
        crystallineBallHeight: 36,
        crystallineBallWidth: 20,
        crystallineWidth: 260

    },
    presbyopia: {
        crystallineBallHeight: 35,
        crystallineBallWidth: 28,
        crystallineWidth: 260
    }
}

export {
    controlOptions,
    eyeballVariants,
    crystallineDimensions
};

export default landingCards;
