import { EventType } from "@testing-library/react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/ui/Button";
import Icon from "../../../../components/ui/Icon";
import iProductBrand from "../../../../interface/product-brand.interface";
import iProductFamily from "../../../../interface/product-family.interface";
import iProduct from "../../../../interface/product.inteface";
import iSubProduct from "../../../../interface/sub-product.inteface";
import { getApp } from "../../../../store/slices/app.slice";
import lensConsultationSun from "../../../../store/slices/lens-consultation-sun-exp.slice";
import { getLensConsultation } from "../../../../store/slices/lens-consultation.slice";
import useBEM from "../../../../utils/hooks/useBEM";
import useTranslation from "../../../../utils/hooks/useTranslation";


type eventType = 'select';
interface Props {
    family: iProductFamily,
    value?: string | boolean,
    extraComponent?: React.ReactNode,
    noLabel?:boolean,
    allowMultiple?:boolean,
    disabled?: boolean,
    onEvent?: (event: EventType, family: iProductFamily) => void,
}

const LensFamilySun:React.FC<Props>  = ({    family,
    value,
    extraComponent,
    noLabel,
    allowMultiple,
    disabled = false,
    onEvent}) => {
    const [B, E] = useBEM('lens-family-sun');
    const dispatch = useDispatch()
    const { t } = useTranslation(useSelector(getApp));

    const onClickHandler = (event: eventType, force = false) => {
        if(!onEvent)return;
        if(event === 'select' && value && !force)return;
        onEvent(event, family);
        dispatch(lensConsultationSun.actions.setFamily(family.id))
    }

    const classModifiers = () => {
        const modifiers: string[] = [];
        if(disabled)modifiers.push('disabled');
        return modifiers;
    }


  return (
    <div className={B()}>
        <div className={E('product', classModifiers())}>
            {value && <div className={E('product-family')}>
                {t(family.label)}
            </div>}
            <div className={E('product-label')}>
                {value ? 
                <>
                    <span>{t(value as string)}</span> 
                    <div className={E('product-edit')} onClick={() => onClickHandler('select', true)}>
                        Edit
                    <Icon name='pencil-sun'/>
                    </div>
                </> 
                    :
                <>
                    <span className={E('product-family')}>{t(family.label)}</span>
                    <button className={E('product-family-add')} onClick={() => onClickHandler('select')}>
                        <Icon name='plus-orange'/>
                    </button>
                </>
                }


                {
                    extraComponent && 
                    extraComponent
                }
            </div>
        </div>
    </div>
  )
}

export default LensFamilySun