import React, { useEffect, useRef, useState } from 'react';
import DiscoveryView from '../../types/discovery-view.type';
import useBEM from '../../utils/hooks/useBEM';
import DiscoveryIntro from './components/DiscoveryIntro';
import DiscoveryLens from './components/DiscoveryLens';
import DiscoverySteps from './components/DiscoverySteps';
import DiscoveryLensInfo from './components/DiscoveryLensInfo';
import { useDispatch, useSelector } from 'react-redux';
import discoverySlice, { getDiscovery } from '../../store/slices/discovery.slice';
import Button from '../../components/ui/Button';
import CircleArrow from '../../components/ui/CircleArrow';
import useGoto from '../../utils/hooks/useGoto';
import routes from '../../constants/routes.constant';
import { getApp } from '../../store/slices/app.slice';
import DiscoverySplashScreen from './components/DiscoverySplashScreen';
import useTranslation from '../../utils/hooks/useTranslation';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import poster from './../../assets/images/discovery/montage_background_00000-min.png';

interface Props {

}

const Discovery : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('discovery');
    const [darkNavBar, setDarkNavBar] = useState(false);
    const montageRef = useRef<any>();
    const { view, selectedLens } = useSelector(getDiscovery);
    const dispatch = useDispatch();
    const timeoutDelays = useRef<any>([]);
    const [fadeInEndExperience, setFadeInEndExperience] = useState(false);
    const [isFinalFrame, setIsFinalFrame] = useState(false);
    const [fadeInFinalFrame, setFadeInFinalFrame] = useState(false);
    const [fadeInTextture, setFadeInTextture] = useState(false);
    const { t } = useTranslation(useSelector(getApp));
    const ga = useGoogleAnalytics();
    const { get } = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const goto = useGoto();
    const [montage, setMontage] = useState<string>();

    // HOOKS
    useEffect(() => {
        const downloadVideo = async () => {
            const vid = await get('localAssetsStore', 'discoveryMontageAnimation');
            if(vid)setMontage(vid.asset);
        }

        downloadVideo();
        // Note: on page reload
        dispatch(discoverySlice.actions.reset())
        return () => {
            dispatch(discoverySlice.actions.reset());
            timeoutDelays.current.forEach( (d:any) => clearTimeout(d) );
            timeoutDelays.current = [];
        }
    }, [])

    // useEffect(() => {
    //     downloadImages();
    //     for (let index = 0; index <= 534; index++) {
    //         const str = String(index);
    //         const paddingLength = 5 - str.length;
    //         const padding = new Array(paddingLength).fill('0').join('');
    //         const filename = `montage_background_${padding}${index}.png`;
    //         const img = await loadImage(filename);
    //         console.log(img);
    //     }
    // }, [])

    // METHODS
    // const downloadImages = async () => {
    //     const images = [];
    //     for (let index = 0; index <= 534; index++) {
    //         const str = String(index);
    //         const paddingLength = 5 - str.length;
    //         const padding = new Array(paddingLength).fill('0').join('');
    //         const filename = `montage_background_${padding}${index}.png`;
    //         const img = await loadImage(filename);
    //         images.push(img);
    //     }
    //     setSprites(images);
    // }

    const addDelay = (callback: any, delay: number) => {
        const timeout = setTimeout(() => {
            callback();
        }, delay);
        timeoutDelays.current.push(timeout);
    }


    const nextView = (newView: DiscoveryView) => {

        if(view === DiscoveryView.INTRO) {
            montageRef.current.play();

            addDelay(() => {
                dispatch(discoverySlice.actions.changeView(DiscoveryView.STEPS))
            }, 4000);
            addDelay(() => {
                setFadeInTextture(true);
            }, 4800);
            addDelay(() => {
                montageRef.current.pause();
            }, 6500);
        }

        if(view === DiscoveryView.STEPS) {
            montageRef.current.play();
            setFadeInTextture(false);

            addDelay(() => {
                setDarkNavBar(true);
            }, 3000);

            addDelay(() => {
                montageRef.current.pause();
                dispatch(discoverySlice.actions.changeView(DiscoveryView.LENS));
            }, 9000);
        }
    }

    // const loadImage = (imgName: string): Promise<string> => {
    //     const promise = new Promise<string>((resolved, reject) => {
    //         import(`../../assets/images/discovery/montage_background_seq/${imgName}`).then(img => {
    //             resolved(img.default);
    //         });
    //     })
    //     return promise;
    // };

    const onInfoClose = () => {
        dispatch(discoverySlice.actions.selectLens(undefined));
        setFadeInEndExperience(true);
    }

    const onEndExperience = () => {
        dispatch(discoverySlice.actions.changeView(DiscoveryView.EXIT))
        montageRef.current.play();

        setTimeout(() => {
            setDarkNavBar(false);
        }, 2000);
        setFadeInEndExperience(false);

        videoEnend();

        ga.event({
            category: 'Home page',
            action: 'Discover Essilor',
            label: 'End Experience'
        })
    }

    const videoEnend = () => {
        setTimeout(() => {
            setIsFinalFrame(true);
            setTimeout(() => {
                setFadeInFinalFrame(true)
            });
            goto.route(routes.home, 3000);
        }, 5150);

    }

    return (
        <div className={B() + ' page'}>
            <div className={E('navbar', darkNavBar ? '' : 'hide')}></div>

            {
                !montage &&
                <DiscoverySplashScreen progress="loading."/>
            }
            {
                montage &&
                <video
                    poster={poster}
                    ref={montageRef}
                    src={montage}
                    className={E('montage')}
                    playsInline
                    autoPlay={false} loop={false}  muted>
                    Your browser does not support the video tag.
                </video>
            }


            { view === DiscoveryView.INTRO && <DiscoveryIntro onNextView={nextView}/>}
            { view === DiscoveryView.STEPS && <DiscoverySteps onNextView={nextView}/>}
            { view === DiscoveryView.LENS && <DiscoveryLens/>}

            <DiscoveryLensInfo onClose={onInfoClose}/>

            <Button
                click={onEndExperience}
                className={E('end-experience', (fadeInEndExperience && (!selectedLens || selectedLens.length < 1)) ? 'fadein' : '')}
                text={t('discovery_essilor_de_end_the_experience')}
                icon={<CircleArrow type="right"/>}
            />

            { isFinalFrame && <div className={E('final-frame', fadeInFinalFrame ? 'fadein' : '')}></div> }

            <div className={E('textture', fadeInTextture?'fadein':'')}></div>
        </div>
    )
}

export default Discovery;
