import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThemeSetting from '../../../components/ThemeSetting';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import needExplorerSlice from '../../../store/slices/need-explorer.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import ENDPOINTS from '../../../constants/endpoints';
import useIndexedDB from '../../../utils/hooks/useIndexedDB';
import useGoto from '../../../utils/hooks/useGoto';
import routes from '../../../constants/routes.constant';
import Modal from '../../../components/Modal';
import measureSlice from '../../../store/slices/measure.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import DeepReplaceObjectValue from '../../../utils/deep-replace-object-value';
import Button from '../../../components/ui/Button';
import Alert from '../../../components/ui/Alert';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import ExperienceType from '../../../types/experience.type';

interface Props {
    isExperience: boolean,
    onActionTrigger: (action: | 'experience' | 'id') => void
}

const LensProposalBuilderFooter : React.FC<Props>  = ({
    isExperience,
    onActionTrigger
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-proposal-builder-footer');
    const { id, ageCategory } = useSelector(getUser);
    const appStore = useSelector(getApp);
    const { globalInformation, token, experience } = appStore;
    
    const userData = useSelector(getUser)
    const { t } = useTranslation(appStore);
    const [db, setDB] = useState<IDBDatabase | undefined>(undefined)
    const dbRef = useRef<IDBDatabase | undefined>(db)
    const { connect, createTables, remove } = useIndexedDB()
    const dispatch = useDispatch()
    const goto = useGoto()
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const { lensRecommendation } = useSelector(getLensConsultation);
    const { measurementData } = useSelector(getMeasure);
    const [offlineAlert, showOfflineAlert] = useState(false);
    const { correctPrescriptionData } = useLensConsultation();


    // HOOKS
    useEffect(() => {
        async function connectIndexDB() {
            const indexDB = await connect((db: any) => createTables(db))
            setDB(indexDB)
        }
        connectIndexDB()
    }, [])

    useEffect(() => {
        dbRef.current = db
    }, [db])

    // METHODS
    const removeUser = (userId: string) => {
        if(dbRef.current)
            remove(dbRef.current, 'userStore', userId)
                .then((result) => console.log("Remove Result", result))
    }

    const appendLensRecommendation = (userData: any, lensRecommendation: any) => {
        return {
            ...userData,
            lensRecommendation,
            lensConsultation: {
                ...userData.lensConsultation,
                lensRecommendation
            }
        }
    }
    

    const handlePushButton = () => {
        const headers = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': "application/json"
            }
        }

        const deepReplace = DeepReplaceObjectValue({ from: undefined, to: "---" })
        let newUserData = deepReplace.replace({
            ...userData,
            // Note: save lens recommendation when order is not validate
            ...
            ( 
                !userData.lensRecommendation ? 
                appendLensRecommendation(userData,lensRecommendation) : 
                {}            
            ),
            // Note: save measures data 
            measures: measurementData
        });

        //Note: prescription data structure correction
        // incase rx data is invalid
        newUserData = correctPrescriptionData(newUserData, '---');

        const data = {
            created_by: globalInformation.license,
            nickname: globalInformation.nickname || globalInformation.storeName,
            order_id: userData.id,
            order: newUserData
        }

        if(!navigator.onLine) {
            showOfflineAlert(true);
            return;
        }

        axios.post(
            ENDPOINTS.orders,
            data,
            headers
        ).then(result => {
            if (result.data.code === '001') {
                removeUser(String(userData.id))
                dispatch(userSlice.actions.clearUserState())
                dispatch(needExplorerSlice.actions.resetData())
                dispatch(measureSlice.actions.resetData())
                dispatch(lensConsultationSlice.actions.resetData())
                setShowSuccessModal(true)
                setTimeout(() => {
                    goto.route(routes.home)
                }, 800)
            }
        })
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    return (
        <div className={B(experience)}>
            <div className={E('id')}>
                <label>{id}</label>
                <Icon name={`pencil-circle-gray${selectIcon()}`} click={() => onActionTrigger('id')}/>
                
                <Button 
                    className={E('push-data')}
                    text={t('data_sharing_push_button')} 
                    click={() => handlePushButton()}
                />
            </div>


            { ageCategory?.value !== "kids" &&
                <div className={E('experiences')}>
                    <label>{!isExperience ? t('lens_proposal_builder_lpb__experiences') : t('lens_proposal_builder_lpb__close')}</label>
                <Icon name={!isExperience ? `experiences${selectIcon()}` : `close-gray${selectIcon()}`} click={() => onActionTrigger('experience')}/>
                </div>
            }

            { ageCategory?.value === "kids" && <ThemeSetting/> }

            <Modal
                onClose={() => setShowSuccessModal(false)}
                height="23rem" width="35rem"
                show={showSuccessModal}
                hideCloseButton={true}
            >
                <div className={E('saved')}>
                    <Icon name="saved"></Icon>
                    <h2>{t('data_sharing_successfully_shared')}</h2>
                </div>

            </Modal>

            <Alert 
                show={offlineAlert}
                message={t('data_sharing_push_id_offline')}
                onButtonSelect={() => showOfflineAlert(false)}
                buttons={[{
                    id: 'ok',
                    label: t('data_sharing_patient_id_has_been_alreayd_selected_ok')
                }]}
            />
        </div>
    )
}

export default LensProposalBuilderFooter;
