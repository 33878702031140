import iNeedExplorerSummaryDetails from "../interface/need-explorer-summary-details";
import iNeedExplorerSummaryProducts from "../interface/need-explorer-summary-products.interface"

const iNeedExplorerSummary: iNeedExplorerSummaryDetails[] = [
    {
        label: "questionnaires_q_screen_usage_summ_age",
        icon: "age",
        type: "1",
        answer: undefined,
        question: "1.2"
    },
    {
        label: "questionnaires_q_screen_usage_summ_gend",
        icon: "gender",
        type: "1",
        answer: undefined,
        question: "1.1"
    },
    {
        label: "questionnaires_q_screen_usage_summ_vtype",
        icon: "vision",
        type: "2",
        answer: undefined,
        question: "1.3"
    },
    {
        label: "questionnaires_q_screen_usage_spend_most",
        icon: "mostSpend",
        type: "2",
        answer: undefined,
        question: "1.6"
    },
    {
        label: "questionnaires_q_screen_usage_spend_main_points",
        icon: "main pain",
        type: "2",
        answer: undefined,
        question: "2.4"
    }
]

    const iNeedExplorerKidsSummary: iNeedExplorerSummaryDetails[] = [
        {
            label: "questionnaires_q_screen_usage_summ_age",
            icon: "Age",
            type: "1",
            answer: undefined,
            question: '6.1'
        },
        {
            label: "questionnaires_q_screen_usage_summ_vision_rating",
            icon: "vision",
            type: "1",
            answer: undefined,
            question: '6.4'
            
        },
        {
            label: "questionnaires_q_screen_usage_spend_main_points",
            icon: "main pain",
            type: "2",
            question: '6.5'
        }
    ]




const iNeedExplorerProductsSummary: iNeedExplorerSummaryProducts[]  = [
    {
        text: 'questionnaires_q__sum_rec_cor_p1',
        lensType: "pair_1_tab_del_2_p1_correct",
        icon: "correct",
        pair: "1",
    },
    {
        text: 'questionnaires_q__sum_rec_cor_p2',
        lensType: "pair_1_tab_del_2_p1_correct",
        icon: "correct",
        pair: "2",
    },
    {
        text: 'questionnaires_q__sum_rec_pro_p1',
        lensType: "pair_1_tab_del_2_p1_protect",
        icon: "protect",
        pair: "1",
    },
    {
        text: 'questionnaires_q__sum_rec_pro_p1_2',
        lensType: "pair_1_tab_del_2_p1_protect",
        icon: "protect",
        pair: "2",
    },
    {
        text: 'questionnaires_q_summ_recomm',
        lensType: "pair_1_tab_del_3_p1_enhance",
        icon: "enhance",
        pair: "1",
    },
    {
        text: 'need_explorer_recom_coating_to_enhance_for_p2',
        lensType: "pair_1_tab_del_3_p1_enhance",
        icon: "enhance",
        pair: "2",
    },
]

export {
    iNeedExplorerSummary,
    iNeedExplorerProductsSummary,
    iNeedExplorerKidsSummary
};