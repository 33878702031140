import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import lensConsultationSlice from './slices/lens-consultation.slice';
import measureSlice from './slices/measure.slice';
import myCompanionSlice from './slices/mycompanion.slice';
import needExplorerSlice from './slices/need-explorer.slice';
import prescriptionSlice from './slices/prescription.slice';
import sidebarSlice from './slices/sidebar.slice';
import lensThicknessSlice from './slices/lens-thickness.slice';
import userSlice from './slices/user.slice';
import educationSlice from './slices/education.slice';
import deliverySlice from './slices/delivery.slice';
import productsSlice from './slices/products.slice';
import productDemoSlice from './slices/products-demo.slice';
import iRootState from './interface/root-state.interface';
import discoverySlice from './slices/discovery.slice';
import licenseSlice from './slices/license.slice';
import packageOfferSlice from './slices/package-offer.slice';
import appSlice from './slices/app.slice';
import summarySlice from './slices/summary.slice';
import tutorialSlice from './slices/tutorial.slice';
import pushNotificationSlice from './slices/push-notification.slice';
import lensConsultationSun from './slices/lens-consultation-sun-exp.slice';
import needExplorerSunSlice from './slices/need-explorer-sun.slice';

const loadFromLocalStorage = (): iRootState | undefined => {
    try {
        const serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState) as iRootState;
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}



const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        user: userSlice.reducer,
        sidebar: sidebarSlice.reducer,
        myCompanion: myCompanionSlice.reducer,
        needExplorer: needExplorerSlice.reducer,
        needExplorerSun: needExplorerSunSlice.reducer,
        prescription: prescriptionSlice.reducer,
        measure: measureSlice.reducer,
        lensConsultation: lensConsultationSlice.reducer,
        lensThickness: lensThicknessSlice.reducer,
        education: educationSlice.reducer,
        delivery: deliverySlice.reducer,
        products: productsSlice.reducer,
        productDemo: productDemoSlice.reducer,
        discovery: discoverySlice.reducer,
        license: licenseSlice.reducer,
        packageOffer: packageOfferSlice.reducer,
        summary: summarySlice.reducer,
        tutorial: tutorialSlice.reducer,
        pushNotification: pushNotificationSlice.reducer,
        lensConsultationSun: lensConsultationSun.reducer
    },
    preloadedState: loadFromLocalStorage(),
    middleware: [...getDefaultMiddleware({immutableCheck: false})]
});

export default store;