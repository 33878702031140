import React from 'react';
import useBEM from '../../../../utils/hooks/useBEM';
import { iMultipleChoice } from './sun-experience.types';

interface Props {
    choice: string | undefined,
    choices: iMultipleChoice[],
    onAnswer: (answer: iMultipleChoice) => void
}

const MultipleChoice : React.FC<Props>  = ({
    choice,
    choices,
    onAnswer
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('multiple-choice');

    // HOOKS

    // METHODS
    const modifier = () => {
        const isWithDesc = choices.filter( x => x.description).length > 0;
        return [
            isWithDesc ? 'with-desc' : ''
        ]
    }

    return (
        <ul className={B( modifier() )}>
            {choices.map( x => (
                <li className={E('choice', choice === x.id ? 'selected' : '')} onClick={() => onAnswer(x)}>
                    {x.label}

                    { 
                        x.description && 
                        <span className={E('description')}>{x.description}</span> 
                    }
                </li>
            ))}
        </ul>
    )
}

export default MultipleChoice;