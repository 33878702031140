import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isummaryDetails from '../../../interface/summary.interface';
import { getApp } from '../../../store/slices/app.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import { getUser } from '../../../store/slices/user.slice';
import ExperienceType from '../../../types/experience.type';
import LensCategoryType from '../../../types/lens-category.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useAdvancePricing from '../../advance-price-setting/useAdvancePricing';
import DeliveryPairConsultation from './DeliveryPairConsultation';
import DeliveryPairDetails from './DeliveryPairDetails';
import DeliveryPairMeasures from './DeliveryPairMeasure';

interface Props {
    // details: {
    //     pair:number,
    //     type: string,
    //     price: string,
    //     description: string    
    // }
    details: isummaryDetails[] | any,
    pair:number
}

const DeliveryPair : React.FC<Props>  = ({
    details,
    pair
}) =>  {
    // VARIABLES
    const [B] = useBEM('delivery-pair');
    const summaryDetails = details[0];
    const { measurementData } = useSelector(getMeasure);
    const { lensConsultation, lensRecommendation, measures } = useSelector(getUser)
    const { breakdown } = lensConsultation;
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const [ advancePrice, setAdvancePrice ] = useState<any>();
    const { 
        isAdvancePricing,
        getAdvancePricingTotal
    } = useAdvancePricing(appStore);
    const { experience } = appStore;


    useEffect(() => {
        if(!lensConsultation.lensRecommendation || !isAdvancePricing)return;
        setAdvancePrice(getAdvancePricingTotal(lensConsultation.lensRecommendation));
    }, [])

    // HOOKS

    // METHODS

    const getAdvancePairPrice = (pair: number) => {
        let price = advancePrice[`pair${pair}`].total;

        if(measures.one) {
            const measuresPair = measures.one[ pair - 1 ];
            price = price + ( measuresPair ? measuresPair.price.value : 0 );
        }
        return price;
    }

    const returnPrice = (pair:number) => {
        if(advancePrice){
            return `${getAdvancePairPrice((pair))} ${breakdown.currency}`;
        }

        return pair === 1 ? `${breakdown.pair1.deliveryPrice} ${breakdown.currency}` : `${breakdown.pair2.deliveryPrice} ${breakdown.currency}`
    }
    const existingMeasurement = () =>{
        if(pair === 1 && measurementData.one.length >= 1) return true;
        if(pair === 2 && measurementData.one.length === 2) return true;   
        return false;
    }
    const get = () => {
        
        let lensDesign = pair === 1 ? lensRecommendation?.pair1?.correct.product?.lensDesign : 
        lensRecommendation?.pair2?.correct.product?.lensDesign;
        switch(lensDesign){
            case LensCategoryType.PROGRESSIVE: return t('pair_1_tab_del_2_p1progressive_vision')
            case LensCategoryType.SINGLE_VISION: return t('pair_1_tab_del_p1__single_vision')
            case LensCategoryType.PROGRESSIVE_SV_LENS: return t('delivery_design_lens3')
            default: return t('delivery_design_lens3')
        }
    }
    const getDesc = () => {
        if(lensRecommendation?.pair1?.correct){
            const lensDesign = pair === 1 ? lensRecommendation?.pair1?.correct.product?.lensDesign : 
            lensRecommendation?.pair2?.correct.product?.lensDesign;
            switch(lensDesign){
                case LensCategoryType.PROGRESSIVE: return t('pair_1_tab_del_p1_progressive_vision_text')
                case LensCategoryType.SINGLE_VISION: return t('pair_1_tab_del_p1_single_vision')
                case LensCategoryType.PROGRESSIVE_SV_LENS: return t('pair_1_tab_del_p1_single_vision')
                default: return t('pair_1_tab_del_p1_single_vision')
            }
        }
        return ''
        
    }
    return (
        <div className={B()}>
                        
            <DeliveryPairDetails 
                pair={pair}
                type={get()}
                description={getDesc()}
                price={returnPrice(pair)}
                benefits = {summaryDetails.products[0].descriptions}
            />     
            <DeliveryPairConsultation data={details} pair={pair}/>    
            { existingMeasurement() && <DeliveryPairMeasures pair={pair}/> }    

        </div>
    )
}

export default DeliveryPair;