import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/ui/Icon';
import { getApp } from '../../store/slices/app.slice';
import lensConsultationSlice from '../../store/slices/lens-consultation.slice';
import userSlice from '../../store/slices/user.slice';
import useBEM from '../../utils/hooks/useBEM';
import usePricing from '../../utils/hooks/usePricing';
import DeliverySummary from './components/DeliverySummary';
import DeliveryTotal from './components/DeliveryTotal';

interface Props {

}

const Delivery : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-page');
    const { getBreakdown } = usePricing()
    const dispatch = useDispatch();
    const appStore = useSelector(getApp);
    const { accountDetails } = appStore;

    // HOOKS
    useEffect(()=>{

        const breakdown = getBreakdown()
        dispatch(lensConsultationSlice.actions.setBreakdown(breakdown));

    },[])

    // METHODS

    return (
        <div className={B()}>

            <DeliveryTotal/>
            <DeliverySummary/>
            <div className={E('cobrand')}>
                <img alt="Co-Brand" className={E('logo')} src={accountDetails?.shop.shop_logo} />
                <div className={E('divider')}></div>
                <Icon name="essilor-logo" type="image"/>
            </div>
        </div>
    )
}

export default Delivery;
