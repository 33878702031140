import React, { useEffect, useState } from 'react';
import ageCategories from '../../../constants/home.contants';
import routes from '../../../constants/routes.constant';
import iAgeCategory from '../../../interface/age-category.interface';
import { useDispatch, useSelector } from 'react-redux';
import userSlice from '../../../store/slices/user.slice';
import useGoto from '../../../utils/hooks/useGoto';
import needExplorerSlice from '../../../store/slices/need-explorer.slice';
import { getProducts } from '../../../store/slices/products.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import deliverySlice from '../../../store/slices/delivery.slice';


interface Props {
    isVisible: boolean,
}


const AgeCategory : React.FC<Props>  = ({
    isVisible = false,
}) =>  {
    // VARIABLES
    const goto = useGoto();
    const dispatch = useDispatch();
    const choices = ageCategories;
    const [choiced, setChoiced ] = useState<iAgeCategory>();
    const [isShow, setShow] = useState(Boolean);
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    useEffect(() => {

        const delay = isVisible ? 700 : 0;
        setTimeout(() => {
            setShow(isVisible);
        },delay);

    }, [isVisible]);

    // METHODS
    const activeChoiceID = (choice: iAgeCategory) => {
        if(!choiced)return "";
        return choiced.value === choice.value ? 'age-category__choice--active' : '';
    }

    const onSelectCategory = (choice: iAgeCategory) => {
        setChoiced(choice);
        
        // TODO
        // CREATE A FUNCTION TO GENERATE
        // VALIDATE IF ALREADY EXIST
        
        setTimeout(() => {
            goto.routeWithTransition(
                routes.home, 
                routes.lensProposalBuilder,
                'SLIDE_LEFT',
                500
            );

            setTimeout(() => {
                const id = Math.floor(100000 + Math.random() * 900000);

                dispatch(deliverySlice.actions.reset({all: true}));
                dispatch(userSlice.actions.setAgeCategory({
                    id: String(id),
                    ageCategory: choice
                }));
                const today = new Date().toISOString();
                dispatch(userSlice.actions.sessionStart(today));
                dispatch(userSlice.actions.createdDate(today));

                //change initial state in need explorer if kids was chosen
                if(choice.value === 'kids'){
                    dispatch(needExplorerSlice.actions.changeInitialStateToKids())
                }
            }, 500)
        }, 250);
    }

    return (
        <div className={`age-category ${isShow ? 'age-category--show' : ''}`}>
            <div className="age-category__head">
                <h5> {t('home_hom_whats_ya_category_')}</h5>

            </div>
            <div className="age-category__selection">
                { choices.map((choice, index) => (
                    <div 
                        key={index}
                        className={`age-category__choice  ${activeChoiceID(choice)}`}
                        onClick={() => onSelectCategory(choice)}>
                        <h5>{t(choice.label)}</h5>
                    </div>
                )) }
            </div>
        </div>
    )
}

export default AgeCategory;