import iLensDemo from "../../interface/lens-demo";
import ProductDemoType from "../../types/product-demo.type";
import Utils from "../../utils/Utils";
const { lensDemoURL } = Utils();

const sellout = [
    'demo_new_demo__xper_tin_l3',
    'demo_new_demo__xper_tin_l4',
    'demo_new_demo__xper_tin_l5',
    'demo_new_demo__xper_tin_l6',
    'demo_new_demo__xper_tin_l7',
    'demo_new_demo__xper_tin_l8',
    'demo_demo__xper_tin_l1',
    'demo_new_demo__xper_tin_l1',
    'demo_demo__xper_tin_l3',
    'demo_new_demo__xper_tin_l2'
]

const packshotTinted: iLensDemo = {
    demo: 'packshot-tinted',
    demoType: ProductDemoType.SLIDER_IMAGE,
    products: [
        {
            id: 'xperio_tinted',
            label: "lens_propo_colors_black",
            asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio_teinted_black.jpg'),
            description: sellout,
            colorID: 'lensTintedBlack'
        },
        {
            id: 'standard',
            label: 'lens_propo_colors_blue_ink',
            asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio_teinted_blue ink.jpg'),
            description: sellout,
            colorID: 'lensTintedBlueInk'
        },
        {
            id: 'standard1',
            label: "lens_propo_colors_brown",
            asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio_teinted_brown.jpg'),
            description: sellout,
            colorID: 'lensTintedBrown'
        },
        {
            id: 'standard2',
            label: "lens_propo_colors_burgundy",
            asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio_teinted_burgundy.jpg'),
            description: sellout,
            colorID: 'lensTintedBurgundy'
        },
        {
            id: 'standard3',
            label: 'lens_propo_colors_gray_green',
            asset: lensDemoURL('Xperio/04_Xperio%20Tinted_1/Xperio_teinted_gray green.jpg'),
            description: sellout,
            colorID: 'lensTintedGreyGreen'
        },
        {
            id: 'standard4',
            label: "lens_propo_colors_grey",
            asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio_teinted_gray.jpg'),
            description: sellout,
            colorID: 'lensTintedGrey'
        },

        {
            id: 'standard5',
            label: "lens_propo_colors_herbal",
            asset: lensDemoURL('Xperio/04_Xperio%20Tinted_1/Xperio_teinted_herbal.jpg'),
            description: sellout,
            colorID: 'lensTintedHerbal'
        },
        {
            id: 'standard6',
            label: "lens_propo_colors_new_brown",
            asset: lensDemoURL('Xperio/04_Xperio%20Tinted_1/Xperio_teinted_new brown.jpg'),
            description: sellout,
            colorID: 'lensTintedNewBrown'
        }
    ]
}


const beachWalking = {
    demo: 'beach-tinted', //pictos
    demoType: ProductDemoType.SLIDER_VIDEO,
    products: [
      {
        id: 'xperio_tinted',
        label: 'Xperio Tinted',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 00.m4v'),
        description: sellout,
        colorID: "standard"
      },
      {
        id: 'xperio_tinted_black',
        label: 'Xperio Tinted Black',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 01 Black.m4v'),
        colorID: "lens_propo_colors_black"
      },
      {
        id: 'xperio_tinted_blue_ink',
        label: 'Xperio Tinted Blue Ink',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 02 Blue Ink.m4v'),
        colorID: "lens_propo_colors_blue ink"
      },
      {
        id: 'xperio_tinted_brown',
        label: 'Xperio Tinted Brown',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 03 Brown.m4v'),
        colorID: "lens_propo_colors_brown"
      },
      {
        id: 'xperio_tinted_grey_green',
        label: 'Xperio Tinted Grey Green',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 05 Grey Green.m4v'),
        colorID: "lens_propo_colors_grey_green"
      },
      {
        id: 'xperio_tinted_grey',
        label: 'Xperio Tinted Grey',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 06 Grey.m4v'),
        colorID: "lens_propo_colors_grey"
      },
      {
        id: 'xperio_tinted_herbal',
        label: ' Xperio Tinted Herbal',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 07 Herbal.m4v'),
        colorID: "lens_propo_colors_herbal"
        
      },
      {
        id: 'xperio_tinted_new_brown',
        label: 'Xperio Tinted New Brown',
        asset: lensDemoURL('Xperio/04_Xperio Tinted_1/Xperio Tinted/Xperio Tinted Avec Filtre 08 New Brown.m4v'),
        colorID: "lens_propo_colors_new_brown"
      }
    ]
}

export const experioTintedDemo: iLensDemo[] = [
    packshotTinted,
    beachWalking
];

