import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useBEM from '../../../utils/hooks/useBEM';
import Arrow from '../../ui/Arrow';
import Icon from '../../ui/Icon';
import SubProducts from './SubProducts';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import iProduct from '../../../interface/product.inteface';

import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import DemoDownloadType from '../../../types/product-download.type';
import Loading from '../../ui/Loading';

interface Props {
    id: string,
    label: string,
    background?: string,
    logo?: string,
    products: iProduct[],
    download?: {
        count: number,
        total?: number,
        status?: DemoDownloadType
    },
    use?: 'homepage'|'default'
}

const ProductCard : React.FC<Props>  = ({
    id,
    label,
    background,
    logo,
    products,
    download,
    use = 'default'
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('product-card');
    const dispatch = useDispatch();
    const  { brandToDemo, openBrand } = useSelector(getProducts);
    const ga = useGoogleAnalytics();
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    

    // METHODS
    const selectBrand = () => {
        const product = (brandToDemo && brandToDemo.id === id) ? undefined :
        {
            id,
            label,
            products: products
        }

        if(product){
            ga.event({
                category: `Product - ${product.label}`, 
                action: 'Button Click - Product',
                label: 'See More'
            });
        }

        dispatch(productsSlice.actions.openBrandCard(id))
        dispatch(productsSlice.actions.selectBrandTodemo(product))
    }

    const isOpen = () => {
        return openBrand && openBrand === id;
    }

    //Note: temp
    const getLogoType = (id: string) => {
        return [
            'blueuv_filter_system',
            'optifog',
            'crizal',
            'xperio',
            'stellest',
            'transitions'
        ].includes(id) ? 'image' : 'svg'
    }

    const DownloadStatus = () => {
        let html = null;
        
        if (download?.total == 0)
            html = <></>
        else 
            html = <>{download?.count}/{download?.total}</>
        
        return <React.Fragment>
            { html } 
        </React.Fragment>
    }


    return (
        <li className={B( isOpen() ? 'open' : '')}> 
            { !isOpen() ? 
            <p className={E('status')}> 
                { download?.status === DemoDownloadType.DOWNLOADING && <Loading size=".27rem" dim="1.25rem"/>}
                <DownloadStatus/>    
            </p> : <></>
            }
            {
                background &&
                <img src={background} alt="" className={E('background')}/>
            }

            {
                logo && 
                <Icon 
                    className={E('logo')}
                    name={logo} 
                    type={getLogoType(id)}
                />                
            }
            
            
            <div className={E('see-more', [ isOpen()? 'open' : '', download?.status === DemoDownloadType.DOWNLOADING ? 'disabled' : ''])} >
                <div className={E('see-more-head')} onClick={selectBrand}>    
                    <label>
                        {t('home_hom_smore')} <Icon name="eye" type="image"/>
                    </label>
                    <label>{t(label)} <Arrow type="down" color="#fff"/></label>
                </div>

                {/* NOTE: This is brand products not sub products */}
                {/* TODO: Rename to Products */}
                <SubProducts 
                    products={products}       
                    use={use}         
                />    
            </div>                          
        </li>
    )
}

export default ProductCard;