import Utils from '../utils/Utils';
import iSunExperienceSolution from './../interface/sun-experience-solution.interface';

const { lensDemoURL } = Utils();

const SUN_EXPERIENCE_SOLUTION: iSunExperienceSolution[] = [
    {
        id: 'xperio_polarised',
        label: 'Polarized',
        sun_experience: {
            id:'polarized',
            label:'polarized',
            // translationCode: 'sun_exp_sun_solution_tint_pol',
            translationCode: ['sun_exp_sun_solution_tint_pol', 'Polarized'],
            disclaimer:['*All substrates above 380 nm. UV cut level may vary slightly depending on the lens and mirror colors.'],
            advantages: [
            {
                id:'eyeProtection',
                label:'Eye Protection',
                value: 90,
            },
            {
                id:'visualComfort',
                label:'Visual Comfort',
                value: 90,
            },
            {
                id:'enhancedContrast',
                label:'Enhanced Contrast',
                value: 90,
            },
            {
                id:'uvProtection',
                label:'UV* Protection',
                value: 90,
            },
            ],
            color_group:[
            {
              group: "C4",
              colors: [
                {   code: "Pc4clr-greyGreen", 
                    color_code: "Grey green", 
                    color_shade: "Polarized", 
                    palette1: "#dedede", 
                    palette2: "#123123",
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey green.jpg'), 
                    color_tid: "369"
                },
                {   code: "Pc4clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "368"
                },
                {   code: "Pc4clr-bluePurple", 
                    color_code: "Blue Purple", 
                    color_shade: "Polarized", 
                    palette1: "#6437b8",
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_blue purple.jpg'), 
                    color_tid: "361"
                },
                {   code: "Pc4clr-blueMirror", 
                    color_code: "Blue Mirror", 
                    color_shade: "Polarized", 
                    palette1: "#395158",
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_blue ink.jpg'), 
                    color_tid: "363"
                },
              ]
            },
            {   group: "C3", 
                colors: [
                {   code: "Pc3clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "364" 
                }
            ]},
            {
              group: "C3/2",
              colors: [
                {   code: "Pc32clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "362" 
                },
                {   code: "Pc32clr-brown", 
                    color_code: "Brown", 
                    color_shade: "Polarized", 
                    palette1: "#964B00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'),
                    color_tid: "366" 
                }
              ]
            },
            { 
                group: "C2 & C3", 
                colors: [
                    { 
                    code: "Pc2&c3clr-Kaki", 
                    color_code: "Kaki", 
                    color_shade: "Polarized", 
                    palette1: "#373622", 
                    palette2: null, 
                    image_path: null, 
                    color_tid: "371" 
                    }
                ]
            }
          ]}
    },
    {
        id: 'xperio_tinted',
        label: 'Tinted',
        sun_experience: {
            id:'tinted',
            label:'tinted',
            // translationCode: 'sun_exp_sun_solution_tint',
            translationCode: ['sun_exp_sun_solution_tint','Tinted'],
            disclaimer:['*All substrates above 380 nm. UV cut level may vary slightly depending on the lens and mirror colors.'],
            advantages: [
            {
                id:'eyeProtection',
                label:'Eye Protection',
                value: 90,
            },
            {
                id:'visualComfort',
                label:'Visual Comfort',
                value: 65,
            },
            {
                id:'enhancedContrast',
                label:'Enhanced Contrast',
                value: 65,
            },
        ],
        color_group:[
            {
            group: "C4",
            colors: [
                {   code: "Pc4clr-greyGreen", 
                    color_code: "Grey green", 
                    color_shade: "Tinted", 
                    palette1: "#dedede", 
                    palette2: "#123123",
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey green.jpg'), 
                    color_tid: "569"
                },
                {   code: "Pc4clr-Amber", 
                color_code: "Amber",
                color_shade: "Polarized",
                palette1: "#FFBF00",
                palette2: "#123123",
                image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                color_tid: "568"
            },
            {   code: "Pc4clr-Herbal",
            color_code: "Herbal",
            color_shade: "Tinted",
            palette1: "#8a8849", 
            palette2: null,
            image_path: lensDemoURL('Xperio/04_Xperio%20Tinted_1/Xperio_teinted_herbal.jpg'),
            color_tid: "367"
        },
    ]
            },
            {   group: "C3", 
            colors: [
                {   code: "Pc3clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Tinted", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "364" 
                }
            ]},
            {
              group: "C3/2",
              colors: [
                {   code: "Pc32clr-Amber",
                  color_code: "Amber",
                  color_shade: "Tinted", 
                  palette1: "#FFBF00",
                  palette2: null,
                  image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                  color_tid: "362"
                },
                {   code: "Pc32clr-brown", 
                color_code: "Brown",
                color_shade: "Tinted", 
                palette1: "#964B00",
                palette2: "#123123",
                image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'),
                color_tid: "366"
            }
        ]
            },
            { 
                group: "C2 & C3", 
                colors: [
                    { 
                    code: "Pc2&c3clr-Kaki",
                    color_code: "Kaki",
                    color_shade: "Tinted", 
                    palette1: "#373622", 
                    palette2: null, 
                    image_path: null, 
                    color_tid: "371" 
                }
            ]
            }
        ]}
    },
    {
        id: 'xperio_polarised_mirrors',
        label: 'Mirror',
        sun_experience: {
            id:'mirror',
            label:'Mirror',
            // translationCode: 'sun_exp_sun_solution_mirr',
            translationCode: ['sun_exp_sun_solution_polar_mirr','Mirrors Polarized'],
            disclaimer:[
                '*All substrates above 380 nm. UV cut level may vary slightly depending on the lens and mirror colors.', 
                '**The performance of polarised mirror lenses may slightly vary based on the base lens color and mirror color combination.'],
            advantages: [
            {
                id:'eyeProtection',
                label:'Eye Protection',
                value: 90,
            },
            {
                id:'visualComfort',
                label:'Visual Comfort',
                value: 95,
            },
            {
                id:'enhancedContrast',
                label:'Enhanced Contrast',
                value: 90,
            },
            {
                id:'uvProtection',
                label:'UV Protection',
                value: 90,
            },
        ],
        color_group:[
            {
              group: "C4",
              colors: [
                {   code: "Pc4clr-greyGreen", 
                    color_code: "Grey green", 
                    color_shade: "Polarized", 
                    palette1: "#dedede", 
                    palette2: "#123123",
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey green.jpg'), 
                    color_tid: "469"
                },
                {   code: "Pc4clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "468"
                },
                {   code: "Pc4clr-silverMirror", 
                    color_code: "Silver Mirror", 
                    color_shade: "Polarized", 
                    palette1: "#8b8d92", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_new silver.jpg'), 
                    color_tid: "968"
                },
              ]
            },
            {   group: "C3", 
                colors: [
                {   code: "Pc3clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "364" 
                }
            ]},
            {
              group: "C3/2",
              colors: [
                {   code: "Pc32clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "362" 
                },
                {   code: "Pc32clr-brown", 
                    color_code: "Brown", 
                    color_shade: "Polarized", 
                    palette1: "#964B00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'),
                    color_tid: "366" 
                }
              ]
            },
            { 
                group: "C2 & C3", 
                colors: [
                    { 
                    code: "Pc2&c3clr-Kaki", 
                    color_code: "Kaki", 
                    color_shade: "Polarized", 
                    palette1: "#373622", 
                    palette2: null, 
                    image_path: null, 
                    color_tid: "371" 
                    }
                ]
            }
          ]}
    },
    {
        id: 'xperio_tinted_mirrors',
        label: 'Mirror',
        sun_experience: {
            id:'mirror',
            label:'Mirror',
            // translationCode: 'sun_exp_sun_solution_mirr',
            translationCode: ['sun_exp_sun_solution_tinted_mirr','Mirrors Tinted'],
            disclaimer:[
                '*All substrates above 380 nm. UV cut level may vary slightly depending on the lens and mirror colors.', 
                '**The performance of tinted mirror lenses may slightly vary based on the base lens color and mirror color.'],
            advantages: [
            {
                id:'eyeProtection',
                label:'Eye Protection',
                value: 90,
            },
            {
                id:'visualComfort',
                label:'Visual Comfort',
                value: 70,
            },
            {
                id:'enhancedContrast',
                label:'Enhanced Contrast',
                value: 65,
            },
        ],
        color_group:[
            {
              group: "C4",
              colors: [
                {   code: "Pc4clr-greyGreen", 
                    color_code: "Grey green", 
                    color_shade: "Polarized", 
                    palette1: "#dedede", 
                    palette2: "#123123",
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey green.jpg'), 
                    color_tid: "469"
                },
                {   code: "Pc4clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "468"
                },
                {   code: "Pc4clr-silverMirror", 
                    color_code: "Silver Mirror", 
                    color_shade: "Polarized", 
                    palette1: "#8b8d92", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/01_Xperio Mirrors/Xperio_Mirror_new silver.jpg'), 
                    color_tid: "968"
                },
              ]
            },
            {   group: "C3", 
                colors: [
                {   code: "Pc3clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "364" 
                }
            ]},
            {
              group: "C3/2",
              colors: [
                {   code: "Pc32clr-Amber", 
                    color_code: "Amber", 
                    color_shade: "Polarized", 
                    palette1: "#FFBF00", 
                    palette2: null, 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'), 
                    color_tid: "362" 
                },
                {   code: "Pc32clr-brown", 
                    color_code: "Brown", 
                    color_shade: "Polarized", 
                    palette1: "#964B00", 
                    palette2: "#123123", 
                    image_path: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'),
                    color_tid: "366" 
                }
              ]
            },
            { 
                group: "C2 & C3", 
                colors: [
                    { 
                    code: "Pc2&c3clr-Kaki", 
                    color_code: "Kaki", 
                    color_shade: "Polarized", 
                    palette1: "#373622", 
                    palette2: null, 
                    image_path: null, 
                    color_tid: "371" 
                    }
                ]
            }
          ]}
    },
]

export default SUN_EXPERIENCE_SOLUTION;