import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ColoredCircleProgress from '../../../components/ui/ColoredCircleProgress';
import iSubProduct from '../../../interface/sub-product.inteface';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';

import concaveLens50 from './../../../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_50.png';
import concaveLens56 from './../../../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_56.png';
import concaveLens60 from './../../../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_60.png';
import concaveLens67 from './../../../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_67.png';
import concaveLens74 from './../../../assets/images/lens-consulatation/lens-thickness/concave/Concave_Lens_74.png';
import convexeLens50 from './../../../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_50.png';
import convexeLens56 from './../../../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_56.png';
import convexeLens60 from './../../../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_60.png';
import convexeLens67 from './../../../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_67.png';
import convexeLens74 from './../../../assets/images/lens-consulatation/lens-thickness/convexe/Convexe_Lens_74.png';
import ExperienceType from '../../../types/experience.type';
import { getLensConsultationSun } from '../../../store/slices/lens-consultation-sun-exp.slice';

interface Props {
    onComplete: () => void,
    type: 'Convexe' | 'Concave'
}

const LensTransitionOut : React.FC<Props>  = ({
    onComplete,
    type
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-transition-out');
    const [thicknessImage, setThicknessImage] = useState(undefined);
    const { prescription } = useSelector(getUser);
    const [ fadeInLensThickness, setFadeInLensThickness] = useState(false);
    const { tempLensThicknessCalculation } = useSelector(getlensThickness);
    const { t } = useTranslation(useSelector(getApp));
    const { 
        lensRecommendation,
        activePair
    } = useSelector(getLensConsultation);
    const { 
        lensRecommendationSun,
        activePairSun
    } = useSelector(getLensConsultationSun);
    const {experience } = useSelector(getApp);
    const isSunExp = experience === ExperienceType.SUN
    const lensRecommendationByExperience = isSunExp ? lensRecommendationSun : lensRecommendation
    const activePairByExperience = isSunExp ? activePairSun : activePair

    const indexImage: any = {
        Concave: {
            t5: concaveLens50,
            t59: concaveLens56,
            t6: concaveLens60,
            t67: concaveLens67,
            t74: concaveLens74
        },
        Convexe: {
            t5: convexeLens50,
            t59: convexeLens56,
            t6: convexeLens60,
            t67: convexeLens67,
            t74: convexeLens74
        }
    }

    // HOOKS
    useEffect(() => {
        setTimeout(() => {
            setFadeInLensThickness(true);
        }, 500);
    },[])
    useEffect(() => {
        if(!lensRecommendationByExperience)return;
        const pair = lensRecommendationByExperience[activePairByExperience];
        if(!pair){
            setThicknessImage(undefined);
            return;
        }
        let index:any = pair.correct.subProduct?.index;
        if(!index)return;
        index = String(index).split('.')[1];
        let lensThicknessType = getLensThicknessType(prescription);
        if(!lensThicknessType)lensThicknessType = 'Convexe';

        const lensImage = indexImage[lensThicknessType][`t${index}`];
        setThicknessImage(lensImage);
    }, [lensRecommendationByExperience, activePairByExperience, prescription]);
    
    const getLensThicknessType = (rx: any) => {
        const sphere = rx.values.sphere;
        if(!sphere.od || !sphere.os)return;
        const add = Number(sphere.od) + Number(sphere.os);
        return add < 0 ? 'Concave' : 'Convexe';
    }

    const recommendedSubProduct = (): iSubProduct | any => {
        if(!lensRecommendationByExperience || !lensRecommendationByExperience[activePairByExperience])return null;
        return lensRecommendationByExperience[activePairByExperience]?.correct?.subProduct as iSubProduct;
    }

    return (
        <div className={B(type)}>
            
            {
                thicknessImage &&
                <img src={thicknessImage} alt="" className={E('index', fadeInLensThickness ? 'fadein': '')}/>
            }

            
            <div className={E('progress-circles')}>
                <div className={E('thickness-weight')}>
                    <ColoredCircleProgress index= {recommendedSubProduct() && recommendedSubProduct().index} value={tempLensThicknessCalculation[activePair]?.weight || 0} label={t('lens_thickness_lt_weight')} />
                </div>
                <div className={E('center-thickness')}>
                    <ColoredCircleProgress index= {recommendedSubProduct() && recommendedSubProduct().index} value={tempLensThicknessCalculation[activePair]?.center || 0} label={t('lens_thickness_lt_center_thickness')} />
                </div>
                <div className={E('edge-thickness')}>
                    <ColoredCircleProgress index= {recommendedSubProduct() && recommendedSubProduct().index} value={tempLensThicknessCalculation[activePair]?.edge || 0} label={t('lens_thickness_lt_edges_thickness')} />
                </div>
            </div>
        </div>
    )
}

export default LensTransitionOut;