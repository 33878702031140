import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideView from '../../components/SlideView';
import Button from '../../components/ui/Button';
import { getApp } from '../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../store/slices/lens-consultation.slice';
import lensThicknessSlice, { getlensThickness } from '../../store/slices/lens-thickness.slice';
import prescriptionSlice from '../../store/slices/prescription.slice';
import productDemoSlice from '../../store/slices/products-demo.slice';
import productsSlice from '../../store/slices/products.slice';
import LensPairType from '../../types/lens-pair.type';
import useBEM from '../../utils/hooks/useBEM';
import useTranslation from '../../utils/hooks/useTranslation';
import LensConsultationBrand from '../lens-consultation/components/LensConsultationBrand';
import LensConsultationProduct from '../lens-consultation/components/LensConsultationProduct';
import LensThicknessControl from '../lens-thickness/components/LensThicknessControl';
import PrescriptionForm from '../prescription/components/PrescriptionForm';
// import PrescriptionFormV2 from '../prescription/components/PrescriptionFormV2';
import LensConsultationSideV2 from './components/LensConsultationSideV2'; 
import ToggleCard from '../../components/ToggleCard'; 
import FrameView from './components/sun-experience/FrameView';
import ExperienceType from '../../types/experience.type';
import LensRecommendation from './components/LensRecommendation';
import { getLensConsultationSun } from '../../store/slices/lens-consultation-sun-exp.slice';

interface Props {

}

const LensConsultationV2 : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('lens-consultation-v2');
    const { sliderPage, productFamily, productBrand, activePair} = useSelector(getLensConsultation); 
    const { tint, solution } = useSelector(getLensConsultationSun); 
    const { view } = useSelector(getlensThickness);
    const [reload, setReload] = useState(true);
    const dispatch = useDispatch();
    let throttle: any;
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const [openPrescription, setOpenPrescription] = useState(true);
    const { experience } = appStore;

    // HOOKS
    useEffect(() => {
        window.addEventListener('resize', windowOnresize);
        dispatch(lensConsultationSlice.actions.sessionStart(new Date().toISOString()));

        dispatch(productDemoSlice.actions.setActiveProductDemo(undefined));
        dispatch(productDemoSlice.actions.setActiveCompareProductDemo(undefined));
        dispatch(productDemoSlice.actions.changeDemoType(undefined));
        // dispatch(productsSlice.actions.selectBrandTodemo(undefined))

        return () => {
            dispatch(lensConsultationSlice.actions.setSliderPage(0));
            dispatch(lensConsultationSlice.actions.selectProductFamily(null));
            dispatch(lensConsultationSlice.actions.changePair(LensPairType.PAIR1));
            dispatch(lensThicknessSlice.actions.changeView('controls'));
            window.removeEventListener('resize', windowOnresize);
        }
    },[]);

    useEffect(() => {
        
        if(sliderPage < 3){
            dispatch(lensConsultationSlice.actions.setSliderPage(0));
        }
        dispatch(lensConsultationSlice.actions.selectProductFamily(null));
    }, [activePair])

    // METHODS
    const windowOnresize = () => {
        if(throttle)clearTimeout(throttle);
        throttle = setTimeout(() => {
            setReload(false);
            setTimeout(() => setReload(true), 100)
        }, 250);
    }

    const slideBack = () => {
        dispatch(lensConsultationSlice.actions.setSliderPage(sliderPage - 1));
        if(sliderPage === 1){
            setTimeout(() => {
                dispatch(lensConsultationSlice.actions.selectProductFamily(null));
            }, 250);
        }
    }

    const getBackButtonLabel = () => {
        let label: string | undefined = '';
        if(sliderPage < 2) label = productFamily?.label;
        if(sliderPage === 2) label = productBrand?.label;
        return t(label as string);
    }

    const showBackButton = () => {
        return (sliderPage > 0 && sliderPage < 3);
    }

    const backLensThicknessControl = () => {
        if(view === "controls"){
            dispatch(lensConsultationSlice.actions.setSliderPage(0));
            return;
        }
        dispatch(lensThicknessSlice.actions.changeView('controls'));
        dispatch(prescriptionSlice.actions.resetData());
    }

    const isSunXp = () => experience === ExperienceType.SUN;

    return (
        <div className={B() + ' page'}>
            <LensConsultationSideV2 experience={experience}/>

            <div className={E('content')}>

            { !isSunXp() && <div className={E('slide-back', [showBackButton() ? 'show' : ''])} >
                <Button 
                    click={slideBack}
                    outlineType="light"
                    text={getBackButtonLabel()} 
                    icon="left-arrow-circle-gray" type="secondary"                  
                    iconPosition="left"/>
            </div>}
            
                { isSunXp() && 
                    <> {sliderPage < 3 ? 
                            <FrameView tint={tint}/> :
                            <LensThicknessControl/>}
                    </>
                }
                
                { reload && !isSunXp() &&
                <SlideView id="lensConsultation" numberOfSlides={4} currentSlide={sliderPage}>                
                        <LensRecommendation/>
                        <LensConsultationBrand/>
                        <LensConsultationProduct/>    
                        <div>
                            <div className={E('slide-container')}>
                                <ToggleCard 
                                    title="Prescription" 
                                    open={openPrescription} 
                                    onToggle={() => setOpenPrescription(!openPrescription)}
                                    >
                                    <PrescriptionForm parent='lens-consultation'/>
                                </ToggleCard>
                                <br/>
                                <LensThicknessControl/>
                            </div>
                        </div>
                        </SlideView> 
                    }
            </div>
        </div>
    )
}

export default LensConsultationV2;