import React, { useState } from 'react';
import useBEM from '../../../../utils/hooks/useBEM';
import Utils from '../../../../utils/Utils';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import Icon from '../../../../components/ui/Icon';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answers: number[]) => void
}

const RatingGroup: React.FC<Props> = ({
    question,
    onAnswer
}) => {
    // VARIABLES
    const [B, E] = useBEM('rating-group');
    const { cloneObject } = Utils();
    const { t } = useTranslation(useSelector(getApp));
    const [rate, setRate] = useState<number[]>([1, 1, 1, 1, 1, 1, 1, 1, 1]);
    // HOOKS

    // METHODS
    const handleOnClick = (data: number, index: number) => {
        const newAnswers = cloneObject(question.answer) as number[];
        newAnswers[index] = data;
        setRate(newAnswers);
        if (onAnswer) onAnswer(newAnswers);

    }

    const getRatingValue = (index: number) => {
        return (question.answer as number[])[index];
    }

    return (
        <div className={B()}>
            {
                question.situations && question.situations.map((slider, index) => (

                    <div className={E('rating-box')} key={index}>
                        <label className={E('rating-label')}>
                            {t(slider.label)}
                        </label>
                        <div className={E('rating-container')}>
                            {[...Array(5)].map((item, i) => {
                                const givenRating = i + 1;
                                const ansRating = getRatingValue(index)
                                const style = givenRating === (ansRating) ? "rating-selected" : givenRating <= (ansRating) ?
                                        "rating-fill" : "rating";

                                return (
                                    <><input
                                        className={E('rating-input')}
                                        type="radio"
                                        value={ansRating}
                                    />
                                        <div
                                            className={E(style)}
                                            onClick={() => handleOnClick(givenRating, index)}
                                        >{givenRating}</div>
                                    </>
                                );

                            })
                            }
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default RatingGroup;
