import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import Text from '../../../components/ui/Text';
import iMeasurementDataOne from '../../../interface/measurement-one-data.inteface';
import iMeasurementDataThree from '../../../interface/measurement-three-data.inteface';
import iMeasurementDataTwo from '../../../interface/measurement-two-data.inteface';
import { getApp } from '../../../store/slices/app.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';

interface Props {
    pair: number
}

const DeliveryPairMeasures : React.FC<Props>  = ({
    pair
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-pair-measures');
    // let measuresImage = "measures01";
    const [measuresImage, setMeasuresImage ] = useState('measures01');
    const [measureStep, setmeasureStep ] = useState(1);
    const [ref, setRef] = useState('');
    const { measurementData } = useSelector(getMeasure);
    const [data, setData] = useState<{
        one: iMeasurementDataOne,
        two: iMeasurementDataTwo,
        three: iMeasurementDataThree
    }>();
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const { t } = useTranslation(useSelector(getApp));
    const { breakdown } = useSelector(getLensConsultation);
    const { isIvsGenerated } = useSelector(getMeasure);

    // HOOKS
    useEffect(() => {
        const p = pair - 1;
        setData({
            one: measurementData.one[p],
            two: measurementData.two[p],
            three: measurementData.three[p]
        })
    }, [measurementData])

    // METHODS
    const slideShow = (action: string) =>{
        let tempStep = measureStep
        if(measureStep<=0) tempStep = 1;
        if(measureStep>3) tempStep = 3;
        if(action ==="next"){
            tempStep++;
        }else{
            tempStep--;
        }

        setMeasuresImage("measures0"+tempStep);
        setmeasureStep(tempStep);

    }

    return (
        <div className={B()}>
            
            <div className={E('image')}>
                
                <div className={E('image-slider')}>
                    <Icon name={measuresImage} type="png"/>

                    <div className="arrows">
                        <Icon 
                        className={measureStep === 1 ? 'disabled': ''}
                        name="right-arrow-circle-white" click={() => slideShow('prev')}/>
                        <Icon 
                        className={measureStep === 3 ? 'disabled': ''}
                        name="right-arrow-circle-white" click={()=> slideShow('next')}/>
                    </div>
                </div>

            </div>
            
            <div className={E('details')}>
                
                <h2>{t('pair_1_tab_del_p1_measures_title')}</h2>
                
                <Text type="light">
                {t('pair_1_tab_del_p1_measures')}
                </Text>

                {
                    data &&
                    <ul className={E('data', experience)}>
                        <li>
                            <label>{t('pair_1_tab_del_p1__measure_1')}</label>
                            <div>
                                <label>  
                                    <p>
                                        {t('measures_mes_a_▭_b')}
                                    </p>
                                    <span>{(data.one?.a.value || 0) + (data.one?.b.value || 0)} mm</span>
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes_d')}
                                    </p>
                                    <span>{data.one?.d.value} mm</span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <label>{t('pair_1_tab_del_p1__measure_2')}</label>
                            <div>
                                <label>  
                                    <p>
                                        {t('measures_mes_left_eye')}&nbsp;{t('measures_mes_pd')}
                                    </p>
                                    <span>{data.two?.left.pd.value} mm</span> 
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes_left_eye')}&nbsp;{t('measures_mes_height')}
                                    </p>
                                    <span>{data.two?.left.height.value} mm</span> 
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes_righ_eye')}&nbsp;{t('measures_mes_pd')}
                                    </p>
                                    <span>{data.two?.right.pd.value} mm</span> 
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes_righ_eye')}&nbsp;{t('measures_mes_height')}
                                    </p>
                                    <span>{data.two?.right.height.value} mm</span> 
                                </label>



                            </div>
                        </li>
                        <li>
                            <label>{t('pair_1_tab_del_p1__measure_3')}</label>
                            <div>
                                <label>  
                                    <p>
                                        {t('measures_mes__3_pantoscopic_angle')}
                                    </p>
                                    <span>{data.three?.pantoscopicAngle.value} °</span>
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes__3_wrap_angle')}
                                    </p>
                                    <span>{data.three?.wrapAngle.value} °</span>
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes__3_vertex_distance')}
                                    </p>
                                    {
                                        pair === 1 && isIvsGenerated ?
                                        <span>Left:{data.three?.vertexDistance.ivsValue.left} | Right:{data.three?.vertexDistance.ivsValue.right} mm</span>
                                        :
                                        <span>{data.three?.vertexDistance.value} mm</span>
                                    }
                                </label>
                                <label>  
                                    <p>
                                        {t('measures_mes__3_reading_distance')}
                                    </p>
                                    <span>{data.three?.readingDistance.value} cm</span>
                                </label>
                            </div>                                
                        </li>
                    </ul>                                    
                }

                <div className={E('frame-selection')}>
                    <h4>{t('pair_1_tab_del_p1_frame_selection_title')}</h4>
                    <Text type="light">
                    {t('pair_1_tab_del_p1_frame_selection')}
                    </Text>

                    <div className={E('frame-selection-reference')}>
                        <label>{t('delivery_(measures)_reference')}</label>

                        <input type="text" 
                            value={ref} 
                            // placeholder="Suspendisse tristique odio odio"
                            onChange={(e) => setRef(e.target.value)}
                        />
                        
                        <span>{`${data?.one.price.value} ${breakdown.currency}`}</span>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default DeliveryPairMeasures;