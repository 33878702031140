import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import appSlice, { getApp } from '../../../store/slices/app.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import ExperienceType from '../../../types/experience.type';
import useTranslation from '../../../utils/hooks/useTranslation';
import { TEMPORARY_PRODUCT_LIST } from '../../../constants/products.constants';
import LensFamilyType from '../../../types/lens-family.type';
import iProductBrand from '../../../interface/product-brand.interface';
import iProduct from '../../../interface/product.inteface';
import LensPairType from '../../../types/lens-pair.type';
import iSubProduct from '../../../interface/sub-product.inteface';
import needExplorerSlice, { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import lensConsultationSun from '../../../store/slices/lens-consultation-sun-exp.slice';
import { getProducts } from '../../../store/slices/products.slice';
import useProducts from '../../../utils/hooks/useProducts';

interface Props {

}

const LensProposaBuilderlExperience : React.FC<Props>  = () =>  {
    // VARIABLES
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const dispatch = useDispatch();
    const {getSunExperienceProducts } = useProducts(useSelector(getProducts));
    const productStore = useSelector(getProducts);
    const buttons = [
        { id: 'standard', text: 'lens_proposal_builder_lpb__standard_experience' },
        { id: 'ava', text: 'lens_proposal_builder_lpb__ava_tm_experience', soon: true },
        { id: 'sun', text: 'lens_proposal_builder_lpb__sun_experience'}
    ];
    const { lensRecommendationStandard,
            lensRecommendationSun,
            activePair,
            lensRecommendation
          } = useSelector(getLensConsultation);
    const tempList:any[] = TEMPORARY_PRODUCT_LIST;
    // HOOKS

    // METHODS
    const buttonIcon = (status?: string) => {
        return <span className={`icon ${status ? 'icon--active' : ''}`}></span>;
    }

    const updateExperience = (ex: any) => {
        if(ex.soon)return;
        if (ex.id === ExperienceType.STANDARD){
            dispatch(lensConsultationSlice.actions.saveLensRecommendationDataFromStore(lensRecommendationStandard || {}))
            dispatch(needExplorerSlice.actions.changeInitialStateToStandard())
        };
        if (ex.id === ExperienceType.SUN) {
            dispatch(lensConsultationSlice.actions.saveLensRecommendationDataFromStore(lensRecommendationSun || {}));
            dispatch(needExplorerSlice.actions.changeInitialStateToSun())
            const sunExpProducts = getSunExperienceProducts(productStore.temporaryProductList)
            dispatch(lensConsultationSun.actions.sunExperienceProducts(sunExpProducts))
        }
        dispatch(appSlice.actions.setExperience(ex.id as ExperienceType));
    }

    return (
        <div className="lens-proposal-experience">
            {
                buttons.map( b => (
                    <Button 
                        soon={b.soon}
                        click={() => updateExperience(b)}
                        active={b.id === appStore.experience}
                        text={t(b.text)} 
                        type="simple" 
                        icon={buttonIcon( b.id === appStore.experience ? 'active' : '' )} 
                        key={b.id}
                    />
                ))
            } 
        </div>
    )
}

export default LensProposaBuilderlExperience;