import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from '../../../components/Modal';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import SideBoxBordered from '../../../components/ui/SideBoxBordered';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import routes from '../../../constants/routes.constant';
import lensThicknessSlice from '../../../store/slices/lens-thickness.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import { getProducts } from '../../../store/slices/products.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import LensCategoryType from '../../../types/lens-category.type';
import LensEnhanceType from '../../../types/lens-enhance.type';
import LensFamilyType from '../../../types/lens-family.type';
import LensProtectType from '../../../types/lens-protect.type';
import PrescriptionViewType from '../../../types/prescription-view.type';
import useBEM from '../../../utils/hooks/useBEM';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import useLensThickness from '../../lens-thickness/useLensThickness';
import PrecisionType from './../../../types/precision.type';
import LensPairType from '../../../types/lens-pair.type';
import ComplementaryEquipmentType from '../../../types/complementary-equipment.type';
import AgeCategoryType from '../../../types/age-category.type';

import clearLens from '../../../assets/images/prescription/Clear_Lens.png';
import violetLens from '../../../assets/images/prescription/Violet_Lens.png';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import useGoto from '../../../utils/hooks/useGoto';
import CircleArrow from './../../../components/ui/CircleArrow';
import ExperienceType from './../../../types/experience.type';
import { getLensConsultationSun } from '../../../store/slices/lens-consultation-sun-exp.slice';


interface Props {

}

const PrescriptionSide : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription-side');
    const { temporaryProductList } = useSelector(getProducts);
    const { ageCategory } = useSelector(getUser);
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const { t } = useTranslation(appStore);

    const {
        view,
        isValidPrescription,
        tempPrescriptionData,
        tempGeneralEquipment,
        tempComplementaryEquipment,
    } = useSelector(getPrescription);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showImportModal, setShowImportModal] = useState(false);
    const lensThicknessRules = LENS_THICKNESS_RULES;
    const { getRecommendedLensThickness } = useLensThickness();
    const {
        rxLensRecommendation,
    } = useLensConsultation();
    const ga = useGoogleAnalytics();
    const user = useSelector(getUser);
    const noRecommendation: any = {
        product: undefined,
        brand: undefined
    }

    const goto = useGoto();
    const { lensRecommendation } = useSelector(getLensConsultation);
    const { lensRecommendationSun } = useSelector(getLensConsultationSun);
    const isSunExp = experience === ExperienceType.SUN
    const lensRecommendationByExperience = isSunExp ? lensRecommendationSun : lensRecommendation

    // Note: save recommended lens on user order data
    useEffect(() => {

        if(!lensRecommendation || !lensRecommendationSun)return;
        dispatch(userSlice.actions.saveLensRecommendationData(lensRecommendation));

    }, [lensRecommendation])

    // METHODS
    const next = () => {
        if(view === 'lens-proposal') {
            dispatch(lensThicknessSlice.actions.clearLensThicknessCalculationData());
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));

            const sphere: any = tempPrescriptionData.values.sphere;
            const thickness = getRecommendedLensThickness({
                od: sphere.od,
                os: sphere.os
            }, lensThicknessRules);

            saveRecommendLensThickness(thickness);
            if(isKids()) {
                recommendProductsForKids();
            } else {
                experience == "standard" && recommendProducts();
            }


            dispatch(userSlice.actions.saveLensEquipment({
                general: tempGeneralEquipment,
                complementary: tempComplementaryEquipment
            }));

            savePrescriptionOnGA();
            goto.route(routes.lensProposalBuilder, 500);
            return;
        }
        dispatch(lensConsultationSlice.actions.setShowDialogue(true))
        dispatch(lensConsultationSlice.actions.setShowWarning(true))
        dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
        dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.LENSPROPOSAL));
    }

    const savePrescriptionOnGA = () => {
        const correct = getGeneralEquipmentValue(LensFamilyType.CORRECT);
        const protect = getGeneralEquipmentValue(LensFamilyType.PROTECT) || '';
        const enhance = getGeneralEquipmentValue(LensFamilyType.ENHANCE) || '';
        const complementary = getComplementaryEquipmentValue() || '';

        const finalText = [
            ...( correct ? [correct] : [] ),
            ...( protect ? [protect] : [] ),
            ...( enhance ? [enhance] : [] ),
            ...( complementary ? [complementary] : [] )
        ].join(',');
        ga.event({
            category: `Prescription - ${tempPrescriptionData.precision}`,
            action: 'Button Click',
            label: `Complete Perscription - ${finalText}`
        });
    }
    const getGeneralEquipmentValue = (family: LensFamilyType) => {
        return tempGeneralEquipment
        .filter( o => o.field === family)
        .map( o => o.value?.value)[0];
    }

    const getComplementaryEquipmentValue = () => {
        return tempComplementaryEquipment[0].value?.value as ComplementaryEquipmentType
    }

    const recommendProductsForKids = () => {
        const correctLensCategory = getGeneralEquipmentValue(LensFamilyType.CORRECT) as LensCategoryType;
        const protectLensCategory = getGeneralEquipmentValue(LensFamilyType.PROTECT) as LensProtectType;
        const enhanceLensCategory = getGeneralEquipmentValue(LensFamilyType.ENHANCE) as LensEnhanceType;

        const pair1Correct = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory: AgeCategoryType.KIDS,
            family: LensFamilyType.CORRECT,
            pair: LensPairType.PAIR1,
            category: correctLensCategory
        });
        let pair1Protect:any = {...noRecommendation};

        if(pair1Correct.product?.id !== 'stellest'){
            pair1Protect = rxLensRecommendation({
                products: temporaryProductList,
                ageCategory: AgeCategoryType.KIDS,
                family: LensFamilyType.PROTECT,
                pair: LensPairType.PAIR1,
                category: protectLensCategory != LensProtectType.PHOTOCROMIC ? correctLensCategory : LensProtectType.PHOTOCROMIC
            });
        }

        let pair1Enhance:any = {...noRecommendation};

        if(enhanceLensCategory){
            pair1Enhance = rxLensRecommendation({
                products: temporaryProductList,
                ageCategory: AgeCategoryType.KIDS,
                family: LensFamilyType.ENHANCE,
                pair: LensPairType.PAIR1,
                category: correctLensCategory
            });
        }

        saveRecommendedProducts({
            correct: pair1Correct,
            protect: pair1Protect,
            enhance: pair1Enhance
        }, LensPairType.PAIR1)

        // PAIR 2
        const selectedEquipment = getComplementaryEquipmentValue();

        const pair2Correct = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory: AgeCategoryType.KIDS,
            family: LensFamilyType.CORRECT,
            pair: LensPairType.PAIR2
        });

        const pair2Protect = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory: AgeCategoryType.KIDS,
            family: LensFamilyType.PROTECT,
            pair: LensPairType.PAIR2,
            equipment: selectedEquipment
        });

        const pair2Enhance = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory: AgeCategoryType.KIDS,
            family: LensFamilyType.ENHANCE,
            pair: LensPairType.PAIR2,
            equipment: selectedEquipment
        });

        saveRecommendedProducts({
            correct: pair2Correct,
            protect: selectedEquipment ? pair2Protect : noRecommendation,
            enhance: selectedEquipment ? pair2Enhance : noRecommendation
        }, LensPairType.PAIR2)
    }

    const recommendProducts = () => {
        const selectedCorrect = getGeneralEquipmentValue(LensFamilyType.CORRECT) as LensCategoryType;
        const selectedProtect = getGeneralEquipmentValue(LensFamilyType.PROTECT) as LensProtectType;
        let selectedEnhance = getGeneralEquipmentValue(LensFamilyType.ENHANCE) as LensEnhanceType;
        if(!selectedEnhance && selectedProtect === LensProtectType.TINTED){
            selectedEnhance = LensEnhanceType.TINTED
        }
        const ageCategory = AgeCategoryType.ADULT;

        // PAIR 1 RECOMMENDATION
        const pair1Correct = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory,
            family: LensFamilyType.CORRECT,
            pair: LensPairType.PAIR1,
            category: selectedCorrect
        });

        const pair1Protect = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory,
            family: LensFamilyType.PROTECT,
            pair: LensPairType.PAIR1,
            category: selectedProtect
        });

        const pair1Enhance = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory,
            family: LensFamilyType.ENHANCE,
            pair: LensPairType.PAIR1,
            category: selectedEnhance
        });

        saveRecommendedProducts({
            correct: pair1Correct,
            protect: pair1Protect,
            enhance: pair1Enhance
        }, LensPairType.PAIR1)

        // PAIR 2 RECOMMENDATION
        const selectedEquipment = getComplementaryEquipmentValue();

        let pair2Correct = pair1Correct;
        if(
            ![ComplementaryEquipmentType.SUN, ComplementaryEquipmentType.DRIVING ].includes(selectedEquipment)
        ){
            pair2Correct = rxLensRecommendation({
                products: temporaryProductList,
                ageCategory,
                family: LensFamilyType.CORRECT,
                pair: LensPairType.PAIR2,
                category: selectedCorrect,
                equipment: selectedEquipment
            });
        }
        const pair2Protect = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory,
            family: LensFamilyType.PROTECT,
            pair: LensPairType.PAIR2,
            equipment: selectedEquipment
        });

        let pair2Enhance = rxLensRecommendation({
            products: temporaryProductList,
            ageCategory,
            family: LensFamilyType.ENHANCE,
            pair: LensPairType.PAIR2,
            equipment: selectedEquipment
        });

        if(!pair2Enhance.product) {
            pair2Enhance = rxLensRecommendation({
                products: temporaryProductList,
                ageCategory,
                family: LensFamilyType.ENHANCE,
                pair: LensPairType.PAIR2,
                equipment: selectedEquipment,
                index: 1
            });
        }

        saveRecommendedProducts({
            correct: selectedEquipment ? pair2Correct : noRecommendation ,
            protect: selectedEquipment ? pair2Protect : noRecommendation,
            enhance: selectedEquipment ? pair2Enhance : noRecommendation
        }, LensPairType.PAIR2)
    }

    const saveRecommendedProducts = (products: {
        correct: any,
        protect: any,
        enhance: any
    }, pair: LensPairType) => {
        const {correct, protect, enhance} = products;

        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            product: correct.product,
            subProduct: correct.subProduct,
            pair
        }));

        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.PROTECT,
            product: protect.product,
            brand: protect.brand,
            manual: false,
            pair
        }));

        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.ENHANCE,
            brand: enhance.brand,
            product: enhance.product,
            pair
        }));
    }

    const clearRecommendedPair2 = () => {
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            product: undefined,
            pair: LensPairType.PAIR2
        }));
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.ENHANCE,
            product: undefined,
            pair: LensPairType.PAIR2
        }));
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.PROTECT,
            product: undefined,
            pair: LensPairType.PAIR2
        }));
    }

    const saveRecommendLensThickness = (thickness: any) => {
        dispatch(lensThicknessSlice.actions.setLensThickness({
            pair1: thickness,
            pair2: thickness
        }));
        dispatch(userSlice.actions.saveLensThickness({
            pair1: thickness,
            pair2: thickness
        }));
    }

    const renderNextButtonLabel = () => {
        return (view === 'prescription') ? t('prescription_rx_next') : t('prescription_rx_save_&_next');
    }

    const renderSideImage = () => {
        if (view === 'lens-proposal') {
            return <>
                {
                    <img
                        src={experience === ExperienceType.SUN ? violetLens : clearLens}
                        alt=""
                        className={E('clear-lens')}
                    />
                }

                {/* <PrescriptionLensOverlayAnimation/> */}
            </>;
        }
        // return `eye-reader-${tempPrescriptionData?.precision}`;
        return <div className={E('precision' ,[tempPrescriptionData?.precision === PrecisionType.STANDARD ? 'swap':''])}>
                    <Icon type="gif" extension="gif" name="eye-reader-ava"/>
                    <Icon type="image" name="eye-reader-standard"/>
                </div>
    }

    const onSelect = (id: string) => {
        //  alert(id);
    }
    const isKids = () => {
        return ageCategory && ageCategory.value === AgeCategoryType.KIDS;
    }

    const getLabelText = () => {
        if(view === 'prescription')return t('prescription_rx_prescription');
        else if(view === 'lens-proposal') return t('lens_thickness_lt_completed');
        return '';
    }

    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    const getLabelIcon = () => {
        const { prescription } = user;
        if(view === 'prescription') return <Icon name={`progress-circle${selectIcon()}`} />;
        else if(view === 'lens-proposal') return <Icon name="check-circle"/>;
        return '';
    }

    return (

        <div className={B()}>
            <SideBoxBordered>
                <div className={E('header')}>
                    <label className={E(view==='lens-proposal' ? 'title white' : 'title')}>
                        {/* <Icon name="progress-circle"/> */}
                        { getLabelIcon() }
                        { getLabelText() }
                    </label>
                    {isKids() &&(
                        <Button icon="menuEducation" type="secondary"
                        text={t('sun_exp_no_rx_learn_more')} soon
                         />
                    )}

                </div>


                {renderSideImage()}

                <div className={E('buttons')}>
                    {/* <Button icon="import" type="secondary"  click={() => setShowImportModal(true)}/> */}
                    <Button
                        disabled={!isValidPrescription}
                        text={renderNextButtonLabel()}
                        icon={
                            <CircleArrow
                                type='right'
                                background='#eee'
                            />
                        }
                        click={next}
                        experience={experience}
                    />
                </div>

                <Modal show={showImportModal} width="43.2rem" height="25.8rem" onClose={() => setShowImportModal(false)}>
                    <div className={E('import-modal')}>
                        <h2>Import</h2>

                        <div>
                            <Button

                                click={() => onSelect('qrcode')}
                                icon="qrcode"
                                text="QR Code"
                                type="secondary"/>
                            <Button
                                click={() => onSelect('id')}
                                icon="stacked-group"
                                text="ID"
                                type="secondary"/>
                        </div>

                    </div>
                </Modal>
            </SideBoxBordered>
        </div>
    )
}

export default PrescriptionSide;
