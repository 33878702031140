import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Accordion from '../../../components/ui/Accordion';
import Button from '../../../components/ui/Button';
import Checkbox from '../../../components/ui/Checkbox';
import InputText from '../../../components/ui/InputText';
import { discount } from '../../../constants/my-companion.constants';
import iDiscountSetting from '../../../interface/discount-setting';
import iLensThicknessSetting from '../../../interface/lens-thickness-setting';
import iProductBrand from '../../../interface/product-brand.interface';
import iProductFamily from '../../../interface/product-family.interface';
import iProduct from '../../../interface/product.inteface';
import iSubProduct from '../../../interface/sub-product.inteface';
import { getApp } from '../../../store/slices/app.slice';
import myCompanionSlice, { getMyCompanion } from '../../../store/slices/mycompanion.slice';
import { getProducts } from '../../../store/slices/products.slice';
import LensFamilyType from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import useGoto from '../../../utils/hooks/useGoto';
import routes from '../../../constants/routes.constant';

interface Props {

 }


const MyCompanionList : React.FC<Props>  = () =>  {
    // VARIABLES
    const { 
        isPriceOff,  
        category, 
        productList, 
        tempLensThicknessSettings,
        tempDiscountSettings,
        essilorRecommend,
    } = useSelector(getMyCompanion);

    const { 
        lensThicknessSettings,
        discountSettings
    } = useSelector(getApp);
    
    const [editProductPrice, setEditProductPrice] = useState<{
        brand: iProductBrand,
        product: iProduct,
        subProduct?: iSubProduct
    }>();
    const [editBrandPrice, setEditBrandPrice] = useState<iProductBrand>();
    const [editProductSubProductsPrice, setEditProductSubProductsPrice] = useState<{
        brandId: string,
        productId: string
    }>();

    const [editThicknessPrice, setEditThicknessPrice] = useState<number>();
    const [editPrice, setEditPrice] = useState<number | null>(null);
    const [displayAdv, setDisplayAdv] = useState<boolean>(false);

    const { temporaryProductList } = useSelector(getProducts);
    const { getUniqueSubProducts } = useLensConsultation();
    const [productFamily, setProductFamily] = useState<iProductFamily>();
    const dispatch = useDispatch();
    const [B, E] = useBEM('mycompanion');
    const {t} = useTranslation(useSelector(getApp));
    const { breakdown } = useSelector(getLensConsultation);
    const goto = useGoto();

    // HOOKS
    useEffect(()=>{

    })
    useEffect(() => {
        dispatch(myCompanionSlice.actions.setLensThicknessSettings(lensThicknessSettings))
        dispatch(myCompanionSlice.actions.setDiscountSettings(discountSettings))
    }, [lensThicknessSettings, discountSettings]);

    useEffect(() => {
        dispatch(myCompanionSlice.actions.setProductList(temporaryProductList))
    }, [temporaryProductList])

    useEffect(() => {
        if(category === 'advancesetting' && !isPriceOff && essilorRecommend) goto.route(routes.advanceSetting);
        else setDisplayAdv(false)
        if(isLensProduct() && productList){
            setProductFamily(getProductBrands());
        } else {
            setProductFamily(undefined);
        }
    }, [category, productList, isPriceOff, essilorRecommend])
    const priceContent = (price: number) => {

        if(isPriceOff) return null;
        return <span className="my-companion-list__price">
                {`${price} ${breakdown.currency}`}
            </span>;
    }

    const showLensThickness = () => {
        return category === 'thickness'
    }

    const showDiscount = () => {
        return category === 'secondpair'
    }

    const enableDisabledLensThickness = (setting: iLensThicknessSetting) => {

        // Note: disabling all index is not allowed
        if(setting.enabled){
            const countEnabled = tempLensThicknessSettings.filter( x => x.enabled).length;
            if(countEnabled === 1)return;
        }
        
        dispatch(myCompanionSlice.actions.enableDisabledLensThicknessSetting(setting));
    }

    const enableDisabledDiscount = (setting: iDiscountSetting) => {
        dispatch(myCompanionSlice.actions.enableDisabledDiscountSettings(setting));
    }

    const submitNewPrice = () => {
        if(editProductPrice){
            dispatch(myCompanionSlice.actions.updateProductPrice({
                ...editProductPrice,
                family: category as LensFamilyType,
                newPrice: editPrice as number
            }));
            setEditProductPrice(undefined);
            setEditPrice(null);
            return
        }

        if(editThicknessPrice) {
            dispatch(myCompanionSlice.actions.updatePriceLensThicknessSetting({
                value: editThicknessPrice,
                newPrice: editPrice as number
            }));
            setEditThicknessPrice(undefined);
            setEditPrice(null);
            return
        }

        if(editBrandPrice){

            dispatch(myCompanionSlice.actions.updateBrandProductPrices({
                familyId: productFamily?.id as string,
                brandId: editBrandPrice.id,
                newPrice: editPrice as number
            }));
            setEditBrandPrice(undefined);
            setEditPrice(null);
        }

        if(editProductSubProductsPrice){
            dispatch(myCompanionSlice.actions.updateProductSubProductsPrices({
                familyId: productFamily?.id as string,
                brandId: editProductSubProductsPrice.brandId,
                productId: editProductSubProductsPrice.productId,
                newPrice: editPrice as number
            }));
            setEditProductSubProductsPrice(undefined);
            setEditPrice(null);
        }
    }

    const priceOnChange = (event: any) => {
        if(isNaN(+event.target.value)) {return}
        setEditPrice(Number(event.target.value));
    }

    const isLensProduct = () => {
        if(!category)return false;
        return ['correct','protect','enhance'].includes(category);
    }

    const getProductBrands = (): iProductFamily => {
        const products = productList.filter( (x:iProductFamily) => x.id === category as LensFamilyType);
        return products[0];
    }
 
    const enableDisableProduct = (brand: iProductBrand, product: iProduct, subProduct?: iSubProduct) => {
        dispatch(myCompanionSlice.actions.enableDisableProduct({
            family: category as LensFamilyType,
            brand, product, subProduct
        }))
    }

    const closeEditPricePopup = () => {
        setEditPrice(null);
    }

    const checkBrandProductPriceZero = (brand: iProductBrand) => {
        const accumulated = brand.products.reduce((total ,item) => {
            return total + (item.price || 0);
        }, 0);
        return accumulated < 1;
    }

    const checkProductSubProductsPriceZero = (product: iProduct) => {
        if(!product.subProducts)return false;
        const accumulated = product.subProducts.reduce((total ,item) => {
            return total + (item.price || 0);
        }, 0);
        return accumulated < 1;
    }

    const renderDiscountRightTitle = (isFirstLayer: boolean, pair: iDiscountSetting) => {
        const isAmountEnabled = tempDiscountSettings[0].amountEnabled

        return isFirstLayer ? <div className={E("lens-thickness-right")}>
            <Button 
                text={breakdown.currency} 
                className={"compact"}
                type={pair.amountEnabled ? 'primary' : 'secondary'} 
                click={() => !pair.amountEnabled ? enableAmountDiscountButton(pair, true) : {}}/>
            <Button 
                className={"compact"}
                text={'%'} 
                type={pair.discountEnabled ? 'primary' : 'secondary'} 
                click={() => !pair.discountEnabled ? enableAmountDiscountButton(pair, false) : {}}/>
        </div> : <div className={E("lens-thickness-right")}>
            <InputText
                className="compact-text"
                value={isAmountEnabled ? pair.discountAmount : pair.discountPercentage}
                onChange={(e)=>{
                    //validate
                    if(isNaN(+e.target.value)) {return}
                    if(!isAmountEnabled && Number(e.target.value) > 100) {return}

                    dispatch(myCompanionSlice.actions.updateDiscountPair({
                        value: pair.value,
                        newPrice: Number(e.target.value),
                        isAmount: isAmountEnabled
                    }))
                }}
            />
            <span>{isAmountEnabled ? breakdown.currency : '%'}</span>
        </div>
    }

    const enableAmountDiscountButton = (pair: iDiscountSetting, isAmount: boolean) => {
        isAmount ? dispatch(myCompanionSlice.actions.enableAmountBtn(pair)) :
        dispatch(myCompanionSlice.actions.enableDiscountBtn(pair))
    }

    return (        
        <div className="my-companion-list">
            {
                !displayAdv && (
                    <>
                        {
                            productFamily && 
                            productFamily.brands.map( brand => (
                                <Accordion 
                                    title={brand.label} key={brand.id} 
                                    rightTitle={ 
                                        productFamily.id !== "correct" && checkBrandProductPriceZero(brand) ? 
                                        priceContent(0) : null
                                    }    
                                    rightTitleClicked={() => {    
                                        if(essilorRecommend)return;
                                        setEditPrice(0);                        
                                        setEditBrandPrice(brand)                            
                                    }}
                                >

                                    {
                                        brand.products.map( (product) => (

                                            <Accordion 
                                                rightTitle = {                                        
                                                    checkProductSubProductsPriceZero(product) || !product.subProducts ?                                         
                                                    priceContent(product.price || 0) :
                                                    null
                                                }

                                                rightTitleClicked={() => {
                                                    if(essilorRecommend)return;
                                                    setEditPrice(product.price || 0);

                                                    if(product.subProducts){
                                                        setEditProductSubProductsPrice({
                                                            productId: product.id,
                                                            brandId: brand.id
                                                        })
                                                        return;
                                                    }

                                                    setEditProductPrice({
                                                        brand,
                                                        product
                                                    })
                                                }}
                                                clicked={() => {
                                                    enableDisableProduct(brand, product)
                                                }}
                                                selected={ product.enabled }
                                                key={product.id} 
                                                title={product.label} type="child">


                                                {/* SUB PRODUCTS */}
                                                {   product.subProducts &&
                                                    getUniqueSubProducts(product.subProducts, {
                                                        isLowestIndex: true
                                                    }).map( subProduct => (
                                                        <Accordion
                                                            clicked={() => enableDisableProduct(brand, product, subProduct)}
                                                            selected={subProduct.enabled}
                                                            key={subProduct.id} 
                                                            title={subProduct.label} 
                                                            type="child"
                                                            rightTitle={priceContent(subProduct.price)}                                                 
                                                            rightTitleClicked={() => {
                                                                if(essilorRecommend)return;
                                                                setEditPrice(subProduct.price);
                                                                setEditProductPrice({
                                                                    brand,
                                                                    product,
                                                                    subProduct
                                                                })
                                                            }}
                                                        />
                                                ))}
                                                {/* SUB PRODUCTS  END*/}


                                            </Accordion>

                                        ))
                                    }

                                    
                                </Accordion>
                            ))
                        }

                        {
                            showLensThickness() &&
                            tempLensThicknessSettings.map( (thickness, index ) => (
                                <Accordion 
                                    className={E('lens-thickness')}
                                    clicked={() => enableDisabledLensThickness(thickness)}
                                    title={String(thickness.value)} 
                                    selected={thickness.enabled}
                                    key={index} 
                                    rightTitle={
                                        <div className={E("lens-thickness-right")}>
                                            {priceContent(thickness.price)}
                                            <Checkbox checked={thickness.enabled}/>
                                        </div>

                                    }
                                    rightTitleClicked={() => {
                                        setEditPrice(thickness.price);
                                        setEditThicknessPrice(thickness.value);
                                    }}
                                />
                            ))
                        }

                        {
                            showDiscount() &&
                            <Accordion
                                title={t('price_bdown_disc')}
                                selected={true}>
                                {
                                    tempDiscountSettings.map( (pair, index) => (
                                        <Accordion
                                            className={E('discount')}
                                            clicked={() => {}}
                                            title={String(t(pair.label))} 
                                            selected={pair.enabled}
                                            key={index}
                                            rightTitle={renderDiscountRightTitle(pair.value === 'p0', pair)}/>
                                    ))
                                }
                            </Accordion>
                        }

                    </>
                )
            }
                
            
            <Modal show={editPrice !== null} onClose={() => {closeEditPricePopup()}}>
                <div className={E('change-price-popup')}>
                    <h2>{t('my_companion__enter_price')}</h2>
                    
                    <div>
                        <InputText 
                            value={editPrice as number}
                            maxlength={10}
                            onChange={priceOnChange}/>
                        
                            <Button text={t('my_practice_my_save')} icon="right-arrow-circle-white" click={submitNewPrice}/> 
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MyCompanionList;