import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Checkbox from '../../../components/ui/Checkbox';
import Icon from '../../../components/ui/Icon';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import iProduct from '../../../interface/product.inteface';
import iSubProduct from '../../../interface/sub-product.inteface';
import { getApp } from '../../../store/slices/app.slice';
import lensConsultationSun, { getLensConsultationSun } from '../../../store/slices/lens-consultation-sun-exp.slice';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import { getMyCompanion } from '../../../store/slices/mycompanion.slice';
import { getUser } from '../../../store/slices/user.slice';
import AgeCategoryType from '../../../types/age-category.type';
import ExperienceType from '../../../types/experience.type';
import LensFamilyType from '../../../types/lens-family.type';
import useBEM from '../../../utils/hooks/useBEM';
import useEprom from '../../../utils/hooks/useEprom';
import useTranslation from '../../../utils/hooks/useTranslation';
import useLensThickness from '../../lens-thickness/useLensThickness';
import useLensConsultation from '../useLensConsultation';

interface Props {

}

const LensConsultationProduct : React.FC<Props>  = () =>  {
    // VARIABLES
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { accountDetails, experience } = useSelector(getApp);
    const isSunExp = () =>{
        return experience === 'sun'
    }
    const [B, E] = useBEM(isSunExp()? 'lens-consultation-product-sun' : 'lens-consultation-product');
    const { 
        productFamily, 
        productBrand, 
        lensRecommendation,
        activePair,
        family,
        sliderPage,
        sliderPageTwo
    } = useSelector(getLensConsultation);
    const {familySun, activePairSun, lensRecommendationSun} = useSelector(getLensConsultationSun);
    const dispatch = useDispatch();
    const [product, setProduct] = useState<iProduct>();
    const [subProduct, setSubProduct] = useState<iSubProduct>();
    const { getUniqueSubProducts } = useLensConsultation();
    const { ageCategory } = useSelector(getUser);
    const { 
        getRecommendedSubProductByIndex, 
        getRecommendedLensThickness,
        filterSubProductsWithEnabledIndex
    } = useLensThickness();
    const {prescription} = useSelector(getUser);
    const { tempLensThicknessSettings } = useSelector(getMyCompanion);
    const { 
        getAvailableProduct,
        isSubProducstAvailable,
        filterSubProductsAvailable,
        getTheLowestIndex
    } = useEprom();
    const enabledIndexes = appStore.lensThicknessSettings.filter( x => x.enabled).map( x => x.value);

    // Note: availabel eprom products for Protect and enhance
    const [availableEPROMProducts, setAvailableEPROMProducts] = useState<string[]>([]);


    // HOOKS
    useEffect(() => {
        if(!subProduct)return;
        dispatch(lensConsultationSlice.actions.setSliderPage(0));
    }, [subProduct])

    useEffect(() => {
        setProduct(undefined);
        setSubProduct(undefined);
    }, [productFamily])

    useEffect(() => {
        if(!lensRecommendation || isFamilyCorrect())return;
        const correct = lensRecommendation[activePair]?.correct;
        if(!correct?.subProduct?.code)return;
    }, [lensRecommendation, productFamily])

    // METHODS
    // const back = () => {
    //     dispatch(lensConsultationSlice.actions.setSliderPage(1));
    // }

    const countryBusinessRule = (id:string) =>{
        //products for italy bypass eprom 
         const italyProductsByPass = ['xperio_mirrors', 'xperio_tinted']

        //products for belgium new business rule
        const productsBelgium = [
        'transitions_xtractive',
        'transitions_xtractive_polarised',
        'xperio_polarised',
        'xperio_tinted',
        'xperio_mirrors']

        const productsBelgiumSunX = [
        'transitions_xtractive',
        'transitions_xtractive_polarised',
        'xperio_polarised',
        'xperio_tinted',
        'xperio_mirrors',
        'xperio_tinted_mirrors',
        'xperio_polarised_mirrors',
        'transitions_style_mirrors']

        const productBelgiumXperioMirrors = ['transitions_xtractive', 'xperio_polarised','xperio_tinted', 'transitions_xtractive_polarised']

        //for filtering protect belgium new business rule
        if(lensRecommendation){
            const protect = lensRecommendation[activePair]?.protect;

            if(accountDetails?.shop.country === 'be' && family ==='protect' && protect && protect[0] && protect[0]?.product?.id){
                if(productBelgiumXperioMirrors.includes(protect[0]?.product?.id)) return id !== 'xperio_mirrors'
                if(protect[0]?.product?.id === "xperio_mirrors") return !productsBelgium.includes(id)
                return protect[0]?.product?.id === id
            }
            //belgium new business rule for sun x protect enhance family (show crizal sun x protect depending on the protect product)
            if(accountDetails?.shop.country === 'be' && family === 'enhance' && lensRecommendation && protect && protect[0]?.product?.id && 
            !productsBelgiumSunX.includes(protect[0]?.product?.id)){
             if(id === "crizal_sun_xprotect")return true
             }

             //ITALY BUSINESS RULE
             if(accountDetails?.shop?.country === 'it' && family === 'protect' && protect && protect[0]?.product){
                if(!italyProductsByPass.includes(protect[0]?.product?.id)){
                    return !italyProductsByPass.includes(id)
                }
            }

        }

    }


    const isAlreadySelected = (id:string) =>{
    if(lensRecommendation){
        const protect = lensRecommendation[activePair]?.protect;
        if((accountDetails?.shop.country === 'be' || accountDetails?.shop?.country === 'it') && 
        protect){
        return protect[0]?.product?.id === id
    }
    }
}

    const isFamilyCorrect = () => {
        return productFamily?.id === LensFamilyType.CORRECT;
    }

    const selectProduct = (p: iProduct) => {
        setSubProduct(undefined);   
        if(product?.id === p.id) {
            setProduct(undefined);
            return;
        }
        setProduct(p);
        if(p.subProducts) return;       
                    
        isSunExp() && familySun === 'enhance' ? dispatch(lensConsultationSlice.actions.setSliderPageTwo(0)) : dispatch(lensConsultationSlice.actions.setSliderPage(0))
        setTimeout(() => {
            if(isSunExp()){
                dispatch(lensConsultationSun.actions.selectProduct({
                    lensFamily: productFamily?.id as LensFamilyType,
                    brand: productBrand,
                    product: p,
                })); 
            }else{
                dispatch(lensConsultationSlice.actions.selectProduct({
                    lensFamily: productFamily?.id as LensFamilyType,
                    brand: productBrand,
                    product: p
                }));
            }
            dispatch(lensConsultationSlice.actions.selectProductFamily(null));                
        }, 250);
    }

    const selectSubProduct = (p: iSubProduct) => {
        if(subProduct?.id === p.id) {
            setSubProduct(undefined);
            return;
        }    
        dispatch(lensConsultationSlice.actions.setSliderPage(0))

        setTimeout(() => {
            setSubProduct(p);
            dispatch(lensConsultationSlice.actions.selectProductFamily(null));

            let recommendedSubProduct;
            if(prescription?.values?.sphere && product?.subProducts){
                const sphere = prescription.values.sphere;
                const thickness = getRecommendedLensThickness({
                    od: sphere.od as number,
                    os: sphere.os as number
                }, LENS_THICKNESS_RULES);
                const subProducts = filterSubProductsWithEnabledIndex(
                    product.subProducts.filter(x => x.label === p.label), 
                    appStore.lensThicknessSettings
                );
                recommendedSubProduct = getRecommendedSubProductByIndex(subProducts, thickness);
            }
            isSunExp() ? 
                dispatch(lensConsultationSun.actions.selectProduct({
                    lensFamily: productFamily?.id as LensFamilyType,
                    brand: productBrand,
                    product,
                    subProduct: recommendedSubProduct || p,
                }))
                : 
                dispatch(lensConsultationSlice.actions.selectProduct({
                lensFamily: productFamily?.id as LensFamilyType,
                brand: productBrand,
                product,
                subProduct: recommendedSubProduct || p
            }))
        }, 250);
    }

    const isSelectedProduct = (p: iProduct) => {
        return p.id === product?.id;
    }
    
    const isSelectedSubProduct = (p: iSubProduct) => {
        return p.id === subProduct?.id;
    }

    const renderSubProducts = (product: iProduct) => {
        if (!product.subProducts)return null;

        const targetIndex = tempLensThicknessSettings.filter( x => x.enabled)[0];
        const availableSubProductsOnEprom = filterSubProductsAvailable(product.subProducts);
        const lowestIndexOnEPROM = getTheLowestIndex(availableSubProductsOnEprom, enabledIndexes);

        const uniqueSubProducts = isEPROMisEnabled() ? 
            lowestIndexOnEPROM : // Data from EPROM
            getUniqueSubProducts(product.subProducts, {
                targetIndex: targetIndex.value
            });

        return (
            <div className={E('sub-list', [isSelectedProduct(product) ? 'show' : ''])}>
                <ul>
                    {
                        uniqueSubProducts.map( (p,index: number) => (
                            <li 
                                key={index}
                                onClick={()=> selectSubProduct(p)}
                                className={E('sub-list-item', [
                                    isSelectedSubProduct(p) ? 'selected' : '',
                                    !p.enabled ? 'disabled' : ''
                                ])}>
                                {t(p.label)}
                                <Checkbox checked={isSelectedSubProduct(p)}/>
                            </li>
                        ))
                    }
                </ul>
            </div>

        )
    }

    const getClassName = (p: iProduct) => {
        const isCategoryKids = ageCategory?.value === AgeCategoryType.KIDS

        let disabled = true 

        if(isCategoryKids){
            //KIDS
            disabled = (p.ageCategoryId === AgeCategoryType.KIDS ||
            p.ageCategoryId === AgeCategoryType.BOTH) && p.enabled ? false : true
        }else{
            //ADULT, YOUNG ADULT
            disabled = (p.ageCategoryId === AgeCategoryType.ADULT || 
            p.ageCategoryId === AgeCategoryType.PRODUCT_YOUNG_ADULT ||
            p.ageCategoryId === AgeCategoryType.BOTH) && p.enabled ? false : true
        }

        return [isSelectedProduct(p) ? 'selected' : '', disabled ? 'disabled' : '']
    }

    const isEPROMisEnabled = () => {
        return accountDetails?.shop.eprom
    }

    const filterOnEPROM = (product: iProduct) => {
        const lensRecommendationByExperience = isSunExp() ? lensRecommendationSun : lensRecommendation
        const activePairByExperience = isSunExp() ? activePairSun : activePair
        if(!isEPROMisEnabled())return true;
        if(!isFamilyCorrect() || !product.subProducts){
            if(!lensRecommendationByExperience)return false;
            const correctCode = lensRecommendationByExperience[activePairByExperience]?.correct?.subProduct?.code;
            if(!correctCode)return false;
            const productType = productFamily?.id === LensFamilyType.PROTECT ?
                'masstint' : 'treatment';
            const available = getAvailableProduct(correctCode, productType);
            return available.includes(product.id);
        }

        const onlyUnique = (value: iSubProduct, index: number, self: any) => {
            return self.indexOf(value) === index;
        }
        const subProducts = product.subProducts.map( x => x.code);
        var unique = subProducts.filter(onlyUnique); 
        return isSubProducstAvailable(unique);  
    }

    const slideBack = () => {
        if(isSunExp() && familySun === LensFamilyType.ENHANCE){
            dispatch(lensConsultationSlice.actions.setSliderPageTwo(sliderPageTwo - 1));
            return
        }
        dispatch(lensConsultationSlice.actions.setSliderPage(sliderPage - 1));
        if(sliderPage === 1){
            setTimeout(() => {
                dispatch(lensConsultationSlice.actions.selectProductFamily(null));
            }, 250);
        }
    }

    return (
        <div className={B()}>
            <div className={E('list-wrapper')}>
                {isSunExp() && 
                    <Button
                        click={slideBack}
                        text={productBrand?.label}
                        sunBack
                        icon="left-arrow-circle-gray-sun"
                        iconPosition="left"/>}
                <ul className={E('list')}>
                    { productBrand?.products.filter(filterOnEPROM).map( product => {   
                        if(!countryBusinessRule(product.id)&& !isAlreadySelected(product.id))return(
                            <li 
                                key={product.id}                                
                                className={E('list-item', getClassName(product))}>
                                <div onClick={()=> selectProduct(product)}>
                                    {t(product.label)}
                                    {product.subProducts && <Icon name={isSunExp() ? 'down-arrow-sun' : 'down-arrow'}/>}
                                    {!product.subProducts && <Checkbox checked={isSelectedProduct(product)}/>}
                                </div>
                                {product.subProducts && renderSubProducts(product)}
                            </li>)
                        
                    })}
                </ul>
            </div>
        </div>
    )
}

export default LensConsultationProduct;