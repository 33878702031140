import React, { useEffect, useRef, useState } from 'react';
import useBEM from '../../../utils/hooks/useBEM'

interface Props {
    value: number,
    onChange: (value: number) => void,
    classname?: string
}

const ItemSlider : React.FC<Props>  = ({
    value,
    onChange,
    children,
    classname
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('item-slider');
    const [objectDimension, setObjectDimension] = useState<{ w: number, h: number }>({w:0,h:0});
    const objectRef = useRef<any>();

    // HOOKS
    useEffect(() => {
        const {width, height} = objectRef.current.getBoundingClientRect();
        setObjectDimension({w: width, h: height})
    }, [])

    // METHODS
    const onChangeHandler = (ev: any) => {
        onChange(ev.target.value);
    }

    const objectDynamicStyle = () => {
        const diff = objectDimension.w * (value / 100);
        return {
            'left': `calc(${value}% - ${diff}px)`
        }
    }

    const dynamicCSSProperty = () => {
        return {
            '--app-slider-thumb-width': `${objectDimension.w}px`,
            '--app-slider-thumb-height': `${objectDimension.h}px`
        } as React.CSSProperties
    }

    return (
        <div className={`${classname} ${B()}`} style={dynamicCSSProperty()}>
            <input
                className={E('slider')}
                type="range"
                min={0}
                max={100}
                value={value}
                onChange={onChangeHandler}
            />

            <div className={E('object')} style={objectDynamicStyle()} ref={objectRef}>
                {children}
            </div>
        </div>
    )
}

export default ItemSlider;
