import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import Text from '../../../components/ui/Text';
import { getApp } from '../../../store/slices/app.slice';
import { getProductDemo } from '../../../store/slices/products-demo.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    isOpen: boolean,
    onClose: () => void
}

const ProductDescription : React.FC<Props>  = ({
    isOpen = false,
    onClose
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('product-description');
    const { productDescription, isCompareEnabled } = useSelector(getProductDemo);
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const getModifiers = () => {
        let modifiers = [];
        if(isOpen)modifiers.push('open')
        if(isCompareEnabled)modifiers.push('compare')
        return modifiers
    }

    return (
        <div className={B( getModifiers() )}>
            <div>
                <Button text={t('lens_proposal_builder_lpb__close')} type="simple" click={onClose}/>

                <div className={E('scroll')}>
                    {
                        productDescription &&
                        productDescription.map( (desc, index) => (
                            <Text type="white" key={index}>
                                {t(desc)}
                            </Text>
                        ))  
                    }
                </div>

                {/* <Text type="white">Nam porttitor quis magna vel pharetra. Etiam vitae ipsum ex. Curabitur in quam nec magna fringilla pulvinar in non libero. Maecenas consectetur, nulla non pharetra tincidunt, neque velit volutpat ex, et tincidunt massa ipsum eget velit. Integer mauris est, pharetra nec erat vel, aliquam dapibus dui. Maecenas at metus turpis. </Text>
                <Text type="white">Maecenas consectetur, nulla non pharetra tincidunt, neque velit volutpat ex, et tincidunt massa ipsum eget velit. Integer mauris est, pharetra nec erat vel, aliquam dapibus dui. Maecenas at metus turpis.</Text> */}
            </div>
        </div>
    )
}

export default ProductDescription;