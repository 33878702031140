import React, { useEffect, useState } from 'react';
import { controlOptions } from '../../../constants/education.constants';
import useBEM from '../../../utils/hooks/useBEM';
import Dropdown from '../../ui/Dropdown';
import ToggleRadio from '../../ui/ToggleRadio';
import Slider from 'react-input-slider';
import ToggleButton from '../../ui/ToggleButton';
import { useSelector, useDispatch } from 'react-redux';
import educationSlice, { getEducation } from '../../../store/slices/education.slice';
import EnvironmentType from '../../../types/environment.type';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';


interface Props {
    isOpen: boolean
}

const EducationControls : React.FC<Props>  = ({
    isOpen = true
}) =>  {
    // VARIABLES
    const {
        isItemEnabled,
        isLensEnabled,
        environment
    } = useSelector(getEducation);
    const dispatch = useDispatch();

    const [B, E] = useBEM('education-controls');
    const [visualDefect1, setVisualDefect1] = useState<string>("");
    const [visualDefect2, setVisualDefect2] = useState<string>("");

    const [item, setItem] = useState<string>();
    const [lensSlider, setLensSlider] = useState(-10);
    const [screenMode, setScreenMode] = useState('');
    const { t } = useTranslation(useSelector(getApp));
    const sliderStyle = {
        track: {
            backgroundColor: "#CFD3D7"
        },
        active: {
            backgroundColor: "transparent",
            borderRadius: "1em"
        },
        thumb: {
            width: 20,
            height: 20,
            opacity: 1
        }
    }
    const lensStyle: any = {
        '-10': '--concave',
        '10': '--convex'
    }

    // HOOKS
    useEffect(() => {
        setVisualDefect2("");
    }, [visualDefect1])

    useEffect(() => {
        setLensSlider(-10);
    }, [isLensEnabled])

    useEffect(() => {
        setItem(undefined);
    }, [isItemEnabled])

    useEffect(() => {
        selectVisualDefect1(visualDefect1)
    }, [visualDefect1])

    useEffect(() => {
        selectVisualDefect2(visualDefect2)
    }, [visualDefect2])

    // METHODS
    const getVisualDefect2Options = () => {
        const key = visualDefect1 ? visualDefect1 : t('education_edu_none_new');
        const options = controlOptions.visualDefect2[key as 'myopia'];
        return options;
    }

    const toggleItem = (value: boolean) => {
        dispatch(educationSlice.actions.toggleItemControl());
    }

    const toggleLens = (value: boolean) => {
        dispatch(educationSlice.actions.toggleLensControl());
    }

    const setEnvironment = (env: string) => {
        dispatch(educationSlice.actions.setEnvironment(env as EnvironmentType))
    }

    const selectVisualDefect1 = (value: string) => {
        dispatch(educationSlice.actions.selectVisualDefect1(value))
    }

    const selectVisualDefect2 = (value: string) => {
        dispatch(educationSlice.actions.selectVisualDefect2(value))
    }

    const onChangeLensSlider = (values: { x: number, y: number}) => {
        setLensSlider(values.x)
        dispatch(educationSlice.actions.setLensVariant(lensStyle[String(values.x)]))
    }

    const onSelectItem = (value: string) => {
        setItem(value)
        dispatch(educationSlice.actions.selectItem(value))
    }

    return (
        <div className={B(!isOpen ? 'collapsed' : '')}>

            <div className={E('control')}>
                <label>{t('education_edu_visual_defect_1')}</label>

                <Dropdown
                    onSelect={(value) => {
                        setVisualDefect1(value)
                        selectVisualDefect1(value)
                    }}
                    value={visualDefect1}
                    options={controlOptions.visualDefect1}
                />

            </div>
            <div className={E('control')}>
                <label>{t('education_edu_visual_defect_2')}</label>

                <Dropdown
                    onSelect={ value => {
                        setVisualDefect2(value)
                        selectVisualDefect2(value)
                    }}
                    value={visualDefect2}
                    options={getVisualDefect2Options()}/>
            </div>
            <div className={E('control')}>
                <label className={!isItemEnabled ? 'temp-no-padding-bottom' : ''}>
                    {t('education_edu_item')}
                    <ToggleRadio on={isItemEnabled} onChange={ v => toggleItem(v)}/>

                </label>

                {
                    isItemEnabled  &&
                    <Dropdown
                        value={item}
                        onSelect={ v => onSelectItem(v)}
                        options={controlOptions.item}/>
                }

            </div>
            <div className={E('control')}>
                <label className={!isLensEnabled ? 'temp-no-padding-bottom' : ''}>
                    {t('education_edu_lens')}
                    <ToggleRadio on={isLensEnabled} onChange={ v => toggleLens(v)}/>
                </label>
                {
                    isLensEnabled &&
                    <div className={E('control-lens-slider')}>
                        <label>Puissance correction</label>
                        <div>
                            <span>-10</span>
                                <Slider
                                    axis="x"
                                    xstep={10}
                                    xmin={-10}
                                    xmax={10}
                                    x={lensSlider}
                                    styles={sliderStyle}
                                    onChange={(value) => onChangeLensSlider(value)}
                                />
                            <span>+10</span>
                        </div>
                    </div>
                }

            </div>
            <div className={E('control')}>
                <label>{t('education_edu_environment_new')} </label>

                <ToggleButton
                    value={environment}
                    onChange={ v => setEnvironment(v) }
                    options={controlOptions.environment}/>
            </div>
            <div className={E('control')}>
                <label>{t('education_edu_environment_new')}</label>

                <ToggleButton
                    value={screenMode}
                    onChange={ v => setScreenMode(v)}
                    options={controlOptions.screenMode}/>
            </div>

        </div>
    )
}

export default EducationControls;
