import routes from "./routes.constant";

const breadcrumbs = {
    home: [
    ],
    myCompanion: [        
        routes.myCompanion
    ],
    practiceID: [        
        routes.practiceID
    ],
    legalNotices: [        
        routes.legalNotices
    ],
    lensProposalBuilder: [        
        routes.lensProposalBuilder
    ],
    needExplorer: [        
        routes.lensProposalBuilder,
        routes.needExplorer
    ],
    prescription: [        
        routes.lensProposalBuilder,
        routes.prescription
    ],
    measures: [        
        routes.lensProposalBuilder,
        routes.measures
    ],
    lensConsultation: [        
        routes.lensProposalBuilder,
        routes.lensConsultation
    ],
    lensThickness: [        
        routes.lensProposalBuilder,
        routes.lensConsultation,
        routes.lensThickness
    ],
    summary: [        
        routes.lensProposalBuilder,
        routes.lensConsultation,
        // routes.lensThickness,
        routes.summary
    ],
    packageOffer: [        
        routes.lensProposalBuilder,
        routes.lensConsultation,
        routes.packageOffer
    ],
    delivery: [        
        routes.lensProposalBuilder,
        routes.delivery
    ],
    demonstration: [        
        routes.lensProposalBuilder,
        routes.lensConsultation,
        routes.demonstration
    ],
    demonstration2: [        
        routes.lensProposalBuilder,
        routes.lensConsultation,
        routes.packageOffer,
        routes.demonstration
    ],
    emat: [
        routes.emat
    ],
    whatsNew: [
        routes.whatsNew
    ],
    licenseActivation: [

    ],
    discovery: [
        routes.discovery
    ],
    //temp
    advanceSetting:[
        routes.myCompanion,
        routes.advanceSetting
    ],
    advanceSettingTest:[
        routes.myCompanion,
        routes.advanceSetting
    ]
}

export default breadcrumbs;