import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Arrow from '../../../components/ui/Arrow';
import iSubProduct from '../../../interface/sub-product.inteface';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import useLensConsultation from '../useLensConsultation';

interface Props {
    options: iSubProduct[],
    selected?: iSubProduct,
    onSelect?: (option: iSubProduct) => void
}

const SubProductsDropdown : React.FC<Props>  = ({
    options,
    selected,
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('sub-products-dropdown');
    const [showOptions, setShowOptions ] = useState(false);
    const { getUniqueSubProducts } = useLensConsultation();
    const {t} = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const selectOption = (option: iSubProduct) => {
        if(onSelect)onSelect(option);
    }

    const focusHandler = () => {
        toggleOptions(showOptions ? false : true);        
    }

    const blurHandler = () => {
        toggleOptions(false)
    }

    const toggleOptions = (show: boolean) => {   
        const delay = show ? 0 : 200;
        setTimeout(() => {
            setShowOptions(show)
        }, delay);
    }

    const renderOptions = (choices: iSubProduct[]) => {
        const uniqueSubProducts = getUniqueSubProducts(choices, {
            isLowestIndex: true
        });

        return <ul className={E('options')}>
            { uniqueSubProducts.map((subProduct: iSubProduct) => (
                        <li key={subProduct.id} 
                            className={E('option', !subProduct.enabled ? 'disabled' : '')}
                            onClick={() => selectOption(subProduct)}    
                        >{subProduct.label}</li>
                    )
            ) }
        </ul>
    }
    
    return (
        <div className={B()}>
            <button             
                onBlur={blurHandler}
                onClick={focusHandler}
                className={E('input' , selected ? 'with-value' : '')}>

                {selected ? selected.label : t('lens_consultation_please_select_product')}

                <Arrow type="down"/>
            </button>
            
            {
                showOptions && 
                <div className={E('options-wrapper')}>
                    {renderOptions(options)}
                </div>
            }
            

        </div>
    )
}

export default SubProductsDropdown;