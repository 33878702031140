import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBEM from '../../../../utils/hooks/useBEM';
import ProtectSelectionStep from './ProtectSelectionStep';
import iSunExperienceSolution from '../../../../interface/sun-experience-solution.interface';
import useGoto from '../../../../utils/hooks/useGoto';
import routes from '../../../../constants/routes.constant';
import TintSelectionStep from './TintSelectionStep';
import iSunExperienceSolutionColor from '../../../../interface/sun-experience-solution-color.interface';
import FrameView from './FrameView';
import Button from '../../../../components/ui/Button';
import ExperienceType from '../../../../types/experience.type';
import CircleArrow from '../../../../components/ui/CircleArrow';
import BlinkEye from '../../../../components/bink-eye';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { getApp } from '../../../../store/slices/app.slice';
import lensConsultationSun, { getLensConsultationSun } from "../../../../store/slices/lens-consultation-sun-exp.slice"
import productsSlice, { getProducts } from '../../../../store/slices/products.slice';
import LensFamilyType from '../../../../types/lens-family.type';
import iProductBrand from '../../../../interface/product-brand.interface';
import iProduct from '../../../../interface/product.inteface';
import iProductDemo from '../../../../interface/product-demo';
import lensConsultationSlice, { getLensConsultation } from '../../../../store/slices/lens-consultation.slice';
import iLensColor from '../../../../interface/lens-color.inteface';
import LensPairType from '../../../../types/lens-pair.type';
import PROTECT_LENS_TYPE from "../../../../constants/lens-consultation-type-protect.constant"
import iProductFamily from '../../../../interface/product-family.interface';
import { getNeedExplorer } from '../../../../store/slices/need-explorer.slice';
import Icon from '../../../../components/ui/Icon';
import needExplorerSliceSun, { getNeedExplorerSun } from '../../../../store/slices/need-explorer-sun.slice';
import LensFamilySun from './LensFamilySun';
import SubProductsDropdown from '../../../lens-consultation/components/SubProductsDropdown';
import iSubProduct from '../../../../interface/sub-product.inteface';
import SlideView from '../../../../components/SlideView';
import LensConsultationBrand from '../../../lens-consultation/components/LensConsultationBrand';
import LensConsultationProduct from '../../../lens-consultation/components/LensConsultationProduct'
import productDemoSlice from '../../../../store/slices/products-demo.slice';
import iLensRecommendationPair from '../../../../interface/lens-recommendaiton-pair.interface';
import { evaluateSunExperience} from '../../../../utils/misc';
import { getPrescription } from '../../../../store/slices/prescription.slice';
import userSlice from '../../../../store/slices/user.slice';


interface Props {
    fadeOut?: boolean,
    onSave: () => void
}



const LensConsultationSideSun: React.FC<Props> = ({
    fadeOut = false,
    onSave
}) => {
    // VARIABLES
    const [B, E] = useBEM('lens-consultation-side-sun');
    const { temporaryProductList, brandToDemo, productToDemo } = useSelector(getProducts);
    const { productFamily, productBrand} = useSelector(getLensConsultation); 
    const currentNeedExplorerData = useSelector(getNeedExplorerSun);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const goto = useGoto();
    const { t } = useTranslation(useSelector(getApp));
    const { experience, accountDetails } = useSelector(getApp);
    const { questions } =(useSelector(getNeedExplorerSun));
    const { alreadyKnow, solution, tint, activePairSun, lensRecommendationSun, validOnEPROM, tooltipOpen, sunExpProducts } = useSelector(getLensConsultationSun);
    const {lensRecommendation, } = useSelector(getLensConsultation);
    const { sliderPage, sliderPageTwo} = useSelector(getLensConsultation);
    const [enableTint, setTint] = useState(false);
    const [reload, setReload] = useState(true);
    const { tempPrescriptionData, isValidPrescription } = useSelector(getPrescription);

    const [recommendationToDemo, setRecommendationToDemo] = useState<{
        correct?: iProduct,
        protect?: iProduct,
        enhance?: iProduct,
    }>();
    const [recommendationToDemoBrands, setRecommendationToDemoBrands] = useState<{
        correct?: iProductBrand,
        protect?: iProductBrand,
        enhance?: iProductBrand,
    }>();
    let throttle: any;
    const isSunExp = () =>{
        return experience === ExperienceType.SUN
    }
    const lensRecommendationByExperience = isSunExp() ? lensRecommendationSun : lensRecommendation
    const currentLensConsultationSun = useSelector(getLensConsultationSun);

    const {isComplete} = questions[0]

    //METHOD
    useEffect(() => {
        const selectedTint = productToDemo ? productToDemo.colors?.find((p: iProductDemo) => p.label === solution?.sun_experience?.translationCode) : undefined;
        if (selectedTint) {
            enableTint && dispatch(lensConsultationSlice.actions.setProtectColor({
                color: selectedTint as iLensColor,
                index: 0
            }))
        }
    }, [tint, solution, productToDemo])

    useEffect(() => {
        dispatch(lensConsultationSlice.actions.setSliderPage(0));
        // if(isComplete && alreadyKnow === 'yes')return setStep(2)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', windowOnresize);
        return () => {
            window.removeEventListener('resize', windowOnresize);
        }
    }, [])

    useEffect(() => {
        const selectedTint = productToDemo ? productToDemo.colors?.find((p: iProductDemo) => p.label === solution?.sun_experience?.translationCode) : undefined;
        if (selectedTint) {
            enableTint && dispatch(lensConsultationSlice.actions.setProtectColor({
                color: selectedTint as iLensColor,
                index: 0
            }))
        }
    }, [tint, solution, productToDemo])

    useEffect(() => {
        if (tint && solution) {
            onSaveHandler()
        }
    }, [])

    useEffect(() => {
        if (!lensRecommendationSun) return;
        const pair: iLensRecommendationPair = lensRecommendationSun[activePairSun] as iLensRecommendationPair;
        if (!pair) return;
        const withDemo = (product?: iProduct) => {
            if (!product ||
                !product.demo ||
                product.demo.length < 1
            ) return undefined;
            return product;
        }

        const recommendation = {
            correct: withDemo(pair.correct?.product),
            protect: pair.protect && pair.protect.length > 0 ? withDemo(pair.protect[0].product) : undefined,
            enhance: withDemo(pair.enhance?.product),
        }

        const recommendationBrands = {
            correct: pair?.correct?.brand || undefined,
            protect: pair.protect && pair.protect.length > 0 ? pair.protect[0].brand : undefined,
            enhance: pair?.enhance?.brand || undefined
        }
        setRecommendationToDemoBrands(recommendationBrands);
        setRecommendationToDemo(recommendation);
    }, [lensRecommendationSun, activePairSun])

    const windowOnresize = () => {
        if(throttle)clearTimeout(throttle);
        throttle = setTimeout(() => {
            setReload(false);
            setTimeout(() => setReload(true), 100)
        }, 250);
    }

    const setBrandDemo = () => {
        const brandId = 'xperio';
        const brand = temporaryProductList
            .find((f: any) => f.id === LensFamilyType.PROTECT).brands
            .find((b: iProductBrand) => b.id === brandId);
        dispatch(productsSlice.actions.selectBrandTodemo(brand))
    }
    const setProductToDemo = (solutionId: string) => {
        let product: any = PROTECT_LENS_TYPE.find((p: any) => p.label === solutionId).value
        const subProduct = brandToDemo ? brandToDemo.products.find((p: iProduct) => p.id === product) : null;
        dispatch(productsSlice.actions.selectProductTodemo(subProduct));
        if (subProduct) {
            dispatch(lensConsultationSlice.actions.selectProduct({
                lensFamily: LensFamilyType.PROTECT,
                brand: brandToDemo,
                product: subProduct,
                manual: false,
                pair: LensPairType.PAIR1,
            }));
        }
    }

    const onAnswerHandler = (alreadyKnow: string, solution?: iSunExperienceSolution, tint?: iSunExperienceSolutionColor) => {
        dispatch(needExplorerSliceSun.actions.resetQuestionStep())
        if (!solution) {
            dispatch(lensConsultationSun.actions.setAlreadyKnow({ alreadyKnow, solution }))
            return;
        } else {
            setTint(true)
            setBrandDemo();
            dispatch(lensConsultationSun.actions.setSolution({ solution, tint }))
        }
        // setProductToDemo(solution.id)
    }

    const onSelectTint = (solution: iSunExperienceSolution, tint: iSunExperienceSolutionColor) => {
        setBrandDemo();
        dispatch(lensConsultationSun.actions.setSolution({ solution, tint }))
        const sunProduct = sunExpProducts.filter((product:any) => product.id === solution.id)[0]
        const brand = temporaryProductList.filter((family:any) => { return family.id === LensFamilyType.PROTECT})[0].brands
        const sunProductBrand = brand.find((prod:any) => {
            return prod.products.find((prod:any) => prod.id === solution.id)
        })

        if(lensRecommendationSun?.pair1?.protect){
            dispatch(lensConsultationSun.actions.removeProduct({
                lensFamily: 'protect' as LensFamilyType,
                index: 'protect' === LensFamilyType.PROTECT ? 0 : undefined
            }));
        }
        dispatch(lensConsultationSun.actions.selectProduct({
            lensFamily: 'protect' as LensFamilyType,
            brand: sunProductBrand,
            product: sunProduct,
        })); 
        // setProductToDemo(solution.id)
        setTint(true)
    }

    const onSaveHandler = () => {
        if (alreadyKnow === 'no') {
            goto.route(routes.needExplorer);
            return;
        }
    }

    const setStepHandler = (solutionId:string) => {
        if(solutionId)setStep(2)
        return
    }

    const isAlreadyKnow = () => {
        if(alreadyKnow ==='no'){
            return isComplete
        }else return alreadyKnow
    }

    const validateProduct = (p: any) => {
        return p && p.id !== undefined ? true : false;
    }

    const getRecommendedValue = (type: LensFamilyType, all = false) => {
        if (!lensRecommendationSun) return;
        const pair = lensRecommendationSun[activePairSun];
        const pairType: any = pair ? pair[type] : null;

        if (!pairType) return null;

        if (type === LensFamilyType.PROTECT) {
            if (pairType && (pairType as any[]).length > 0) {
                return pairType;
            }
            return null;
        }
        if (all) return pairType;
        return pairType.subProduct ? pairType.subProduct : pairType.product;
    }
    const selectSubProduct = (brand: iProductBrand, product: iProduct, subProduct: iSubProduct) => {
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            brand,
            product,
            subProduct: subProduct
        }));
    }

    const renderExtraComponent = (family: LensFamilyType, index: number, p: any) => {
        const recommendedValue = getRecommendedValue(family, true);
        if (!recommendedValue) return null;
        if (!validateProduct(p)) return null;

        return null;
    }
    const filterFamily = (id:string) =>{
        return temporaryProductList.filter((family:any) => family.id === id)[0]
    }
    const eventHander = (event: 'select' | 'delete', family: iProductFamily, index: number) => {
        if (event === 'select') {
            if(family.id === 'correct'){
                dispatch(lensConsultationSlice.actions.setSliderPage(1))
            }else{
                dispatch(lensConsultationSlice.actions.setSliderPageTwo(1));
            }
            dispatch(lensConsultationSlice.actions.selectProductFamily(family));
            return;
        }
        if (event === 'delete') {
            dispatch(lensConsultationSlice.actions.removeProduct({
                lensFamily: family.id,
                index: family.id === LensFamilyType.PROTECT ? index : undefined
            }));
        }
    }

    const isWithDemo = (family: any) => {
        if (Array.isArray(family)) {
            if (family && family.length > 0 && family[0].product && (family[0].product.demo || []).length > 0) return true;
            return false;
        }
        if (family && family.product && (family.product.demo || []).length > 0) return true;
        return false;
    }

    const getInitialDemo = () => {
        if (!lensRecommendationSun) return;
        const pair: iLensRecommendationPair = lensRecommendationSun[activePairSun] as iLensRecommendationPair;
        const { correct, protect, enhance } = pair;
        if (isWithDemo(correct)) return { family: LensFamilyType.CORRECT, data: correct };
        if (isWithDemo(protect)) return { family: LensFamilyType.PROTECT, data: protect[0] };
        if (isWithDemo(enhance)) return { family: LensFamilyType.ENHANCE, data: enhance };
    }
    const demoProducts = () => {
        const initialDemo: any = getInitialDemo();
        if (!initialDemo) return;
        dispatch(productsSlice.actions.selectBrandTodemo(initialDemo.data.brand))
        dispatch(productsSlice.actions.selectProductTodemo(initialDemo.data.product))
        if (!recommendationToDemo || !recommendationToDemoBrands) return;
        dispatch(productDemoSlice.actions.setRecommendationDemo(recommendationToDemo));
        dispatch(productDemoSlice.actions.selectLensFamily(initialDemo.family));
        dispatch(productDemoSlice.actions.setDemoFrom(routes.lensConsultation));
        dispatch(productDemoSlice.actions.setRecommendationDemoBrands(recommendationToDemoBrands));
        goto.route(routes.demonstration);     
    }
    const withCorrect = () => {
        if (!lensRecommendationSun) return false;
        return lensRecommendationSun[activePairSun]?.correct ? true : false;
    }

    const withValue = (family:string) => {
        if (!lensRecommendationSun) return false;
        if(family === 'correct')return lensRecommendationSun[activePairSun]?.correct?.subProduct?.label
        else return lensRecommendationSun[activePairSun]?.enhance?.product?.label
    }

    const isActivePairWithCorrectSubProduct = (pair?: LensPairType) => {

        if (!lensRecommendationByExperience) return false;
        const pair1Correct = lensRecommendationByExperience.pair1?.correct;
        const pair2 = lensRecommendationByExperience.pair2;

        if (!pair1Correct || !pair1Correct.product || !pair1Correct?.subProduct)
            return false;
        if ((pair2?.correct || (pair2?.protect || []).length > 0 || pair2?.enhance)
            && (!pair2?.correct?.product || !pair2?.correct?.subProduct))
            return false;
        if (pair === LensPairType.PAIR2 && !pair2?.correct) return false;
        return true;
    }
    const disableNextButton = () => {
        if (!lensRecommendationByExperience) return true;
        if (lensRecommendationByExperience.pair1?.correct === null) return true;


        if (accountDetails?.shop.eprom && (
            validOnEPROM?.pair1 === false ||
            validOnEPROM?.pair2 === false
        )) return true;
        
        //eprom belgium new business rule
        const productsBelgiumSunX = [
            'transitions_xtractive',
            'transitions_xtractive_polarised',
            'xperio_polarised',
            'xperio_tinted',
            'xperio_mirrors',
            'xperio_tinted_mirrors',
            'xperio_polarised_mirrors',
            'style_mirrors']

        if(lensRecommendationByExperience){
            const protect = lensRecommendationByExperience[activePairSun]?.protect
            const enhance = lensRecommendationByExperience[activePairSun]?.enhance
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id &&
            enhance && 
            enhance?.product?.id
            ){
                if(enhance?.product?.id === 'crizal_sun_xprotect' && !productsBelgiumSunX.includes(protect[0]?.product?.id))return true
            }
            
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            !protect[0] &&
            enhance && 
            enhance?.product?.id &&
            enhance?.product?.id === 'crizal_sun_xprotect'
            )return true
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id === 'xperio_mirrors' && 
            !protect[1]
            )return true
        }



        // Lens Thickness
        if (sliderPage === 3){
            return !isValidPrescription;
        }
        return !isActivePairWithCorrectSubProduct(activePairSun);
    }


    return (
        <div className={B(fadeOut ? 'fadeout' : '')}>
            {
                step === 1 && 
                <ProtectSelectionStep
                    alreadyKnow={alreadyKnow}
                    solutionId={solution?.id}
                    onAnswer={onAnswerHandler}
                    onSave={onSaveHandler}
                    setStep={(x:any) => setStepHandler(x)}
                />
            }
            {
                step === 2 && isAlreadyKnow() &&
                <>
                    {reload && 
                        <SlideView id="lensConsultation" numberOfSlides={3} currentSlide={sliderPage}>            
                            <LensFamilySun
                                extraComponent={renderExtraComponent(filterFamily('correct').id, 0, filterFamily('correct'))}
                                onEvent={(event, family) => eventHander('select', family, 0)}
                                family={filterFamily('correct')}
                                value={withValue('correct')}
                            /> 
                            <LensConsultationBrand/>
                            <LensConsultationProduct/>
                        </SlideView>}
                        <TintSelectionStep
                            onSaveAndNext={onSave}
                            solution={solution}
                            value={tint}
                            onBack={(stp) => setStep(1)}
                            onSelect={onSelectTint}
                        />
                        {reload && sliderPage < 3 &&
                        <SlideView id="lensConsultationTwo" numberOfSlides={3} currentSlide={sliderPageTwo}>            
                            <LensFamilySun
                                extraComponent={renderExtraComponent(filterFamily('enhance').id, 0, filterFamily('enhance'))}
                                onEvent={(event, family) => eventHander('select', family, 0)}
                                family={filterFamily('enhance')}
                                value={withValue('enhance')}
                                disabled={!withCorrect()}
                            /> 
                            <LensConsultationBrand/>
                            <LensConsultationProduct/>
                        </SlideView>}
                        { !tooltipOpen && sliderPage < 3 &&
                            <div className={E('buttons')}>
                                <Button 
                                    experience={ExperienceType.SUN}
                                    text={t('lens_consultation_lc_demo')} 
                                    disabled={false} 
                                    icon={<BlinkEye colored={true}/>} 
                                    type="secondary" 
                                click={() => {demoProducts()}}/>

                                <Button
                                    experience={ExperienceType.SUN}
                                    disabled={disableNextButton()}
                                    text={t('lens_consultation_lc_save_&_next')}
                                    icon={  
                                        <CircleArrow
                                            type='right'  
                                            size='1rem'
                                        />
                                    }
                                    type="primary"
                                    outlined={false}
                                    click={onSave}/>
                            </div> }
                </>
            }
        </div>
    )
}

export default LensConsultationSideSun;