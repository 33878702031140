import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import GenericToolTip from '../../../components/ui/GenericToolTip';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import ExperienceType from '../../../types/experience.type';
import LensCategoryType from '../../../types/lens-category.type';
import LensProposalEquipmentType from '../../../types/lens-proposal-section.type';
import PrescriptionViewType from '../../../types/prescription-view.type';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import LensFamilyType from '../../../types/lens-family.type';
import iProductBrand from '../../../interface/product-brand.interface';
import useLensConsultation from '../../lens-consultation/useLensConsultation';
import iSubProduct from '../../../interface/sub-product.inteface';
import LensPairType from '../../../types/lens-pair.type';
import iProduct from '../../../interface/product.inteface';
import { TEMPORARY_PRODUCT_LIST } from '../../../constants/products.constants';
import lensConsultationSun from '../../../store/slices/lens-consultation-sun-exp.slice';


interface Props {

}

const PrescriptionLensProposalSun : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription-lens-proposal-sun');
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const dispatch = useDispatch();
    const { temporaryProductList} = useSelector(getProducts);
    const [activeTooltip, setActiveTooltip] = useState('');
    const [selectedDesign, setSelectedDesign] = useState<LensCategoryType>();
    const {
        tempGeneralEquipment,
        tempPrescriptionData,
    } = useSelector(getPrescription);
    const tempList:any[] = temporaryProductList;
    const designs: any[] = [
        {
            brand: "eyezen",
            id: "eyezen_sunfit",
            value: LensCategoryType.SINGLE_VISION,
            label: 'prescription_rx_single_vision',
            description:  `Eyezen${'\u00AE'} SunFit${'\u2122'}`,
            tooltip: 'sun_exp_rx_vision_need_why_choose_design',
            image: 'sun_xp_single-vision'
        },
        {
            brand: "varilux",
            id: "varilux_sunmax",
            value: LensCategoryType.PROGRESSIVE,
            label: 'prescription_rx_progressive',
            description: `Varilux${'\u00AE'} SunMax${'\u2122'}`,
            tooltip: 'sun_exp_rx_vision_need_why_choose_design',
            image: 'sun_xp_progressive-vision'
        }
    ];

    useEffect(() => {
        preSelectDesign()
    }, [tempPrescriptionData])

    // METHODS

    const preSelectDesign = () => {
        const preselect = tempPrescriptionData.values.addition ?
        LensCategoryType.PROGRESSIVE:
        LensCategoryType.SINGLE_VISION;

        setSelectedDesign(preselect);
    }

    const back = () => {
        dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTION));
    }

    const onSelectDesignHandler = (lensType: LensCategoryType , id: any ,brandId: any) => {
        setActiveTooltip(lensType)
        setTimeout(() => setActiveTooltip(''), 7000)

        setSelectedDesign(lensType);
        const { label, value } = designs.find( design => design.value === lensType);
        const newState = tempGeneralEquipment.map( equipment => {
            if(equipment.field === 'correct'){
                return { ...equipment, value: { label, value }  }
            }
            return equipment;
        })
        //temporary used products.constant.ts
                const brand = tempList
                .find( (f:any) => f.id === LensFamilyType.CORRECT).brands
                .find((b: iProductBrand) => b.id === brandId);
    
            const product = brand.products.find((p: iProduct) => p.id === id);
            dispatch(lensConsultationSun.actions.selectProduct({ 
                lensFamily: LensFamilyType.CORRECT,
                brand,
                product,
                subProduct: product?.subProducts[0] as iSubProduct,
                manual: true,
                pair: LensPairType.PAIR1
            }));

        dispatch(prescriptionSlice.actions.updateLensEquipment({
            data: newState,
            equipment: LensProposalEquipmentType.GENERAL
        }));
    }

    const onCLickInfo = (lensType: LensCategoryType) => {
        dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTIONLENS))
        dispatch(prescriptionSlice.actions.setLensType(lensType))
    }

    const onClickSunwear = () => {
        dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.SUNWEAR))
    }

    return (
        <div className={B()}>

            <Button
                click={back}
                text={t('demo_demo__back')}
                icon={
                    <CircleArrow
                        type='left'
                        background='#eee'
                    />
                }
                iconPosition="left"
                type="secondary"
                experience={ExperienceType.SUN}
            />

            <div className={E('content')}>

                <div className={E('text')}>
                    <label>
                        {t('sun_exp_vision_need_design_choice', 'Design Choice')}
                    </label>
                    <h4>
                        {t('sun_exp_vision_need_exp_great_vision', 'Experience great vision')}
                    </h4>
                </div>

                <div className={E('choice')}>
                    {
                        designs.map( design => (
                            <div
                                className={E('choice-item', selectedDesign === design.value ? 'selected' : '')}
                                onClick={() => onSelectDesignHandler(design.value , design.id, design.brand)}
                            >
                                <Icon
                                    className={E('choice-item-bg')}
                                    type="image"
                                    extension='png'
                                    name={design.image}
                                    background={true}
                                />

                                <div className={E('choice-info')}>
                                    <label>
                                        {t(design.label)}
                                    </label>

                                    <input
                                        className={E('tooltip-toggle')} type='text' value="i" readOnly
                                        onFocus={() => setActiveTooltip(design.value)}
                                        onBlur={() => setActiveTooltip('')}
                                        onClick={() => onCLickInfo(design.value)}
                                    />

                                    <p>{design.description}</p>
                                </div>


                                <GenericToolTip
                                    position='bottom-left'
                                    show={activeTooltip === design.value}
                                    backgroundColor={['#f0a954','#f95162']}
                                    onClick={() => onClickSunwear()}
                                >
                                    <div className={E('tip')}>
                                        <label>
                                            {t(design.tooltip, "Why choosing a design optimized for sunwear?")}
                                        </label>

                                        <CircleArrow
                                            type='right'
                                            diameter={1.5}
                                            size='.75rem'
                                            color='#fff'
                                            background='#ffffff40'
                                        />
                                    </div>
                                </GenericToolTip>

                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

export default PrescriptionLensProposalSun;
