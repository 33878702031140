import React, { useEffect, useRef, useState } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import { CircularInput, CircularProgress, CircularThumb, CircularTrack, useCircularDrag, useCircularInputContext } from 'react-circular-input';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: number) => void
}

const Dial : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const { t } =  useTranslation(useSelector(getApp));
    // const [answer, setAnswer] = useState((question.answer as number)/10); 
    const min = 0.0099;
    const max = 0.96;
    const answer = question.answer as number;
    const [value, setValue] = useState(answer/10); 
    const [count, setCount] = useState(0);
    const prevValue = usePreviousValue(value) || 0;
    const prevCount = usePreviousCount(count) || 0;
    // console.log('QA ',question.answer)
    function usePreviousValue(value:any) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }
  
    function usePreviousCount(value:any) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }
  

    //hooks
    useEffect(() => {
      if(answer >= 1 && count === 0) setCount(1);
    }, [answer, count]);

    useEffect(() => {
      if(onAnswer)onAnswer(returnWhole(value));
    }, [value]);
    
    // METHODS
    const returnWhole = (whole: any) =>{
      return Math.ceil(Math.floor(whole*100)/10)
    }
    const valueWithinLimits = (value:any) => {
      const whole = returnWhole(value);
      const prev = returnWhole(prevValue);
      const minMax = Math.min(Math.max(value, min), max);
      if( prev >= 10 && whole <= 5 && count >= 1) {
        return max
      } 
      if( prev <= 10 && whole <= 5 && whole !== 0 && count === 0) {
        setCount(count+1)
        // return min 
      }
      if( prevCount !== 0 && whole === 0  && count === 1) {
        setCount(0)
      }
      
      if (prev <= 1 && whole >= 5 && (prevCount === 0 || prevCount === undefined) && prevValue !== 0){
        return min
      }
      
      
      return minMax
    };
    const dialChanged = (data: number) => {
        const whole = returnWhole(data)
        console.log('DIAL CHANGE', whole, data)
        if(onAnswer)onAnswer(whole);
    }

    return (
        <div className="dial">
            <CircularInput
                value={valueWithinLimits(value)}
                radius={115}
                
                onChange={v => setValue(valueWithinLimits(v))}
                min={question.dial?.min}
                max={question.dial?.max}
                // onChangeEnd={(data:number) => { dialChanged(data); }}
            >
                    <CircularTrack strokeWidth={20} stroke="rgb(242,249,253)">
                        
                    </CircularTrack>
                    <CircularProgress strokeWidth={20} stroke="#0386D6"
                    className="addBackground"/>
			        <CircularThumb r={14} fill="white"
                    className="addBackground" />
                    {/* <CustomThumb/> */}
                    <text textAnchor="middle" x="116" y="120" 
                    style={{ fontSize: "4.5rem", fontWeight: "bold" }}
                    fill="#0386D6"
                    >
                            {Math.ceil(Math.floor(value*100)/10)}
                    </text>
                    <br></br>
                    <text textAnchor="middle" x="116" y="150"
                    style={{ fontSize: "1.25rem", fontWeight: "bold" }}
                    fill="#0386D6"
                    >
                        {t('need_explorer_hr_per_day')}                            
                    </text>
            </CircularInput>
        </div>
    )
}

export default Dial;

