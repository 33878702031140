import iLensDemo from "../../interface/lens-demo";
import ProductDemoType from "../../types/product-demo.type";
import Utils from "../../utils/Utils";
const { lensDemoURL } = Utils();


const sellout = [
  'demo_new_demo_xpol_l1',
  'demo_new_demo_xpol_l2',
  'demo_new_demo_xpol_l3',
  'demo_new_demo_xpol_l4',
  'demo_new_demo_xpol_l5',
  'demo_new_demo_xpol_l6',
  'demo_new_demo_xpol_l7',
  'demo_new_demo_xpol_l8',
  'demo_new_demo_xpol_l9',
  'demo_new_demo_xpol_l10'
]

const packshotPolarized: iLensDemo = {
    demo: 'packshot-polarized',
    demoType: ProductDemoType.SLIDER_IMAGE,
    products: [
      {
        id: 'xperio_polarised',
        label: "lens_propo_colors_amber",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_amber.jpg'),
        description: sellout,
        colorID: 'lensPolarizedAmber'
      },
      {
        id: 'standard',
        label: 'lens_propo_colors_blue_ink',
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_blue ink.jpg'),
        description: sellout,
        colorID: 'lensPolarizedBlueInk'
      },
      {
        id: 'standard1',
        label: "lens_propo_colors_violet",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_violet.jpg'),
        description: sellout,
        colorID: 'lensPolarizedViolet'
      },          
      {
        id: 'standard2',
        label: 'lens_propo_colors_blue_sky',
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_sky.jpg'),
        description: sellout,
        colorID: 'lensPolarizedBlueSky'
      },
      {
        id: 'standard3',
        label: "lens_propo_colors_herbal",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_herbal.jpg'),
        description: sellout,
        colorID: 'lensPolarizedHerbal'
      },
      {
        id: 'standard4',
        label: "lens_propo_colors_grey",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey.jpg'),
        description: sellout,
        colorID: 'lensPolarizedGrey'
      },
      {
        id: 'standard5',
        label: "lens_propo_colors_grey_green",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_grey green.jpg'),
        description: sellout,
        colorID: 'lensPolarizedGreyGreen'
      },
      {
        id: 'standard6',
        label: "lens_propo_colors_brown",
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_brown.jpg'),
        description: sellout,
        colorID:'lensPolarizedBrown'
      },
      {
        id: 'standard7',
        label: 'lens_propo_colors_blue_purple',
        asset: lensDemoURL('Xperio/02_Xperio Polarized_1/Xperio_Polarized_blue purple.jpg'),
        description: sellout,
        colorID:'lensPolarizedBluePurple'
      }
    ]
}


const getVideoByColor = (
  color: string, 
  folder: 'with-filter' | 'without-filter'
) => {
  return lensDemoURL(`Xperio/02_Xperio Polarized_1/car/${folder}/XPERIO_POLARIZED_CAR_${folder}-${color}.mp4`)
}

const generateDemo = (label: string) => {
  const color = ( label || '').replace(/ /g, '-').toLocaleLowerCase();
  const colorCode = (label || '').replace(/ /g, '_').toLocaleLowerCase()
  return {
    demo: `car-polarised-${color}`, 
    demoType: ProductDemoType.SIDE_BY_SIDE_VIDEO,
    hideSlider: true,
    products: [
        {
            id: 'xperio_polarised',
            label: `Xperio Polarised ${label}`,
            asset: getVideoByColor(color, 'with-filter'),
            description: sellout,
            colorID: `lens_propo_colors_${colorCode}`
        },
        {
            id: 'standard',
            label: 'packaged_offer_popup_po_popup_standard',
            asset: getVideoByColor(color, 'without-filter'),
        }
    ]
  }
}

const carPolarised: iLensDemo = {
    demo: 'car-polarised', 
    demoType: ProductDemoType.NESTED_DEMO,
    hideCenterButton: true,
    products: [
        generateDemo('Amber'),
        generateDemo('Grey'),
        generateDemo('Grey-green'),
        generateDemo('Brown'),
        generateDemo('Blue Ink'),
        generateDemo('Blue purple'),
        generateDemo('Blue sky'),
        generateDemo('Herbal'),
        generateDemo('Violet'),

    ]
}

export const experioPolarisedDemo: iLensDemo[] = [
    packshotPolarized,
    carPolarised
];