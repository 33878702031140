import React from 'react';
import iQuestionChoice from '../../../../interface/question-option.interface';
import useBEM from '../../../../utils/hooks/useBEM';
import ReactHtmlParser from 'react-html-parser';
import Icon from '../../../../components/ui/Icon';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import iQuestionOption from '../../../../interface/question-option.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answer: iQuestionOption | iQuestionOption[]) => void,
}

const ChoiceBoxImage : React.FC<Props>  = ({
    question,
    onAnswer
}) =>  {
    // VARIABLES
    const  [B, E] = useBEM('choice-box-image');
    const { t } =  useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS
    const getAnswer = () => {
        if(!question.answer) return question.canSelectMultiple ? [] : '';

        if(question.canSelectMultiple) {
            return question.answer as iQuestionOption[];
        }
        return (question.answer as iQuestionOption).value;
    }

    const select = (choice: iQuestionChoice) => {

        if(question.canSelectMultiple) {

            const answers = getAnswer() as iQuestionOption[];
            const isExist = answers.filter(c => c.value === choice.value);
            let finalAnswer: iQuestionChoice | iQuestionChoice[];

            if( isExist.length > 0) {
                finalAnswer = answers?.filter(c => c.value !== choice.value);
            } else {
                finalAnswer = [ ...answers, choice ]

                if (choice?.type === 'clear') {
                    finalAnswer = [ choice ]
                } else {
                    finalAnswer = finalAnswer.filter(fa => fa?.type !== 'clear')
                }
            }

            if(onAnswer) onAnswer(finalAnswer);

            return
        }

        if(onAnswer) onAnswer(choice)
    }

    const selectedChoiceClassName = (option: iQuestionChoice) => {
        if( question.canSelectMultiple ) {

            const answer = getAnswer() as iQuestionOption[];
            const isSelected = answer.filter(c => c.value === option.value)

            return [isSelected.length > 0 ? 'selected' : '']
        }

        return [getAnswer() === option.value ? 'selected' : '']
    }

    return (
        <ul className={`${B()} ${question.options && question.options.length === 3 ? '-one-row' : ''}`}>
            {
                question.options && question.options.map(option => (
                    <li
                        key={option.value}
                        className={`${E('item', selectedChoiceClassName(option))} ${option.type ? '-clear' : ''}`}
                        onClick={() => select(option)}
                    >

                        <Icon
                            type="image"
                            name={option.image || ''}
                            className={`${E('image')} ${option.type ? '-clear' : ''}`}
                            background={option.type ? false : true}
                            position={question.position && question.position}
                        />

                        <span className={E('check')}></span>

                        <div>
                            <label className={`label ${option.type ? '-black' : ''}`}>{ReactHtmlParser(t(option.label))}</label>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}

export default ChoiceBoxImage;
