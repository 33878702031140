import React from 'react';
import Icon from './Icon';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';
import { useSelector } from 'react-redux';
import ExperienceType from '../../types/experience.type';
interface Props {
    text?: string,
    className?: string,
    type?: 'primary' | 'secondary' | 'simple',
    disabled?: boolean,
    icon?: string | React.ReactNode,
    iconPosition?: 'right' | 'left'
    outlined?: boolean,
    circle?: boolean,
    active?: boolean,
    weight?: 'bold' | 'normal',
    outlineType?: 'light' | 'colored' | 'none',
    textSize?: 'default'  | 'small',
    themed?: boolean,
    soon?: boolean,
    click?: () => void,
    icontype?: 'svg' | 'image' | 'png' | 'gif',
    experience?: ExperienceType,
    pointer?:any
    //temporary
    notAvailable?: boolean,
    sunBack?:boolean


}

const Button : React.FC<Props>  = ({
    text,
    className,
    type = "primary",
    disabled = false,
    icon,
    outlined = true,
    circle = false,
    active = false,
    iconPosition = 'right',
    weight = 'bold',
    outlineType = 'colored',
    textSize = 'default',
    themed = false,
    soon = false,
    click,
    icontype,
    experience,
    sunBack=false

}) =>  {
    // VARIABLES
    const { t } = useTranslation(useSelector(getApp));
    const finalClass = () => {
        const classes = [];
        if(className)classes.push(className);
        if(disabled)classes.push(`button--disabled`);
        if(!text)classes.push(`button--icon-only`);
        if(outlined)classes.push(`button--outlined`);
        if(circle)classes.push(`button--circle`);
        if(active)classes.push(`button--active`);
        if(themed)classes.push(`button--themed`);
        if(soon)classes.push(`button--soon`);
        if(experience)classes.push(`button--${experience}`);
        if(sunBack)classes.push(`button--sun-back`)
                
        classes.push(`button--icon-${iconPosition}`);
        classes.push(`button--${weight}`);
        classes.push(`button--outline-${outlineType}`);
        classes.push(`button--text-${textSize}`);

        classes.push(`button--${type}`);
        return classes.join(" ");
    }


    // HOOKS



    // METHODS
    const onClickHandler = () => {
        if(!click)return;
        click();
    }

    return (
        <button 
            disabled={disabled}
            onClick={onClickHandler}
            className={`button ${finalClass()}`}
            >
            {   soon && (
                    <div className="soon-hover">
                        {t('education_edu_coming_soon')}
                    </div>
                )
            }
            {
                text && <span className="text-placement">{text}</span>
            }
            
            
            {icontype && typeof icon === 'string' && <Icon className="button__icon" name={icon} type={icontype} />}
            { !icontype && icon && typeof icon === 'string' && <Icon className="button__icon" name={icon}/> }

            { icon && typeof icon !== 'string' && icon }
        </button>
    )
}

export default Button;