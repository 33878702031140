import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import iNeedExplorerCategory from '../../../interface/need-explorer-category.interface';
import iNeedExplorerQuestion from '../../../interface/need-explorer-question.interface';
import { getApp } from '../../../store/slices/app.slice';
import { getNeedExplorer } from '../../../store/slices/need-explorer.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {

}

const NeedExplorerProgress : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('need-explorer-progress');
    const { questions, activeCategory } = useSelector(getNeedExplorer);
    const { t } =  useTranslation(useSelector(getApp));
    const [generalProgressPercentage, setGeneralProgressPercentage] = useState<number>(0);
    const [othersProgressPercentage, setOthersProgressPercentage] = useState<number>(0);
    const { ageCategory } = useSelector(getUser);
    const [isKids, setIsKids] = useState(false);


    // HOOKS

    useEffect(() => {
        const localIsKids = ageCategory?.value === 'kids';
        setIsKids(localIsKids);

        if(!localIsKids) {
            // Note: General Questions Progress
            const generalQuestions = getCategoryQuestions(questions, "1");
            const generalAnsweredQuestions = countCategoryAnsweredQuestions(generalQuestions);
            setGeneralProgressPercentage(computePercentage(generalQuestions.length, generalAnsweredQuestions));
        }

        // Note: Other Categories Progress
        let consolidatedOtherCategoryQuestions: iNeedExplorerQuestion[] = [];
        let totalOtherCategoryAnsweredQuestions = 0;

        const otherCategories = questions.filter( q => q.id !== "1");
        otherCategories.forEach( c => {
            consolidatedOtherCategoryQuestions = [
                ...consolidatedOtherCategoryQuestions,
                ...c.questions
            ];
            totalOtherCategoryAnsweredQuestions +=  countCategoryAnsweredQuestions(c.questions);
        });
        setOthersProgressPercentage(computePercentage(consolidatedOtherCategoryQuestions.length, totalOtherCategoryAnsweredQuestions));

    },[questions])

    const getCategoryQuestions = (categories: iNeedExplorerCategory[], categoryId: string) => {
        const category = categories.filter( c => c.id === categoryId )[0];
        return category?.questions;
    }

    const countCategoryAnsweredQuestions = (questions: iNeedExplorerQuestion[]) => {
        return questions.filter( q => q.isAnswered ).length;
    }

    const computePercentage = (total: number, of: number) => {
        const percent = 100 / total * of;
        return Math.ceil(percent);
    }

    const dynamicShadeWidth = (width: number) => {
        return {
            '--shade-width': `${width}%`
        } as React.CSSProperties
    }

    const dynamicTheme = () => {
        const total = generalProgressPercentage + othersProgressPercentage;
        let h = '353deg';
        let s = '77%';
        let l = '64%';

        if(total > 0) {
            h = '25deg';
            s = '84%';
            l = '64%';
        }

        if(total > 90) {              
            h = '174deg';
            s = '42%';
            l = '56%';
        }

         return {
            '--progress-theme-h': h,
            '--progress-theme-s': s,
            '--progress-theme-l': l
        } as React.CSSProperties
    }

    return (
        <div className={B()} style={dynamicTheme()}>
            <div className={E('labels')}>
                <label>
                    {t('questionnaires_q_patient')}
                </label>
                <label className={E('percentage')}>
                    { generalProgressPercentage + othersProgressPercentage } %
                </label>
            </div>

            <div className={E('progress')}>
                <div 
                    style={dynamicShadeWidth(!isKids ? generalProgressPercentage : othersProgressPercentage)}
                    className={E('progress-bar', 'general') }>
                </div>

                {
                    (othersProgressPercentage > 0 || activeCategory.id !== '1') && !isKids &&
                    <div 
                        style={dynamicShadeWidth(othersProgressPercentage)}
                        className={E('progress-bar', 'others')}>
                    </div>
                }                
            </div>
        </div>
    )
}

export default NeedExplorerProgress;
