import React, { useState, useEffect } from 'react';
import useBEM from '../../../../utils/hooks/useBEM';
import Utils from '../../../../utils/Utils';
import iNeedExplorerQuestion from '../../../../interface/need-explorer-question.interface';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import Icon from '../../../../components/ui/Icon';

interface Props {
    question: iNeedExplorerQuestion,
    onAnswer?: (answers: number[]) => void
}

const RatingGroup: React.FC<Props> = ({
    question,
    onAnswer
}) => {
    // VARIABLES
    const [B, E] = useBEM('rating-group-image');
    const { cloneObject } = Utils();
    const { t } = useTranslation(useSelector(getApp));
    const [rate, setRate] = useState<number[]>([1, 1, 1, 1, 1, 1, 1, 1, 1]);
    const [noSports, setNoSports] = useState<boolean>(false)
    // HOOKS

    // METHODS
    const handleOnClick = (data: number, index: number) => {
        dynamicClass(index)
        const newAnswers = cloneObject(question.answer) as number[];
        newAnswers[index] = data;
        setRate(newAnswers);
        setNoSports(false)
        if (onAnswer) onAnswer(newAnswers);
    }

    const getRatingValue = (index: number) => {
        return (question.answer as number[])[index];
    }

    const answer = (i:number) =>{
        if(i === 3 && question.id === '8.11'){
            setNoSports(true)
            if(onAnswer) onAnswer([0,0,0])
        }
    }
    const dynamicClass = (i:number) =>{
        if(i === 3 && question.id === '8.11' && noSports){
            return 'selected'
        }else return ''
    }

    return (
        <div className={B()}>
            {
                question.situations && question.situations.map((slider, index) => (

                    <div className={` ${E('box', dynamicClass(index))} ${slider.type ? 'clear' : ''}` } key={index} onClick={()=>answer(index)}>
                        <Icon
                            type={slider.type? 'svg': 'image'}
                            name={slider.image || ''}
                            className={` ${'box-bg'} ${slider.type ? 'clear' : ''}`}
                            background={slider.type ? false : true}
                            position='center'
                        />

                        {
                            <div className={`${'label-group'} ${slider.type ? '' : 'withImage'}`}>
                                <label className={slider.type ? '-black' : ''} >
                                    {t(slider.label)}

                                </label>
                                <label className='sublabel'>
                                    {slider.subLabel}
                                </label>
                               { !slider.type &&  <div className={E('rating-container')}>
                                    {[...Array(5)].map((item, i) => {
                                        const givenRating = i + 1;
                                        const ansRating = getRatingValue(index)
                                        const style = givenRating === (ansRating) ? "rating-selected" : givenRating <= (ansRating) ?
                                            "rating-fill" : "rating";

                                        return (
                                                <div
                                                    className={E(style)}
                                                    onClick={() => handleOnClick(givenRating, index)}
                                                >{givenRating}</div>
                                        );

                                    })
                                    }
                                </div> }
                            </div>
                        }

                    </div>
                ))
            }

        </div>
    )
}

export default RatingGroup;
