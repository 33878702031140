import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/ui/Button";
import { getApp } from "../../../store/slices/app.slice";
import tutorialSlice, { getTutorial } from "../../../store/slices/tutorial.slice";
import ExperienceType from "../../../types/experience.type";
import useBEM from "../../../utils/hooks/useBEM";
import useTranslation from "../../../utils/hooks/useTranslation";

interface Props {

}
const TutorialSection : React.FC<Props>  = () =>  {
    // VARIABLES
    const dispatch = useDispatch();
    const [B, E] = useBEM('tutorial-section');
    const { description, view, items, selected } = useSelector(getTutorial);
    const [file, setFile] = useState('');
    const { t } = useTranslation(useSelector(getApp));
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    
    // METHOD
    const renderContainer = () =>{
        const labelDetails = 
        description?.content?.map((ls,i)=>{
            return <div className={E('container')} key={i}>
                    <p>{ls.title}</p>
                    <div className={E('container--btn')}>
                        <Button
                            text={t('my_practice_my_video')}
                            type="secondary" weight="normal"
                            icon={`video${selectIcon()}`} 
                            icontype="png"
                            iconPosition="left" 
                            click={()=>showVideo(ls.file, description, i)}
                        ></Button>
                        <Button
                            text={t('my_practice_my_interac_tuto')}
                            type="primary" weight="normal"
                            icon="interactive-white" 
                            icontype="png"
                            iconPosition="left" 
                            soon
                        ></Button>
                    </div>
            </div>
        })
        return labelDetails;
    }
    const showVideo = (file:string = "", content:any = {}, key: number = 0) =>{
        dispatch(tutorialSlice.actions.saveViewMode('tutorialVideo'));
        setFile(file);
        //set to true once viewed
        onChangeView(key,content)
        dispatch(tutorialSlice.actions.saveTutorialItems(onChangeView(key,content)));
    }
    const onChangeView = (key:number = 0, content:any = {}) =>{
        let newList = {};
        switch(selected?.toString()) {
            case 'newFeatures':  
                newList = {
                    ...items,
                    newFeatures: items?.newFeatures?.map(v=>{
                            if(v.key === content.key){
                                return {
                                    ...v,                    
                                    content: v.content?.map((sub,i:number)=>{
                                        if(key === i || sub.viewed) {
                                            return {
                                                ...sub,
                                                viewed: true
                                            }
                                        } else{
                                            return {
                                                ...sub,
                                                viewed: false,
                                            }
                                        }                                
                                    })
                                }
                            }
                            return {
                                ...v
                            }
                        })
                }
                break;
            case 'useJourney':
                newList = {
                    ...items,
                    useJourney: items?.useJourney?.map(v=>{
                            if(v.key === content.key){
                                return {
                                    ...v,                    
                                    content: v.content?.map((sub,i:number)=>{
                                        if(key === i  || sub.viewed) {
                                            return {
                                                ...sub,
                                                viewed: true
                                            }
                                        } else{
                                            return {
                                                ...sub,
                                                viewed: false,
                                            }
                                        }                                
                                    })
                                }
                            }
                            return {
                                ...v
                            }
                        })
                }
                break;
            case 'personalizedCompanion':
                newList = {
                    ...items,
                    personalizedCompanion: items?.personalizedCompanion?.map(v=>{
                            if(v.key === content.key){
                                return {
                                    ...v,                    
                                    content: v.content?.map((sub,i:number)=>{
                                        if(key === i  || sub.viewed) {
                                            return {
                                                ...sub,
                                                viewed: true
                                            }
                                        } else{
                                            return {
                                                ...sub,
                                                viewed: false,
                                            }
                                        }                                
                                    })
                                }
                            }
                            return {
                                ...v
                            }
                        })
                }
                break;
            case 'updateApp':
                newList = {
                    ...items,
                    updateApp: items?.updateApp?.map(v=>{
                            if(v.key === content.key){
                                return {
                                    ...v,                    
                                    content: v.content?.map((sub,i:number)=>{
                                        if(key === i  || sub.viewed) {
                                            return {
                                                ...sub,
                                                viewed: true
                                            }
                                        } else{
                                            return {
                                                ...sub,
                                                viewed: false,
                                            }
                                        }                                
                                    })
                                }
                            }
                            return {
                                ...v
                            }
                        })
                }
                break;
            default: 
                break;
        }
        return newList;
    }
    const renderVideo = () =>{
        return <div className={E('video')}>
            <video controls autoPlay >
                <source src={file} type="video/mp4"/>
            </video>
        </div>
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    return(
        <div className={B('')}>
            {
                view === 'tutorialSection' && (
                    <div className={E('header')}>
                        <h3>{description?.label}</h3>
                        <p>{description?.description}</p>
                    </div>
                )
            }            
            <div className={E('body')}>
                { view === 'tutorialSection' && (renderContainer())}
                { view === 'tutorialVideo' && (renderVideo())}
            </div>
        </div>
    )

}
export default TutorialSection;