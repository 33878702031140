import React from "react";
import useBEM from "../../../../utils/hooks/useBEM";

type circleProps = {
    radius: number;
    pct: number | undefined;
    advName: string;
    advText: string;
    advId:string;
}

const AdvantageCircle: React.FC<circleProps> = ({radius, pct, advName, advText, advId}) => {
    const [B,E] = useBEM('advantage');
    const percentage = pct ? pct : 0
    const circumference = 2*radius*Math.PI;
    const pctAbs = Math.abs(percentage);
    const calcProgress = (100-pctAbs)/100*circumference;
    const isEyeProtection = advId === 'eyeProtection'

    const pctDisp = () => {
        if (percentage >= 0) return "+" + percentage.toString();
        return percentage.toString();
    }

    return(
        <div className={E('cont')}>
                <div className={E('circle')}>
                <svg
                        className={E('progress-cont')}
                        width="80"
                        height="80">
                        <circle
                            className={E('progress-back')}
                            stroke="#FDE4DA"
                            stroke-width="4"
                            fill="transparent"
                            r="32"
                            cx="40"
                            cy="40"/>
                        <circle
                            className={E('progress-bar')}
                            stroke="#F35D1D"
                            stroke-width="4"
                            strokeDasharray={circumference}
                            strokeDashoffset={calcProgress}
                            fill="transparent"
                            r="32"
                            cx="40"
                            cy="40"/>
                    </svg>
                    <span className={E('circle--text')}>{pctDisp()}<span style={{fontSize: "1rem"}}> %</span></span>
                </div>

                <h4 className={E('adv-title')}>
                    {advName}
                    {isEyeProtection && '*'}
                </h4>
                <p className={E('lipsum')}>{advText}</p>
        </div>
    )
}

export default AdvantageCircle;