import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import { getApp } from '../../../store/slices/app.slice';
import prescriptionSlice from '../../../store/slices/prescription.slice';
import ExperienceType from '../../../types/experience.type';
import PrescriptionViewType from '../../../types/prescription-view.type';
import useBEM from '../../../utils/hooks/useBEM'
import useTranslation from '../../../utils/hooks/useTranslation';
import { PRESCRIPTION_SUNWEAR_DATA } from '../../../constants/prescription.constants';
import ReactHtmlParser from 'react-html-parser';
import Icon from '../../../components/ui/Icon';
import { Any } from '@react-spring/types';
import LensCategoryType from '../../../types/lens-category.type';

interface Props {

}
interface brandLogos {
  name:string
  value:LensCategoryType
}

const PrescriptionSunwear : React.FC<Props> = ({ }) => {
  const [B, E] = useBEM('prescription-sunwear')
  const appStore = useSelector(getApp);
  const { t } = useTranslation(appStore);
  const dispatch = useDispatch();
  const benefitDetails = PRESCRIPTION_SUNWEAR_DATA.default.details
  const brandLogos:brandLogos[] = [
    { name:'eyezen_sunfit',
      value: LensCategoryType.SINGLE_VISION,
    },
    {
      name:'varilux_sunfit',
      value: LensCategoryType.PROGRESSIVE,
    }]

  const goBack = () => dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.LENSPROPOSAL))

  const onCLickInfo = (lensType: LensCategoryType) => {
    dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTIONLENS))
    dispatch(prescriptionSlice.actions.setLensType(lensType))
}

  return (
    <div className={B()}>
      <div className={E('header')}>
        <h2>
          {t(PRESCRIPTION_SUNWEAR_DATA.title, PRESCRIPTION_SUNWEAR_DATA.default.title)}
        </h2>
        <Button
          click={goBack}
          text={t('demo_demo__back')}
          icon={
              <CircleArrow
                  type='left'
                  background='#eee'
              />
          }
          iconPosition="left"
          type="secondary"
          experience={ExperienceType.SUN}
        />
      </div>

      <p className={E('subtitle')}>
        {t(PRESCRIPTION_SUNWEAR_DATA.subtitle, PRESCRIPTION_SUNWEAR_DATA.default.subtitle)}
      </p>

      <div className={E('details')}>
        {
          benefitDetails.map((benefit: any, index: number) => {
            return (
              <div className='detail' key={index}>
                {benefit.description &&
                  <span>{benefit.description ? benefit.description : ''}</span>
                }
                <ul>
                  {benefit.benefits && benefit.benefits.map((b: any) => (
                    <li>{b}</li>
                    ))}
                </ul>
              </div>
            )
          })
        }
        <div className={E('details-images')}>
          {benefitDetails.map((benefit:any, index:number) => (
                <Icon name={benefit.img} type='image' className={E('image')} key={index}/>
                ))
              }
        </div>
      </div>

      <p className={E('footer')}>
        {ReactHtmlParser(t(PRESCRIPTION_SUNWEAR_DATA.footer, PRESCRIPTION_SUNWEAR_DATA.default.footer))}
        <div className={E('footer-brands')}>
          {brandLogos.map(brand => 
            <div onClick={() => onCLickInfo(brand.value)}>
              <Icon name={brand.name} extension='jpg' type='image'/>
            </div>
            )}
        </div>
      </p>
    </div>
  )
}

export default PrescriptionSunwear
