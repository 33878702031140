import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../store/slices/app.slice';
import ExperienceType from '../types/experience.type';
import useBEM from '../utils/hooks/useBEM';
import Utils from '../utils/Utils';
import eyeGif from './../assets/gif/eye.gif';
import eyeSVG from './../assets/svg/eye.svg';
import eyeSunGif from './../assets/gif/eye-sun.gif';
import eyeSunSVG from './../assets/svg/eye-sun.svg';

interface Props {
    colored?: boolean
}

const BlinkEye: React.FC<Props> = ({
    colored = false
}) => {
    // VARIABLES
    const [B, E] = useBEM('blink-eye');
    const [onHoverEye, setOnHoverEye] = useState(false);
    const { downloadAsset } = Utils();
    const [gifSource, setGifSource] = useState<string>();
    const [svgSource, setSvgSource] = useState<string>();
    const appStore = useSelector(getApp);
    const { experience } = appStore;

    // HOOKS
    useEffect(() => {
        downloadAsset(selectGif()).then(b => setGifSource(b))
        downloadAsset(selectSVG()).then(b => setSvgSource(b))
    }, [])

    // METHODS
    const onHoverHandler = (isHover: boolean) => {
        setTimeout(() => {
            setOnHoverEye(isHover);
        }, isHover ? 0 : 250);
    }
    const selectGif = () => {
        return experience === ExperienceType.SUN ? eyeSunGif : eyeGif
    }
    const selectSVG = () => {
        return experience === ExperienceType.SUN ? eyeSunSVG : eyeSVG
    }
    
    return (
        <div className={B(colored ? 'colored' : '')}
            onMouseEnter={() => onHoverHandler(true)}
            onMouseLeave={() => onHoverHandler(false)}
        >
            {/* { svgSource && <img src={svgSource} alt=""/> } */}

            {
                gifSource &&
                <img className={onHoverEye ? 'gif' : 'svg'} src={onHoverEye ? gifSource : svgSource} alt="" />
            }
        </div>
    )
}

export default BlinkEye;