export const roundCorner = {
    textDecoration: "none",
    "-ms-interpolation-mode": "bicubic",
    height: "auto",
    border: "0",
    width: "100%",
    maxWidth: "650px",
    display: "block",
    align: "center",
    margin: "auto"
}
export const backgroundPerRow = {
    minWidth: "320px",
    maxWidth: "650px",
    OverflowWrap: "break-word",
    WordWrap : "break-word",
    WordBreak : "break-word",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    padding: "5px 0"
}

export const imageLogo = {
    "text-decoration": "none", 
    "-ms-interpolation-mode": "bicubic", 
    "height": "auto",
    "border": 0, "width": "100%", 
    "max-width": "59px", 
    "display": "block", 
    "align": "center",
    marginLeft: "10px",
}
export const h3= {
        color: "#0086d6",
        Direction : "ltr",
        fontFamily: "Lato, Tahoma, Verdana, Segoe, sans-serif",
        fontSize: "16px",
        FontWeight : "normal",
        letterSpacing: "normal",
        lineHeight: "120%",
        "text-align" : "left",
        marginTop: "0",
        marginBottom: '10px',
        marginLeft: "40px",
        TextTransform: "capitalize"
}

export const paragraph= {
    Direction : "ltr",
    fontFamily: "Lato, Tahoma, Verdana, Segoe, sans-serif",
    fontSize: "12px",
    lineHeight: "1.5",
    TextAlign : "left",
    marginTop: "0",
    marginBottom: '0',
    marginLeft: "40px",
    WordBreak : "break-word",

}

export const pairHeader= {
    paddingTop: "10px",
    paddingRight: "20px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    "text-align": "right"

}

export const pairContainer= {
    "-webkit-text-size-adjust": "none",
    "text-decoration": "none",
    "display": "inline-block",
    "color": "#0086d6",
    "background-color": "#d9edf9",
    "border-radius": "60px",
    "-webkit-border-radius": "60px",
    "-moz-border-radius": "60px",
    "width": "auto",
   " border-top": "0px solid #8a3b8f",
    "border-right": "0px solid #8a3b8f",
    "border-bottom":" 0px solid #8a3b8f",
    "border-left": "0px solid #8a3b8f",
    "padding-top":" 0px",
    "padding-bottom": "0px",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "text-align": "center",
    "mso-border-alt": "none",
    "word-break": "keep-all",
    height: "30px"

}

export const pairSpan= {
    "padding": "10px 35px",
    "font-size": "11px",
    "display": "inline-block",
    "letter-spacing": "1px",

}

export const summaryRow= {
    paddingTop: "10px",
    paddingRight: "20px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    "display" : "flex"
}
export const summaryRowPadding= {
    paddingTop: "10px",
    paddingBottom: "10px",
    "display" : "flex"
}
export const summaryRowBlock= {
    paddingTop: "10px",
    paddingBottom: "10px",
    "display" : "block"
}

export const summaryRowTitle= {
    "word-break": "inherit",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "font-size": "12px",
    "color": "#5a6675",
    "vertical-align": "middle",
    padding: "10px",
    "text-transform": "capitalize"
}

export const summaryRowTitleLabel= {
    "word-break": "inherit",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "font-size": "12px",
    "color": "#5a6675",
    "vertical-align": "middle",
    "text-transform": "capitalize"
}

export const summaryRowTitleII= {
    "word-break": "inherit",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "font-size": "12px",
    "color": "#5a6675",
    "vertical-align": "middle",
    // padding: "10px 139px",
    "text-transform": "capitalize"
}

export const summaryProductTitle= {
    "display": "table-cell",
    "vertical-align": "top",
    "max-width": "403px",
    // "min-width": "324px",
    "background-color": "#d9edf9",
    "width": "1%",
}

export const summaryBenefitContainer= {
    "display": "flex",
    "justify-content": "space-evenly",
}

export const summaryBenefitContainerLI= {
    "display": "flex",
    margin: "0 15px",
    "flex-flow": "column",
    "align-items": "center"
}

export const icon = {
    width: "5rem",
    // height: "4.5rem"
}

export const mainIcon = {
    width: "5rem",
    height: "4rem"
}

export const footerText = {
    fontSize: "12px",
    "text-align": "left",
    marginBottom: '10px',
    marginLeft: "40px",
    marginRight: "40px",
}

export const footerLogo = {
    "text-decoration": "none", 
    "-ms-interpolation-mode": "bicubic", 
    "height": "auto, border: 0",
     "width": "100%", 
     "max-width": "60px", 
     "display": "block",
     margin: "10px 72px"
}

export const flexSpaceBetween = {
    display: "flex", justifyContent: "space-between"
}

export const valueContainer = {
    "-webkit-text-size-adjust": "none",
    "text-decoration": "none",
    "display": "inline-block",
    "color": "#2f3b4a",
   " background-color": "transparent",
    "border-radius": "5px",
    "-webkit-border-radius": "5px",
    "-moz-border-radius": "5px",
    "border": "1px solid #0086D6",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "text-align": "center",
    "mso-border-alt": "none",
    "word-break": "keep-all",
    fontSize: "12px",
    padding:"10px 35px",
    margin: "auto",
    width: "127px"
}
export const valueContainerAdd = {
    "-webkit-text-size-adjust": "none",
    "text-decoration": "none",
    "display": "inline-block",
    "color": "#2f3b4a",
   " background-color": "transparent",
    "border-radius": "5px",
    "-webkit-border-radius": "5px",
    "-moz-border-radius": "5px",
    "border": "1px solid #0086D6",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "text-align": "center",
    "mso-border-alt": "none",
    "word-break": "keep-all",
    fontSize: "12px",
    padding:"10px 35px",
    margin: "auto",
    width: "384px",
    height: "12px"
}

export const headerImage1 = {
    backgroundImage: "url('https://cpbx-dev-app-frc-04-nodejs.azurewebsites.net/static/media/delivery-summary-pair1.d1f561f8.png')",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    display: "table",
    backgroundPosition: "5% 10%",
    "max-width": "320px",
    "min-width": "324px",
    "width": "325px",
    backgroundSize: "cover"
    // "height": "200px"
}
export const headerImage2 = {
    backgroundImage: "url('https://cpbx-dev-app-frc-04-nodejs.azurewebsites.net/static/media/delivery-summary-pair2.e7651f9b.png')",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    display: "table",
    backgroundPosition: "20% 10%",
    "max-width": "320px",
    "min-width": "324px",
    "width": "325px",
    backgroundSize: "cover"

    // "height": "200px"
}

export const backgroundColorHeader = {
    "vertical-align": "top",
    "max-width": "320px",
    "min-width": "324px",
    "background-color": "#000000",
    "width": "325px",
    color: "white",
    padding: "2rem 1rem",
    "text-align":"left"
}

export const containerWhite = {
    "-webkit-text-size-adjust": "none",
    "text-decoration": "none",
    "display": "inline-block",
   " background-color": "transparent",
    "border-radius": "5px",
    "-webkit-border-radius": "5px",
    "-moz-border-radius": "5px",
    "width": "auto",
    "border": "1px solid white",
    "font-family": "'Lato', Tahoma, Verdana, Segoe, sans-serif",
    "text-align": "center",
    "mso-border-alt": "none",
    "word-break": "keep-all",
    fontSize: "11px",
    padding:"10px",
    "margin-top": "3rem",
    "margin-bottom": "1.5rem"
}

export const h2 ={
    "color": "#ffffff",
    Direction: "ltr",
    "font-family": "Lato, Tahoma, Verdana, Segoe, sans-serif",
    "font-size": "18px",
    "font-weight": "bold",
    "letter-spacing": "normal",
    "line-height": "120%",
    "text-align": "left",
    
}

export const prescriptionLabel = {
    fontSize: "12px", padding:"10px 0", marginLeft:"-36px",
    margin: "auto",
    width: "13%"
}

export const flexSpaceAround= {
    "display": "flex",
    "justify-content": "space-around",
    "margin": "2rem 0",
    "align-content": "center",
    "align-items": "center",
    
}