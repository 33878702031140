const SKIP_EPROM_VALIDATION_DATA: {
    [key: string]: {
        [key: string]: string[]
    }
} = {
    europe: {
        // stellest: [
        //     'stellest'
        // ],
        // eyezen_kids: [
        //     'eyezen_kids'
        // ]
    }
}

export default SKIP_EPROM_VALIDATION_DATA;