const settings: any = {
    environment: 'dev',
    dev: {
        version: 'version: 0.14.37',
        apiUrl: 'https://essilor-sandbox-dev.apigee.net/gse/essilorcompanion/',
        GA: 'UA-203551100-2',
        // demoAssetsUrl: 'https://cpbx-dev-app-frc-03-companion.azurewebsites.net/sites/default/files/demo/'
        demoAssetsUrl: 'https://companion-cdn-dev.azureedge.net/demostration/optimized/',
        newAssetsUrl:'https://cpbxdevstafrccompanion.blob.core.windows.net/demostration/',
        siteAssets: 'https://companion-cdn-dev.azureedge.net/sites/assets/',
        dataStructureVersion: 76,
        signalRUrl: 'https://cpbx-dev-fnc-frc-01.azurewebsites.net',
        azureApiUrl: 'https://cpbx-dev-fnc-frc-companion.azurewebsites.net/api/',
        azureApiCodes: [
            'XdDlrxDnhMaGPn91ffoXGPsmkly0vI6uCmzAU5ZTdpyRyHVoV4uFRQ==',
            'ZCCbqSaNHqbYh/axQC6MNh4PlHHyFN7UmhwLHUL5RAAgr9QqVMfqAg==',
            'Q8I1NaJjkxWBbOdK7J3FKyubys5UMpnk6T6U0Qk4DoLI/JMr921/1g==',
            'jOjyPr0BHP7s0E7Mo3elhcQ4nwqNpSZ/tXTmTCIdDYrSUVpcysmYzQ=='
        ]
    },
    uat: {
        version: 'version: 0.8.47',
        // apiUrl: 'https://cpbx-tst-app-frc-01-companion.azurewebsites.net/api/v1/',
        apiUrl: 'https://essilor-int.apigee.net/gse/essilorcompanion/',
        GA: 'UA-197617238-1', // UAT
        // GA: 'UA-197524783-1', // DEV
        demoAssetsUrl: 'https://companion-cdn-qua.azureedge.net/demonstration/optimized/',
        newAssetsUrl:'https://cpbxtstaspfrc01companion.blob.core.windows.net/demonstration/',
        siteAssets: 'https://companion-cdn-qua.azureedge.net/sites/assets/',
        dataStructureVersion: 59,
        signalRUrl: 'https://cpbx-qua-fnc-frc-01.azurewebsites.net',
        azureApiUrl: 'https://cpbx-dev-fnc-frc-companion.azurewebsites.net/api/',
        azureApiCodes: [
            'XdDlrxDnhMaGPn91ffoXGPsmkly0vI6uCmzAU5ZTdpyRyHVoV4uFRQ==',
            'ZCCbqSaNHqbYh/axQC6MNh4PlHHyFN7UmhwLHUL5RAAgr9QqVMfqAg==',
            'Q8I1NaJjkxWBbOdK7J3FKyubys5UMpnk6T6U0Qk4DoLI/JMr921/1g==',
            'jOjyPr0BHP7s0E7Mo3elhcQ4nwqNpSZ/tXTmTCIdDYrSUVpcysmYzQ=='
        ]
    },
    prod: {
        version: 'version: 2.3.39',
        apiUrl: 'https://essilor-prd.apigee.net/gse/essilorcompanion/',
        GA: 'UA-202077407-1',
        // demoAssetsUrl: 'https://cpbx-dev-app-frc-03-companion.azurewebsites.net/sites/default/files/demo/'
        demoAssetsUrl: 'https://companion-cdn.azureedge.net/demonstration/optimized/',
        newAssetsUrl:'https://cpbxprdaspfrc01companion.blob.core.windows.net/demonstration/',
        siteAssets: 'https://companion-cdn.azureedge.net/sites/assets/',
        dataStructureVersion: 29,
        signalRUrl: 'https://function-pwa-signalr-prod.azurewebsites.net',
        azureApiUrl: 'https://cpbx-prd-fnc-frc-01.azurewebsites.net/api/',
        azureApiCodes: [
            'Q4yW0Qf/F1R7OalWv5VgdknPLf5AXdKnrvZXK/TW1QeZx2XQHYLkYA==',
            'ZCCbqSaNHqbYh/axQC6MNh4PlHHyFN7UmhwLHUL5RAAgr9QqVMfqAg=='
        ]
    },
}

export default settings;