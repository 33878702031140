import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getApp } from '../../../store/slices/app.slice'
import { getEducation } from '../../../store/slices/education.slice'
import useBEM from '../../../utils/hooks/useBEM'
import useTranslation from '../../../utils/hooks/useTranslation'
import EducationEyeball from '../components/EducationEyeball'
import ItemSlider from './ItemSlider'

interface Props {

}

const EducationFigure : React.FC<Props> = ({ }) => {

  // VARIABLES
  const [B, E] = useBEM('education-figure')
  const { t } = useTranslation(useSelector(getApp))
  const {
    isLensEnabled,
    isItemEnabled,
    item,
    lensVariant
  } = useSelector(getEducation)
  const [sliderValue, setSliderValue] = useState(0)

  return (
    <div className={B()}>
      {isItemEnabled &&
        <ItemSlider
            onChange={setSliderValue}
            value={sliderValue}
            classname={E('slider')}
        >
          <div className='itemobject'>
            <div className={`item --${item?.toLowerCase()}`} />
            {/* <div className='slider' /> */}
          </div>
        </ItemSlider>
      }
      { isLensEnabled &&
        <div className={`${E('lens')} ${lensVariant}-max`}>
        </div>
      }
      <EducationEyeball />
    </div>
  )
}

export default EducationFigure
