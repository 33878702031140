import React from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../store/slices/app.slice';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    label?: string,
    checked?: boolean
}

const Checkbox : React.FC<Props>  = ({
    label,
    checked = false
}) =>  {
    // VARIABLES
    const {experience } = useSelector(getApp);
    const isSunExp = () =>{
        return experience === 'sun'
    }
    const [B, E] = useBEM(isSunExp() ? 'checkbox-sun' : 'checkbox');

    // HOOKS

    // METHODS

    return (
        // <div className={`checkbox ${checked ? 'checkbox--checked' : ''}`}>
        <div className={B(checked ? 'checked' : '')}>
            {
                label && <label>{label}</label>
            }
        </div>
    )
}

export default Checkbox;