import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iLensConsultationSun from '../interface/lens-consultation-sun.interface';
import iSunExperienceSolution from '../../interface/sun-experience-solution.interface';
import iSunExperienceSolutionColor from '../../interface/sun-experience-solution-color.interface'; 
import iRootState from '../interface/root-state.interface';
import LensPairType from '../../types/lens-pair.type';
import pricingBreakdown from '../../constants/pricing-breakdown';
import lensConsultationData from '../../constants/lens-consultation-data.constants';
import iPriceBreakdown from '../../interface/price-breakdown.interface';
import iLensConsultationType from '../../interface/lens-consultation-type.interface';
import iLensConsultationBrand from '../../interface/lens-consultation-brand.interface';
import LensFamilyType from '../../types/lens-family.type';
import iProductBrand from '../../interface/product-brand.interface';
import iProduct from '../../interface/product.inteface';
import iSubProduct from '../../interface/sub-product.inteface';
import iProductFamily from '../../interface/product-family.interface';
import iLensColor from '../../interface/lens-color.inteface';
import iLensRecommendationPair from '../../interface/lens-recommendaiton-pair.interface';

const initialState: iLensConsultationSun = {
    alreadyKnow: '',
    solution: undefined,
    tint: undefined,
    solutionId: "",
    activePairSun: LensPairType.PAIR1,
    isLensThickness: false,
    data: lensConsultationData,
    sliderPageSun: 0,
    lensType: null,
    lensRecommendationSun: {},
    isValidated: false,
    selectedProtect : {},
    lensProducts: {
        correct: {
            product: null,
            subProduct: null
        },
        protect: {
            product: null,
            subProduct: null
        },
        enhance: {
            product: null,
            subProduct: null
        }
    },
    breakdown: pricingBreakdown,
    familySun:'',
    tooltipOpen:false,
    sunExpProducts:[],
}

const lensConsultationSun = createSlice({
    name: 'lensConsultationSun',
    initialState,
    reducers: {
        setAlreadyKnow: (state, action: PayloadAction< {alreadyKnow: string, solution?: iSunExperienceSolution }>) => ({
            ...state, 
            alreadyKnow: action.payload.alreadyKnow,
            solution: action.payload.solution
        }),
        setSolution: (state, action: PayloadAction<{solution: iSunExperienceSolution, tint?:iSunExperienceSolutionColor }>) => ({
            ...state,
            solution: action.payload.solution,
            tint: action.payload.tint ? action.payload.tint : action.payload.solution?.sun_experience?.color_group[0]?.colors[0]
        }),
        setTint: (state, action: PayloadAction<iSunExperienceSolutionColor>) => ({
            ...state,
            tint: action.payload
        }),
        setSolutionId: (state, action: PayloadAction<string>) => ({
            ...state,
            solutionId: action.payload
        }),
      
        resetData: (state) => ({
            ...state,
            ...initialState
        }),
        setSliderPage: (state, action: PayloadAction<number>) => ( {...state, sliderPageSun: action.payload} ),
        selectLensType: (state, action: PayloadAction<iLensConsultationType | any>) => ( {...state, lensType: action.payload} ),
        selectLensBrand: (state, action: PayloadAction<iLensConsultationBrand>) => ( {...state, lensBrand: action.payload} ),
        selectLensProduct: (state, action: PayloadAction<any>) => ( {...state, lensProducts: action.payload} ),
        setPrefilledDataFromNeedExplorer: (state, action: PayloadAction<any>) => ( {...state, lensRecommendationSun: action.payload} ),
        setPrefilledDataFromNeedExplorerStored: (state, action: PayloadAction<any>) => ( {...state, storedLensRecommendationSun: action.payload} ),
        
        changePair: (state, action: PayloadAction<LensPairType>) => ( {...state, activePair: action.payload} ),  
        setIsLensThickness: (state, action: PayloadAction<boolean>) => ( {...state, isLensThickness: action.payload} ),  
        validate: (state, action: PayloadAction<boolean>) => ({
            ...state, 
            isValidated: action.payload,
            dateValidated: new Date().toISOString()
        }),  
        
        removeProduct: (state, action: PayloadAction<{
            lensFamily: LensFamilyType,
            index?: number
        }>) => {
            const { lensFamily, index } = action.payload;

            const currentActivePair: any = state.lensRecommendationSun && 
                          state.lensRecommendationSun[state.activePairSun] ? 
                          state.lensRecommendationSun[state.activePairSun] : null;            

            let newFamilyState = null;

            if(lensFamily === LensFamilyType.PROTECT){
                const currentProtects = currentActivePair[lensFamily];
                newFamilyState = currentProtects.filter( (item: any, i: number) => index !== i);
            }

            const newState = {
                ...state,
                lensRecommendationSun: {
                    ...state.lensRecommendationSun,
                    [state.activePairSun]: {
                        ...currentActivePair,
                        [lensFamily]: newFamilyState
                    }
                },
                validOnEPROM: {
                    ...state.validOnEPROM,
                    [state.activePairSun]: true
                }
            }
            return {
                ...state,                 
                ...newState,
            }
        },  
        selectProduct: (state, action: PayloadAction<{
            lensFamily: LensFamilyType,
            brand?: iProductBrand,
            product?: iProduct,
            subProduct?: iSubProduct,
            manual?: boolean,
            pair?: LensPairType
        }>) => {
            const { lensFamily, brand, product, subProduct, manual } = action.payload;
            const isManual = (manual === null) || (manual === undefined) ? true : manual;
            const pair = action.payload.pair || state.activePairSun;

            const currentActivePair: any = state.lensRecommendationSun && 
                          state.lensRecommendationSun[pair] ? 
                          state.lensRecommendationSun[pair] : {};

            const currentLensFamily = currentActivePair[lensFamily] || [];
            
            let newLensFamily: any = null;

            if(lensFamily === LensFamilyType.CORRECT) {
                newLensFamily = product ? { brand, product, subProduct } : null;
            }else if(lensFamily === LensFamilyType.ENHANCE) {
                newLensFamily = product ? { brand, product } : null;
            }else {
                if(!product){
                    newLensFamily = null
                } else {
                    newLensFamily = isManual ? [...currentLensFamily, { brand, product }] : [{ brand, product }]
                }
            }

            const newState = {
                ...state,
                lensRecommendationSun: {
                    ...state.lensRecommendationSun,
                    [pair]: {
                        ...currentActivePair,
                        [lensFamily]: newLensFamily
                    }
                }
            }
            return {
                ...state,                 
                ...newState,
            }
        },  
        saveSelectProtect: (state, action: PayloadAction<any>)=>{
            // const currentActivePair: any = state.lensRecommendation && 
            // state.lensRecommendation[state.activePair] ? 
            // state.lensRecommendation[state.activePair] : {};
            const newState = {
                ...state,
                selectedProtect: {
                    ...state.selectedProtect,
                    [state.activePairSun]: action.payload
                }
                
            }
            return {
                ...state,
                ...newState
            }
        },
        selectProductFamily: (state, action: PayloadAction<iProductFamily | null | undefined>) => ( {...state, productFamilySun: action.payload} ),
        selectProductBrand: (state, action: PayloadAction<iProductBrand>) => ( {...state, productBrandSun: action.payload} ),        
        setProtectColor: (state, action: PayloadAction<{
            color:iLensColor,
            index: number,
        }>) => {
            const currentActivePair: any = state.lensRecommendationSun && 
                          state.lensRecommendationSun[state.activePairSun] ? 
                          state.lensRecommendationSun[state.activePairSun] : {};
            
            const newState = {
                ...state,
                lensRecommendation: {
                    ...state.lensRecommendationSun,
                    [state.activePairSun]: {
                        ...currentActivePair,
                        protect: currentActivePair.protect.map( (x: any, index: number) => {
                            if(index === action.payload.index) {
                                return {
                                    ...x,
                                    color: action.payload.color,
                                }
                            }
                            return x;
                        })
                    }
                }
            }

            return { ...state, ...newState }
        },        
        saveLensRecommendationDataFromStore: (state , action: PayloadAction<{
                pair1?: iLensRecommendationPair,
                pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendation: action.payload
        }),
        saveLensRecommendationStandard: (state , action: PayloadAction<{
            pair1?: iLensRecommendationPair,
            pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendation: action.payload
        }),
        saveLensRecommendationSun: (state , action: PayloadAction<{
            pair1?: iLensRecommendationPair,
            pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendation: action.payload
        }),
        sessionStart: (state, action: PayloadAction<string | undefined>) => ({...state, sessionStart: action.payload }),
        
        //BREAKDOWN
        setBreakdown: (state, action: PayloadAction<iPriceBreakdown>) => ( {
            ...state, 
            breakdown: action.payload
        }),

        //CURRENCY
        setCurrency: (state, action: PayloadAction<any>) => ( {
            ...state, 
            breakdown: {
                ...state.breakdown,
                currency: action.payload
            }
        }),
        setValidOnEPROM: (state, action: PayloadAction<boolean>) => ({
            ...state,
            validOnEPROM: {
                ...(state.validOnEPROM || {}),
                [state.activePairSun]: action.payload
            }
        }),
        setFamily: (state, action: PayloadAction<string>) => ({
            ...state,
            familySun: action.payload
        }),
        openTooltip: (state, action: PayloadAction<boolean>) => ({
            ...state,
            tooltipOpen: action.payload
        }),
        sunExperienceProducts: (state, action: PayloadAction<any>) =>({
            ...state,
            sunExpProducts:action.payload
        }),
        setAlreadyKnow2: (state, action: PayloadAction<any>) =>({
            ...state,
            alreadyKnow:action.payload
        })
    }
})
export const getLensConsultationSun = (state:iRootState) => state.lensConsultationSun;
export default lensConsultationSun;