import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../components/ui/Icon';
import { getApp } from '../../../store/slices/app.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';

interface Props {
    price: string,
    name?: string | null,
    selected: boolean,
    categories: Array<Object>,
    isDisabled: boolean,
    label: string
}

const PackageProduct : React.FC<Props>  = ({
    price,
    name,
    selected,
    categories,
    isDisabled,
    label
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('package-product');
    const {t} = useTranslation(useSelector(getApp));

    // HOOKS

    // METHODS

    return (
        <div className={B()}>
            
                {
                    !isDisabled ? (
                        <div className={E('product')}>
                            <p>
                            <span className="price">{price}</span>
                            <span className="sublabel">{t(label)}</span>
                            </p>                            
                            <h4>{name}</h4>

                            { selected && <Icon name="check" type="png"/> }
                            { !selected && <div></div> }
                        </div>
                    ) : (
                        <div className={E('product')}>
                            <h4>Not available</h4>
                        </div>
                    )
                }
            

            <ul className={E('lenses')}>
                {
                    categories.map((i:any, k:any)=>{
                        return (
                            <li key={k} style={{display: i.label ? 'block' : 'none' }}>
                            <span></span>
                            <label>{t(i.label)}</label>
                            </li> 
                        )
                    })

                }
            </ul>

            {/* <span className={E('glass')}>* Le verre</span> */}
        </div>
    )
}

export default PackageProduct;