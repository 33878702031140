import React, { useEffect, useState } from 'react';
import useBEM from '../../utils/hooks/useBEM';
import PrescriptionLensProposal from './components/PrescriptionLensProposal';
import PrescriptionForm from './components/PrescriptionForm';
import PrescriptionSide from './components/PrescriptionSide';
import { useDispatch, useSelector } from 'react-redux';
import prescriptionSlice, { getPrescription } from '../../store/slices/prescription.slice';
import PrescriptionViewType from '../../types/prescription-view.type';
import { getApp } from '../../store/slices/app.slice';
import ExperienceType from '../../types/experience.type';
import Modal from '../../components/Modal';
import NoRxModal from './components/NoRxModal';
import PrescriptionLensProposalSun from './components/PrescriptionLensProposalSun';
import useTranslation from '../../utils/hooks/useTranslation';
import PrescriptionLens from './components/PrescriptionLens';
import { PRESCRIPTION_LENS_DATA } from '../../constants/prescription.constants';
import PrescriptionSunwear from './components/PrescriptionSunwear';

interface Props {

}

const Prescription : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('prescription');
    const { view, lensType } = useSelector(getPrescription);
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const {t} = useTranslation(appStore);
    const [showRxModal, setShowRxModal] = useState(false);

    const dispatch = useDispatch();

    // HOOKS
    useEffect(() => {
        return () => {
            dispatch(prescriptionSlice.actions.changeView(PrescriptionViewType.PRESCRIPTION))
            dispatch(prescriptionSlice.actions.resetData());
        }
    },[])

    // METHODS

    return (
        <div className={B(experience) + ' page'}>

            {(view !== PrescriptionViewType.PRESCRIPTIONLENS && view !== PrescriptionViewType.SUNWEAR) && <PrescriptionSide />}

            <div className={E('main')}>

                {
                    view === 'prescription' &&
                    <>
                        <PrescriptionForm/>

                        {
                            experience === ExperienceType.SUN &&
                            <p className={E('no-rx')} onClick={() => setShowRxModal(true)}>
                                {t('sun_exp_no_rx', 'Prescription is not important for me')}
                            </p>
                        }
                    </>
                }

                {
                    view === 'lens-proposal' && experience !== ExperienceType.SUN &&
                    <PrescriptionLensProposal/>
                }

                {
                    view === 'lens-proposal' && experience === ExperienceType.SUN &&
                    <PrescriptionLensProposalSun/>
                }

                {
                    view === PrescriptionViewType.PRESCRIPTIONLENS && experience === ExperienceType.SUN &&
                    <PrescriptionLens lensType={lensType ? lensType : ""} content={lensType && PRESCRIPTION_LENS_DATA[lensType]} />
                }

                {
                    view === PrescriptionViewType.SUNWEAR && experience === ExperienceType.SUN &&
                    <PrescriptionSunwear />
                }
            </div>

            {
                showRxModal &&
                <NoRxModal show={showRxModal} onClose={() => setShowRxModal(false)}/>
            }

        </div>
    )
}

export default Prescription;
