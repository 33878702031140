import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { lang } from 'moment';
import { discount, lensThickness } from '../../constants/my-companion.constants';
import { KIDS_THEMES } from '../../constants/theme.constants';
import {TRANSLATIONS_DEFAULT} from '../../constants/translations.contants';
import iDiscountSetting from '../../interface/discount-setting';
import ematDetails from '../../interface/emat-details';
import iGlobalInformation from '../../interface/global-information';
import iLensThicknessSetting from '../../interface/lens-thickness-setting';
import iTheme from '../../interface/theme.interface';
import iTranslation from '../../interface/translation.interface';
import iAppStore from '../interface/app-store.interface';
import iMyCompanionStore from '../interface/mycompanion-store.interface';
import iRootState from '../interface/root-state.interface';
import { languageCode } from '../../constants/language-code.constants';
import iProductCombi from '../interface/product-combi.interface';
import iRegion from '../../interface/region.interface';
import iCountry from '../../interface/country.interface';
import ExperienceType from '../../types/experience.type';
import { IProductEprom } from '../../constants/eprom.constants';

const initialState: iAppStore  = {
    dataStructureVersion:0,
    loading: {
        show: false
    },
    theme: KIDS_THEMES[1],
    lensThicknessSettings: lensThickness,
    discountSettings: discount,
    language: 'enUS',
    subLanguage: '',
    regions: [],
    translation: TRANSLATIONS_DEFAULT.enUS,

    globalInformation: {
        storeName: '',
        phone: '',
        address: '',
        email: '',
        license: '',
        nickname: ''
    },
    recentEmat: [],
    recentNew: [],
    firstLogin: {
        view: 'region',
        isOpen: false,
        selectedLanguage: 'enUS',
        subLanguage: {code: '', country: ''},
        firstStatus: false,
        cookieEnabled: false
    },
    ematModal : {
        isOpen: false,
        selected: {
            type: '',
            label: '',
            description: '',
            created_date: '',
            file: '',
        }
    },
    products: [],
    productCombi: [],
    myCompannion: {
        isPriceOff: true,
        essilorRecommend: false,
        category: 'correct',
        tempLensThicknessSettings: lensThickness,
        tempDiscountSettings: discount,
        configUpdatedAt: ''
    },

    dataRetention: {
        lastDeletionDate: new Date('01/01/2000'),
        lastCheckedServerDate: new Date('01/01/2000'),
        isDateSync: true
    },
    globalAlert: {
        show: false,
        message: ''
    },
    experience: ExperienceType.STANDARD,
    isModalOpen:false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        loading: (state, action: PayloadAction<boolean | {
            show: boolean,
            text?: string,
            textOnly?: boolean
        }>) => {
            if(typeof action.payload === 'boolean'){
                return {
                    ...state,
                    loading: {
                        show: action.payload,
                    }
                }
            }

            return {
                ...state,
                loading: action.payload
            }

        },
        changeTheme: (state, action: PayloadAction<iTheme>) => ({...state, theme: action.payload }),
        showGlobalAlert: (state, action: PayloadAction<{
            show: boolean,
            message?: string,
            action?: () => void
        }>) => ({...state, globalAlert: action.payload }),
        // saveVideoBlob: (state, action: PayloadAction<{
        //     video: 'discovery' | 'measurement',
        //     blob: string | undefined
        // }>) => ({
        //     ...state,
        //     videos: {
        //         ...state.videos,
        //         [action.payload.video]: action.payload.blob
        //     }
        // }),
        saveLensThicknessSettings: (state, action: PayloadAction<iLensThicknessSetting[]>) => {
            return {
                ...state,
                lensThicknessSettings: action.payload
            }
        },
        saveDiscountSettings: (state, action: PayloadAction<iDiscountSetting[]>) => {
            return {
                ...state,
                discountSettings: action.payload
            }
        },
        setLanguage: (state, action: PayloadAction<string>) => ({ ...state, language: action.payload }),
        setSubLanguage: (state, action: PayloadAction<string>) => ({ ...state, subLanguage: action.payload }),
        setToken: (state, action: PayloadAction<string>) => ({ ...state, token: action.payload }),
        saveAccountDetails: (state, action: PayloadAction<any>) => ({ ...state, accountDetails: action.payload }),
        saveRegions: (state, action: PayloadAction<iRegion[]>) => ({ ...state, regions: action.payload }),
        setTranslationRegion: (state, action: PayloadAction<iRegion>) => ({ ...state, translationRegion: action.payload }),
        setTranslationCountry: (state, action: PayloadAction<iCountry>) => ({ ...state, translationCountry: action.payload }),
        setTranslationCountryLanguage: (state, action: PayloadAction<{
            country: string,
            languageCode: string
        } | undefined >) => ({ ...state, translationCountryLanguage: action.payload }),        
        // setTranslations: (state, action: PayloadAction<{[key: string]: iTranslation}>) => ({ ...state, translations: action.payload }),
        setTranslations: (state, action: PayloadAction<{[key: string]: iTranslation}>) => {
            let o = action.payload;
            let sorted:any = {},
            key:any, store:any = [],
            storeKey:any = [];
            o = {
                ...action.payload,
                ...languageCode
            }


            for (key in o) {
                if (o.hasOwnProperty(key)) {
                    store.push(o[key]);
                    storeKey.push(key);
                }
            }
            store.sort( function( firstSort:any, secondSort:any ) {
                firstSort = firstSort.country.toLowerCase();
                secondSort= secondSort.country.toLowerCase();
                return firstSort < secondSort ? -1 : firstSort > secondSort ? 1 : 0;
            });
            storeKey.sort();
            store.map((key:any, i:number)=>{
                sorted[key.code] = key;
            })

            return {
                ...state, translations: sorted
            }
        },
        setTranslation: (state, action: PayloadAction<iTranslation>) => ({
            ...state,
            translation: action.payload
        }),
        setProducts: (state, action: PayloadAction<any>) => ({ ...state, products: action.payload }),
        setProductCombi: (state, action: PayloadAction<{
            data: iProductCombi[],
            isInAppRefresh?: boolean
        }>) => {
            let { data, isInAppRefresh = false } = action.payload;

            const getOriginalValue = (find: iProductCombi, list: iProductCombi[]) => {
                return list.filter( x => (
                    find.correct === x.correct &&
                    find.correct_sub === x.correct_sub &&
                    find.protect1 === x.protect1 &&
                    find.protect2 === x.protect2 &&
                    find.enhance === x.enhance &&
                    find.index === x.index
                ))[0]
            }

            if(isInAppRefresh){
                const originalDataList = [...state.productCombi];
                data = data.map( x => {
                    const originalData = getOriginalValue(x, originalDataList);
                    return !originalData ? x : {
                        ...originalData,
                        price: x.price,
                        protect1_price: x.protect1_price,
                        protect2_price: x.protect2_price
                    };
                });
            }

            return {
                ...state,
                productCombi: data
            }
        },
        updateAdvancePriceCombinations: (state, action: PayloadAction<any>) => ( {
            ...state,
            myCompannion: {
                ...state.myCompannion,
                isPriceOff: false,
                essilorRecommend: true
            },
            productCombi: action.payload
        }),
        saveGlobalInformation: (state, action: PayloadAction<iGlobalInformation>) => ({ ...state, globalInformation: action.payload }),
        saveLicense: (state, action: PayloadAction<string>) => ({
            ...state,
            globalInformation: {
                ...state.globalInformation,
                license: action.payload
            }
        }),
        updateLicenseStatus: (state, action: PayloadAction<string>) => {
            if(!state.accountDetails)return state;
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    state: action.payload
                }
            }
        },
        setLicenseValidationDate: (state, action: PayloadAction<string>) => ({
            ...state,
            licenseValidationDate: action.payload
        }),
        seteMatList: (state, action: PayloadAction<{today: any, load : ematDetails[]}>) => {
            const payload = action.payload;
            let newList:any[];
            newList = payload.load.map((v, i: number) =>{
                // const currDate = moment(action.payload.today);
                // const createdDate = moment(moment(v.created_date, "YYYY-MM-DD").format("MM/DD/YYYY"));
                // const validate = currDate.subtract(2, 'weeks');
                // if (createdDate.isSameOrAfter(validate) && i <= 5) {
                //     return v;
                // }
                // else return 0
                const start = moment(v.created_date);
                const end = moment(action.payload.today);
                const daysOld = end.diff(start, "days");
                if(daysOld <= 14)return v;
                return 0;
            })
            return {
                ...state,
                recentEmat: newList
            }
        },
        setNewList: (state, action: PayloadAction<{today: any, load :  ematDetails[]}>) => {
            const payload = action.payload;
            let newList:any[];
            newList = payload.load.map((v, i: number )=>{
                // const currDate = moment(action.payload.today);
                // const createdDate = moment(moment(v.created_date, "YYYY-MM-DD").format("MM/DD/YYYY"));
                // const validate = currDate.subtract(2, 'weeks');
                // if (createdDate.isSameOrAfter(validate) && i <= 5) {
                //     return v;
                // }
                // else return 0
                const start = moment(v.created_date);
                const end = moment(action.payload.today);
                const daysOld = end.diff(start, "days");
                if(daysOld <= 14)return v;
                return 0;
            })
            return {
                ...state,
                recentNew: newList
            }
        },
        setEmatModal: (state, action: PayloadAction<{open: boolean, detail?: ematDetails}>) => ({
            ...state,
            ematModal: {
                isOpen: action.payload.open,
                selected: action.payload.detail
            }
        }),
        setViewFirstLogin: (state, action: PayloadAction<string>) => ({
            ...state,
            firstLogin: {...state.firstLogin, view: action.payload}
        }),
        setOpenFirstLogin: (state, action: PayloadAction<boolean>) => ({
            ...state,
            firstLogin: {...state.firstLogin, isOpen: action.payload}
        }),
        selectedLanguage: (state, action: PayloadAction<string>) => ({
            ...state,
            firstLogin: {...state.firstLogin, selectedLanguage: action.payload}
        }),
        selectedSubLanguage: (state, action: PayloadAction<{code: string, country: string}>) => ({
            ...state,
            firstLogin: {...state.firstLogin, subLanguage: {
                code: action.payload.code, country: action.payload.country
            }}
        }),
        setFirstLoginStatus: (state, action: PayloadAction<boolean>) => ({
            ...state,
            firstLogin: {...state.firstLogin, firstStatus: action.payload}
        }),
        setCookieEnabled: (state, action: PayloadAction<boolean>) => ({
            ...state,
            firstLogin: {...state.firstLogin, cookieEnabled: action.payload}
        }),
        updateDataStructureVersion: (state, action: PayloadAction<number>) => ({
            ...state,
            dataStructureVersion: action.payload
        }),
        resetData: (state) => ({
            ...state,
            recentEmat: [],
            recentNew: [],
            ematModal : {
                isOpen: false,
                selected: {
                    type: '',
                    label: '',
                    description: '',
                    created_date: '',
                    file: '',
                }
            },
            globalAlert: {
                show:false
            },
            myCompannion: {
                ...( state.myCompannion || initialState.myCompannion )
            },

            //remove propery
            translations: undefined,
            videos: undefined
        }),
        //MY COMPANNION
        saveMyCompannionData: (state, action: PayloadAction<iMyCompanionStore>) => ( {
            ...state,
            myCompannion: action.payload
        }),

        updateDataRententionData: (state, action: PayloadAction<{
            key: string,
            value: any
        }>) => ( {
            ...state,
            dataRetention: { 
                ...state.dataRetention,
                [action.payload.key]: action.payload.value
            }
        }),
        setExperience: (state, action: PayloadAction<ExperienceType>) => ({
            ...state,
            experience: action.payload
        }),
        saveEPROMData: (state, action: PayloadAction<IProductEprom>) => ({
            ...state,
            epromData: action.payload
        }),
        setIsModalOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isModalOpen: action.payload
        }),
    }
});

export const getApp = (state:iRootState) => state.app;
export default appSlice;
