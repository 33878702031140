import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import landingCards from '../../../constants/education.constants';
import iOption from '../../../interface/option.interface';
import { getApp } from '../../../store/slices/app.slice';
import educationSlice from '../../../store/slices/education.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useTranslation from '../../../utils/hooks/useTranslation';
import Icon from '../../ui/Icon';

interface Props {
    onSelect?: (view: iOption) => void
}

const EducationLanding : React.FC<Props>  = ({
    onSelect
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('education-landing');
    const cards = landingCards;
    const dispatch = useDispatch();
    const { t } = useTranslation(useSelector(getApp));

    // HOOKS
    useEffect(() => {
        dispatch(educationSlice.actions.sessionStart(new Date().toISOString()));
    }, [])

    // METHODS
    const selectCard = ({id, label}: any) => {
        //remove after pilot
        if(id && onSelect) onSelect({ value: id, label })
    }

    return (
        <div className={B()}>
            <div className={E('instruction')}>
                <h2>
                    {t('education_header_ttile_engage')}
                </h2>
                {/* <p>
                    Etiam gravida est vel dolor finibus, at gravida nibh consectetur. Nunc feugiat ut metus at vestibulum. Aliquam in condimentum eros. Aliquam mollis nibh arcu.
                </p> */}
            </div>
            <div className={E('card-container')}>

                {cards.map((card, index) => {
                    return (
                        <div className={E('card')} onClick={() => selectCard(card)} key={index}>
                            <div className={E('card-background', [card.soon ? 'soon' : ''])}>

                                <Icon name={card.image} type="image"/>
                                <div className="info">
                                    {
                                        card.icon &&
                                        <span>
                                            <Icon name={card.icon} type="image"/>
                                        </span>
                                    }
                                    <label>{t(card.label)}</label>

                                    { card.soon && card.id !== 'eye'? <button>{t('education_edu_coming_soon')}</button> : ''}
                                    {/* //remove after pilot */}
                                    { card.soon && card.id === 'eye'? <button>{t('education_edu_coming_soon')}</button> : ''}
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default EducationLanding;
