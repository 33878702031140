import iNeedExplorerQuestion from "../interface/need-explorer-question.interface";
import iNeedExplorerCategory from "../interface/need-explorer-category.interface";


const needExplorerSunQuestionaires: iNeedExplorerCategory[] = [
    {
    id: '8',
    category: 'need_explorer_sun',
    isComplete:false,
    questions: [
        {
            id: '8.1',
            question: 'What factors affect your purchase decision the most while buying sunglasses?',
            kind: 'Rank from most to least important',
            type: 'RANKING_GROUP_IMAGE',
            withImage: true,
            situations: [
                { key: '8.5.1', label: 'UV Protection', image: 'sun_uv_protection' },
                { key: '8.5.2', label: 'Vision comfort', subLabel: "(less eyes strain and squinting)", image: 'sun_vision_comfort' },
                { key: '8.5.3', label: 'Performance when practicing outdoor sports (better depth and contrast perception)', image: 'sun_performance_outdoor' },
                { key: '8.5.4', label: 'Stylish frames and lens colors', image: 'sun_stylish' },
            ],
            answer: [],
            isAnswered: false
        },
        {
            id: '8.2',
            question: 'Rate the following based on what describes your lifestyle the best.',
            kind: '',
            type: 'RATING_GROUP', //to change
            withImage: false,
            situations: [
                { key: '8.2.1', label: 'Walking/cycling around the city' },
                { key: '8.2.2', label: 'working outdoors on your laptop' },
                { key: '8.2.3', label: 'Spending time in the park/under the sun reading in your Tablet' },
                { key: '8.2.4', label: 'Jogging/practicing sports' },
                { key: '8.2.5', label: 'Going on road trips' },
                { key: '8.2.6', label: 'Hiking/trekking' },
                { key: '8.2.7', label: 'Spending time by the sea' },
                { key: '8.2.8', label: 'Spending time aroun the environment where it snows' }
            ],
            answer: [],
            isAnswered: false
        },
        {
            id: '8.3',
            question: 'How many hours per day on average do you spend on digital screens while outdoors?',
            kind: '',
            type: 'FREQUENCY', //to change --> frequency
            image: "sun_rx_wearers",
            frequency: 4,
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.4',
            question: 'Rate the following digital devices based on daily usage when outdoors',
            kind: 'Rate the situation from 1 to 5  (5 being the highest)',
            type: 'RATING_GROUP_IMAGE', //to change
            withImage: true,
            situations: [
                { key: '8.5.1', label: 'Laptop/Table', image: "sun_laptop_table" },
                { key: '8.5.2', label: 'Smartphone', image: "sun_vision_comfort2" },
                { key: '8.5.3', label: 'Navigation System', subLabel: "(Google Maps...)", image: "sun_vision_navigation" },
                { key: '8.5.4', label: 'Smart Watch', image: "sun_smart_watch" },
            ],
            answer: [],
            isAnswered: false
        },
        {
            id: '8.5',
            question: "Do you feel the need to protect your eyes from the sun when you're exposed to bright light?",
            subQuestion: "(ex: covering them with your hand)",  //--> to add
            kind: '',
            image: "sun_rx_wearers_woman",
            frequency: 5,
            type: 'FREQUENCY',
            options: [
                { value: '8.6.1', label: 'daily_mobility_q_sc_yes' },
                { value: '8.6.2', label: 'daily_mobility_q_sc_no' }
            ],
            isAnswered: false
        },
        {
            id: '8.6',
            question: 'How often do you ride a scooter/bicycle?',
            kind: '',
            type: 'FREQUENCY', //to change --> frequency
            frequency: 1,
            image: "sun_carry_bike",
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.7',
            question: 'How often do you drive a car/motorbike?',
            kind: '',
            type: 'FREQUENCY', //to change --> frequency
            frequency: 1,
            image: "sun_car_drive",
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.8',
            question: "Does the bright light outdoors cause difficulties in visualizing objects while driving?",
            //    subtext: "(ex: covering them with your hand)",  --> to add
            kind: 'need_explorer_single_choice_question',
            type: 'FREQUENCY',
            frequency: 5,
            image: "sun_car_sunlight",
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.9',
            question: 'How often do you drive long distances(>50km)?',
            kind: '',
            type: 'FREQUENCY', //to change --> frequency
            image: "sun_car_hiway",
            frequency: 2,
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.10',
            question: 'How often do you find yourself in an environment where it snows?',
            kind: '',
            type: 'FREQUENCY', //to change --> 
            image: "sun_snow",
            frequency: 3,
            answer: 0,
            isAnswered: false
        },
        {
            id: '8.11',
            question: 'Rate the following sport activities based on how often you practice them',
            kind: 'Rate the situation from 1 to 5  (5 being the highest)',
            type: 'RATING_GROUP_IMAGE', //to change
            withImage: true,
            situations: [
                { key: '8.12.1', label: 'Water sports', image: 'sun_speedboat' },
                { key: '8.12.2', label: 'Urban Sports',subLabel: "(running,skateboarding, rollerblading...)" ,image: 'sun_skateboard' },
                { key: '8.12.3', label: 'Mountain Sports', subLabel: "(Hiking, Mountain Biking...)", image: 'sun_bike_ride' },
                { key: '8.12.4', label: "I don't practice any of these sports", image: 'stop-black', type:'clear' },
            ],
            answer: [],
            isAnswered: false
        },
    ]
}
]

export default needExplorerSunQuestionaires;