const PRESCRIPTION_RULES = {
    standard: {
        sphere: {
            increment: 0.25,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0.25,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false,
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: false,
                requiredSign: true
            }
        }
    },
    ava: {
        sphere: {
            increment: 0,
            min: -20.00,
            max: 20.00,
            validation: {
                required: true,
                requiredSign: true
            }
        },
        cylinder: {
            increment: 0,
            min: -6,
            max: 6,
            validation: {
                required: false,
                requiredSign: true
            }
        },
        axis: {
            increment: 1,
            min: 0,
            max: 180,
            suffix: '°',
            validation: {
                required: false,
                requiredSign: false
            }
        },
        addition: {
            increment: 0.25,
            min: 0.50,
            max: 4.00,
            validation: {
                required: false,
                requiredSign: true
            }
        }
    }
}

const PRESCRIPTION_FORM = [
    {
        label: 'prescription_rx_sphere',
        name: 'sphere',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_cylinder_',
        name: 'cylinder',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_axis_',
        name: 'axis',
        fields: [
            {
                position: 'od',
                label: 'rx_righ_teye'
            },
            {
                position: 'os',
                label: 'rx_left_eye'
            }
        ]
    },
    {
        label: 'prescription_rx_addition_',
        name: 'addition',
        fields: [
            {}
        ]
    }
]

const PRESCRIPTION_LENS_DATA : any = {
    "single vision": {
        title: `Eyezen${'\u00AE'} SunFit${'\u2122'}`,
        titleDescription:'sun_exp_rx_eyezen_sun_selected_title_desc',
        description: "sun_exp_rx_eyezen_sun_selected_1st_pars",
        subtitle: "sun_exp_rx_eyezen_sun_selected_2nd_par_title",
        benefits: [
            "sun_exp_rx_eyezen_sun_selected_2nd_par_cont_1",
            "sun_exp_rx_eyezen_sun_selected_2nd_par_cont_2",
            "Comfortable vision even with high-wrap frames"
        ],
        footer: "sun_exp_rx_varilux_sun_selected_2nd_par_cont_4s",
        citation: "sun_exp_rx_eyezen_sun_selected_2nd_par_cont_5",
        icons:[
            {
            icon:'sharpvision',
            label:['sun_exp_rx_sharp_vis', 'SHARP VISION'],
            },
            {
            icon:'widefield',
            label:['sun_exp_rx_wide_vis', 'WIDE FIELDS OF VISION']
            },
            {
            icon:'visionComfort',
            label:['sun_exp_rx_vis_comf','VISION COMFORT']
            }
        ],

        default: {
            titleDescription:' - single vision correction outdoors',
            description: `This new versatile sun lens design is adapted to daily life outdoor activities and urban mobility, with wide field of vision and special optimization for outdoor digital usage, offering great visual comfort in dynamic conditions. <br><br> Eyezen${'\u00AE'} SunFit${'\u2122'} comes with the new W.A.V.E™ Sun technology for improved visual acuity outdoors.* <br><br>`,
            subtitle: "Benefits for the wearers:",
            benefits: [
                "High-contrast vision at both near and far distance in all outdoor conditions (also in cloudy environment) thanks to the W.A.V.E™ Sun technology​",
                'Large fields of view in far and near vision',
                "Comfortable vision even with curved sport frames"
            ],
            footer: "For the first time, Essilor R&D took into account the size of the pupil behind sun lenses in its W.A.V.E. measurements. <br /><br> Thanks to W.A.V.E™ Sun technology, your contrast perception is considerably enhanced, thus resulting in a sharper, clearer vision, in all sunglass usage conditions.*",
            citation: "*Compared with standard SV products.",
            icons:[
                {
                icon:'sharpvision',
                label:'SHARP VISION',
                },
                {
                icon:'widefield',
                label:'WIDE FIELDS OF VISION'
                },
                {
                icon:'visionComfort',
                label:'VISION COMFORT'
                },
            ]
        }
    },
    "progressive": {
        title: `Varilux${'\u00AE'} SunFit${'\u2122'}`,
        titleDescription:'sun_exp_rx_varilux_sun_selected_title_desc',
        description: "sun_exp_rx_varilux_sun_selected_1st_par",
        subtitle: "sun_exp_rx_eyezen_sun_selected_2nd_par_title",
        benefits: [
            "sun_exp_rx_eyezen_sun_selected_2nd_par_cont_1",
            "sun_exp_rx_vision_need_why_choose_design_par_3",
            "sun_exp_rx_varilux_sun_selected_cont_3"
        ],
        footer: "sun_exp_rx_varilux_sun_selected_2nd_par_cont_4",
        citation: "*Compared with standard Essilor PAL products.",
        icons:[
            {
            icon:'sharpvision',
            label:['sun_exp_rx_sharp_vis', 'SHARP VISION'],
            },
            {
            icon:'widefield',
            label:['sun_exp_rx_wide_vis', 'WIDE FIELDS OF VISION']
            },
            {
            icon:'visionComfort',
            label:['sun_exp_rx_vis_comf','VISION COMFORT']
            },
            {
                icon:'smoothTransition',
                label:['sun_exp_rx_vis_smooth_trans','SMOOTH TRANSITION']
            },
        ],

        default: {
            titleDescription:' - presbyopia correction outdoors',
            description: "This Progressive Addition design is adapted to daily life outdoor activities and urban mobility, with wide field of vision, smooth far-near transition and special optimization for the outdoor usage of digital devices.",
            subtitle: "Benefits for the wearers:",
            benefits: [
                "High-contrast vision in outdoor conditions (also in cloudy environment) thanks to the Wave Sun technology. <br /> Large fields of view in Far Vision",
                "Large fields of view in far and near vision",
                "Comfortable vision even with curved sport frames",
                [
                    "Quick and easy adaptation",
                    "Smooth transitions Near-Far Vision:",
                    [
                        "for digital usage",
                        "for dynamic conditions (going down the stairs/a slope..)"
                    ]
                ],
            ],
            footer: "For the first time, Essilor R&D took into account the size of the pupil behind sun lenses in its W.A.V.E. measurements.<br /> Thanks to WaveSun technology, your contrast perception is considerably enhanced, thus resulting in a sharper, clearer vision, in all sunglass usage conditions.",
            citation: "*Compared with standard Essilor PAL products.",
            icons:[
                {
                icon:'sharpvision',
                label:'sun_exp_rx_sharp_vis',
                },
                {
                icon:'widefield',
                label:'sun_exp_rx_wide_vis'
                },
                {
                icon:'visionComfort',
                label:'sun_exp_rx_vis_comf'
                }
            ],

        }
    }
}

const PRESCRIPTION_SUNWEAR_DATA : any = {
    title: "sun_exp_rx_vision_need_why_choose_design",
    subtitle: "sun_exp_rx_vision_need_why_choose_design_par_1",
    details: [
        "sun_exp_rx_vision_need_why_choose_design_par_2",
        "sun_exp_rx_vision_need_why_choose_design_par_3",
        "sun_exp_rx_vision_need_why_choose_design_par_4"
    ],
    footer: "sun_exp_rx_vision_need_why_choose_design_par_5",

    default: {
        title: "Why choosing a design optimized for sunwear?",
        subtitle: "Because outdoor vision conditions and needs are specific! ",
        details: [
            {
                img: "sun_point_mountain",
                description: "Specific outdoor vision conditions",
                benefits: [
                    "Bright light & light intensity variation",
                    "Different pupil behavior behind sun lenses affecting visual aquity",
                    "Prevalence of far distance field of view"
                ]
            },
            {
                img: "sun_smart_mountain",
                benefits: [
                    "Increasing outdoor usage of digital devices, demanding improved visual comfort in near vision ",
                    "Need of easy transition from far to near (and vice versa)",
                    "Need to account for sun frames with large wrap angles (specific sport sun shapes), to allow a comfortable and clear vision "
                ]
            },
            {
                img: "sun_two_jetski",
            }
        ],
        footer: "Two optical designs have thus been specially developed taking into account the vision behind sun lenses, to offer <strong>optimal vision when out in the sun.</strong>"
    }
}

const NO_RX_DATA: { [key: string]: {
    title: string,
    paragraph: string,
    subTitle: string,
    label?: string,
    list: {
        image: string,
        label: string,
        paragraph?: string
    }[],
    defaultEnglish?: any,
}} = {
    default: {
        //Vision outside is just as important as vision indoors
        title: 'sun_exp_no_rx_importance_of_vision_outside',
        // If you need vision correction to see indoors, then you need vision correction to see outdoors. Wearing non-prescription sunglasses if you require prescriptive lenses may compromise your vision outdoors and may contribute to eye fatigue.
        paragraph: 'sun_exp_no_rx_importance_of_vision_outside_exp',
        // Your Sun equipment should:
        subTitle: 'sun_exp_no_rx_sun_equip',
        list: [
            // Correct your vision so you <br>  can see well near and far
            { label: 'sun_exp_no_rx_correct_your_vision', image: 'correct' },
            // Protect your eyes<br> from UV & glare
            { label: 'sun_exp_no_rx_protectc_your_eyes', image: 'protect' },
            // Enhance<br> your experience
            { label: 'sun_exp_no_rx_enhance_exp', image: 'enhance' }
        ]
    },
    myopia: {
        title: 'education_edu_myopia',
        paragraph: 'sun_exp_rx_popup_2_myopia_1st_par',
        label: 'Myopia',
        subTitle: 'sun_exp_rx_popup_2_vision_imp',
        list: [
            { label: 'sun_exp_rx_popup_2_vision_driving', image: 'Driving', paragraph: 'sun_exp_rx_popup_2_vision_tracffic'},
            { label: 'sun_exp_rx_popup_2_outdoor', image: 'OutdoorActivites', paragraph: 'sun_exp_rx_popup_2_clearly'},
        ],

        // TEMP
        defaultEnglish: {
            title: 'Myopia',
            paragraph: 'People with <strong>myopia</strong> are also called shortsighted. It means they have trouble with long-distance vision but have a good short-distance vision.',
            label: 'c',
            subTitle: 'Vision impact:',
            list: [
                { label: 'Driving', image: 'refresh', paragraph: 'It may be difficult to read traffic and road signs ahead'},
                { label: 'Outdoor activites', image: 'refresh', paragraph: 'It may be difficult to clearly see the players or plays on a sports field or appreciate scenic views on a hike or vacation'},
            ]
        }
    },
    hyperopia: {
        label: 'Hyperopia',
        title: 'education_edu_hyperopia',
        paragraph: 'sun_exp_rx_popup_2_hyperopia_1st_par',
        subTitle: 'sun_exp_rx_popup_2_vision_imp',
        list: [
            { label: 'sun_exp_rx_popup_2_vision_driving', image: 'Driving', paragraph: 'sun_exp_rx_popup_2__hyperopia_driving__impact' },
            { label: 'sun_exp_rx_popup_2_outdoor', image: 'OutdoorActivites', paragraph: 'sun_exp_rx_popup_2__hyperopia_outdoor_act_impact' }
        ],

        // TEMP
        defaultEnglish: {
            title: 'Hyperopia',
            paragraph: 'People with <strong>hyperopia</strong> are called hyperopic or far-sighted. It causes near objects to appear blurred. It can come to a point where both distant and near objects may be blurred. The eye must constantly accommodate and this causes visual fatigue.',
            label: 'c',
            subTitle: 'Vision impact:',
            list: [
                { label: 'Driving', image: 'refresh', paragraph: 'It may be difficult to read directions on your GPS or see the digital dash clearly.'},
                { label: 'Outdoor activites', image: 'refresh', paragraph: 'It may be difficult to focus on objects close up like a fitness tracker.'},
            ]
        }

    },
    presbyopya: {
        label: 'Presbyopia',
        title: 'education_edu_presbyopia',
        paragraph: 'sun_exp_rx_popup_2_presbyopia_1st_par',
        subTitle: 'sun_exp_rx_popup_2_vision_imp',
        list: [
            { label: 'sun_exp_rx_popup_2_vision_driving', image: 'Driving', paragraph: 'sun_exp_rx_popup_2__presbyopia_driving__impact' },
            { label: 'sun_exp_rx_popup_2_outdoor', image: 'OutdoorActivites', paragraph: 'sun_exp_rx_popup_2__presbyopia_outdoor_act_impact' }
        ],

        // TEMP
        defaultEnglish: {
            title: 'Presbyopia',
            paragraph: '<strong>Presbyopia</strong> is a natural change of vision that affects people over the age of 40. It can exist in conjunction with any other existing eyesight problems. It becomes increasingly difficult to see close up.',
            label: 'c',
            subTitle: 'Vision impact:',
            list: [
                { label: 'Driving', image: 'refresh', paragraph: 'You may have difficulty checking the directions in your GPS or speed on your car dashboard'},
                { label: 'Outdoor activites', image: 'refresh', paragraph: 'You may have difficulty during activities like cycling when trying to change from viewing the terrain ahead back to your mileage tracker. '},
            ]
        }
    },
    astigmatism: {
        label: 'Astigmatism',
        title: 'education_edu_astigmatism',
        paragraph: 'sun_exp_rx_popup_2_astigmatism_1st_par',
        subTitle: 'sun_exp_rx_popup_2_vision_imp',
        list: [
            { label: 'sun_exp_rx_popup_2_vision_driving', image: 'Driving', paragraph: 'sun_exp_rx_popup_2__astigmatism_driving__impact'},
        ],

        // TEMP
        defaultEnglish: {
            title: 'Astigmatism',
            paragraph: 'People with <strong>astigmatism</strong> have an imprecise short and long-distance vision. They have a distorted vision of objects. Astigmatic people confuse similar letters such as h, m and n, or 8 and 0. This vision defect can be present at the same time as myopia or hyperopia.',
            label: 'c',
            subTitle: 'Vision impact:',
            list: [
                { label: 'Driving', image: 'refresh', paragraph: 'Added blur at all distances.'},
                { label: 'Outdoor activites', image: 'refresh', paragraph: 'You could struggle from discomfort while watching a child in a pool or from wearing non-prescription sunglasses and reading a text message outdoors.'},
            ]
        }
    }
}

export {
    PRESCRIPTION_FORM,
    NO_RX_DATA,
    PRESCRIPTION_LENS_DATA,
    PRESCRIPTION_SUNWEAR_DATA
}
export default PRESCRIPTION_RULES;
