import React, { useEffect, useRef, useState } from 'react'; 
import useBEM from '../../../../utils/hooks/useBEM';
import MultipleChoice from './MultipleChoice'; 
import CircleCheck from './../../../../components/ui/CircleCheck'; 
import SUN_EXPERIENCE_SOLUTION from './../../../../constants/sun-solution.constants';
import iSunExperienceSolution from '../../../../interface/sun-experience-solution.interface';
import iSunExperienceSolutionColor from '../../../../interface/sun-experience-solution-color.interface';
import Loading from '../../../../components/ui/Loading';
import useTranslation from '../../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../../store/slices/app.slice';
import { getNeedExplorer } from '../../../../store/slices/need-explorer.slice';
import { getNeedExplorerSun } from '../../../../store/slices/need-explorer-sun.slice';
import lensConsultationSun, { getLensConsultationSun } from '../../../../store/slices/lens-consultation-sun-exp.slice';
import sunSolution from '../../../../constants/sun-solution.constants'

interface Props { 
    alreadyKnow?: string, 
    solutionId?: string,
    onAnswer: (alreadyKnow: string, solution?: iSunExperienceSolution, tint?: iSunExperienceSolutionColor  ) => void 
    onSave: () => void
    setStep: (alreadyKnow:string | undefined) => void
}

const ProtectSelectionStep : React.FC<Props>  = ({ 
    alreadyKnow,
    solutionId,
    onAnswer,
    onSave,
    setStep
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('protect-selection-step'); 
    const timer = useRef<any>();
    const [showLoader, setShowLoader] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const firstLoad = useRef(true);
    const {t} = useTranslation(useSelector(getApp))
    const { questions } =useSelector(getNeedExplorerSun);
    const { sunExpProducts } =useSelector(getLensConsultationSun);
    const {isComplete} = questions[0]
    const sunExpProductsOrder:any = []
    const sunExpReorder = sunSolution.map(prod => sunExpProductsOrder.push(sunExpProducts.find(prd => prd.id === prod.id)))
    const sunExpProdReorder:iSunExperienceSolution[] = sunExpProductsOrder.filter((prd:iSunExperienceSolution | undefined) => prd !== undefined)

    //hooks
    useEffect(() => { 
        return () => {
            clearTimer();
        }
    }, [])
    useEffect(() => {
        clearTimer();
        setIsSaved(false); 
        setShowLoader(false);
        // if(alreadyKnow === 'no')
        save();
    }, [alreadyKnow]);

    useEffect(() => {        
        if(firstLoad.current){
            firstLoad.current = false;
            return;
        }
        
        if(!solutionId)return;
        clearTimer();     
        save();
    }, [solutionId]);
    
    //methods
    const onAnswerHandler = (alreadyKnow: string, solutionId?: string) => {
        const targetSolution: iSunExperienceSolution = sunExpProducts.find( x => x.id === solutionId)
        const defaultTint:iSunExperienceSolutionColor = targetSolution?.sun_experience?.color_group[0].colors[0]
        onAnswer(alreadyKnow, targetSolution, defaultTint);
    }

    const save = () => {
        setShowLoader(true); 
        setIsSaved(false); 
        timer.current = setTimeout(x => {
            setShowLoader(false); 
            setIsSaved(true);  
            
            timer.current = setTimeout(() => {
                setStep(solutionId)
                onSave();
            }, 1000);
        }, 1000);
    }

    const clearTimer = () => {
        if(timer.current)clearTimeout(timer.current);
    }

    return (
        <div className={B()}> 
             

            <div className={E('content')}> 
                {/* <label>
                    {t('sun_exp_protect_you_from_sun' , 'Protect you from the sun')}                
                </label>  */}

                <h4>
                    {t('sun_exp_sun_solution', 'Do you already know which sun solution you need?')}
                </h4>

                    <MultipleChoice
                    choice={ alreadyKnow }
                    choices={[
                        { id: 'yes', label: t('sun_exp_sun_solution_yes', 'Yes') },
                        { id: 'no', label: t('sun_exp_sun_solution_no', 'No') },
                    ]}
                    onAnswer={(x) => onAnswerHandler(x.id)}/>
                {
                    alreadyKnow === 'yes' && <>
                        <hr className={E('divider')}/>
                        <MultipleChoice 
                            choice={solutionId}
                            choices={sunExpProdReorder?.map( ({id, sun_experience}) => ({
                                id,
                                label: t(sun_experience?.translationCode[0], sun_experience?.translationCode[1]),
                                description: `${sun_experience?.color_group?.length} ${t('sun_exp_sun_solution_tint_colors', 'Colors')}`
                            }))}
                            onAnswer={(x) => onAnswerHandler(alreadyKnow, x.id)}
                        />                        
                    </>
                }     

                <div className={E('saved')}> 
                    { (showLoader && !isSaved) &&
                        <Loading dim='2rem' size='.25rem'/>
                    }
                    { isSaved && 
                        <>
                            <CircleCheck color='#F35D1D' diameter={1.75}/> {t('sun_exp_sun_solution_tint_yes', 'Saved')}
                        </>                        
                    }

                </div>
    
            </div>
        </div>
    )
}

export default ProtectSelectionStep;