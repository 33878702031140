
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from '../../components/ui/ButtonGroup';
import DEMO_CONTROL_BUTTONS from '../../constants/demonstrations.contants';
import productDemoSlice, { getProductDemo } from '../../store/slices/products-demo.slice';
import productsSlice, { getProducts } from '../../store/slices/products.slice';
import LensFamilyType from '../../types/lens-family.type';
import useBEM from '../../utils/hooks/useBEM';
import ProductDemo from './components/ProductDemo';
import ProductDemoCompare from './components/ProductDemoCompare';
import ProductDemoSelection from './components/ProductDemoSelection';
import ProductDescription from './components/ProductDescription';
import useGoogleAnalytics from '../../utils/hooks/useGoogleAnalytics';
import iLensDemo from '../../interface/lens-demo';
import iProductDemo from '../../interface/product-demo';
import Utils from '../../utils/Utils';
import Spinner from '../../components/ui/Spinner';
import Button from '../../components/ui/Button';
import useGoto from '../../utils/hooks/useGoto';
import iButtonGroupButton from '../../interface/button-group-button.interface';
import ProductDemoType from '../../types/product-demo.type';
import { getLensConsultation } from '../../store/slices/lens-consultation.slice';
import useTranslation from '../../utils/hooks/useTranslation';
import { getApp } from '../../store/slices/app.slice';
import Icon from '../../components/ui/Icon';
import { IndexedDBContext, IndexedDBContextType } from '../../utils/context/indexedDBContext';
import DemoDownloadType from '../../types/product-download.type';
import lensConsultationSun, { getLensConsultationSun } from "../../store/slices/lens-consultation-sun-exp.slice"
import ExperienceType from '../../types/experience.type';

interface Props {
    type?: 'page' | 'modal'
}

const Demonstrations : React.FC<Props>  = ({
    type = 'page'
}) =>  {
    
    // VARIABLES
    const [B, E] = useBEM('demonstrations');
    const dispatch = useDispatch();
    const { 
        brandToDemo, 
        productToDemo, 
        demoDownloadStatus, 
        preloadedProductDemos,
        isQrCodeOpen
    } =  useSelector(getProducts);
    const { 
        demoType, 
        subDemoType,
        activeProductDemo,
        isControlsDisabled,
        isCompareEnabled,
        lensFamily,
        productDescription,
        recommendationDemo,
        recommendationDemoBrand,
        demoFrom,
        enableDrawLine,
        qrPopup,
    } = useSelector(getProductDemo);
    // const [position, setPosition] = useState('center');   
    const [demoTypes, setDemoTypes] = useState<any[]>();   
    const { downloadAssetBase64 } = Utils();
    const ga = useGoogleAnalytics();
    const [preloadingComplete, setPreloadingComplete] = useState(false);  
    const [preloadCount, setPreloadCount] = useState(0);   
    const [preloadCompleteCount, setPreloadCompleteCount] = useState(0);   
    const goto = useGoto();
    const [recommendationTypes, setRecommendationTypes] = useState<iButtonGroupButton[]>();
    const { lensRecommendation, selectedProtect, activePair} = useSelector(getLensConsultation);
    const { t } = useTranslation(useSelector(getApp));
    const [isChangeDemoTypeLoading, changeDemoTypeLoading] = useState(false);
    const { isOpen } = useSelector(getProducts);
    const {get, insert} = React.useContext(IndexedDBContext) as IndexedDBContextType;
    const mounted = useRef<boolean>(false);
    const { tint } = useSelector(getLensConsultationSun);
    const [parentDemo, setParentDemo] = useState<iLensDemo>();
    const [demoColor, setDemoColor] = useState<any>();
    const appStore = useSelector(getApp);
    const { experience } = appStore;

    // HOOKS
    const [isQrOpen, setIsQrOpen] = useState<boolean>(false)

    useEffect(() => {
        dispatch(productDemoSlice.actions.updateVideoPlayFlag(0))
        mounted.current = true;
        return () => {
            dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
            dispatch(productDemoSlice.actions.disableControls(false));
            dispatch(productDemoSlice.actions.toggleDrawLine(true));
            dispatch(productDemoSlice.actions.setProductDescription(null));
            mounted.current = false;
        }
    }, []);
    useEffect(() => {
        if(type === 'page'){
            setLensType(LensFamilyType.CORRECT)
        }
    }, []);

    useEffect(() => {
        if(!demoType)return;

        const eventAction = isOpen ? 'Product' : 'LC';
        ga.event({
            category: `Demo - ${productToDemo?.label}`, 
            action: `Demonstration - ${eventAction}`,
            label: `See Demo - ${demoType?.demo}`
        });

    }, [demoType, productToDemo])

    useEffect(() => {
        if(lensFamily === LensFamilyType.PROTECT && lensRecommendation) {
            const protect = lensRecommendation[activePair]?.protect;
            if(protect && protect?.length > 0 && protect[0].color) {
            setDemoColor(protect[0].color?.label)
            }
        } 

    }, [lensRecommendation])

    useEffect(() => {
        const lensTypes: iButtonGroupButton[] = [
            {
                value:'correct',
                label:'demo_demo__correct',
                disabled: !recommendationDemo?.correct
            },
            {
                value:'protect',
                label:'demo_demo__protect_',
                disabled: !recommendationDemo?.protect
            },
            {
                value:'enhance',
                label:'demo_demo__enhance',
                disabled: !recommendationDemo?.enhance
            }
        ]
        setRecommendationTypes(lensTypes);
    }, [recommendationDemo]);

    useEffect(() => {
        if(!brandToDemo)return;
        ga.event({
            category: `Demo - Brand`, 
            action: brandToDemo.label
        });
    }, [brandToDemo])

    useEffect(() => {
        if(!productToDemo)return;
        setDemoTypes(productToDemo.demo?.map( d => ({
            icon: d.demo,
            value: d.demo
        })));        
        // const defaultDemo = (productToDemo.demo as iLensDemo[])[0];
        // changeDemoType(defaultDemo);

        setPreloadingComplete(false);
        setPreloadCompleteCount(0);
        preloadDemoAssets(() => {
            if(!mounted.current)return;
            const defaultDemo = (productToDemo.demo as iLensDemo[])[0];
            setParentDemo(defaultDemo);
            changeDemoType(defaultDemo);
        });        
    }, [productToDemo])

    // METHODS
    const isAlreadyPreloaded = async (demo: string, productId: string) => {
        const id = `${demo}_${productId}`;
        const asset = await get('lensDemoStore',id);
        // console.log(`is already preloaded = ${asset ? true: false}`, asset)
        return asset ? true : false; 
    }

    const isNested = (type: ProductDemoType) => {
        return [
            ProductDemoType.NESTED_DEMO, 
            ProductDemoType.NESTED_DEMO_TRANSVERSAL
        ].includes(type)
    }

    const preloadDemoAssets = async (onComplete?: () => void) => {
        const toPreloadAssets: any[] = [];
        productToDemo?.demo?.forEach( d => {

            if(d.is360){
                (d.products as iProductDemo[]).forEach( (p) => {
                    // if(!p.drawline || isAlreadyPreloaded(d.demo, p.id))return;

                    if(!p.drawline)return;

                    const leftright = Object.keys(p.drawline);
                    for(const posKey of leftright){
                        const pos = (p.drawline as any)[posKey];
                        const blurline = Object.keys(pos);
                        for(const imgType of blurline){
                            const id = `${p.id}_${posKey}-${imgType}`;
                            toPreloadAssets.push({
                                demo: d.demo,
                                productId: id,
                                asset: pos[imgType]
                            });

                        }
                    }
                });
                return;
            }

            if(isNested(d.demoType)) {
                (d.products as iLensDemo[]).forEach( d => {
                    (d.products as iProductDemo[]).forEach( p => {
                        if(!p.asset)return;
                        toPreloadAssets.push({
                            demo: d.demo,
                            productId: p.id,
                            asset: p.asset
                        });
                    }) 
                });
                return;
            }

            // Note: stellest start
            let additionalAssets: any[] = [];
            if(d.control) {
                d.control.products.forEach( ( chp: any )=> {
                    if(!chp.asset)return;
                    toPreloadAssets.push({
                        demo: d.control?.demo,
                        productId: chp.id,
                        asset: chp.asset
                    });
                });

                d.control.children?.forEach( (ctrlChild) => {                    
                    ctrlChild.products.forEach( ( ctrlChildProduct: any )=> {
                        if(!ctrlChildProduct.asset)return;
                        toPreloadAssets.push({
                            demo: ctrlChild?.demo,
                            productId: ctrlChildProduct.id,
                            asset: ctrlChildProduct.asset
                        });
                    });
                })
            }
            // if(d.corect)additionalAssets.push(d.corect);

            const children = d.children;
            if(children && children.length > 0 ) {
                children.forEach( ch => {
                    ch.products.forEach( ( chp: any )=> {
                        if(!chp.asset)return;
                        toPreloadAssets.push({
                            demo: ch.demo,
                            productId: chp.id,
                            asset: chp.asset
                        });
                    });
                });
            }

            additionalAssets.forEach( ap => {
                if(!ap.asset)return;
                toPreloadAssets.push({
                    demo: d.demo,
                    productId: ap.id,
                    asset: ap.asset
                });
            });
            // Note: stellest end

            (d.products as iProductDemo[]).forEach( (p) => {
                if(!p.asset)return;
                toPreloadAssets.push({
                    demo: d.demo,
                    productId: p.id,
                    asset: p.asset
                });
            });
        });

        setPreloadCount(toPreloadAssets.length);
        for (const  asset of toPreloadAssets) {
            const isAlreadyDownloaded = await isAlreadyPreloaded(asset.demo, asset.productId)
            if(!isAlreadyDownloaded){
                const base64 = await downloadAssetBase64(asset.asset);
                await insert('lensDemoStore', {
                    ...asset, 
                    id: `${asset.demo}_${asset.productId}`,
                    asset: base64,
                });
            }
            setPreloadCompleteCount(prev => prev + 1);
        }
            setPreloadingComplete(true);
            if(onComplete)onComplete();
            dispatch(productsSlice.actions.productPreloadComplete(productToDemo?.id as string))
    }


    const getSubDemoTypes = (demo: iLensDemo) => {

        return (demo.products as iLensDemo[]).filter( d => {
            if (lensFamily === LensFamilyType.PROTECT) {
                return (d.products as iProductDemo[]).filter( (p) => p.colorID === demoColor).length > 0
            }
            else {
            return (d.products as iProductDemo[]).filter( (p) => p.id === productToDemo?.id).length > 0
            }
        });
    }

    const withStandard = (products: any[]) => {
        return products.filter( x => x.id.includes('standard')).length
    }

    const changeDemoType = (demo: iLensDemo) => {
         if(isChangeDemoTypeLoading)return;
        dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
        changeDemoTypeLoading(true);
        setTimeout(() => {
            changeDemoTypeLoading(false);
        }, 1500);
    
        if(isNested(demo.demoType)){
            const demoToUse = getSubDemoTypes(demo)[0];
            dispatch(productDemoSlice.actions.enableCompare(true));
            dispatch(productDemoSlice.actions.changeDemoType(demo));       
            dispatch(productDemoSlice.actions.setSubDemoType(demoToUse));     
            const rightAssset = (demoToUse.products as iProductDemo[]).filter(p => p.id === productToDemo?.id)[0];
            const leftVideo = (demoToUse.products as iProductDemo[]).filter(p => p.id === 'standard')[0];
            dispatch(productDemoSlice.actions.setActiveProductDemo(leftVideo));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(rightAssset));
            return;
        }

        const isTransversal = demo.products.length >= 3 && withStandard(demo.products);
        const productsToUse = demo.products as iProductDemo[];
        let activeProductDemo = productsToUse.filter(p => p.id === productToDemo?.id)[0];
        
        const isDividerOnly = demo.demoType === ProductDemoType.DIVIDER_VIDEO;

        // Note: compare is enable by default if
        // Transversal , Divider, Child demo
        const isCompare = ( isTransversal && !demoType?.is360 ) || isDividerOnly || demo.compareByDefault === true;
        
        dispatch(productDemoSlice.actions.enableCompare(isCompare));
        dispatch(productDemoSlice.actions.changeDemoType(demo));       
        
        // Note: if compare, set left and right video/image
        if( isCompare ) {
            
            let leftVideo = productsToUse.filter(p => p.id === 'standard')[0];

            // set default color to demo for PROTECT 
            if(lensFamily === LensFamilyType.PROTECT && type === 'page' && lensRecommendation){
                const protect = lensRecommendation[activePair]?.protect;
                if(protect && protect?.length > 0 && protect[0].color){
                    const selectedColor = productsToUse.filter(p => p.colorID === protect[0].color?.id)[0];
                    if(selectedColor) activeProductDemo = selectedColor;        
                }

                // change the left video if it is equal to right video
                if(leftVideo.id === activeProductDemo.id){
                    leftVideo = productsToUse.filter(p => p.id !== activeProductDemo.id)[0];
                }
            }
            
            dispatch(productDemoSlice.actions.setActiveProductDemo(leftVideo));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(activeProductDemo));
        } else {
            let productDemo = productsToUse.find((p) => p.colorID === demoColor) || activeProductDemo
            lensFamily === LensFamilyType.PROTECT ? dispatch(productDemoSlice.actions.setActiveProductDemo(productDemo)):
            dispatch(productDemoSlice.actions.setActiveProductDemo(activeProductDemo))
        }
    }

    const hideDescription = () => {
        dispatch(productDemoSlice.actions.setProductDescription(null))
    }

    const toggleCompare = (demo: iLensDemo | undefined, forceValue?: boolean) => {
        changeDemoTypeLoading(true);
        setTimeout(() => {
            changeDemoTypeLoading(false);
        }, 1500);
        
        dispatch(productDemoSlice.actions.updateVideoPlayFlag(0));
        const enable = forceValue !== undefined ? forceValue : !isCompareEnabled;

        const productsToUse = (demoType?.demoType && isNested(demoType?.demoType) ? subDemoType : demoType)?.products as iProductDemo[];
        
        if(!activeProductDemo)return;
        if(!demo)return;
        if(demo?.products.length < 2)return;

        if(demo?.products.length >= 3) {
            //Note: transversal
            const leftVideoID = enable ? 'standard' : productToDemo?.id;
            let leftVideo = productsToUse.filter( p => p.id === leftVideoID)[0];
            
            if(leftVideoID === "standard" && !leftVideo){
                leftVideo = productsToUse.filter( p => p.id !== productToDemo?.id)[0];
            }

            const rightVideo = enable ? productsToUse.filter( p => p.id === productToDemo?.id)[0] : undefined;
                
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(rightVideo));
            if(leftVideo)dispatch(productDemoSlice.actions.setActiveProductDemo(leftVideo));
        } else {
            
            const standard = productsToUse.filter( p => p.id === 'standard')[0];
            const withEffects = productsToUse.filter( p => p.id === productToDemo?.id)[0];
            const leftVideo =  enable ? standard : withEffects;
            if(leftVideo)dispatch(productDemoSlice.actions.setActiveProductDemo(leftVideo));

            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(enable ? withEffects: undefined));
        }

        dispatch(productDemoSlice.actions.enableCompare(enable));
    }

    const setLensType = (value: LensFamilyType) => {
        dispatch(productDemoSlice.actions.openQrPopup(false))
        dispatch(productDemoSlice.actions.changeDemoType(undefined));
        dispatch(productDemoSlice.actions.setActiveProductDemo(undefined));
        dispatch(productDemoSlice.actions.setActiveCompareProductDemo(undefined));
        dispatch(productsSlice.actions.selectBrandTodemo(undefined))
        dispatch(productDemoSlice.actions.selectLensFamily(value));        
        setTimeout(() => {
            if(!recommendationDemo || !recommendationDemoBrand)return;
            const newProductToDemo = recommendationDemo[value];
            const newBrand = recommendationDemoBrand[value];

            dispatch(productsSlice.actions.selectBrandTodemo(newBrand))
            dispatch(productsSlice.actions.selectProductTodemo(newProductToDemo))
        }, 500);
    }

    const onSelectProduct = (product: iProductDemo) => {
        dispatch(productDemoSlice.actions.setActiveProductDemo(product));
    }
    const back = () => {
        if(!demoFrom)return;
        goto.route(demoFrom);        
    }

    // const enableProductSelection = () => {
    //     if(!demoType)return false;
    //     return ![
    //         ProductDemoType.SEE_THROUGH_IMAGE,
    //         ProductDemoType.SEE_THROUGH_VIDEO
    //     ].includes(demoType?.demoType);
    // }

    const isDemoWithCompare = () => {
        if(!demoType || demoType.hideCompareButton )return false;
        return ![
            ProductDemoType.SEE_THROUGH_IMAGE,
            ProductDemoType.SEE_THROUGH_VIDEO,
            ProductDemoType.VIDEO,
            ProductDemoType.NESTED_DEMO,
            ProductDemoType.DIVIDER_VIDEO
        ].includes(demoType?.demoType);
    }

    const isDemoWithCenter = () => {
        if(!demoType || demoType.hideCenterButton )return false;

        return ![
            ProductDemoType.SEE_THROUGH_IMAGE,
            ProductDemoType.SEE_THROUGH_VIDEO,
            ProductDemoType.SIDE_BY_SIDE_VIDEO,
            ProductDemoType.VIDEO,
            ProductDemoType.DIVIDER_VIDEO
        ].includes(demoType?.demoType);
    }

    const selectSubDemo = (subDemo: iLensDemo) => {
        if(isChangeDemoTypeLoading)return;
        changeDemoTypeLoading(true);
        setTimeout(() => {
            changeDemoTypeLoading(false);
        }, 1000);

        dispatch(productDemoSlice.actions.setSubDemoType(subDemo)); 
        const rightAssset = (subDemo.products as iProductDemo[]).filter(p => p.id === productToDemo?.id)[0];  
        if(!isCompareEnabled){
            dispatch(productDemoSlice.actions.setActiveProductDemo(rightAssset));
            dispatch(productDemoSlice.actions.setActiveCompareProductDemo(undefined));
            return;
        }
        const leftVideo = (subDemo.products as iProductDemo[]).filter(p => p.id === 'standard')[0];
        dispatch(productDemoSlice.actions.setActiveProductDemo(leftVideo));
        dispatch(productDemoSlice.actions.setActiveCompareProductDemo(rightAssset));
    }

    const triggerCenter = () => {
        dispatch(productDemoSlice.actions.triggerCenter());
    }

    const toggleDrawLine = () => {
        dispatch(productDemoSlice.actions.toggleDrawLine(!enableDrawLine))
    }

    const isProductHasSubDemo = (products: iProductDemo[]) => {
        return products.filter( p => p.id === productToDemo?.id).length > 0
    }

    const selectChildDemo = (option: string) => {
        if(
            !parentDemo || 
            !parentDemo.children
        )return;

        if(!option){
            changeDemoType(parentDemo);   
            return;
        }

        const toDemo = parentDemo.children.filter(d => d.demo === option)[0];
        if(toDemo)changeDemoType(toDemo);   
    }

    const showControlChildren = () => {
        return ( parentDemo?.control &&
               parentDemo?.control.demo === demoType?.demo ) ||
               (demoType && parentDemo?.control?.children?.map( x => x.demo).includes(demoType?.demo))
    }

    const onSelectControl = (v: string) => {
        if(demoType?.demo === v || !parentDemo)return;
        const toSelect = parentDemo.control?.demo === v ? 
            parentDemo.control : 
            parentDemo;

        changeDemoType(toSelect);   
    }

    const preloadCountProgress = () => {
        if(!brandToDemo)return;
        const brandIsDownloaded = demoDownloadStatus && 
            demoDownloadStatus[brandToDemo.id] &&
            demoDownloadStatus[brandToDemo.id].status === DemoDownloadType.COMPLETED;
        const productIsPreloaded = (preloadedProductDemos || []).includes(productToDemo?.id as string);   
        if( brandIsDownloaded || productIsPreloaded)return '';
        return `${preloadCompleteCount}/${preloadCount}`;
    }
    const selectRGB = () => (experience === ExperienceType.SUN ? "215 64 0" : "0 134 214")
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    // const isTransitions = () =>{
    //     if(brandToDemo && demoFrom?.id === 'lensConsultation')
    //     return brandToDemo.id === 'transitions'
    // }

    const openQrCode = () =>{
        dispatch(productDemoSlice.actions.openQrPopup(!qrPopup));
    }



    return (
        <div className={B(type)}>
            
            { !preloadingComplete &&
                <div className={E('preload')}>
                    <Spinner 
                        color={`rgb(${selectRGB()} / 33%)`} 
                        text={preloadCountProgress()}   
                    />

                    <label>Pre-loading Assets...</label>
                </div> }

            { preloadingComplete && type === 'page' && demoFrom &&
                <Button 
                    className={E('back')}
                    outlineType="light"
                    text={t(demoFrom.label)} 
                    icon={`left-arrow-circle-gray${selectIcon()}`}
                    iconPosition="left" type="secondary" 
                    click={back}
            /> }


            {
                preloadingComplete && 
                <>
                        {/* <div className={E('canvas', isTransitions() ? 'side-by-side-video': demoType?.demoType)}> */}
                        <div className={E('canvas', demoType?.demoType)}>
                            {
                                !isCompareEnabled && productToDemo && activeProductDemo &&
                                <>
                                    <ProductDemoSelection 
                                    selectedDemo={activeProductDemo}
                                    onSelectProduct={(p) => onSelectProduct(p as iProductDemo)}
                                    />     
                                    <ProductDemo isChangeDemoTypeLoading={isChangeDemoTypeLoading}/>   
                                </>
                            }

                            { qrPopup && <div className={E('qrcode-container')}>
                                <div className={E('qrcode-wrapper')}>
                                    <div className={E('qrcode-header')} onClick={openQrCode}>
                                        <div className={E('qrcode-header-icon')}>
                                            <Icon name='close-white-modal'/>
                                        </div>
                                        Virtually try out your next lenses and frames
                                    </div>
                                    <div className={E('qrcode')}>
                                        <p className={E('qrcode-label')} >
                                            <ul>
                                                <li className={E('qrcode-label-title')}>
                                                    Try out in 3 steps
                                                </li>
                                                <li className={E('qrcode-label-step')}>
                                                    <span>1 -</span>
                                                    <span>Start your Virtual Try-On experience by scanning the QR Code</span>
                                                </li>
                                                <li className={E('qrcode-label-step')}>
                                                    <span>
                                                        2 -
                                                    </span>
                                                    <p>
                                                     Configure your glasses by selecting a lens type, lens color and frame type.
                                                    </p>
                                                </li>
                                                <li className={E('qrcode-label-step')}>
                                                    <span>
                                                    3 -
                                                    </span>
                                                    <p>
                                                      Screenshot and share it with your friends or family
                                                    </p>
                                                </li>
                                            </ul>
                                        </p>
                                        <div className={E('qrcode-icon-placement')}>
                                            <div className={E('qrcode-icon')}>
                                                <Icon name={'vto_qr'} type='png'/>
                                            </div>
                                        </div>
                                    <div className={E('qrcode-footer')}>
                                            *To fully enjoy the VTO experience, avoid backlight and position yourself arms length from your laptop or your smartphone
                                    </div>
                                    </div>
                                </div>
                            </div>}
                            
                            <ProductDescription isOpen={productDescription ? true : false} onClose={() => hideDescription()}/>  

                            { isCompareEnabled && <ProductDemoCompare type={type} isChangeDemoTypeLoading={isChangeDemoTypeLoading}/> }

                            {   demoType?.demoType === ProductDemoType.NESTED_DEMO_TRANSVERSAL &&
                                <div className={E('nested-demo' , [isChangeDemoTypeLoading ? 'loading': ''])}>                                    
                                    {
                                        (demoType.products as iLensDemo[]).map( d => (
                                            isProductHasSubDemo(d.products as iProductDemo[]) && 
                                            <label 
                                                key={d.demo} 
                                                onClick={() => selectSubDemo(d)} 
                                                className={subDemoType?.demo === d.demo ? 'active': ''}>
                                                <Icon type="png" name={d.demo}/>
                                            </label>
                                        ))
                                    }
                            </div> }


                            {   
                                parentDemo && parentDemo.control &&
                                showControlChildren() &&
                                <div className={E('control-children', type)}>
                                    <Button 
                                        click={() => {
                                            if(parentDemo && parentDemo?.control){
                                                changeDemoType(parentDemo?.control);
                                            }
                                        }}
                                        disabled={isChangeDemoTypeLoading}
                                        type={parentDemo?.control.demo === demoType?.demo ? 'primary' : 'secondary'}
                                        text={t(parentDemo?.control.label || '')}
                                    />

                                    { parentDemo?.control.children?.map( childDemo => (
                                        <Button 
                                            disabled={isChangeDemoTypeLoading}
                                            type={childDemo.demo === demoType?.demo ? 'primary' : 'secondary'}
                                            text={t(childDemo.label || '')} 
                                            click={() => changeDemoType(childDemo)}
                                        />
                                    ))}
                                </div> 
                            }  
                            {/* {   
                                isTransitions() && type === 'page' &&
                                <div className={E('vto-qrCode', type)}>
                                    <Button 
                                        click={() => {openQrCode()}}
                                        type={'primary'}
                                        text={"Virtually try out your next lenses and frames"}
                                        icon={'qrcode-white'}
                                    />
                                </div> 
                            }  
                             */}
                        </div>
                        { !isQrCodeOpen &&<div className={E('controls')}>
                            {
                                demoTypes &&
                                <ButtonGroup 
                                    disabled={isChangeDemoTypeLoading}
                                    iconType="png"
                                    value={ demoType?.demo }
                                    onSelect={v => {
                                        if(!v)return;
                                        const toDemo = productToDemo?.demo?.filter(d => d.demo === v)[0];
                                        if(toDemo)changeDemoType(toDemo);   
                                        setParentDemo(toDemo);

                                        ga.event({
                                            category: `Demo - Brand`, 
                                            action: brandToDemo
                                        });
                                    }}
                                    buttons={demoTypes}
                                    productId={productToDemo?.id}
                                    />
                            }

                            {/* { isDemoWithCenter() && !isTransitions() && */}
                            { isDemoWithCenter() &&
                                <ButtonGroup 
                                    disabled={isChangeDemoTypeLoading}
                                    onSelect={() => triggerCenter()}
                                    buttons={[{
                                        value:'center',
                                        label:'demo_demo_center'
                                    }]}/> 
                            }

                            { demoType?.is360 &&
                                <ButtonGroup 
                                    value={ enableDrawLine ? 'draw-line': '' }
                                    onSelect={v => toggleDrawLine()}
                                    buttons={[{
                                        value:'draw-line',
                                        label:t('demo__draw_line')                                    
                                    }]
                                }/> 
                            }

                            
                            {   //temporary hidden
                                // isDemoWithCompare() && !isTransitions() &&
                                isDemoWithCompare() &&
                                <ButtonGroup 
                                    disabled={isChangeDemoTypeLoading}
                                    className={E('compare')}
                                    value={isCompareEnabled ? 'compare' : ''}
                                    onSelect={v => toggleCompare(demoType)}
                                    buttons={[{
                                        value:'compare',
                                        label: 'demo_demo__compare'
                                    }]}/> 
                            }

                            {
                                parentDemo?.control &&
                                <ButtonGroup 
                                    disabled={ isChangeDemoTypeLoading }
                                    value={(() => { 
                                        const isChildControlSelected = parentDemo?.control.children?.
                                            map( x => x.demo).includes(demoType?.demo || '');
                                        if(isChildControlSelected)return parentDemo?.control.demo;

                                        return demoType?.demo;
                                    })()}
                                    onSelect={ onSelectControl }
                                    buttons={[
                                        { 
                                            value: parentDemo.demo, 
                                            label: t('demo_demo__correct')
                                        },
                                        { 
                                            value: parentDemo?.control.demo, 
                                            label: t('_control'), 
                                            instruction: t(parentDemo?.control.instruction || '') 
                                        }
                                    ]}/> 
                            }


                            { type === 'page' && recommendationTypes &&
                                <ButtonGroup 
                                className={E('family')}
                                value={lensFamily}
                                onSelect={v => setLensType(v as LensFamilyType)}
                                buttons={recommendationTypes}/> }
                            { isControlsDisabled && <div className={E('controls-disabled')}></div> }

                            {
                                parentDemo?.children && 
                                <ButtonGroup 
                                    disabled={isChangeDemoTypeLoading}
                                    value={demoType?.demo}
                                    className={E('children')}
                                    onSelect={selectChildDemo}
                                    buttons={parentDemo.children.map( x => ({
                                        value: x.demo,
                                        label: t(x.label || '')                              
                                    }))}                                    
                                />
                            }
                        </div>}                     
                </>
            }


                        
        </div>
    )
}

export default Demonstrations;
