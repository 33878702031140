import React, { useEffect, useState } from 'react'
import useBEM from '../../../utils/hooks/useBEM'
import { useSelector } from 'react-redux'
import { getEducation } from '../../../store/slices/education.slice'
import { eyeballVariants, crystallineDimensions } from '../../../../src/constants/education.constants'
import EyeballCrystalline from './EyeballCrystalline'


interface Props {

}

const EducationEyeball : React.FC<Props> = () => {

  const [B, E] = useBEM('education-eyeball')
  const {
    environment,
    visualDefect1,
    visualDefect2
  } = useSelector(getEducation)
  const [crystallineBallHeight, setCrystallineBallHeight] = useState(0)
  const [crystallineBallWidth, setCrystallineBallWidth] = useState(0)
  const [linesColor, setLinesColor] = useState('pink')

  useEffect(() => {
    if (visualDefect1 !== 'presbyopia') {
      setCrystallineBallHeight(36)
      setCrystallineBallWidth(20)
    } else {
      setCrystallineBallHeight(35)
      setCrystallineBallWidth(28)
    }
  }, [visualDefect1])

  const eyeballType = () => {
    if (visualDefect1 === '' && visualDefect2 === '') return ''
    if ((visualDefect1 === 'astigmatism' && visualDefect2 === '')|| (visualDefect1 === 'presbyopia' && visualDefect2 === '')) return 'Mid'
    if (visualDefect1 === 'myopia' && visualDefect2 === '') return 'Max'
    if (visualDefect1 === 'hyperopia' && visualDefect2 === '') return 'Min'
    if ((visualDefect1 === 'astigmatism' || visualDefect1 === 'presbyopia') && visualDefect2 === 'myopia') return 'Max'
    if ((visualDefect1 === 'astigmatism' || visualDefect1 === 'presbyopia') && visualDefect2 === 'hyperopia') return 'Min'
  }

  const corneaType = () => visualDefect1 === 'astigmatism' ? 'max' : 'min'

  const crystallineType = () =>  visualDefect1 === 'presbyopia' ? 'min' : 'max'

  const environmentType = () => {
    if (environment === undefined) return
    if (environment === 'sunlight') return 'light'
    else if (environment === 'night') return 'dark'
  }

  const eyeball = `${environmentType()}${eyeballType()}`

  const renderEyeball = (vD1: string, vD2: string = '') => {
    if (vD2 !== '') {
      return (
        <EyeballCrystalline
          classname='canvas'
          linesColor={linesColor}
          crystalLineBallHeight={crystallineDimensions[vD1].crystallineBallHeight}
          crystalLineBallWidth={crystallineDimensions[vD1].crystallineBallWidth}
          crystalLineWidth={crystallineDimensions[vD2].crystallineWidth}
          dimension={{h: 200, w: 400}}
          reflectionDiameter={10}
        />
      )
    }

    return (
      <EyeballCrystalline
        classname='canvas'
        linesColor={linesColor}
        crystalLineBallHeight={crystallineDimensions[vD1].crystallineBallHeight}
        crystalLineBallWidth={crystallineDimensions[vD1].crystallineBallWidth}
        crystalLineWidth={crystallineDimensions[vD1].crystallineWidth}
        dimension={{h: 200, w: 400}}
        reflectionDiameter={10}
      />
    )
  }

  return (
    <div className={`eyeball ${B()}`}>
      {
        visualDefect1 &&
        <>
          <img className={`${E('eyeimage')}`} src={eyeballVariants[eyeball]} />
          {/* <div className={`${E('cornea')} --${corneaType()}`} />
          <div className={`${E('crystalline')} --${crystallineType()}`} /> */}
          {renderEyeball(String(visualDefect1), visualDefect2)}
        </>
      }
    </div>
  )
}

export default EducationEyeball
