import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import SideBoxBordered from '../../../components/ui/SideBoxBordered';
import useBEM from '../../../utils/hooks/useBEM';
import LensAnimation from './LensAnimation';
import eyeGif from './../../../assets/gif/eye.gif';
import eyeSVG from './../../../assets/svg/eye.svg';
import { useDispatch, useSelector } from 'react-redux';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import LensPairType from '../../../types/lens-pair.type';
import LensTransitionInAnimation from './LensTransitionInAnimation';
import LensTransitionOut from './LensTransitionOut';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import lensThicknessSlice, { getlensThickness } from '../../../store/slices/lens-thickness.slice';
import prescriptionSlice, { getPrescription } from '../../../store/slices/prescription.slice';
import useLensThickness from '../../lens-thickness/useLensThickness';
import routes from '../../../constants/routes.constant';
import { useHistory } from 'react-router';
import { LENS_THICKNESS_RULES } from '../../../constants/lens-thickness.constants';
import Utils from '../../../utils/Utils';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import summarySlice from '../../../store/slices/summary.slice';
import productsSlice, { getProducts } from '../../../store/slices/products.slice';
import useGoto from '../../../utils/hooks/useGoto';
import productDemoSlice from '../../../store/slices/products-demo.slice';
import iLensRecommendationPair from '../../../interface/lens-recommendaiton-pair.interface';
import iProduct from '../../../interface/product.inteface';
import LensFamilyType from '../../../types/lens-family.type';
import { getApp } from '../../../store/slices/app.slice';
import useTranslation from '../../../utils/hooks/useTranslation';
import iProductBrand from '../../../interface/product-brand.interface';
import ExperienceType from '../../../types/experience.type';
import CircleArrow from '../../../components/ui/CircleArrow';
import LensConsultationSideSun from './sun-experience/LensConsultationSideSun';
import lensConsultationSun, { getLensConsultationSun } from "../../../store/slices/lens-consultation-sun-exp.slice"
import { TEMPORARY_PRODUCT_LIST } from '../../../constants/products.constants';

interface Props {
    experience: ExperienceType
}

const LensConsultationSideV2: React.FC<Props> = ({
    experience
}) => {
    // VARIABLES
    const [B, E] = useBEM('lens-consultation-side-v2');
    const history = useHistory();
    const [onHoverEye, setOnHoverEye] = useState(false);
    const [transitionOutType, setTransitionOutType] = useState<'Convexe' | 'Concave'>('Convexe');
    const [isAnimateLensThickness, setAnimateLensThickness] = useState(false);
    const { isLensThickness, showDialogue, showWarning } = useSelector(getLensConsultation)
    const [fadeOutSun, setFadeOutSun] = useState(false);
    const { temporaryProductList } = useSelector(getProducts)
    const { solution, lensRecommendationSun, } = useSelector(getLensConsultationSun);
    const tempList: any[] = TEMPORARY_PRODUCT_LIST;
    const {
        activePair,
        lensRecommendation,
        productFamily,
        sliderPage,
        validOnEPROM
    } = useSelector(getLensConsultation);

    const { prescription } = useSelector(getUser);
    const {
        getRecommendedSubProductByIndex,
        getRecommendedLensThickness,
        filterSubProductsWithEnabledIndex
    } = useLensThickness();
    const dispatch = useDispatch();
    const [isTransitionInAnimationComplete, setTransitionInAnimationComplete] = useState(false);
    const { view } = useSelector(getlensThickness);
    const { tempPrescriptionData, isValidPrescription } = useSelector(getPrescription);
    const { tempLensThickness } = useSelector(getlensThickness);
    const lensThicknessRules = LENS_THICKNESS_RULES;
    const lensThicknessTimestamp = useRef<Date>();
    const lensConsultationTimestamp = useRef<Date>();
    const { getDateTimeDifference } = Utils();
    const ga = useGoogleAnalytics();
    const goto = useGoto();
    const [recommendationToDemo, setRecommendationToDemo] = useState<{
        correct?: iProduct,
        protect?: iProduct,
        enhance?: iProduct,
    }>();
    const [recommendationToDemoBrands, setRecommendationToDemoBrands] = useState<{
        correct?: iProductBrand,
        protect?: iProductBrand,
        enhance?: iProductBrand,
    }>();
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { lensThicknessSettings, accountDetails } = appStore;
    const { deepEqual } = Utils();
    const isSunExp = () => experience === ExperienceType.SUN;
    const lensRecommendationByExperience = isSunExp() ? lensRecommendationSun : lensRecommendation

    // HOOKS
    useEffect(() => {
        lensConsultationTimestamp.current = new Date();
        setAnimateLensThickness(isLensThickness)
    }, [])
    useEffect(() => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        if (!pair) return;
        const withDemo = (product?: iProduct) => {
            if (!product ||
                !product.demo ||
                product.demo.length < 1
            ) return undefined;
            return product;
        }

        const recommendation = {
            correct: withDemo(pair.correct?.product),
            protect: pair.protect && pair.protect.length > 0 ? withDemo(pair.protect[0].product) : undefined,
            enhance: withDemo(pair.enhance?.product),
        }

        const recommendationBrands = {
            correct: pair?.correct?.brand || undefined,
            protect: pair.protect && pair.protect.length > 0 ? pair.protect[0].brand : undefined,
            enhance: pair?.enhance?.brand || undefined
        }
        setRecommendationToDemoBrands(recommendationBrands);
        setRecommendationToDemo(recommendation);
    }, [lensRecommendation, activePair])

    useEffect(() => {
        // Note: dont update on lens thickness
        if (sliderPage === 3) return;
        const sphere = prescription.values && prescription.values.sphere;
        if (sphere !== undefined) {
            if (!sphere.od || !sphere.os) return;
            const add = Number(sphere.od) + Number(sphere.os);
            setTransitionOutType(add < 0 ? 'Concave' : 'Convexe');
        }
    }, [prescription]);

    // Note: update recommended lens thickness
    const timer = useRef<any>();
    useEffect(() => {
        if (sliderPage < 3) return;
        const isSame = deepEqual(tempPrescriptionData, prescription);
        if (!isValidPrescription || isSame) {
            clearTimeout(timer.current);
            return;
        }
        if (timer.current) clearTimeout(timer.current);
        dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
        timer.current = setTimeout(() => {
            recommendLensThickness(tempPrescriptionData);
        }, 1000);
    }, [tempPrescriptionData])

    useEffect(() => {
        if (sliderPage === 0) {
            setAnimateLensThickness(false);
        }
    }, [sliderPage]);

    // METHODS
    const changePair = (pair: LensPairType) => {
        if (!isActivePairWithCorrectSubProduct(pair) && sliderPage === 3) return;

        dispatch(lensConsultationSlice.actions.changePair(pair));
    }

    const getLabelText = () => {
        if (sliderPage === 0) return t('lens_consultation_lc_lens_consultation');
        if (sliderPage === 3) return t('lens_thickness_lt_completed');
        if (productFamily) return t(productFamily.label);
        return '';
    }

    const getLabelIcon = () => {
        if (sliderPage === 0) return '';
        if (sliderPage === 3) return <Icon name="check-circle" />;
        if (productFamily) return <Icon name="progress-circle-white" type="png" />;
        return '';
    }
    const displayWarning = () => {
        if (!lensRecommendation) return false;

        const pair2 = lensRecommendation.pair2;
        if (
            (!pair2?.correct && (pair2?.enhance || (pair2?.protect || []).length > 0)) ||
            (pair2?.correct)
        ) return showDialogue;
        return false;
    }
    const displayWarning2 = () => {
        if (!lensRecommendation) return false;

        const pair2 = lensRecommendation.pair2;
        if ((pair2?.correct?.subProduct === null || !pair2?.correct?.subProduct) || (pair2?.protect && pair2?.protect.length < 1 ) || !pair2?.enhance?.product)return showWarning
        if(pair2?.correct?.subProduct && pair2.protect[0]?.product && pair2.enhance?.product)return false
        else return false;
    }
    const clearSecondPairRecom = (clear:boolean) => {
      dispatch(lensConsultationSlice.actions.clearSecondPair(clear));
    }

    const top = () => (
        <div className={E('top', experience)}>
            <label>
                {getLabelIcon()}
                {getLabelText()}
            </label>
            <div className={E('lens-pairs', activePair)}>
                <div className="pair1-selected">
                    <Icon name="lc-pair-1" click={() => changePair(LensPairType.PAIR1)} />
                </div>
                <span className="seprate"></span>
                <div className="pair2-selected">
                    <Icon name="lc-pair-2" click={() => changePair(LensPairType.PAIR2)} />
                </div>
                    {displayWarning2() && 
                        <div className="warning-icon">
                            <span>!</span>
                        </div>
                    }
                {displayWarning() &&
                    <>
                        <div className="dialogue">
                            {t('ec_pair_2_recommend_mess', 'Do you want recommend the complementary pair?')}
                            <div className='dialogue-buttons-container'>
                                <div className='dialogue-buttons'>
                                    <div className='dialogue-buttons-yes'onClick={() => clearSecondPairRecom(true)}>
                                        {t('daily_mobility_q_sc_yes')}
                                    </div>
                                    <div className='dialogue-buttons-no' onClick={() => clearSecondPairRecom(false)}>
                                        {t('daily_mobility_q_sc_no')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )

    const onHoverHandler = (isHover: boolean) => {
        setTimeout(() => {
            setOnHoverEye(isHover);
        }, isHover ? 0 : 500);
    }

    const eyeIconGif = () => {
        return <div className={E('eye-blink')}
            onMouseEnter={() => onHoverHandler(true)}
            onMouseLeave={() => onHoverHandler(false)}
        >
            <img src={eyeGif} alt="" />
            <img className={onHoverEye ? 'gif' : 'svg'} src={onHoverEye ? eyeGif : eyeSVG} alt="" />
        </div>
    }

    const isActivePairWithCorrectSubProduct = (pair?: LensPairType) => {

        if (!lensRecommendationByExperience) return false;
        const pair1Correct = lensRecommendationByExperience.pair1?.correct;
        const pair2 = lensRecommendationByExperience.pair2;

        if (!pair1Correct || !pair1Correct.product || !pair1Correct?.subProduct)
            return false;
        if ((pair2?.correct || (pair2?.protect || []).length > 0 || pair2?.enhance)
            && (!pair2?.correct?.product || !pair2?.correct?.subProduct))
            return false;
        if (pair === LensPairType.PAIR2 && !pair2?.correct) return false;
        return true;
    }

    const disableNextButton = () => {
        if (!lensRecommendationByExperience) return true;
        if (lensRecommendationByExperience.pair1?.correct === null) return true;

        if(accountDetails?.shop.eprom && ExperienceType.SUN)return false

        if(lensRecommendationByExperience && accountDetails?.shop.country === 'be'){
            const protect = lensRecommendationByExperience[activePair]?.protect
            if(protect && protect[1] && (protect[1]?.product?.id === 'xperio_mirrors' || protect[0]?.product?.id === 'xperio_mirrors'))return false

        }

        if (accountDetails?.shop.eprom && (
            validOnEPROM?.pair1 === false ||
            validOnEPROM?.pair2 === false
        )) return true;
        
        //eprom belgium new business rule
        const productsBelgiumSunX = [
            'transitions_xtractive',
            'transitions_xtractive_polarised',
            'xperio_polarised',
            'xperio_tinted',
            'xperio_mirrors',
            'xperio_tinted_mirrors',
            'xperio_polarised_mirrors',
            'style_mirrors']

        if(lensRecommendationByExperience){
            const protect = lensRecommendationByExperience[activePair]?.protect
            const enhance = lensRecommendationByExperience[activePair]?.enhance
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id &&
            enhance && 
            enhance?.product?.id
            ){
                if(enhance?.product?.id === 'crizal_sun_xprotect' && !productsBelgiumSunX.includes(protect[0]?.product?.id))return true
            }
            
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            !protect[0] &&
            enhance && 
            enhance?.product?.id &&
            enhance?.product?.id === 'crizal_sun_xprotect'
            )return true
            if(accountDetails?.shop.country === 'be' && 
            protect && 
            protect[0]?.product?.id === 'xperio_mirrors' && 
            !protect[1]
            )return true
        }



        // Lens Thickness
        if (sliderPage === 3){
            return !isValidPrescription;
        }
        return !isActivePairWithCorrectSubProduct(activePair);
    }
    const saveAndNext = () => {
        if (sliderPage < 3) {
            dispatch(lensConsultationSlice.actions.setSliderPage(3));
            setAnimateLensThickness(true);
            saveLensConsultationSessionDuration();
            lensThicknessTimestamp.current = new Date();
            if (lensRecommendationByExperience) {
                dispatch(userSlice.actions.saveLensRecommendationData(lensRecommendationByExperience));
                if (experience === ExperienceType.STANDARD) dispatch(lensConsultationSlice.actions.saveLensRecommendationStandard(lensRecommendationByExperience));
                if (experience === ExperienceType.SUN) dispatch(lensConsultationSlice.actions.saveLensRecommendationSun(lensRecommendationByExperience));
            }
        } else {
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
            gotoSummary();
            saveLensThicknessSessionDuration()
        }
    }

    const saveLensThicknessSessionDuration = () => {
        if (!lensThicknessTimestamp.current) return;
        const timediff = getDateTimeDifference(lensThicknessTimestamp.current, new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Lens Thickness Completion',
            label: timediff
        });
        lensThicknessTimestamp.current = undefined;
    }

    const saveLensConsultationSessionDuration = () => {
        if (!lensConsultationTimestamp.current) return;
        const timediff = getDateTimeDifference(lensConsultationTimestamp.current, new Date());
        ga.event({
            category: 'Lens Consulation',
            action: 'Consultation Completion',
            label: timediff
        });
        lensConsultationTimestamp.current = undefined;
    }

    const isPrescriptionComplete = () => {
        return (prescription.values && prescription.values.sphere.od) ? true : false
    }

    const gotoSummary = () => {
        if (view === 'prescription') {
            dispatch(lensThicknessSlice.actions.clearLensThicknessCalculationData())
            dispatch(lensThicknessSlice.actions.changeView('controls'));
            dispatch(userSlice.actions.savePrescriptionData(tempPrescriptionData));
            recommendLensThickness(tempPrescriptionData);
            dispatch(prescriptionSlice.actions.resetData());
            return;
        }

        if (tempLensThickness) dispatch(userSlice.actions.saveLensThickness(tempLensThickness));
        if (lensRecommendation?.pair1 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendationByExperience?.pair1,
                pair: LensPairType.PAIR1
            }))
        }
        if (lensRecommendation?.pair2 !== undefined) {
            dispatch(summarySlice.actions.setPairDetails({
                brand: lensRecommendation?.pair2,
                pair: LensPairType.PAIR2
            }))
        }
        history.push(routes.summary.path);
    }

    const recommendLensThickness = (prescription: any) => {
        const sphere = prescription.values.sphere;
        const thickness = getRecommendedLensThickness({
            od: sphere.od,
            os: sphere.os
        }, lensThicknessRules)
        recommendSubProductByIndex(LensPairType.PAIR1, thickness);
        recommendSubProductByIndex(LensPairType.PAIR2, thickness);
    }

    const recommendSubProductByIndex = (pair: LensPairType, index: number) => {
        if (!lensRecommendation) return;
        if (!lensRecommendation[pair]! || !lensRecommendation[pair]?.correct) return;
        const correct = lensRecommendation[pair]?.correct;
        if (!correct || !correct.product || !correct.product.subProducts) return;
        const subProducts = filterSubProductsWithEnabledIndex(
            correct.product?.subProducts.filter(x => x.label === correct.subProduct?.label),
            lensThicknessSettings
        );
        let recommended = getRecommendedSubProductByIndex(subProducts, index);
        if (!recommended) return;
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.CORRECT,
            brand: correct?.brand,
            product: correct?.product,
            subProduct: recommended,
            manual: true,
            pair: pair
        }));
    }

    const isWithDemo = (family: any) => {
        if (Array.isArray(family)) {
            if (family && family.length > 0 && family[0].product && (family[0].product.demo || []).length > 0) return true;
            return false;
        }
        if (family && family.product && (family.product.demo || []).length > 0) return true;
        return false;
    }

    const getInitialDemo = () => {
        if (!lensRecommendation) return;
        const pair: iLensRecommendationPair = lensRecommendation[activePair] as iLensRecommendationPair;
        const { correct, protect, enhance } = pair;
        if (isWithDemo(correct)) return { family: LensFamilyType.CORRECT, data: correct };
        if (isWithDemo(protect)) return { family: LensFamilyType.PROTECT, data: protect[0] };
        if (isWithDemo(enhance)) return { family: LensFamilyType.ENHANCE, data: enhance };
    }

    const demoProducts = () => {
        const initialDemo: any = getInitialDemo();
        if (!initialDemo) return;
        dispatch(productsSlice.actions.selectBrandTodemo(initialDemo.data.brand))
        dispatch(productsSlice.actions.selectProductTodemo(initialDemo.data.product))
        if (!recommendationToDemo || !recommendationToDemoBrands) return;
        dispatch(productDemoSlice.actions.setRecommendationDemo(recommendationToDemo));
        dispatch(productDemoSlice.actions.selectLensFamily(initialDemo.family));
        dispatch(productDemoSlice.actions.setDemoFrom(routes.lensConsultation));
        dispatch(productDemoSlice.actions.setRecommendationDemoBrands(recommendationToDemoBrands));
        goto.route(routes.demonstration);
    }

    const disableDemo = () => {
        if (!recommendationToDemo) return true;
        const {
            correct,
            protect,
            enhance
        } = recommendationToDemo;
        if (!correct && !protect && !enhance) return true;
        return false;
    }


    const bottom = () => (
        <div className={E(isSunExp() ? 'bottom-sun' :'bottom', (isSunExp() && sliderPage < 3) ? 'remove' : '',)}>
            <Button
                experience={experience}
                text={t('lens_consultation_lc_demo')}
                disabled={disableDemo()}
                icon={eyeIconGif()}
                type="simple"
                click={() => demoProducts()} />
            <Button
                experience={experience}
                disabled={disableNextButton()}
                text={t('lens_consultation_lc_save_&_next')}
                icon={
                    <CircleArrow type='right' background='#eee' />
                }
                type="secondary"
                outlined={false}
                click={() => saveAndNext()}
            />
        </div>
    )

    const transitionInAnimationComplete = () => {
        setTransitionInAnimationComplete(true);
    }

    const sunOnSaveHandler = () => {
        if (solution) {
            dispatch(lensConsultationSun.actions.setSolutionId(solution.id))
        }
        const brandId = 'crizal';
        const brand = tempList
            .find((f: any) => f.id === LensFamilyType.ENHANCE).brands
            .find((b: iProductBrand) => b.id === brandId);
        const product = brand.products.find((p: iProduct) => p.id === 'crizal_sun_xprotect');
        dispatch(lensConsultationSlice.actions.selectProduct({
            lensFamily: LensFamilyType.ENHANCE,
            brand,
            product,
            manual: true,
            pair: LensPairType.PAIR1
        }));
        // dispatch(lensConsultationSlice.actions.setSliderPage(3));
        // setAnimateLensThickness(true);
        // saveLensConsultationSessionDuration();
        setFadeOutSun(true);
        setTimeout(() => {
            saveAndNext();
        }, 500);
    }

    return (
    <>
        <div className={B(experience)}>
        {/* <div style={{width:'45rem'}}>
        {isSunExp() &&
                    <LensConsultationSideSun
                    fadeOut={fadeOutSun}
                    onSave={sunOnSaveHandler} />
                }
        </div> */}
            <SideBoxBordered type={isSunExp() ? 'plain' : 'default'}>
                {top()}
                <LensAnimation />
                {!isTransitionInAnimationComplete && !isAnimateLensThickness &&
                    <LensTransitionInAnimation onComplete={transitionInAnimationComplete} />
                }
                {isSunExp() &&
                    <LensConsultationSideSun
                    fadeOut={fadeOutSun}
                    onSave={sunOnSaveHandler} />
                }
                {isAnimateLensThickness &&
                    <LensTransitionOut type={transitionOutType} onComplete={transitionInAnimationComplete} />
                }
                {bottom()}
            </SideBoxBordered>
        </div>
    </>
    )
}

export default LensConsultationSideV2;