import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Arrow from '../../../../components/ui/Arrow';
import CircleArrow from '../../../../components/ui/CircleArrow';
import iSunExperienceSolutionColor from '../../../../interface/sun-experience-solution-color.interface';
import { getApp } from '../../../../store/slices/app.slice';
import useBEM from '../../../../utils/hooks/useBEM';
import useTranslation from '../../../../utils/hooks/useTranslation';
import GenericToolTip from './../../../../components/ui/GenericToolTip';
import lensConsultationSun, { getLensConsultationSun } from "../../../../store/slices/lens-consultation-sun-exp.slice"
interface Props {
    id: string,
    label: string,
    open?: boolean,
    onToggle: () => void,
    choices?: any[],
    value?: iSunExperienceSolutionColor,
    onSelect: (value: iSunExperienceSolutionColor) => void
    sunExp:any[]
}

const TintSelection: React.FC<Props> = ({
    id,
    label,
    open = false,
    onToggle,
    choices,
    value,
    onSelect,
    sunExp
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('tint-selection');
    const [showToolTip, setShowToolTip] = useState(false);
    const { t } = useTranslation(useSelector(getApp));
    const { lensRecommendationSun, tint} = useSelector(getLensConsultationSun);
    const [isSelected,showSelected] = useState(false);
    const { solution, tooltipOpen } = useSelector(getLensConsultationSun);
    const dispatch = useDispatch();

    useEffect(() => {
        if (solution?.id === id) {
            showSelected(true)
        }
    }, [lensRecommendationSun,tint])
    const onToggleSelect = (x: any) => {
        onSelect(x);
        showSelected(true);
    }
    const categoryOrder = ['C4', 'C3', 'C2', 'C1', 'C3/2', 'C3/0', 'C2 & C3']
    const colorCategoryReoder:any[] = []
    const reoderColor = categoryOrder.map(cat => colorCategoryReoder.push(sunExp.find(colorCat => colorCat.group === cat)))
    const colorProperOrder = colorCategoryReoder.filter(color => color !== undefined)
    
    const tipSelector = (id: string) => {
        switch (id) {
            case 'polarized':
                return t('sun_exp_masstintpoltooltipdesc', 'For ultimate visual comfort in bright, sunny conditions and strong glare environments')
            case 'mirror-tinted': case 'mirror-polarized':
                return t('sun_exp_masstintmirrortooltipdesc', 'For increased visual comfort and trendy look.')
            case 'Tinted':
                return t('sun_exp_masstinttintedtooltipdesc', 'For essential all-round protection and comfort with a timeless look.')
            default:
                return `${t('sun_exp_sun_solution_learn', 'Learn more about color influence')}... (${t('lens_thickness_lt_coming_soon')})`
        }
    }

    const showTooltip = (show:boolean) => {
        dispatch(lensConsultationSun.actions.openTooltip(show))
        setShowToolTip(show)
    }

    return (
        <div className={B(open ? 'open' : '')}>
            <div className={E('header')}>
                <label onClick={onToggle}>{label}</label>

                <div>
                    <div className={E('info-tooltip')}>

                        <input
                            onFocus={() => showTooltip(true)}
                            onBlur={()=> showTooltip(false)}
                            className={E('info-tooltip-toggle')}
                            type='text' value='i' readOnly />

                        <GenericToolTip
                            position='right'
                            show={showToolTip}
                            backgroundColor={['#f0a954', '#f95162']}
                        >
                            <span className={E('info-tooltip-text')}>
                                {tipSelector(id)}
                            </span>

                            <CircleArrow type='right' color='#fff' background='#fa7381' />

                        </GenericToolTip>
                    </div>

                    <div className={E('arrow')} onClick={onToggle}>
                        <Arrow type={!open ? 'down' : 'up'} />
                    </div>


                </div>
            </div>
            {
                open && colorProperOrder.map(tint => (

                    <ul className={E('selection')}>
                        <div style={{marginRight:'12px'}}>
                            {tint?.group}
                        </div>
                    <div className={E('selection-colors')}>
                        {
                            tint.colors.map( (x: { color_code: string | undefined; palette1: any; palette2: any; color_tid:string }) => (
                                <li
                                onClick={() => onToggleSelect(x)}
                                className={ x.color_tid === value?.color_tid && isSelected ? 'selected' : ''}
                                style={{
                                    '--app-tint-gradient-dark': x.palette1,
                                    '--app-tint-gradient-light': !!x.palette2 ? x.palette2 : x.palette1,
                                } as React.CSSProperties}>
                                </li>
                            ))
                        }
                    </div>
                </ul>
                    ))
            }

        </div>
    )
}

export default TintSelection;
