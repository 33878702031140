import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import Button from '../../../components/ui/Button';
import RouterLink from '../../../components/ui/RouterLink';
import measuresSteps from '../../../constants/measure-steps.contants';
import routes from '../../../constants/routes.constant';
import iMeasureStep from '../../../interface/measure-step.interface';
import iMeasurementDataOne from '../../../interface/measurement-one-data.inteface';
import iMeasurementDataThree from '../../../interface/measurement-three-data.inteface';
import iMeasurementDataTwo from '../../../interface/measurement-two-data.inteface';
import measureSlice, { getMeasure } from '../../../store/slices/measure.slice';
import userSlice, { getUser } from '../../../store/slices/user.slice';
import AgeCategoryType from '../../../types/age-category.type';
import useBEM from '../../../utils/hooks/useBEM';
import MeasureModal from './MeasureModal';
import MeasureStepNavigation from './MeasureStepNavigation';
import MeasureStepOne from './MeasureStepOne';
import MeasureStepThree from './MeasureStepThree';
import MeasureStepTwo from './MeasureStepTwo';
import useTranslation from '../../../utils/hooks/useTranslation';
import { getApp } from '../../../store/slices/app.slice';
import usePricing from '../../../utils/hooks/usePricing';
import lensConsultationSlice, { getLensConsultation } from '../../../store/slices/lens-consultation.slice';
import SvgIcon from '../../../components/ui/svg-icon/SvgIcon';
import ExperienceType from '../../../types/experience.type';

interface Props {

}

const MeasuresContainer : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('measures-container');
    // const [step ,setStep] = useState(0);
    const {step ,activeDataIndex, measurementData} = useSelector(getMeasure);
    const steps: iMeasureStep[] =  measuresSteps;
    const [showImportModal, setShowImportModal] = useState(false);
    const [showNewPairModal, setShowNewPairModal] = useState(false);
    const dispatch = useDispatch();
    const { ageCategory, measures } = useSelector(getUser);
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const { t } = useTranslation(appStore);
    const { getBreakdown } = usePricing();
    const currentLensConsultation = useSelector(getLensConsultation);

    // HOOKS
    useEffect(()=>{
        dispatch(userSlice.actions.saveMeasuresData(measurementData)); 
    },[measurementData])

    useEffect(()=>{
        const breakdown = getBreakdown()
        dispatch(lensConsultationSlice.actions.setBreakdown(breakdown));
        dispatch(userSlice.actions.savePrefilledData({
            ...currentLensConsultation,
            breakdown: breakdown
        } ));
    },[measures])

    // METHODS
    const activeStep = () => {

        return steps[step || 0];
    }

    const getAge = () => {
        const age = ageCategory?.value;
        return age ===AgeCategoryType.KIDS ? true : false
    }

    const nextStep = (
        key: 'one' | 'two' | 'three',
        data: iMeasurementDataOne | iMeasurementDataTwo | iMeasurementDataThree) =>
    {

        dispatch(measureSlice.actions.saveMeasureData({
            key,
            data
        }));

        if(step === (steps.length - 1)) {
            dispatch(measureSlice.actions.savePairData(activeDataIndex + 1));
            setShowNewPairModal(true);
            return;
        }
        setStep(step + 1, false);
    }
    const renderMeasureStep = () => {
        switch (step) {
            case 0: return <MeasureStepOne experience={experience} isKids={getAge()} data={measurementData.one[activeDataIndex]} onSave={(data) => nextStep('one', data)}/>
            case 1: return <MeasureStepTwo experience={experience} isKids={getAge()} data={measurementData.two[activeDataIndex]} onSave={(data) => nextStep('two', data)}/>
            case 2: return <MeasureStepThree experience={experience} isKids={getAge()} data={measurementData.three[activeDataIndex]} onSave={(data) => nextStep('three', data)}/>
            default: break;
        }
    }

    const addNewPair = () => {
        dispatch(userSlice.actions.saveMeasuresData(measurementData));
        if(isMaxPair()) {
            dispatch(measureSlice.actions.changeView('data'));
            return;
        }


        setShowNewPairModal(false);
        dispatch(measureSlice.actions.changeView('measure'));
        dispatch(measureSlice.actions.changeStep(0));
        dispatch(measureSlice.actions.addPair());
    }

    const setStep = (newStep: number, cond: boolean = true) => {
        if((!measurementData.one[activeDataIndex] && step === 0 && newStep === 1) && cond)
            newStep = 0

        else if((!measurementData.two[activeDataIndex] && step === 1 && newStep === 2) && cond)
            newStep = 1

        else if (newStep === 2 && step < 1) return

        dispatch(measureSlice.actions.changeStep(newStep));
    }

    const isMaxPair = () => {
        return measurementData.three.length > 1 || experience === ExperienceType.SUN;
    }

    return (
        <div className={B()}>

            {/* <Icon type="image" background={true} name={activeStep().backgroundImage} className={E('background')}/> */}

            <MeasureStepNavigation step={step || 0} onChange={(s) => setStep(s)} steps={steps}/>

            <MeasureModal onImport={() => setShowImportModal(true)}
                step={step} label={t(activeStep().label)} description={t(activeStep().description)}>
                {renderMeasureStep()}
            </MeasureModal>


            <Modal show={showImportModal} width="43rem" height="25rem" onClose={() => setShowImportModal(false)}>
                <div className={E('import-modal')}>
                    <h2>Import</h2>
                    <div>
                        <Button  icon="qrcode" text="QR Code" type="secondary"/>
                        <Button  icon="stacked-group" text="ID" type="secondary"/>
                    </div>
                </div>
            </Modal>

            <Modal show={showNewPairModal} width="38rem" height="30rem" onClose={() => setShowNewPairModal(false)}>
                <div className={E('new-pair-modal')}>
                <h2>{t('measures_mes__3_measures')}</h2>

                    <Button
                        experience={experience}
                        weight="normal"
                        text={ isMaxPair() ? t('measures__meas_add_pair_2_popup_') : t('measures_mes__3_add_a_new_pair')}
                        type="secondary"  icon={ 

                            <SvgIcon 
                                name='add' 
                                height='2.25rem'    
                            />

                        } click={addNewPair}/>

                    <RouterLink route={routes.lensProposalBuilder}>
                        {t('measures_mes__3_back_to_lpb')}
                    </RouterLink>
                </div>
            </Modal>
        </div>
    )
}

export default MeasuresContainer;
