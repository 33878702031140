import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeliveryTemplate from '../../../components/email-template/DeliveryTemplate';
import Modal from '../../../components/Modal';
import Arrow from '../../../components/ui/Arrow';
import Button from '../../../components/ui/Button';
import { getApp } from '../../../store/slices/app.slice';
import deliverySlice, { getDelivery } from '../../../store/slices/delivery.slice';
import { getMeasure } from '../../../store/slices/measure.slice';
import { getPrescription } from '../../../store/slices/prescription.slice';
import { getUser } from '../../../store/slices/user.slice';
import useBEM from '../../../utils/hooks/useBEM';
import useGoogleAnalytics from '../../../utils/hooks/useGoogleAnalytics';
import useTranslation from '../../../utils/hooks/useTranslation';
import Utils from '../../../utils/Utils';
import { saveToPdf, sendMail, SummaryExportType } from '../../../utils/misc';
import useAdvancePricing from '../../advance-price-setting/useAdvancePricing';
import ExperienceType from '../../../types/experience.type';
import CircleArrow from '../../../components/ui/CircleArrow';

interface Props {
    summaryDomRef: any;
    experience: ExperienceType
}

const DeliveryNavigation : React.FC<Props>  = ({
    summaryDomRef,
    experience
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-navigation');
    const [isShowExportModal, setIsShowExportModal] = useState(false);
    const { activeSection, isWithInteraction } = useSelector(getDelivery);
    const { measurementData } = useSelector(getMeasure)

    const dispatch = useDispatch();
    const ga = useGoogleAnalytics();
    const { getDateTimeDifference } = Utils();
    const timestamp = useRef<any>();
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore);
    const { summaryList1, summaryList2, complementaryEquipment, measures } = useSelector(getUser);
    const { tempPrescriptionData } = useSelector(getPrescription);
    const { token, globalInformation } = appStore;
    const isWithInteractionRef = useRef<boolean>(isWithInteraction);
    const { id, lensConsultation } = useSelector(getUser)
    const { breakdown } = lensConsultation

    const navigationItems = [
        {
            label: 'delivery_del__prescription',
            view: 'prescription'
        },
        {
            label:'delivery_del_2_pair_1',
            view:'pair1',
        },
        {
            label:'delivery_del_3_pair_2',
            view:'pair2',
        },
        {
            label: 'delivery_del_advices',
            view:'advices',
        },
        {
            label:'pair_2_tab__del_p2__vision_tests',
            view:'vision_test',
        },
    ];
    const [ advancePrice, setAdvancePrice ] = useState<any>();
    const { 
        isAdvancePricing,
        getAdvancePricingTotal
    } = useAdvancePricing(appStore);


    useEffect(() => {
        if(!lensConsultation.lensRecommendation || !isAdvancePricing)return;
        setAdvancePrice(getAdvancePricingTotal(lensConsultation.lensRecommendation));
    }, [])


    // HOOKS
    useEffect(() => {
        isWithInteractionRef.current = isWithInteraction;
    }, [isWithInteraction])

    useEffect(() => {
        timestamp.current = new Date();
        return () => {
            saveDeliverySessionDuration();            
            dispatch(deliverySlice.actions.reset({all: false}))
        }
    },[])

    // METHODS
    const scrollTo = (view: string) => {
        dispatch(deliverySlice.actions.scrollToView(view));
    }

    const saveDeliverySessionDuration = () => {
        if(!timestamp.current || !isWithInteractionRef.current)return;
        const timediff = getDateTimeDifference(timestamp.current, new Date());
        ga.event({
            category: 'Delivery',
            action: 'Completion',
            label: timediff
        });
        timestamp.current = undefined;
    }

    const hideSummary = () => {
        dispatch(deliverySlice.actions.showSummaryDetails(false));
        dispatch(deliverySlice.actions.setActiveSection('prescription'));
        dispatch(deliverySlice.actions.scrollToView('prescription'));
    }

    const exportSummary = (type: string) => {
        ga.event({
            category: 'Delivery - Export',
            action: 'Button Click',
            label: type
        });

        takeExportSummaryAction(type);
        // saveDeliverySessionDuration();
    }
    
    const takeExportSummaryAction = (type: string) => {
        switch (type) {
            case SummaryExportType.QRCODE: //qr code 
                break;
            case SummaryExportType.PRINT: 
                // const pageOptions = Object.keys(breakdown?.pair2 ?? {}).length > 0 ? {topOffset: {2: 7, 3:15}} : {topOffset: {1: 22, 2: 25}}
                const pageOptions = {topOffset: {1: 22, 2: 25}}

                saveToPdf({
                    inputDom: summaryDomRef.current, 
                    pdfName: `${id}_delivery_summary_${Date.now()}.pdf`, 
                    pageOptions: {...pageOptions, removeElements:['vision_test'], containerWidth: '1440px'},
                    scale:1
                })

                break;
            case SummaryExportType.EMAIL: //email code
                sendMail(globalInformation.email, `Delivery Summary for ${id}`, token ?? '',<DeliveryTemplate complementaryEquipment={complementaryEquipment} appStore={appStore} tempPrescriptionData={tempPrescriptionData} summaryList1={summaryList1} summaryList2={summaryList2} breakdown={breakdown}/>)
                break;
            default: return;
        }
    }

    const getAdvancePairPrice = (pair: 'pair1' | 'pair2') => {
        if(!breakdown[pair].enabled)return 0;
        let price = advancePrice[pair].total;

        if(measures.one) {
            const measuresPair = measures.one[ pair === 'pair1' ? 0 : 1 ];
            price = price + ( measuresPair ? measuresPair.price.value : 0 );
        }
        return price;
    }

    const totalPrice = () => {
         if(advancePrice){
            return getAdvancePairPrice('pair1') + getAdvancePairPrice('pair2')
         }

        return breakdown.deliveryTotal;
    }

    const saveAndExport = () => {
        dispatch(deliverySlice.actions.save());
        setIsShowExportModal(true);
    }

    const isSunXP = () => experience === ExperienceType.SUN;

    return (
        <div className={B()}>                
            <div className={E('toggle')} onClick={hideSummary}>
                <Arrow type="down"/>
            </div>
            
            <ul className={E('controls', experience)}>
                {
                    navigationItems.map( ({label, view}) => (
                        <li 
                            onClick={item => scrollTo(view)}
                            key={view} className={`${view === activeSection ? 'active' : ''}`}>
                            {t(label)}
                        </li>
                    ))
                }                                  
            </ul>
            <div className={E('buttons')}>
                
                <label> 
                    { totalPrice() } { breakdown.currency }                    
                </label>        
                <Button 
                    text={t('delivery_del__save_&_export')}
                    click={saveAndExport}
                    experience={experience}    
                    icon={
                        <CircleArrow 
                            type='right' 
                            background={'#fff'}
                        />
                    }
                />

            </div>

            <Modal show={isShowExportModal} width="62rem" onClose={() => {setIsShowExportModal(false)}}>
                <div className={E('export-modal')}>
                    <h2>{t('summary_sum_export')}</h2>
                    <div className="buttons">
                        {/* <Button weight="normal" text="QR Code" type="secondary"  icon="qrcode" click={() => exportSummary(SummaryExportType.QRCODE)}/> */}
                        {/* <Button weight="normal" text="PMS" type="secondary"  icon="monitor-blue"/> */}
                        <Button 
                            weight="normal" 
                            text={t('summary_sum_print')} 
                            type="secondary"  
                            icon={ isSunXP() ? 'print-sun' : 'print'} 
                            click={() => exportSummary(SummaryExportType.PRINT)}
                        />
                        <Button 
                            weight="normal" 
                            text={t('summary_sum_mail')} 
                            type="secondary"  
                            icon={ isSunXP() ? 'mail-sun' : 'mail'} 
                            click={() => exportSummary(SummaryExportType.EMAIL)} 
                            disabled={globalInformation.email === ''}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DeliveryNavigation;