import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import iNeedExplorerStore from '../interface/need-explorer-store.interface';
import iRootState from '../interface/root-state.interface';
import NeedExplorerViewType from '../../types/need-explorer-view.type';
import iQuestionOption from '../../interface/question-option.interface';
import iNeedExplorerQuestion from '../../interface/need-explorer-question.interface';
import iNeedExplorerCategory from '../../interface/need-explorer-category.interface';
import needExplorerSunQuestionaires from '../../constants/need-explorer-sun-questionaires.constant';


const initialState: iNeedExplorerStore = {
    view: NeedExplorerViewType.INTRO,
    categoryStep: 0,
    questionStep: 0,
    questions: needExplorerSunQuestionaires,
    activeCategory: needExplorerSunQuestionaires[0],
    activeQuestion: needExplorerSunQuestionaires[0].questions[0],
    isGeneralDone: false,
    isExtraDone: false,
    answers: [],
    showSaved: false,
}

const needExplorerSliceSun = createSlice({
    name: 'needExplorerSun',
    initialState,
    reducers: {
        changeView: (state, action: PayloadAction<NeedExplorerViewType>) => ( {...state, view: action.payload }),
        resetQuestionStep: (state) => ( {...state, questionStep:0, activeQuestion:state.questions[0].questions[0] }),
        nextQuestion: (state) => {
            let newQuestionStep:number = state.questionStep + 1;
            let newCategoryStep = state.categoryStep;
            let categories = state.questions;
            const isLastQuestionOfCategory = categories[state.categoryStep]?.questions.length ===  (state?.questionStep + 1);
            const isLastCategory = (state.categoryStep + 1) === state.questions.length;
            let generalDone = false

            //NOTE: IF THE CURRENT QUESTION IS THE LAST QUESTION OF THE CATEGORY
            if( isLastQuestionOfCategory ) {
                return
            };
                
            //     // RESET question to 0 and increment Category Step
            //     newQuestionStep = 10
            //     // If last category reset category to 0
            //     // otherwise increment by 1
            //     newCategoryStep = 0
            // }

            const appendAnswerOnToQuestion = (
                categoryQuestions: iNeedExplorerQuestion[],
                answer: iQuestionOption | iQuestionOption[] | string | number | number[] | undefined
            ) => {
                return categoryQuestions.map(q => {
                    if(q.id === state.activeQuestion.id) return { 
                        ...q,
                        answer,
                        categoryIndex: state.categoryStep,
                        questionIndex: state.questionStep,
                        isAnswered: true
                    }
                    return q;
                });
            }
            const categoriesWithAnswer = categories.map(cat => {
                if(cat.id === state.activeCategory.id) {
                    return{
                        ...cat,
                        questions: appendAnswerOnToQuestion(cat.questions, state.activeQuestion.answer)
                    }
                }
                return cat;
            });
            return {
                ...state,
                questionStep: isLastQuestionOfCategory ? 10 : newQuestionStep,
                categoryStep: newCategoryStep,
                activeCategory: state.questions[newCategoryStep],
                activeQuestion: isLastQuestionOfCategory ? state?.questions[0]?.questions[10]: state?.questions[newCategoryStep]?.questions[newQuestionStep],
                questions: categoriesWithAnswer,
                // Once the general category has been passed then the isGeneralDone state will always be true
                isGeneralDone:  state.isGeneralDone ? true : generalDone
            }
        },
        previousQuestion: (state) => {
            const prevStep = state.questionStep - 1;
            return {
                ...state,
                questionStep: prevStep,
                activeQuestion: state.questions[state.categoryStep].questions[prevStep]
            }
        },
        questionaireFromStart: (state) => ({
            ...state,
            questionStep: 0,
            categoryStep: 0,
            activeCategory: state.questions[0],
            activeQuestion: state.questions[0].questions[0]
        }),
        answerQuestion: (state, action: PayloadAction<{
            answer: string | iQuestionOption | iQuestionOption[] | number | number[],
            category: number,
            question: number}>) => {
                let categories = state.questions;
                const appendAnswerOnToQuestion = (
                    categoryQuestions: iNeedExplorerQuestion[],
                    answer: iQuestionOption | iQuestionOption[] | string | number | number[] | undefined
                ) => {
                    return categoryQuestions.map(q => {
                        if(q.id === state.activeQuestion.id) return { 
                            ...q,
                            answer,
                            isAnswered: true
                        }
                        return q;
                    });
                }
                const categoriesWithAnswer = categories.map(cat => {
                    if(cat.id === state.activeCategory.id) {
                        return{
                            ...cat,
                            questions: appendAnswerOnToQuestion(cat.questions, action.payload.answer)
                        }
                    }
                    return cat;
                });
                return {
                    ...state,
                    activeQuestion: {
                        ...state.activeQuestion,
                        answer: action.payload.answer,
                    },
                    questions: categoriesWithAnswer,
                }
                
        },
        gotoCategory: (state, action: PayloadAction<{category: iNeedExplorerCategory, index: number}>) => ( {
            ...state,
            activeCategory: action.payload.category,
            activeQuestion: action.payload.category.questions[0],
            categoryStep: action.payload.index,
            questionStep: 0
        } ),
        gotoQuestion: (state, action: PayloadAction<{category: number, question: number}>) => ( {
            ...state,
            questionStep: action.payload.question,
            categoryStep: action.payload.category,
            activeCategory: state.questions[action.payload.category],
            activeQuestion: state.questions[action.payload.category].questions[action.payload.question]
        } ),


        resetData: (state) => ({
            ...state,
            ...initialState
        }),
        setShowSaved: (state, action: PayloadAction<any>) => ( { ...state, showSaved: action.payload } ),
        setData: (state, action: PayloadAction<iNeedExplorerStore>) => ( {...state, ...action.payload }),
        populateSelectedModal: (state, action: PayloadAction<any>) => ( {...state, selectedId: action.payload} ),
        populateSelectedModalSignificance: (state, action: PayloadAction<any>) => ( {...state, significance: action.payload} ),
        populateSelectedModalLens: (state, action: PayloadAction<any>) => ( {...state, selectedLens: action.payload} ),
        populateSelectedModalLensIcon: (state, action: PayloadAction<any>) => ( {...state, selectedLensIcon: action.payload} ),
        setIsExtraDone: (state, action: PayloadAction<boolean>) => ( {...state, isExtraDone: action.payload} ),
        setFinalAnswers: (state, action: PayloadAction<any>) => ( {...state, answers: action.payload} ),
        generalQuestionSessionStart: (state, action: PayloadAction<string | undefined>) => ( {...state, generalQuestionSessionStart: action.payload} ),
        moreQuestionSessionStart: (state, action: PayloadAction<string | undefined>) => ( {...state, moreQuestionSessionStart: action.payload}),

    }
});

export const getNeedExplorerSun = (state:iRootState) => state.needExplorerSun;
export default needExplorerSliceSun;
