import React, { useState, useEffect, useRef } from 'react'
import useBEM from '../../../utils/hooks/useBEM'
import Modal from '../../../components/Modal'
import axios from 'axios';
import ENDPOINTS from '../../../constants/endpoints';
import iUserStore from '../../../store/interface/user-store.interface';
import useTranslation from '../../../utils/hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';
import Button from '../../../components/ui/Button';
import routes from '../../../constants/routes.constant';
import useGoto from '../../../utils/hooks/useGoto';
import userSlice from '../../../store/slices/user.slice';
import needExplorerSlice from '../../../store/slices/need-explorer.slice';
import lensConsultationSlice from '../../../store/slices/lens-consultation.slice';
import prescriptionSlice from '../../../store/slices/prescription.slice';
import useIndexedDB from '../../../utils/hooks/useIndexedDB';
import DeepReplaceObjectValue from '../../../utils/deep-replace-object-value';
import { IndexedDBContext, IndexedDBContextType } from '../../../utils/context/indexedDBContext';
import lensThicknessSlice from '../../../store/slices/lens-thickness.slice';
import measureSlice from '../../../store/slices/measure.slice';
import summarySlice from '../../../store/slices/summary.slice';
import refresh from '../../../assets/svg/refresh.svg';
import Alert from '../../../components/ui/Alert';
import Spinner from '../../../components/ui/Spinner';
import useLensConsultation from '../../lens-consultation/useLensConsultation';

interface Props {
  showModal?: boolean,
  onModalClose?: () => void
}

interface Order {
  order_id: string
  order: iUserStore,
  nickname?: string,
  created_by: string,
  created_at: string
}

const PatientListModal : React.FC<Props> = ({
  showModal,
  onModalClose
}) => {
  const [B, E] = useBEM('patient-list-modal');
  const [orders, setOrders] = useState([])
  const { t } = useTranslation(useSelector(getApp));
  const goto = useGoto()
  const dispatch = useDispatch()
  const values = {
    sphere: { od: undefined, os: undefined },
    cylinder: { od: undefined, os: undefined },
    axis: { od: undefined, os: undefined },
    addition: undefined
  }
  const { connect, createTables, insert } = useIndexedDB()
  const [db, setDB] = useState<IDBDatabase | undefined>(undefined)
  const dbRef = useRef<IDBDatabase | undefined>(db)
  const { get } = React.useContext(IndexedDBContext) as IndexedDBContextType;
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { correctPrescriptionData } = useLensConsultation();

  useEffect(() => {
    async function connectIndexDB() {
        const indexDB = await connect((db: any) => createTables(db))
        setDB(indexDB)
    }
    connectIndexDB()
  }, [])

  useEffect(() => {
    if(showModal)loadOrders();
  }, [showModal])

  const loadOrders = () => {
    if(!navigator.onLine)return;
    setIsLoading(true);
    axios.get(
        ENDPOINTS.orders,
        { headers: {Authorization: true, background: true} }
      ).then((result: any) => {
        setIsLoading(false);
        if (result.data.code === '001') {
          setOrders(result.data.data)
        }
      }
    )
  }

  useEffect(() => {
    dbRef.current = db
  }, [db])

  const transformNeedExplorer = (userData: iUserStore) => {
    let newData = userData.needExplorer;

    // Note: set canSelectMultiple: false for question '6.3' for kids
    // select only one answer
    if(userData.ageCategory?.value === 'kids'){
        newData = {
            ...newData,
            questions: newData.questions.map( q => {
                if(q.id === '6'){
                    return {
                        ...q,
                        questions: q.questions.map( x => {
                            if(
                                x.id === '6.3' &&
                                x.canSelectMultiple &&
                                x.answer
                            ){
                                return {
                                    ...x,
                                    answer: (x.answer as any[])[0],
                                    canSelectMultiple: false
                                }
                            }
                            return x;
                        })
                    }
                }
                return q;
            })
        }
    }
    return newData;
}

  const onSelectPatient = (patientId: string) => {
    axios.get(
      ENDPOINTS.order(patientId),
      { headers: {Authorization: true, background: false} }
    ).then( async (result: any) => {

      if (result.data.code === '001') {

        const replace = DeepReplaceObjectValue({from: '---', to: undefined})
        let newOrderData = replace.replace(result.data.data.order)

        //Note: prescription data structure correction
        // incase rx data is invalid
        newOrderData = correctPrescriptionData(newOrderData, undefined);

        if(dbRef.current)await insert(dbRef.current, 'userStore', newOrderData)

        const user = await get('userStore',String(result.data.data.order_id));

        dispatch(userSlice.actions.setUserData(user));
        dispatch(lensThicknessSlice.actions.setLensThickness(user.lensThickness));

        const finalNeedExplorer = transformNeedExplorer(user);

        dispatch(needExplorerSlice.actions.setData(finalNeedExplorer));
        dispatch(lensConsultationSlice.actions.saveLensRecommendationDataFromStore(user.lensRecommendation));
        dispatch(measureSlice.actions.saveMeasureFromStore(user.measures));
        dispatch(summarySlice.actions.saveSummary1(user.summaryList1));
        dispatch(summarySlice.actions.saveSummary2(user.summaryList2));
        dispatch(measureSlice.actions.saveMeasureFromStore(user.measures));

        setTimeout(() => { goto.route(routes.lensProposalBuilder) }, 800);
        return;
      }
      setShowAlert(true);
    })
  }

  const todaysOrders = (orderList: any) => {
    const filtered = orderList.filter((order: Order) => {
      return (new Date(order.created_at).getDate() === new Date().getDate())
    })
    return filtered
  }

  const orderStatus = ({
    prescription,
    needExplorer,
    measures,
    lensRecommendation
  }: iUserStore) => {
    let status = ""
    let ctrPres = 0

    if (needExplorer.isGeneralDone || needExplorer.isExtraDone) status = "need_explorer_order"
    if (Object.values(measures)[0].length > 0) status = "measures_order"
    if (prescription.values) {
      Object.values(prescription.values).map(el => {
        if (Object.keys(el).length > 0) ctrPres++
      })
    }
    if (ctrPres > 0) status = "prescription_order"
    if (lensRecommendation) status = "lens_consultation_order"

    return status
  }
  const modalStyle = {
    width: "68.3rem",
    height: "52.1rem",
    borderRadius: "2rem"
  }

  return (
    <>
    <Modal show={showModal} onClose={onModalClose} style={modalStyle}>
      <div className={B()}>

        <div className={E('head')}>
          <span className={E('head-count')}>
            {todaysOrders(orders).length} {t('waiting_list_wl_results')}
          </span>

          <img
            src={refresh}
            alt=""
            className="refresh"
            onClick={() => loadOrders()}
          />
        </div>

        {
          isLoading &&
          <div className={E('loader')}>
            <Spinner color='#eee'/>
          </div>
        }


        <div className={E('table')}>

            {
              todaysOrders(orders).length === 0 ?
                <div className={E('table-row')}>
                    <div>{t('data_sharing_no_data')}</div>
                </div> :
                todaysOrders(orders).map((order: Order, i: number) => {
                  const { order: orderDetails } = order
                  return (
                    <div className={E('table-row')} key={i}>
                      <div className={E('table-column')}>
                        {new Date(order.created_at).toLocaleTimeString()}
                      </div>
                      <div className={E('table-column')}>
                        {order.order_id}
                      </div>
                      <div className={E('table-column')}>
                        {order.nickname}
                      </div>
                      <div className={E('table-column')}>
                        {t(orderStatus(orderDetails))}
                      </div>
                      <div className={E('table-column')}>
                        <Button
                          click={() => onSelectPatient(order.order_id)}
                          text={t('waiting_list_wl_select')}
                        />
                      </div>
                    </div>
                  )
                })
            }
        </div>

        {
          !navigator.onLine &&
          <span className={E('offline-message')}>
            {t('data_sharing_pull_id_offline')}
          </span>
        }


      </div>
    </Modal>
    <Alert
      show={showAlert}
      message={t('data_sharing_patient_id_has_been_alreayd_selected')}
      buttons={[
        {
          id: 'ok',
          label: t('data_sharing_patient_id_has_been_alreayd_selected_ok')
        }
      ]}
      onButtonSelect={() => {
        setShowAlert(false);
        loadOrders();
      }}
    />
    </>
  )
}

export default PatientListModal
