import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMPLEMENTARY_EQUIPMENTS, GENERAL_EQUIPMENTS } from '../../constants/prescription-lens-proposal.constants';
import iLensProposalField from '../../interface/lens-proposal-field.interface';
import iPrescriptionData from '../../interface/prescription-data.interface';
import LensProposalEquipmentType from '../../types/lens-proposal-section.type';
import PrecisionType from '../../types/precision.type';
import PrescriptionViewType from '../../types/prescription-view.type';
import iPrescriptionStore from '../interface/prescription-store.interface';
import iRootState from '../interface/root-state.interface';


const initialState: iPrescriptionStore  = {
    view: PrescriptionViewType.PRESCRIPTION,
    isValidPrescription: false,

    tempGeneralEquipment: GENERAL_EQUIPMENTS,
    tempComplementaryEquipment: COMPLEMENTARY_EQUIPMENTS,
    tempPrescriptionData: {
        precision: PrecisionType.STANDARD,
        values: {
            sphere: { od: undefined, os: undefined },
            cylinder: { od: undefined, os: undefined },
            axis: { od: undefined, os: undefined },
            addition: undefined
        }
    },
    valueSign: {
        sphere: '',
        cylinder: ''
    },
    lensType: ""
}

const prescriptionSlice = createSlice({
    name: 'prescription',
    initialState,
    reducers: {
        setLensType: (state, action: PayloadAction<string>) => ({ ...state, lensType: action.payload }),
        changeView: (state, action: PayloadAction<PrescriptionViewType>) => ({...state, view: action.payload}),
        saveTemporaryLensEquipment: (
            state,
            action: PayloadAction<{general:iLensProposalField[], complementary:iLensProposalField[]}>) =>
        {
            const { general, complementary } = action.payload;
            return {
                ...state,
                tempGeneralEquipment: general,
                tempComplementaryEquipment: complementary
            }
        },
        updateLensEquipment: (
            state,
            action: PayloadAction<{data:iLensProposalField[], equipment: LensProposalEquipmentType}>) =>
        {
            const { data, equipment } = action.payload;
            const capitalizedEquipment = equipment.charAt(0).toUpperCase() + equipment.slice(1);

            const storeProperty = `temp${capitalizedEquipment}Equipment`;
            return {...state, [storeProperty]: data}
        },
        validPrescription: (state, action: PayloadAction<boolean>) => ({...state, isValidPrescription: action.payload }),

        setPrescriptionPrecision: (state, action: PayloadAction<PrecisionType>) => {
            return {
                ...state,
                tempPrescriptionData: {
                    ...state.tempPrescriptionData,
                    precision: action.payload
                }
            }
        },
        saveTempPrescriptionData: (state, action: PayloadAction<iPrescriptionData>) => ( {
            ...state,
            tempPrescriptionData: {
                ...state.tempPrescriptionData,
                values: action.payload.values
            }
        }),
        saveValueSign: (state, action: PayloadAction<{presicription: string, sign: string}>) =>{
            let newState:any;
            if(action.payload.presicription === 'sphere') {
                newState = {
                    ...state.valueSign,
                    sphere: action.payload.sign
                }
            }
            else if (action.payload.presicription === 'cylinder') {
                newState = {
                    ...state.valueSign,
                    cylinder: action.payload.sign
                }
            }
            return{
                ...state,
                valueSign: newState
            }
        },

        resetData: (state) => ({
            ...state,
            ...initialState
        })
    }
});

export const getPrescription = (state:iRootState) => state.prescription;
export default prescriptionSlice;
