import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import React from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../../store/slices/app.slice';
import { getLensConsultationSun } from '../../store/slices/lens-consultation-sun-exp.slice';
import { getLensConsultation } from '../../store/slices/lens-consultation.slice';
import ExperienceType from '../../types/experience.type';
import useBEM from '../../utils/hooks/useBEM';

interface Props {
    children: React.ReactNode,
    type?: 'default' | 'plain',
    width?: string,
}

const SideBoxBordered : React.FC<Props>  = ({
    children,
    type = 'default',
    width
}) =>  {
    // VARIABLES
    const [B] = useBEM('side-box-bordered');
    const { sliderPage, sliderPageTwo} = useSelector(getLensConsultation);
    const {experience} = useSelector(getApp)
    const { tooltipOpen} = useSelector(getLensConsultationSun);
    const isSunExp = experience === ExperienceType.SUN
    // HOOKS

    // METHODS
    

    const overFlow = sliderPage < 3 && !tooltipOpen && isSunExp ? 'hidden auto' : 'unset'

    return (
        <div className={B(type)} style={{width, overflow:overFlow}}>
            {children}
        </div>
    )
}

export default SideBoxBordered;