import React from 'react';
import useBEM from '../../utils/hooks/useBEM'; 
import SvgIcon from './svg-icon/SvgIcon';

interface Props {
    diameter?: number,
    background?: string,

    // ARROW PROPS
    type: 'right' | 'left' | 'up' | 'down',
    size?: string,
    color?: string,
    thickness?: string,
    className?: string,
    disabled?: boolean,
}

const CircleArrow : React.FC<Props>  = ({
    diameter,
    background,
    type,
    color,
    size = '1rem'
}) =>  {
    // VARIABLES
    const [B] = useBEM('circle-arrow');

    // HOOKS

    // METHODS
    const customStyle = () => {
        const diam = diameter ? `${diameter}rem` : '2rem';
        const bg = background || '#fff';
        return {
            width: diam,
            height: diam,
            backgroundColor: bg
        }
    }

    return (
        <div className={B(type)} style={customStyle()}>
            <SvgIcon
                name='arrowRight'
                height={size}
                color={color}
            />
        </div>
    )
}

export default CircleArrow;