import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import Button from '../../components/ui/Button';
import Icon from '../../components/ui/Icon';
import MaterialInput from '../../components/ui/MateriaTextlnput';
import routes from '../../constants/routes.constant';
import appSlice, { getApp } from '../../store/slices/app.slice';
import useBEM from '../../utils/hooks/useBEM';
import useGoto from '../../utils/hooks/useGoto';
import useTranslation from '../../utils/hooks/useTranslation';
import ExperienceType from '../../types/experience.type';

interface Props {

}

const PracticeID : React.FC<Props>  = () =>  {
    // VARIABLES

    // Temporary
    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [B, E] = useBEM('practice-id');
    const [showSaveModal, setShowSaveModal] = useState(false);
    const { globalInformation, accountDetails} = useSelector(getApp);
    const dispatch = useDispatch();
    const goto = useGoto();
    const [globalInfo, setGlobalInfo] = useState({
        ...globalInformation,
        storeName: globalInformation.storeName || accountDetails?.shop.shop_name || ''
    });
    const emailValidation = [
        { regex: emailReg, errorMessage: 'message_mess_my_practice_id' },
        { required: true, errorMessage: 'practice_id_email_is_required' },
    ];
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    
    //TEMP
    const [invalidForm, setInvalidForm] = useState(false);
    const {t} = useTranslation(useSelector(getApp));

    const expertLevelMapping: { [key: string]: string } = {
        gold: 'my_practice_my_essilor_exp_lev_gold',
        silver: 'my_practice_my_essilor_exp_lev_silver',
        bronze: 'my_practice_my_essilor_exp_lev_bronze'
    }


    // HOOKS

    // METHODS
    const onSave = () => {
        dispatch(appSlice.actions.saveGlobalInformation(globalInfo));
        setShowSaveModal(true);
        goto.route(routes.home, 2000);
    }

    const onChangeHandler = (key: string, value: string) => {
        setGlobalInfo({
            ...globalInfo,
            [key]: value
        })
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    return (
        <div className={B()}>

            <div className={E('image')}/>
            <div className={E('form')}>


                <div className={E('form-section')}>
                    <h2>{t('my_practice_my_glob_info')}</h2>
                    <MaterialInput
                        placeholder={t('my_practice_my_name')}
                        label={t('practice_id_store_name')}
                        value={globalInfo.storeName}
                        onChange={(v) => onChangeHandler('storeName', v)}
                    />

                    <MaterialInput
                        placeholder={t('my_practice_my_phone')}
                        label={t('practice_id_phone')}
                        value={globalInfo.phone}
                        onChange={(v) => onChangeHandler('phone', v)}
                    />

                    <MaterialInput
                        placeholder={t('my_practice_my_address')}
                        label={t('practice_id_address')}
                        value={globalInfo.address}
                        onChange={(v) => onChangeHandler('address', v)}
                    />

                    <MaterialInput
                        placeholder={t('my_practice_my_mail_address')}
                        label={t('practice_id_mail_address')}
                        value={globalInfo.email}
                        validation={emailValidation}
                        onChange={(v) => onChangeHandler('email', v)}
                        onValidate={(valid) => setInvalidForm(valid)}
                    />

                    <MaterialInput
                        placeholder={t('data_sharing_device_name')}
                        label={t('masterlicense_nickname')}
                        value={globalInfo.nickname}
                        onChange={(v) => onChangeHandler('nickname', v)}
                    />
                </div>


                <div className={E('form-section')}>
                    <h2>{t('my_practice_my_lic_numbers')}</h2>
                    <MaterialInput
                        readOnly
                        value={`${globalInformation.license} ${accountDetails?.is_master ? "\[master\]" : ""}`}
                        placeholder={`Essilor Companion: ${t('questionnaires_q_type_here')}`} 
                        label={t('my_practice_my_essilor_compa')} 
                    />
                    <MaterialInput
                        readOnly
                        placeholder={t('my_practice_my_lic__ava_module') +': '+ t('questionnaires_q_type_here')} 
                        label={t('my_practice_my_lic__ava_module')} 
                        value={t('my_practice_my_lic__ava_module_standard')}
                    />
                    {/* <MaterialInput
                        readOnly
                        placeholder={t('my_practice_my_essilor_exp_lev') +': '+ t('questionnaires_q_type_here')}
                        label={t('my_practice_my_essilor_exp_lev')} 
                        value={
                            accountDetails?.shop.classification &&
                            t(expertLevelMapping[accountDetails?.shop.classification])                      
                        }
                    /> */}
                    {/* <MaterialInput placeholder="Essilor Companion VR: Type here" label="Essilor Companion VR"/> */}
                    <Button
                        disabled={invalidForm}
                        click={onSave}
                        text={t('my_practice_my_save')}
                        icon={`right-arrow-circle-white${selectIcon()}`}
                        className={E('form-save')}/>
                </div>

            </div>

            <Modal
                onClose={() => setShowSaveModal(false)}
                height="23rem" width="26rem"
                show={showSaveModal} hideCloseButton={true}>

                <div className="my-companion-footer__saved">
                    <Icon name="saved"></Icon>
                    <h2>{t('my_practice_my_saved')}</h2>
                </div>

            </Modal>
        </div>
    )
}

export default PracticeID;
