import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; //
import { getApp } from '../../../../store/slices/app.slice';
import useBEM from '../../../../utils/hooks/useBEM';
import useTranslation from '../../../../utils/hooks/useTranslation';
import TintSelection from './TintSelection';
import iSunExperienceSolution from '../../../../interface/sun-experience-solution.interface';
import iSunExperienceSolutionColor from '../../../../interface/sun-experience-solution-color.interface';
import { getLensConsultationSun } from '../../../../store/slices/lens-consultation-sun-exp.slice';
import sunSolution from '../../../../constants/sun-solution.constants'


interface Props {
    solution?: iSunExperienceSolution,
    value?: iSunExperienceSolutionColor,
    onBack: (step: number) => void,
    onSelect: (solution: iSunExperienceSolution, tint: iSunExperienceSolutionColor) => void,
    onSaveAndNext: () => void
}

const TintSelectionStep : React.FC<Props>  = ({
    solution,
    value,
    onBack,
    onSelect,
    onSaveAndNext
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('tint-selection-step');
    const appStore = useSelector(getApp);
    const { t } = useTranslation(appStore); 
    const [openSolution, setOpenSolution] = useState(solution?.id); 
    const { sunExpProducts } = useSelector(getLensConsultationSun);
    const sunExpProductsOrder:any = []
    const sunExpReorder = sunSolution.map(prod => sunExpProductsOrder.push(sunExpProducts.find(prd => prd.id === prod.id)))
    const sunExpProdReorder:iSunExperienceSolution[] = sunExpProductsOrder.filter((prd:iSunExperienceSolution | undefined) => prd !== undefined)

    // HOOKS
    const onToggleHandler = (tintId: string) => {
        setOpenSolution(tintId === openSolution ? '' : tintId);
    }
    return (
        <div className={B()}>
            <div className={E('labels')}>
                <label>
                    {t('sun_exp_sun_solution_tint_enhance_vision', 'Protect your vision')}
                </label>
                <h4>
                    {t('sun_exp_sun_solution_choose_tint', 'Choose your tint')}

                    <span style={{
                        '--app-tint-gradient-dark': value?.palette1,
                        '--app-tint-gradient-light': value?.palette2 ? value?.palette2 : value?.palette1,
                    } as React.CSSProperties}>
                        {t(value?.color_code as string, value?.color_code)}
                    </span>    
                </h4>
            </div>

            <div className={E('tints')}>
                {
                    sunExpProdReorder.map( x => (
                        <TintSelection 
                        key={x.id} 
                        id={x.id} 
                        label={t(x?.sun_experience?.translationCode[0], x?.sun_experience?.translationCode[1])} 
                        open={x.id === openSolution} 
                        onToggle={() => onToggleHandler(x.id)}
                        sunExp={x.sun_experience?.color_group}
                        value={value}
                        onSelect={ (tint) => onSelect(x, tint) }
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default TintSelectionStep;