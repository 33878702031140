const getValuePair = (recommendation: any) => {

    return [
        { id: recommendation?.pair1?.correct.product?.id, significance: recommendation?.pair1?.correct.significantAnswers },
        { id: recommendation?.pair2?.correct.product?.id, significance: recommendation?.pair2?.correct.significantAnswers },
        { id: recommendation?.pair1?.protect[0].product?.id, significance: recommendation?.pair1?.protect[0].significantAnswers },
        { id: recommendation?.pair2?.protect[0].product?.id, significance: recommendation?.pair2?.protect[0].significantAnswers },
        { id: recommendation?.pair1?.enhance.product?.id, significance: recommendation?.pair1?.enhance.significantAnswers },
        { id: recommendation?.pair2?.enhance.product?.id, significance: recommendation?.pair2?.enhance.significantAnswers }
    ]
}

export default getValuePair ;
