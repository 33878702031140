import React from 'react';
import { useSelector } from 'react-redux';
import { getApp } from '../store/slices/app.slice';
import ExperienceType from '../types/experience.type';
import useBEM from '../utils/hooks/useBEM';
import Spinner from './ui/Spinner';

interface Props {
    show: boolean,
    text?: string,
    textOnly?: boolean
}

const Loading : React.FC<Props>  = ({
    text,
    textOnly,
    show
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('loading');
    const appStore = useSelector(getApp);
    const { experience } = appStore;

    // HOOKS

    // METHODS
    const selectRGB = () => (experience === ExperienceType.SUN ? "215 64 0" : "0 134 214")

    return (
        <div className={B( show ? 'show' : '')}>
            {
                !textOnly &&
                <Spinner color={`rgb(${selectRGB()} / 33%)`}/>
            }
            

            {
                text &&
                <h1 className={E('text')}>{text}</h1>
            }
            
        </div>
    )
}

export default Loading;