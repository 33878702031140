import React, { useState } from 'react';
import Accordion from '../../../components/ui/Accordion';
import useBEM from '../../../utils/hooks/useBEM';
import ListMenu from '../../../components/ui/ListMenu';
import iListMenuItem from '../../../interface/list-menu-item.interface';
import {visionTestValues, NearVisionMenuItems} from '../../../constants/delivery.constants';
import useTranslation from '../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';

interface Props {

}

const DeliveryVisiontest : React.FC<Props>  = () =>  {
    // VARIABLES
    const [B, E] = useBEM('delivery-vision-test');
    const [selectedMenuItem, setSelectedMenuItem] = useState<iListMenuItem | undefined>(undefined);
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const { t } = useTranslation(appStore);
    // HOOKS

    // METHODS

    const onDeviceSelect = ( menuItem: iListMenuItem ) => {
        setSelectedMenuItem(menuItem)
    }

    return (
        <div className={B(experience)}>
            <h2>{t('vision_tests_tab_del__verify_your_vision')}</h2>
            <div className={E('main')} >
                <div className={E('side-menu')}>
                    <Accordion title={t('vision_tests_tab_del__near_vision')} key='near-vision' className={E('accordion')} >
                        <ListMenu 
                            experience={experience}
                            items={NearVisionMenuItems} 
                            onSelect={onDeviceSelect} 
                            value={selectedMenuItem} 
                        />
                    </Accordion>
                </div>
                <div className={E('vision-display', selectedMenuItem ? []: ['empty'] )}>       
                    {selectedMenuItem !== undefined ?
                        visionTestValues.map( (testItem, index) => (    
                            <div className={E('vision-test-container')} key={testItem.m+testItem.p+testItem.va}>
                                    <div className={E('measurement')}>
                                        <p>{`${testItem.m} M`}</p>
                                        <p>{`P ${testItem.p}`}</p>
                                        <p>{`VA = ${testItem.va}`}</p>
                                    </div>
                                    <div className={`${E('text')} ${E(selectedMenuItem ? selectedMenuItem.value : '', [`${index}`])}`}>
                                        {t(testItem.text)}
                                    </div>
                            </div>
                        ))
                    : 
                        <h2 className={E('empty-selection-text')}>
                            {/* {`You must select
                            a distance`} */}
                        </h2>
                    }
                </div>
            </div>
        </div>
    )
}

export default DeliveryVisiontest;