import React, { useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import Arrow from '../../../components/ui/Arrow';
import Button from '../../../components/ui/Button';
import CircleArrow from '../../../components/ui/CircleArrow';
import Icon from '../../../components/ui/Icon';
import useBEM from '../../../utils/hooks/useBEM';
import ReactHtmlParser from 'react-html-parser';
import NoRxFooter from './NoRxFooter';
import { NO_RX_DATA } from '../../../constants/prescription.constants';
import ButtonTab from '../../../components/ui/ButtonTab';
import useTranslation from '../../../utils/hooks/useTranslation';
import { useSelector } from 'react-redux';
import { getApp } from '../../../store/slices/app.slice';


interface Props {
    show: boolean,
    onClose: () => void
}

type ContentType = 'default' | 'myopia' | 'hyperopia' | 'presbyopya' | 'astigmatism';
type methodType = 'tab' | 'next'

const NoRxModal : React.FC<Props>  = ({
    show,
    onClose
}) =>  {
    // VARIABLES
    const [B, E] = useBEM('no-rx-modal');
    const [contentKey, setContentKey] = useState<ContentType>('default');
    const [contentNum, setContentNum] = useState<number>(0)
    const data = NO_RX_DATA;
    const {t} = useTranslation(useSelector(getApp));
    // console.log('contentKey',contentKey)
    const contentConfig:ContentType[] = ['default','myopia', 'hyperopia', 'presbyopya', 'astigmatism']
    // HOOKS

    useEffect(() => {
      setContentKey(contentConfig[contentNum])
    }, [contentNum])
    
    
    // METHODS
    const changeContent = (key:ContentType, method:methodType = 'next') => {
        if(method === 'tab'){
            setContentKey(key as ContentType)
            setContentNum(contentConfig.indexOf(key))
            return
        }
        if(contentNum === 4){setContentNum(0)}
        else setContentNum(() => contentNum + 1)
    }

    const getTabItems = () => {
        const keys = Object.keys(data).filter( x => x!== 'default');

        return keys.map( x => ({
            id: x,
            label: t(data[x].label as string) || ''
        }))
    }

    const getFooterLabel = () => {
        const label = contentKey === 'default' ?
            'sun_exp_no_rx_impact_visual_impact' : // 'Visual defect impact' :
            'sun_exp_no_rx_impact_add_rx'; // Add a prescription

        return label;
    }

    return (
        <Modal
            className={B()}
            show={show}
            width='85.3rem'
            height='59.6rem'
            onClose={onClose}
        >
            { contentKey === 'default' &&
                <h4 className={E('text-important')}>
                    {t('sun_exp_no_rx_importance_of_sunglasses', 'Why is it important to adapt my sunglasses with prescription?')}
                </h4>
            }

            {
                contentKey !== 'default' &&
                <ButtonTab items={getTabItems()} active={contentKey} onSelect={(key) => changeContent(key as ContentType, 'tab')}/>
            }


            <div className={E('center-content')}>
                <div>
                    <h4 className={E('text-vision-outside')}> { t(data[contentKey].title, data[contentKey].defaultEnglish?.title) } </h4>

                    <p> { ReactHtmlParser(t(data[contentKey].paragraph, data[contentKey].defaultEnglish?.paragraph)) } </p>

                    <div className={E('info')}>
                        <label>
                            { t(data[contentKey].subTitle, data[contentKey].defaultEnglish?.subTitle) }
                        </label>

                        <ul className={E('info-list', contentKey)}>
                            { data[contentKey].list.map( (x, i) => (
                                <li>
                                    <div className={E('info-list-content')}>
                                    <Icon name={x.image}/>
                                    <label>{ReactHtmlParser(t(x.label, data[contentKey].defaultEnglish?.list[i].label))}</label>
                                    {
                                        x.paragraph &&
                                        <p>{ReactHtmlParser(t(x.paragraph, data[contentKey].defaultEnglish?.list[i].paragraph))}</p>
                                    }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>


                </div>

                <div className={E('next')} onClick={() => changeContent('myopia', 'next')}>
                    <Arrow type='right' size='.75rem' color='#F35D1D'/>
                </div>
            </div>


            <NoRxFooter
                buttonLabel={getFooterLabel()}
                contentKey={contentKey}
                onAction={(action) => {
                    if(action === 'close'){
                        onClose();
                        return;
                    }
                    setContentKey(action as ContentType)
                    setContentNum(contentConfig.indexOf(action as ContentType))
                }}
            />
        </Modal>
    )
}

export default NoRxModal;
