import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import lensConsultationData from '../../constants/lens-consultation-data.constants';
import { discount, lensThickness } from '../../constants/my-companion.constants';
import neddExplorerQuestionaires from '../../constants/need-explorer-questionaires.contants';
import pricingBreakdown from '../../constants/pricing-breakdown';
import iAgeCategory from '../../interface/age-category.interface';
import iLensProposalField from '../../interface/lens-proposal-field.interface';
import iLensRecommendationPair from '../../interface/lens-recommendaiton-pair.interface';
import iLensThicknessCalculation from '../../interface/lens-thickness-calculation';
import iPrescriptionData from '../../interface/prescription-data.interface';
import iPriceBreakdown from '../../interface/price-breakdown.interface';
import LensPairType from '../../types/lens-pair.type';
import NeedExplorerViewType from '../../types/need-explorer-view.type';
import PrecisionType from '../../types/precision.type';
import iMeasureStore from '../interface/measure-store.interface';
import iMyCompanionStore from '../interface/mycompanion-store.interface';
import iNeedExplorerStore from '../interface/need-explorer-store.interface';
import iRootState from '../interface/root-state.interface';
import summaryStore from '../interface/summary.interface';
import iUserStore from '../interface/user-store.interface';
import needExplorerSunQuestionaires from '../../constants/need-explorer-sun-questionaires.constant';

const initialState: iUserStore  = {
    prescription: {
        precision: PrecisionType.STANDARD,
        values: {
            sphere: { od: undefined, os: undefined },
            cylinder: { od: undefined, os: undefined },
            axis: { od: undefined, os: undefined },
            addition: undefined
        }
    },
    needExplorer: {
        view: NeedExplorerViewType.INTRO,
        categoryStep: 0,
        questionStep: 0,
        questions: neddExplorerQuestionaires,
        activeCategory: neddExplorerQuestionaires[0],
        activeQuestion: neddExplorerQuestionaires[0].questions[0],
        isGeneralDone: false,
        isExtraDone: false,
        answers: [],
        showSaved: false,

    },
    lensConsultation: {
        activePair: LensPairType.PAIR1,
        isLensThickness: false,
        data: lensConsultationData,
        sliderPage: 0,
        sliderPageTwo:0,
        lensType: null,
        lensProducts: {
            correct: {
                product: null,
                subProduct: null
            },
            protect: {
                product: null,
                subProduct: null
            },
            enhance: {
                product: null,
                subProduct: null
            }
        },
        isValidated: false,
        breakdown: pricingBreakdown
    },
    measures: {
        one: [],
        two: [],
        three: []
    },
    summaryList1: [],
    summaryList2: [],

}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setId: (state, action: PayloadAction<string>) => ( {...state, id: action.payload} ),
        setUserData: (state, action: PayloadAction<iUserStore>) => ( {...state, ...action.payload} ),
        clearUserState: () => ({
            ...initialState
        }),
        setAgeCategory: (state, action: PayloadAction<{
            id: string,
            ageCategory: iAgeCategory
        }>) => ({
            ...state,
            id: action.payload.id,
            ageCategory: action.payload.ageCategory
        }),


        //PRESCRIPTION
        savePrescriptionData: (state, action: PayloadAction<iPrescriptionData>) => ( {
            ...state,
            prescription: action.payload
        }),
        saveLensEquipment: (
            state,
            action: PayloadAction<{general:iLensProposalField[], complementary:iLensProposalField[]}>) =>
        {
            return {
                ...state,
                generalEquipment: action.payload.general,
                complementaryEquipment: action.payload.complementary
            }
        },

        //MEASURES
        saveMeasuresData: (state, action: PayloadAction<iMeasureStore["measurementData"]>) => ( {
            ...state,
            measures: action.payload
        }),
        saveFrametraceMeasure: (state, action: PayloadAction<any>) => ({
            ...state, measures: action.payload
        }),
        //SUMMARY
        saveSummary1: (state, action: PayloadAction<summaryStore["summaryList1"]>) => ( {
            ...state,
            summaryList1: action.payload
        }),
        saveSummary2: (state, action: PayloadAction<summaryStore["summaryList2"]>) => ( {
            ...state,
            summaryList2: action.payload
        }),

        //NEEDEXPLORER
        saveNeedExplorerData: (state, action: PayloadAction<iNeedExplorerStore>) => ( {
            ...state,
            needExplorer: action.payload
        }),

        //LENS CONSULTATION
        savePrefilledData: (state, action: PayloadAction<any>) => ( {
            ...state,
            lensConsultation: action.payload
        }),

        saveLensThickness: (state , action: PayloadAction<{
            pair1?: number,
            pair2?: number
        }>) => ({
            ...state,
            lensThickness: action.payload
        }),

        saveLensRecommendationData: (state , action: PayloadAction<{
                pair1?: iLensRecommendationPair,
                pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendation: action.payload
        }),
        saveLensRecommendationSunData: (state , action: PayloadAction<{
                pair1?: iLensRecommendationPair,
                pair2?: iLensRecommendationPair
        }>) => ({
            ...state,
            lensRecommendationSun: action.payload
        }),

        sessionStart: (state, action: PayloadAction<string | undefined>) => ({
            ...state,
            sessionStart: action.payload
        }),

        createdDate: (state, action: PayloadAction<string | undefined>) => ({
            ...state,
            createdDate: action.payload
        }),

        //MY COMPANNION
        saveMyCompannionData: (state, action: PayloadAction<iMyCompanionStore>) => ( {
            ...state,
            myCompannion: action.payload
        }),

        //BREAKDOWN
        setBreakdown: (state, action: PayloadAction<iPriceBreakdown>) => ( {
            ...state,
            breakdown: action.payload
        }),
    }
});

export const getUser = (state:iRootState) => state.user;
export default userSlice;
