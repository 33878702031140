
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sidebarSlice, { getSidebar } from '../../store/slices/sidebar.slice';
import useBEM from '../../utils/hooks/useBEM';
import Modal from '../../components/Modal';
import Icon from '../../components/ui/Icon';
import Accordion from '../../components/ui/Accordion';
import { TUTORIAL_CONTENT } from '../../constants/tutorial-content.constants';
import TutorialCard from './components/TutorialCard';
import tutorialSlice, { getTutorial } from '../../store/slices/tutorial.slice';
import TutorialMain from './components/TutorialMain';
import TutorialSection from './components/TutorialSection';
import Button from '../../components/ui/Button';
import axios from 'axios';
import { getApp } from '../../store/slices/app.slice';
import useTranslation from '../../utils/hooks/useTranslation';
import ENDPOINTS from '../../constants/endpoints';
import tutorialContentDetails from '../../interface/tutorial-content-details.interface';
import tutorialDetails from '../../interface/tutorial-content-details';
import ExperienceType from '../../types/experience.type';

interface Props {

}

const Tutorial : React.FC<Props>  = () =>  {

    // VARIABLES
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [B, E] = useBEM('tutorial');
    const { selectedMenu } = useSelector(getSidebar);
    const { view, items } = useSelector(getTutorial);
    const { token } = useSelector(getApp);
    const { t } = useTranslation(useSelector(getApp));
    const appStore = useSelector(getApp);
    const { experience } = appStore;
    const modalStyle = {
        width: "95.4rem",
        height: "69.8rem",
        borderRadius: "15px"
    }
    // HOOKS
    // METHODS
    useEffect(() => {
        if(selectedMenu?.id !== 'tutorial')return;
        setShow(true);
    }, [selectedMenu])

    useEffect(() => {
        if(show)fetchMore();

    }, [show])
    const fetchMore = () =>{
        const axiosConfig =  { headers: {"Authorization" : `Bearer ${token}`} }
        // const axiosConfig =  { headers: {"Authorization" : `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFc3NpbG9yIENvbXBhbmlvbiIsImF1ZCI6IiIsImF2YWlsYWJsZV9kYXRlIjoiMjAyMS0wNi0wNCIsImlzcyI6InVybjpcL1wvYXBpZ2VlLWVkZ2UtZXNzaWxvci1KV1QiLCJtYWNoaW5lX2lkIjoiZDUyMGM3YTgtNDIxYi00NTYzLWI5NTUtZjVhYmM1NmI5N2VjIiwic3RhdGUiOiI4IiwiZXhwaXJhdGlvbl9kYXRlIjoiMjAyMS0wNy0wNCIsImlhdCI6MTYyMzI5NTk1MywianRpIjoiZmVjYTAwYmYtMjEyMy00MWU5LWE1MDctMTVkYTgxZmE3YTAyIiwibGljZW5zZV9udW1iZXIiOiJURVNULVczTUEtSk1BTS0xNU1MTSJ9.DmF_8MEBvCUeFmJCaA2pageG-NHrd_wt7sOf7XtHAN60Hbp2FK-09H3amNcyh3nxLAu7HgZzRDchXi-IBnzTvkE5hbDck72l3up1F__dY828hEFeYBdrQuhvu1xL9S48AJym_z_xxShEja32ZxoVZekLTSyOdfxBGy6U4yQjQmOsN-xk8R9rPI2u5eXUdgie59Vsni3AQFHdKkQDeVWict8yTwdZNVpzkzxn2uu6rZAiHMriBKStTpRRDIkIP209Z9POIdHf-kzh28JEgFLbuXcLwhIdA1hMzvVErISrysCDtqyxi9Gog4o9Z-KwKOkI6G7ZArk81H5ozGxWy4ETww`} }
        const url =
        ENDPOINTS.tutorial
        axios.get(
            url, axiosConfig)
        .then(res => {
            if(items === undefined){
                dispatch(tutorialSlice.actions.saveTutorialItems(res.data));
            }
            else{
                filterTutorialData(res.data)
            }

        }).catch(onError);
    }
    const onError = (error: any) => {
    }
    const filterTutorialData = (data:any) =>{
        const stateNewFeatures = data?.newFeatures && data?.newFeatures.map((v: tutorialDetails, index:number) =>{
            return{
                ...v,
                content: v.content?.map((content:tutorialContentDetails, contentIndex:number)=>{
                    if(items?.newFeatures){
                        const existing = items?.newFeatures.length-1 >= index &&
                        items?.newFeatures[index].content && items?.newFeatures[index].content?.filter((c:tutorialContentDetails)=>c.viewed === true
                        && c.title === content.title)
                        if(existing && existing.length > 0 && items?.newFeatures.length >= index){
                            return {
                                title: content.title,
                                viewed: true,
                                file: content.file
                            }
                        }else{
                            return {
                                title: content.title,
                                viewed: false,
                                file: content.file
                            }
                        }

                    }

                })
            }
        });
        const personalizedCompanion = data?.personalizedCompanion && data?.personalizedCompanion.map((v:tutorialDetails, index:number) =>{
            return{
                ...v,
                content: v.content?.map((content:tutorialContentDetails, contentIndex:number)=>{
                    if(items?.personalizedCompanion){
                        const existing =items?.personalizedCompanion.length-1 >= index &&
                        items?.personalizedCompanion[index].content && items?.personalizedCompanion[index].content?.filter((c:tutorialContentDetails)=>c.viewed === true
                        && c.title === content.title)
                        if(existing && existing.length > 0){
                            return {
                                title: content.title,
                                viewed: true,
                                file: content.file
                            }
                        }else{
                            return {
                                title: content.title,
                                viewed: false,
                                file: content.file
                            }
                        }

                    }

                })
            }
        });
        const useJourney = data?.useJourney && data?.useJourney.map((v:tutorialDetails, index:number) =>{
            return{
                ...v,
                content: v.content?.map((content:tutorialContentDetails, contentIndex:number)=>{
                    if(items?.useJourney){
                        const existing = items?.useJourney.length-1 >= index &&
                        items?.useJourney[index].content && items?.useJourney[index].content?.filter((c:tutorialContentDetails)=>c.viewed === true
                        && c.title === content.title)
                        if(existing && existing.length > 0){
                            return {
                                title: content.title,
                                viewed: true,
                                file: content.file
                            }
                        }else{
                            return {
                                title: content.title,
                                viewed: false,
                                file: content.file
                            }
                        }

                    }

                })
            }
        });
        const updateApp = data?.updateApp && data?.updateApp.map((v:tutorialDetails, index:number) =>{
            return{
                ...v,
                content: v.content?.map((content:tutorialContentDetails, contentIndex:number)=>{
                    if(items?.updateApp){
                        const existing = items?.updateApp.length-1 >= index &&
                         items?.updateApp[index].content?.filter((c:tutorialContentDetails)=>c.viewed === true && c.title === content.title)

                        if(existing && existing.length > 0){
                            return {
                                title: content.title,
                                viewed: true,
                                file: content.file
                            }
                        }else{
                            return {
                                title: content.title,
                                viewed: false,
                                file: content.file
                            }
                        }

                    }

                })
            }
        });
        const newState = {
            newFeatures: stateNewFeatures,
            personalizedCompanion: personalizedCompanion,
            updateApp: updateApp,
            useJourney: useJourney
        }
        dispatch(tutorialSlice.actions.saveTutorialItems(newState || {}));
    }
    const modalOnClose = () => {
        dispatch(sidebarSlice.actions.selectMenu({menu: undefined}));
        setShow(false);
    }
    const backButton = () =>{
        if(view === 'tutorialSection')
            dispatch(tutorialSlice.actions.saveViewMode('tutorialMain'));
        else
            dispatch(tutorialSlice.actions.saveViewMode('tutorialSection'));
    }
    const selectIcon = () => (experience === ExperienceType.SUN ? "-sun" : "")

    return (
        <Modal show={show} style={modalStyle} className={B()} onClose={() => modalOnClose()}
        hideCloseButton={view==='tutorialVideo'? true : false}>
                <div className={E('modal')}>
                    <div className={E('header')}>
                        {
                            view !== 'tutorialMain' && (
                                <Button
                                text={t('my_practice_my_back')}
                                type="secondary" weight="normal"
                                icon={`left-arrow-circle-gray${selectIcon()}`}
                                iconPosition="left"
                                className={view==='tutorialSection' ? '' : 'over-display'}
                                click={()=>backButton()}
                                />
                            )
                        }


                        {view === 'tutorialMain' && (<h3>{t('my_practice_my_tutorial')}</h3>) }
                    </div>
                    <div className={E('body')}>
                        {view === 'tutorialMain' ?  <TutorialMain></TutorialMain> :
                        <TutorialSection></TutorialSection>
                        }

                    </div>
                </div>
        </Modal>
    )
}

export default Tutorial;
